import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ReferLoginModal from "../components/Modal/ReferLoginModal";
import copy from "copy-to-clipboard";
import {
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import kalashIcon from "../assets/img/icons/kalash.png";
import email48 from "../assets/img/icons/icons-gmail.png";
import whatsapp48 from "../assets/img/icons/whatsapp-48.png";
import twitter48 from "../assets/img/icons/twitter-48.png";
import { SERVER_URL } from "../config";
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import { Helmet } from 'react-helmet';

const ReferAndEarn = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("usertoken");
  const [showReferAndEarn, setShowReferAndEarn] = useState(false);
  const [showLoginRefer, setShowLoginRefer] = useState(false);
  const page = window.location.pathname;
  const shareUrl = SERVER_URL;
  const code = useSelector((state) => state?.LoginReducer?.user?.promoCode);
  const title = `I have registered with Aakashganga, a new open access journals platform, built with love in India, for the world.
  As a researcher, I appreciate their vision of removing traditional barriers to publishing research, and democratizing knowledge through their automated digital Open Access Journals Platform to accelerate scientific progress. I invite you to join Aakashganga.
  Use the special invitation code: ${code} to register on the platform and publish your research. Their APCs are one of the lowest in the world, and their smart automated solutions fast track research publishing.`;

  const handleCodeCopy = (e) => {
    copy(code);
    e.target.textContent = "Copied";
    e.target.style.setProperty("color", "#404040");
  };

  useEffect(() => {
    if (page === "/refer-and-earn" && token) {
      setShowReferAndEarn(true);
    } else if (page === "/refer-and-earn" && !token) {
      setShowReferAndEarn(true);
      // setShowLoginRefer(true);
    }
  }, [page])

  return (
    <div className="tagbody">
       <Helmet>
          <title>Refer and Earn with Aakashganga Open</title>
         
     </Helmet> 
      <AakashgangaHeader />
        <h1 className="seo-related-tag">
          Refer and Earn
        </h1>
        <Modal
          show={showReferAndEarn}
          id="referModal"
          tabIndex={-1}
          className="md-x authorReferPopup"
          keyboard={false}
          backdrop="static"
        >
          <div>
            <div className="modal-content border-0 referContent">
              <div className="modal-header pb-0">
                <button
                  type="button"
                  className="btn-close"
                  onClick={(e) => {
                    setShowReferAndEarn(false);
                    navigate('/');
                  }}
                  aria-label="Close"
                />
                <h5 className="modal-title referText">Refer and Earn</h5>
                <p className="bigSubText">Invite 5 friends to register</p>
                <div className="mudraCount">
                  <span>
                    <b>Earn</b>
                    <br />
                    250 Mudras
                  </span>
                  <i>
                    <img src={kalashIcon} alt="#" />
                  </i>
                </div>
              </div>
              <div className="modal-body pt-0 pb-0">
                <div className="px-2 text-center shareModal">
                  <div className="referStep">
                    <ul>
                      <li>
                        <span>
                          <b className="icon-persons" />
                        </span>
                        Invite 5 friends to register
                      </li>
                      <li>
                        <span>
                          <b className="icon-register" />
                        </span>
                        Your friends register
                      </li>
                      <li>
                        <span>
                          <b className="icon-wallet" />
                        </span>
                        You receive 250 Mudras{" "}
                      </li>
                    </ul>
                  </div>
                  <p className="receiveText">
                    Your friends will receive 250 Mudras after registration.<br />
                    Log into your account to share the invite.
                  </p>
                  <div className="socialShare">
                    <small>Invite via</small>
                    <ul className="socialRefer">
                      <li>
                        <EmailShareButton
                          separator=" "
                          subject="Your friend has invited you to join Mission Aakashganga"
                          body={title}
                          url=" "
                          shareUrl={title}
                        >
                          <img src={email48} alt="#" />
                        </EmailShareButton>
                      </li>

                      <li>
                        <WhatsappShareButton
                          url={shareUrl}
                          title={title}
                          separator=" "
                          className="Demo__some-network__share-button"
                        >
                          <img src={whatsapp48} alt="#" />
                        </WhatsappShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          url={shareUrl}
                          title={title}
                          separator=" "
                          className="Demo__some-network__share-button"
                        >
                          <img src={twitter48} alt="#" />
                        </TwitterShareButton>
                      </li>
                    </ul>
                  </div>
                  <div className="divideLine">
                    <b>or</b>
                  </div>
                  <div>
                    <small>Refer using your unique code</small>
                    <div className="codeWrap">
                      <p id="#code" className="pr-color flex-fill">
                        {code?code:"Login to reveal the code"}
                      </p>
                      <p onClick={(e) => handleCodeCopy(e)} className="sr-color">
                        <b id="code" className="icon-copy sr-color" />
                        &nbsp;&nbsp;Copy Code
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer pt-0 pb-0">
                <div class="questionBox reddot">
                  <span>
                    Receiving 250 Mudras is conditional to 5 individuals
                    registering with us using your referral code
                  </span>
                  <span>
                    You will be able to check your reward in the “Mudrakosh”
                    section
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <ReferLoginModal
          showLoginRefer={showLoginRefer}
          setShowLoginRefer={setShowLoginRefer}
        />
      <AakashgangaFooter />
    </div>
  );
};

export default ReferAndEarn;
