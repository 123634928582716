import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import SubmitArticleModal from "../Modal/SubmitArticleModal";
import { isMobile } from 'react-device-detect';
import MobileModalAlert from "../Modal/MobileModalAlert";
const NewWays = () => {
  const [uploadArticle, setUploadArticle] = useState(false);
  const [mobileAlert, setMobileAlert] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("usertoken");
  return (
    <div className="ag_main_wrap ag_newways_wrap">
      <div className="container">
        <div className="row justify-content-between align-items-center pt-4">
          <div className="col-4 align-items-center aos-init upPosi" data-aos="fade-up">
            <div className="ag_heading_center_wrap afterNone">
              <h2 className="tagh2 text-left afterValue">
                9 Bold New Ways{" "}
                <span className="pt-1">
                  To Publ<span className="publish-i-dot">i</span>sh Your Research
                </span>
              </h2>
              <div className="newWayeDsk">
              <div className="ag_special_offer_btn">
              {!token  && (
                <Link
                to="javascript:;"
                  onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")}
                  className="ag_btn_red"
                >
                  Publish Your Article
                </Link>
              )}
              {token && (
                <Link
                onClick={() =>isMobile ? setMobileAlert(true):setUploadArticle(true)}
                  className="ag_btn_red"
                >
                  Publish Your Article
                </Link>
              )}
              <p className="tagp">
                Inclusive, Affordable And <i>Open For All</i>
              </p>
            </div>
            </div>
            </div>
          </div>
          <div
            className="col-12 downPosi ag_newways_wrap-main-col align-items-center aos-init"
            data-aos="fade-up"
          >
            <div
              className="image-keywords customNewWaypage"
            >
              <h3 className="tagh3">
                besides the nine headers, please add the following: Article
                Processing Charge, Low APC, Research Article, Indian research,
                Pre-Publishing Double anonymous Peer Review, Post Publishing
                Open Peer Review, Publishing Editors, Editorial Assessment, Open
                access, Journal Publishing, Society Publishing, Institute
                Journals, University Journals, Indian Nationals wanting to
                publish their research, Foreign nationals wanting to Publish
                their research, citations, impact factors, Grants to write
                research article, How to publish your research, automated
                digital platform, made in india research platform, similarity
                index check, plagiarism check, profanity check in research
                article, grammar and language check in research article, online
                submission for research article writing, online peer review
                reports, automated peer review allotment
              </h3>
            </div>
            <img
              className="desktop-image tagimg"
              src="assets/images/9boldways.jpg"
              alt="New Ways To Publish The Research On Aakashganga"
            />
            <img
              className="mobile-image tagimg"
              src="assets/images/9-bold-ways-mobile.png"
              alt="New Ways To Publish The Research On Aakashganga"
            />
            <div className="newWayeMobile">
            <div className="ag_special_offer_btn">
              {!token  && (
                <Link to="javascript:;"
                  onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")}
                  className="ag_btn_red"
                >
                  Publish Your Article
                </Link>
              )}
              {token && (
                <Link
                onClick={() =>isMobile ? setMobileAlert(true):setUploadArticle(true)}
                  className="ag_btn_red"
                >
                  Publish Your Article
                </Link>
              )}
              <p className="tagp">
                Inclusive, Affordable And <i>Open For All</i>
              </p>
            </div>
</div>
          </div>
        </div>
      </div>
      <SubmitArticleModal uploadArticle={uploadArticle} setUploadArticle={setUploadArticle} />
      <MobileModalAlert mobileAlert={mobileAlert} setMobileAlert={setMobileAlert} />
    </div>
  );
};

export default NewWays;
