import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import  "../assets/assets_html/scss/marketing.scss";
import MainBanner from "../components/home/MainBanner";
import SpecialLaunch from "../components/home/SpecialLaunch";
import NewWays from "../components/home/NewWays";
import Opportunities from "../components/home/Opportunities";
import Partners from "../components/home/Partners";
import PublishJournal from "../components/home/PublishJournal";
import SevenMegaJournals from "../components/home/SevenMegaJournals";
import Testimonials from "../components/home/Testimonials";
import UnlockKnowledge from "../components/home/UnlockKnowledge";
import NewsLetter from "../components/global/NewsLetter";
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import MobileModalAlert from "../components/Modal/MobileModalAlert";
import ApcCard from "../components/articleProcessingCharges/ApcCard";
import {isMobile} from 'react-device-detect';
/*eslint-disable*/
import "slick-carousel";
import SubmitArticleModal from "../components/Modal/SubmitArticleModal";
import { Helmet } from 'react-helmet';
function Home() {
  const getPage = new URLSearchParams(window.location.search).get("page") || "";
  const [uploadArticle, setUploadArticle] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [mobileAlert, setMobileAlert] = useState(false);
  const navigate = useNavigate();
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  const token = localStorage.getItem("usertoken");
  useEffect(() => {
    $(".slider-nav").slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: false,
      infinite: true,
      centerMode: true,
      centerPadding: "10px",
      asNavFor: ".slider-for",
      focusOnSelect: true,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 20000,
    });
    $(".slider-for").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: ".slider-nav",
      dots: false,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 20000,
    });
    var $slider = $(".slider-for");
    var imgcount = 10;
    if ($slider.length) {
      var currentSlide;
      var slidesCount;
      var sliderCounter = document.createElement("div");
      sliderCounter?.classList?.add("slider__counter");
      var updateSliderCounter = function (slick, currentIndex) {
        currentSlide = slick.slickCurrentSlide() + 1;
        slidesCount = slick.slideCount;
        if (slidesCount == null) {
          $(sliderCounter).text(currentSlide + "/" + imgcount);
        } else {
          $(sliderCounter).text(currentSlide + "/" + slidesCount);
        }
      };
      $slider.on("init", function (event, slick) {
        $slider.append(sliderCounter);
        updateSliderCounter(slick);
      });
      $slider.on("afterChange", function (event, slick, currentSlide) {
        updateSliderCounter(slick, currentSlide);
      });
      try {
        $slider.slick();
      } catch (ex) {
      }
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleContent = (type) => {
    const element = document.getElementById(`viewScroll${type}`);
    if (element) {
      element.scrollIntoView(0);
    }
  };

  useEffect(() => {
    handleContent(getPage);
  }, [getPage])

  return (
    <>
      <Helmet>
        <title>Aakashganga: India's First Discipline Specific Mega Journals and Open Access Hosting Platform</title>
          <meta
          name="description"
          content="Aakashganga | India's first Multidisciplinary Open Access Publishing Platform for Researchers & Independent Academic Publishers." />
          <meta property="og:title" content="Aakashganga: India's First Discipline Specific Mega Journals and Open Access Hosting Platform" data-react-helmet="true" />
          <meta property="og:description" content="Aakashganga | India's first Multidisciplinary Open Access Publishing Platform for Researchers & Independent Academic Publishers." data-react-helmet="true" />
          <meta property="og:url" content="https://www.aakashgangaopen.in/" data-react-helmet="true" />
          <link rel="canonical" href="https://www.aakashgangaopen.in/" data-react-helmet="true" />
          <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="tagbody">
      <AakashgangaHeader />
        <h1 className="seo-related-tag">
          An Indian Open Access Journal Platform for Research Dissemination
        </h1>
        {/* Banner Start */}
        <MainBanner />
        {/* Built With Start */}
        <div className="ag_main_wrap ag_builtwith_wrap">
          <div className="container">
            <div className="row align-items-center mt-5">
              <div className="col-12 align-items-center">
              <img src="assets/images/built-with-love.gif" className="m-auto d-block mw-100" alt="Build with love" />
                {/* <div className="ag_builtwith_video">
                  
                  <video autoPlay muted loop playsinline controls="false">
                    <source
                      src="assets/images/built-with-love.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* Our Partners Start */}
        <Partners />
        {/* Opportunities Start */}
        <div className="ag_main_wrap ag_opportunities_wrap">
          <div className="container">
            <div className="row justify-content-between align-items-center mt-5">
              <div className="col-12 align-items-center mb-3">
                <div className="ag_heading_center_wrap">
                  <h2 className="cus-title tagh2">
                  Publishing Resources and Gu<div className="custom-i-dot">i</div>del<div className="custom-i-dot">i</div>nes
                    
                  </h2>
                  
                </div>
              </div>
              <Opportunities />
            </div>
          </div>
        </div>
        {/* Special Launch Offer Start  */}
        <SpecialLaunch />
        
        {/* New Ways Start */}
        <NewWays  />
        {/* Mega Journals Start */}
        <div id={`viewScroll${"Seven-Mega-Journals"}`}>
          <SevenMegaJournals />
        </div>
        {/* Publish Your Journal Start */}
        <PublishJournal />
        {/* <!-- How Works Start --> */}
        <div className="ag_main_wrap ag_howworks_wrap mt-5">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-12 align-items-center aos-init"
                data-aos="fade-up"
              >
                <div id={`viewScroll${"Start-Your-Submission-Journey"}`} className="ag_heading_center_wrap">
                  <img src="assets/images/three-dots.png" alt="Publish Journal" />
                  <h2 className="tagh2">
                    How AAKASHGANGA Works?{" "}
                    <span>Publish Your Article In 6 Simple Steps</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="howitworks">
            <div className="">
              <div className="howitworksImg">
                <img src="assets/images/how-works.png" alt="How Aakashganga Works" />
              </div>
              <div className="ag_howworks_btn taga">
              {!token && (
                  <Link to="javascript:;"
                    onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")}
                    className="ag_btn_red"
                  >
                    Publish Your Article
                  </Link>
                )}
                {token && (
                  <Link
                    className="ag_btn_red"
                    onClick={() =>isMobile ? setMobileAlert(true):setUploadArticle(true)}
                  >
                    Publish Your Article
                  </Link>
                )}
                <p>
                  Inclusive, Affordable And <i>Open For All</i>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Resource Hub Start --> */}
        <div className="ag_main_wrap ag_resourcehub_wrap">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-12 align-items-center aos-init"
                data-aos="fade-up"
              >
                <div className="ag_heading_center_wrap">
                  <h2 className="tagh2">
                    Unlock Knowledge <b className="bold500">At Your Go-To Resource Hub</b>
                  </h2>
                </div>
              </div>
              <UnlockKnowledge />
            </div>
          </div>
        </div>
        {/* <!-- Warm Words Start --> */}
        <div className="ag_main_wrap ag_warmwords_wrap testimonial-slider pt-0 pb-0">
          <div className="container">
            <div
              className="col-12 align-items-center detail-slider-title aos-init"
              data-aos="fade-up"
            >
              <div className="ag_heading_center_wrap">
                <h2 className="tagh2">
                  Warm Words{" "}
                  <span>
                    That Insp<span class="testimonial-i-dot">i</span>re
                  </span>
                </h2>
              </div>
            </div>
            <div className="ag_main_wrapmain-col row justify-content-between align-items-center">
              <div className="col-xl-12 col-lg-12 col-sm-12">
                <div className="warm_slider ">
                  <div className="warm-slider-img slider-nav " >
                    <img
                      src="assets/images/Dr-Harivansh-Chaturvedi.jpg"
                      alt="Dr. Harivansh Chaturved " className="tagimg"
                    />
                    <img
                      src="assets/images/Dr-Amitabh-Ranjan.jpg"
                      alt="Dr Amitabh Ranjan Image" className="tagimg"
                    />
                    <img
                      src="assets/images/AMB-Rajiv-Bhatia.jpg"
                      alt="Amb Rajiv Bhatia" className="tagimg"
                    />
                    <img
                      src="assets/images/Dr-Bharat-Wakhlu.jpg"
                      alt="Dr. Bharat Wakhlu" className="tagimg"
                    />
                    <img
                      src="assets/images/Prof-Jagdish-Sheth.jpg"
                      alt="Prof. Jagdish Sheth" className="tagimg"
                    />
                    <img
                      src="assets/images/Ramesh-Gaur.jpg"
                      alt="Ramesh Gaur" className="tagimg"
                    />
                    <img
                      src="assets/images/Dr-Sadhana-Rout.jpg"
                      alt="Dr. Sadhana Rout" className="tagimg"
                    />
                    <img
                      src="assets/images/Dr-Bhimaraya-Metri.jpg"
                      alt="Dr. Bhimaraya Metri" className="tagimg"
                    />
                    <img
                      src="assets/images/Dr-Sanjay-Srivastava.jpg"
                      alt="Dr. SanjaySrivastava" className="tagimg"
                    />
                    <img
                      src="assets/images/Rajesh-Chandrashekaran.jpg"
                      alt="Rajesh Chandrashekaran" className="tagimg"
                    />
                    <img
                      src="assets/images/Dr-Asha-bhandarker.jpg"
                      alt="Asha Bhandarker" className="tagimg"
                    />
                  </div>
                  <div id={`viewScroll${"Testimonials"}`} className="warm-slider-des slider-for">
                    <Testimonials />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- NewsLetter Start --> */}
        <NewsLetter />
        <AakashgangaFooter  />
        <SubmitArticleModal uploadArticle={uploadArticle} setUploadArticle={setUploadArticle} />
        <MobileModalAlert mobileAlert={mobileAlert} setMobileAlert={setMobileAlert}/>
      </div>
    </>
  );
}
export default Home;