import React from "react";
import PropTypes from "prop-types";
import Select from 'react-select';
import "./index.scss";
function SelectComponent(Props) {
  const {
    handleChange,
    options,
    optionValues,
    name,
    selectedValue,
    disabled,
    label,
    labelClass,
    required,
    placeholder,
    activeWithoutValue,
    isSearchable=false,
    mandatory = false,
    mandatoryAsterisk = false
  } = Props;
  
  const optionsData = () => {
    
    return options.map((val, ind) => {
      return {
        value: !!optionValues ? optionValues[ind] : val,
        label: val
      }
    })
  }

  const selectClasses = "" // (selectedValue || activeWithoutValue) ? "form-select select-active" : "form-select select-disable"
  const handleFieldChange = (data) => {
    handleChange(data.value)
  }

  const selectedOptionValue = optionsData().find(a => a.value == selectedValue) || null;
  return (
    <div className="">
      {labelClass!=='none' &&<label className={labelClass}>{label} {mandatory && <span className="option-badge mandatory-span badge">Mandatory</span>} {mandatoryAsterisk && <small className="input-required">*</small>} </label>}
      <Select
        classNamePrefix="reactSelect" 
        placeholder={placeholder || "Select"}  
        required={required}
        isDisabled={disabled}
        className={'reactSelect-container ' + selectClasses}
        onChange={handleFieldChange}
        name={name}
        isSearchable={isSearchable}
        options={optionsData()}
        value={selectedOptionValue}
        multiple={false}
      />
    </div>
  );
}

SelectComponent.propTypes = {
  handleChange: PropTypes.func,
  selectedvalue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  selectedValue: PropTypes.string,
  disabled: PropTypes.bool,
  labelClass: PropTypes.string,
  label: PropTypes.string,
  required:PropTypes.oneOfType([PropTypes.string,PropTypes.bool]),
  placeholder: PropTypes.string,
  activeWithoutValue: PropTypes.bool,
};

SelectComponent.defaultProps = {
  handleChange: () => null,
  selectedvalue: "",
  options: [],
  name: "",
  selectedValue: "",
  disabled: false,
  labelClass: "hidden",
  label: "label",
  required: false,
  placeholder: "",
  activeWithoutValue: false,
};

export default SelectComponent;
