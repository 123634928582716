import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {FetchArticleById} from "../submitArticle/queries";
import ViewHeaderLink from "../../../components/viewArticle/headerLink";
import Authors from "../../../components/viewArticle/authors";
import PublishedDate from "../../../components/viewArticle/publishedDate";
import ArticleLinks from "../../../components/viewArticle/articleLinks";
import TableOfContent from "../../../components/viewArticle/tableOfContent";
import LeftCommonLink from "../../../components/viewArticle/leftCommonLink";
import ContentDetails from "../../../components/viewArticle/contentDetails";
import copy from "../../../assets/img/icons/copy.svg";
import src from "../../../assets/img/sample/coverImage.png";
import infoI from "../../../assets/img/icons/infoI.svg";
import copyC from "../../../assets/img/icons/copyC.svg";
import downloadImg from "../../../assets/img/sample/demo001.png";
import active_info from "../../../svg/active-info.svg";
import { checkEmptyArticle } from "../../../utils";

function perviewArticle() {
    const router = useLocation();
    const [articleDetails, setarticleDetails] = useState({fundingInfo:'',authorContributer:'',statements:{versionOfManuscript:'',commercialOrfinancialConflictOfInterest:''}, id:'',abbreviations:[],abstract:'',articleData:{acknowledgement:'',articleSection:[],references:''},articleViewCount:0,authorDetails:{author:[],contributionStatement:'',notThePrimaryAuthor:false},citations:[],formulae:[],fundingDetails:{funder:[],fundingInformation:'',noFundingRecevied:true},images:[],keywords:[],reviewType:{isRecommendReviewers:false,paymentDone:false,suggestedReviewers:[],typeOfReview:''},runningTitle:'',submissionDetail:{articleType:{},journalName:"",journalType:{},societyName:"",specialization:{},subject:{subjectName:''}},supplymentaryMaterial:[],title:'', masthead: "" });
    const sucessHandler = (articleData) => {
        if (articleData.status === 200) {
            const article=articleData.data.data;
            const journalData = articleData?.data?.journalData;
            const articleDetail=articleDetails;
            articleDetail.id=article?._id;
            if (journalData?.masthead) {
                articleDetail.masthead = journalData?.masthead;
            }
            if(article?.fundingDetails.fundingInformation!=='')
            articleDetail.fundingInfo=article?.fundingDetails.fundingInformation;
            if(article?.authorDetails.contributionStatement!=='')
            articleDetail.authorContributer=article?.authorDetails.contributionStatement;
            if(article?.statements.commercial.commercialOrfinancialConflictOfInterest==='1')
            articleDetail.statements.commercialOrfinancialConflictOfInterest='The authors declare that the research was conducted in the absence of any commercial or financial relationships that could be construed as a potential conflict of interest.';
            else if(article?.statements.commercial.commercialOrfinancialConflictOfInterest==='2')
            articleDetail.statements.commercialOrfinancialConflictOfInterest=article?.statements.commercial.text;
            if(article?.statements.AakashGangaJournal.versionOfManuscript==='no')
            articleDetail.statements.versionOfManuscript=''
            else if(article?.statements.AakashGangaJournal.versionOfManuscript!=='no')
            articleDetail.statements.versionOfManuscript=article?.statements.AakashGangaJournal.text
            if(article?.abbreviations)
            articleDetail.abbreviations=article?.abbreviations;
            if(article?.abstract)
            articleDetail.abstract=article?.abstract;
            if(article?.articleData?.acknowledgement)
            articleDetail.articleData.acknowledgement=article?.articleData?.acknowledgement;
            if(article?.articleData?.articleSection)
            articleDetail.articleData.articleSection=article?.articleData?.articleSection;
            if(article?.articleData?.references)
            articleDetail.articleData.references=article?.articleData?.references;
            if(article?.articleViewCount)
            articleDetail.articleViewCount=article?.articleViewCount;
            if(article?.authorDetails?.author)
            articleDetail.authorDetails.author=article?.authorDetails.author;
            if(article?.authorDetails?.contributionStatement)
            articleDetail.authorDetails.contributionStatement=article?.authorDetails.contributionStatement;
            if(article?.authorDetails?.notThePrimaryAuthor)
            articleDetail.authorDetails.notThePrimaryAuthor=article?.authorDetails.notThePrimaryAuthor;
            if(article?.citations)
            articleDetail.citations=article?.citations;
            if(article?.formulae)
            articleDetail.formulae=article?.formulae;
            if(article?.fundingDetails?.funder)
            articleDetail.fundingDetails.funder=article?.fundingDetails.funder;
            if(article?.fundingDetails?.fundingInformation)
            articleDetail.fundingDetails.fundingInformation=article?.fundingDetails.fundingInformation;
            if(article?.fundingDetails?.noFundingRecevied)
            articleDetail.fundingDetails.noFundingRecevied=article?.fundingDetails.noFundingRecevied;
            if(article?.images)
            articleDetail.images=article?.images;
            if(article?.keywords)
            articleDetail.keywords=article?.keywords;
            if(article?.reviewType?.isRecommendReviewers)
            articleDetail.reviewType.isRecommendReviewers=article?.reviewType.isRecommendReviewers;
            if(article?.reviewType?.paymentDone)
            articleDetail.reviewType.paymentDone=article?.reviewType.paymentDone;
            if(article?.reviewType?.suggestedReviewers)
            articleDetail.reviewType.suggestedReviewers=article?.reviewType.suggestedReviewers;
            if(article?.reviewType?.typeOfReview)
            articleDetail.reviewType.typeOfReview=article?.reviewType.typeOfReview;
            if(article?.runningTitle)
            articleDetail.runningTitle=article?.runningTitle;
            if(article?.submissionDetail?.articleType)
            articleDetail.submissionDetail.articleType=article?.submissionDetail.articleType;
            if(article?.submissionDetail?.journalName)
            articleDetail.submissionDetail.journalName=article?.submissionDetail.journalName;
            if(article?.submissionDetail?.journalType)
            articleDetail.submissionDetail.journalType=article?.submissionDetail.journalType;
            if(article?.submissionDetail?.societyName)
            articleDetail.submissionDetail.societyName=article?.submissionDetail.societyName;
            if(article?.submissionDetail?.specialization)
            articleDetail.submissionDetail.specialization=article?.submissionDetail.specialization;
            if(article?.submissionDetail?.subject)
            articleDetail.submissionDetail.subject=article?.submissionDetail.subject;
            if(article?.supplymentaryMaterial)
            articleDetail.supplymentaryMaterial=article?.supplymentaryMaterial;
            if(article?.title)
            articleDetail.title=article?.title;
            setarticleDetails({...articleDetail})
        }
    }
    const { mutate: getArticleById } = FetchArticleById(sucessHandler);

    useEffect(() => { 
        const articleID = router.pathname.split("/")[2]; 
       getArticleDetail(articleID);
    }, []);  

async function getArticleDetail(id) {
    //
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      params: { articleId: id },
      headers: headers,
    };

    getArticleById(queryParams);
  }
    const [collapseActive, setIsActive] = useState(false);

    const [historyResult, setHistoryResult] = useState(false);
    const [imageDownload, setimageDownload] = useState(false);

    function getAuthors()
     {
        let  authors=[]
        if(articleDetails.authorDetails.author)
        {
            authors= articleDetails.authorDetails.author;
        }
        return authors
    }
    const handleClick2 = (event) => { 
        const target = event.target;
            if (target.tagName.toLowerCase() === 'a') {
              // event.preventDefault();
              const hrefValue = target.getAttribute('href').substring(1);
              // console.log(hrefValue);
                const targetElement = document.getElementById(hrefValue);
                // console.log(targetElement.parentNode.parentNode);
                if (targetElement) {
                    targetElement.parentNode.parentNode.classList.add('dshighlight');
                    setTimeout(() => {
                      targetElement.parentNode.parentNode.classList.remove('dshighlight');
                    }, 3000);
                }
            }
      }
    
      useEffect(() => { 
    
        document.addEventListener('click', handleClick2);
     
        // Clean up the event listener when the component unmounts
        // return () => {
        //     document.querySelectorAll('a').forEach(link => {
        //         link.removeEventListener('click', function(event) {
        //             // Your click event handler logic
        //         });
        //     });
        // };
    }, []);
    function getTableOfContent()
     {
        let  content=['Abstract']
        const regex = /(<([^>]+)>)/gi;

        if(articleDetails.articleData.articleSection.length>0)
        {
            articleDetails.articleData.articleSection.forEach((article, k) => {
             let images = article.content.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/i);
            if(article.content.replace(regex, "")!=='' || images)
            content.push(article.title);
            
            });
        }
        let akimages =articleDetails.articleData.acknowledgement.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/i);
        if(articleDetails.articleData.acknowledgement.replace(regex, "")!=='' || akimages)
        content.push('Acknowledgements');
        if(articleDetails.articleData.references.replace(regex, "")!=='')
        content.push('References');
        return content
    } 
    function getNameFromUrl(url)
    {
      let name='';
      const pieces = url.split(/[\s/]+/)
      const last = pieces[pieces.length - 1]
      if(last)
      {
        const dtl=last.split('_');
        if(dtl[1])
        name=dtl[1].replace(/%20/g, " ")
        else
        name=last.replace(/%20/g, " ")
      }
      
      return name
    }

    return <>

        
        <div className="inner-pages">
            <div className="centerSection">
                <div className="previewPageWrap w-100">
                    <div className="editorHeadMain">
                        <div className="editorHead">
                            <div className="editorHeadLeft">
                                <h2>Preview Article</h2>
                            </div>
                            <div className="editCenterWrap">
                                <b className="blinkText"></b>
                            </div>
                            <div className="text-right">
                                <div className="articleRightBtn">
                                <Link
                              to={`/dashboard/edit-article/${articleDetails.id}`}
                              
                              className="button-link"
                            >
                              BACK TO EDITOR
                            </Link>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="previewHero mt-0" style={{
                        backgroundImage: `url()`,
                        backgroundSize: `100%`,
                    }}>
                        <img src={articleDetails?.masthead ? articleDetails?.masthead : src}  style={{width: `100%` }}  />
                    </div>

                    <div className="previewTags">
                       <ViewHeaderLink pagename='previewArticle' details={{first:'',second:articleDetails.submissionDetail.societyName,fourth:articleDetails.submissionDetail.articleType.articleName}}/>
                    </div>

                    <div className="publiseTitle">
                        <div className="innerMergin">{articleDetails.title}</div>
                    </div>
                    <Authors pagename='previewArticle' details={getAuthors()}/>
                    <PublishedDate pagename='previewArticle' details=''/>
                    
                     
                    <div className="publisActionWrap">
                    <div className="innerMergin">
                    <ArticleLinks pagename='previewArticle' details='' journalName={articleDetails?.submissionDetail?.journalName}/>
                    </div>
                    </div>
                    <div className="previewMain">
                    <div className="innerMergin">
                    
                        <div className="row">
                            <div className="col-md-4">
                                <div className="mt-3">

                                   <TableOfContent pagename='previewArticle' details={getTableOfContent()}/>

                                   {articleDetails.keywords.length>0 && <div className="keyBox previewKey commanBox"> 
                                   <span class="tagTitle">Keyword</span>
                                    <div className="previewKeyArea">
                                        {articleDetails.keywords.map((keyword, index) => (
                                            <span key={keyword} className="t-tag">
                                            {keyword}
                                            
                                            </span>
                                        ))}
                                        </div>
                                    </div>  }
                                    {articleDetails.abbreviations.length>0 && <div className=" commanBox abbreviations mb-4 d-inline-block w-100">
                                        <span class="tagTitle">Abbreviations</span>
                                        {articleDetails.abbreviations.map((abbreviation, index) => (
                                            <span key={abbreviation} className="t-tag abbreView">
                                            <b>{abbreviation.apprv}</b><b>{abbreviation.fullForm}</b>
                                            
                                            </span>
                                        ))}
                                    </div>}
                                    {articleDetails.images.length>0 && <div className="commanBox mb-25">
                                        <span className="tagTitle">Images and Figures
                                        <div className="customTool">
                                                {["bottom"].map((placement) => (
                                                    <OverlayTrigger
                                                    key={placement}
                                                    placement={placement}
                                                    overlay={
                                                        <Popover>
                                                        <Popover.Body>
                                                            <div className="popoverToolWrap">
                                                            <p>We require figures to be submitted individually, in the same order as they are presented in the manuscript. Kindly ensure that each table and figure is mentioned in the text and in numerical order.</p>
                                                            </div>
                                                        </Popover.Body>
                                                        </Popover>
                                                    }
                                                    >
                                                    <img
                                                        className="pe-cursor ps-1"
                                                        src={active_info}
                                                        alt="no img"
                                                    />
                                                    </OverlayTrigger>
                                                ))}
                                                </div></span>
                                        <div className="imgShowBox">
                                        {articleDetails.images.map((src, index) => (
                                            <b
                                            key={src}
                                            style={{
                                                backgroundImage: `url(${src})`,
                                            }}
                                            ></b>
                                        ))}
                                        </div>
                                    </div>}
                                    {articleDetails.supplymentaryMaterial.length>0 &&  <div className="commanBox mb-25">
                                        <span className="tagTitle">Supplementary Material <div className="customTool">
                                                {['bottom'].map((placement) => (
                                                    <OverlayTrigger 
                                                    key={placement}
                                                    placement={placement}
                                                    overlay={
                                                        <Popover>
                                                        <Popover.Body>
                                                        <div className="popoverToolWrap"> 
                                                        <p>
                                                                Please note that supplementary material is not typeset. Kindly ensure that all information is clearly presented. 
                                                            
                                                            </p><br/>
                                                            <p>
                                                                1.	Please use a simple file name with formate .pdf, .doc, .docx, .xlsx, .pptx or .rtf.
                                                            </p>
                                                            <p>
                                                                2.	Add appropriate caption in the supplementary file including all highlights, line numbers. 
                            
                                                            </p>
                                                            <p>
                                                            3.	Remove all the track changes and ensure that the style conforms to the rest of the article.
                                                            </p>
                                                            </div>
                                                        </Popover.Body>
                                                        </Popover>
                                                    }
                                                    >
                                                    <img
                                                        className="pe-cursor ps-1"
                                                        src={active_info}
                                                        alt="no img"
                                                        /> 
                                                    </OverlayTrigger>
                                                ))}
                                            </div> </span>
                                        
                                        {articleDetails.supplymentaryMaterial.map((img, index) => (
                                        <div className="docShowBox supplimentPreview" key={`mat${index}`}> 
                                        <div className="docShowList">
                                        <a >
                                        <span>{getNameFromUrl(img)}</span>
                                        
                                        </a> 
                                        </div>
                                        </div>
                                        ))}
                                        
                                    </div>}
                                   <LeftCommonLink pagename='previewArticle' details={{img:infoI,text:'Information, Rights and Permissions', type: 'Information'}}/>
                                   <LeftCommonLink pagename='previewArticle' details={{img:copyC,text:'Copyright and License', type: 'Copyright'}}/>
                                   
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="publiceUiBox ">
                                    <div className="publiceDetailsWrap">
                                        
                                    </div>
                                    <div className="previewBox previewBox se-wrapper-inner">
                                        <ContentDetails pagename='previewArticle' details={{title:'Abstract',desc:articleDetails.abstract}}/>
                                        {articleDetails.articleData.articleSection.map((article, index) => (
                                        <>{checkEmptyArticle(article?.content)!=='' && <ContentDetails pagename='previewArticle' details={{title:article.title,desc:article.content}}/>}</> 
                                        ))}
                                        {checkEmptyArticle(articleDetails.authorContributer)!=='' && <ContentDetails pagename='previewArticle' details={{title:'Author Contributions',desc:articleDetails.authorContributer}}/>}
                                        {checkEmptyArticle(articleDetails.fundingInfo)!=='' && <ContentDetails pagename='previewArticle' details={{title:'Funding Information',desc:articleDetails.fundingInfo}}/>}
                                        {checkEmptyArticle(articleDetails.statements.commercialOrfinancialConflictOfInterest)!=='' && <ContentDetails pagename='previewArticle' details={{title:'Conflict of Interest',desc:articleDetails.statements.commercialOrfinancialConflictOfInterest}}/>}
                                        {checkEmptyArticle(articleDetails.articleData.references)!=='' && <ContentDetails pagename='previewArticle' details={{title:'References',desc:articleDetails.articleData.references}}/>}
                                        {checkEmptyArticle(articleDetails.articleData.acknowledgement)!=='' && <ContentDetails pagename='previewArticle' details={{title:'Acknowledgements',desc:articleDetails.articleData.acknowledgement}}/>}
                                        
                                        

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                                            </div>

                </div >

               
            </div>
        </div>



        <Modal
            show={historyResult}
            size="md md-x"
            id="unicheck-results__modal"
            tabIndex={-1}
            dialogClassName="modal-dialog-centered authorModal"
            className="md-x"
        >
            <Modal.Header>
                <h5 className="modal-title pr-color">how to cite this article</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={(e) => setHistoryResult(false)}
                    aria-label="Close"
                />
            </Modal.Header>
            <Modal.Body className="pt-0 pb-2">
                <div className="citeModalWrap">
                    <div className="citeText">[ Ayoubi R, Fotouhi M, Southern K et al. The identification of high antibodies for Vacuolar protein sorting-associated protein 35 (hVPS35) for use in Western Blot, immunoprecipitation and immunofluorescence [version 1; peer review: awaiting peer review]. Aakashganga 2023, 12:452 (https://doi.org/10.12688/Aakashganga.133696.1) ]</div>

                    <div className="questionBox">
                        <span>It is important to ensure the information in square brackets after the title is included in all citations of this article.</span>
                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer className="modal-footer pb-3">

                <button
                    className="button-link"
                    onClick={(e) => handleRedirect(e, "/myArticle/submit")}
                >
                    <img src={copy} alt="#" /> Copy Citation Details
                </button>
            </Modal.Footer>

        </Modal>


        <Modal
            show={imageDownload}
            size="lg"
            id="unicheck-results__modal"
            tabIndex={-1}
            dialogClassName="modal-dialog-centered authorModal"

        >
            <Modal.Header className="imgDownloadHeader">
                <h5>figure 1</h5>
                <div className="imgDownloadBtn">
                    <button
                        type="button"
                        className="btn-close"
                        onClick={(e) => setimageDownload(false)} 
                    >close</button>
                    <span className="button-link">download image</span>
                </div>
            </Modal.Header>
            <Modal.Body className="pt-0 pb-2">
                <div className="imageDownloadWrap">
                    <figure>
                        <img src={downloadImg} alt="#" />
                    </figure>
                    <span>CT images of spondyloptosis at various anatomical levels of spine</span>
                    <p>Embryology in development of the caudal region. Drawing of a 16-day embryo (left) shows the primitive streak, which is a longitudinal ridge of ectodermal cells at the caudal end of the bilaminar embryonic disc. Drawing of a 20-day embryo  (middle) demonstrates that primitive regression. Embryology in development of the caudal region.</p>
                </div>

            </Modal.Body>


        </Modal>



    </>

}
export default perviewArticle


