import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Pagination } from "semantic-ui-react";
import SocietyJournalImg from "../assets/assets_html/images/society homepage.png";
import pg01 from "../assets/assets_html/images/perv_arrow.png";
import pg02 from "../assets/assets_html/images/next_arrow.png";
import NewsLetter from "../components/global/NewsLetter";
import JournalMainBanner from "../components/journals/JournalMainBanner";
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import {
  getJournalDetailById,
  getPEForJournalHomePage,
} from "../store/apiCalls/dashboard";
import { getJournalSubjects } from "../store/apiCalls/articles";
import SubmitArticleModal from "../components/Modal/SubmitArticleModal";
import { toast } from "react-toastify";

const PublishEditor = () => {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const location = useLocation();
  const journalName = location?.state?.journalName;
  const journalId = location?.state?.journalId;
  // console.log("journalId",id);
  const token = localStorage.getItem("usertoken");
  const navigate = useNavigate();
  const limit = 10;
  const [page, setPage] = useState(1);
  const societyJournal = location?.state?.societyJournal;
  const [specializationOptions, setSpecializationOptions] = useState([]);
  const [publishEditorData, setPublishEditorData] = useState([]);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [uploadArticle, setUploadArticle] = useState(false);
  const [activeType, setActiveType] = useState("pEditors");
  let totalPages = Math.ceil(publishEditorData?.totalCount / limit);

  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const [journalData, setJournalData] = useState([]);
  const [filteredData, setFilteredData] = useState(specializationOptions);

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = specializationOptions.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );
    const specSearch = [...filtered];
    setFilteredData(specSearch);
  };

  const toggleSearchDropdown = () => {
    setShowSearchDropdown(!showSearchDropdown);
  };
  
  const getJournalDetail = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      journalId,
    };
    const response = await getJournalDetailById(headers, params);
    if (response?.status === 200) {
      setJournalData(response?.data?.data);
      getAllSubject(response?.data?.data?.journalName);
    } else return;
  };
  const handleScrollTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const getAllSubject = async (decId) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      params: { journalName: decId },
      headers: headers,
    };
    const response = await getJournalSubjects(queryParams);
    if (response?.status === 200) {
      let newRecord = response?.data?.data?.specilisation;
      if (decId !== "AyurYoga – The Mega Journal of Ayurveda and Yoga") {
        let specOpt = newRecord
          ?.filter(
            (spec) =>
              spec?.specialization !==
              "Other Subject (choose this option to register your subject)"
          )
          .map((spec) => spec?.specialization);
        setSpecializationOptions(specOpt);
        setFilteredData(specOpt);
      } else {
        let specOpt0 = newRecord?.[0]
          ?.filter(
            (spec) =>
              spec?.specialization !==
              "Other Subject (choose this option to register your subject)"
          )
          .map((spec) => spec?.specialization);

        let specOpt1 = newRecord?.[1]
          ?.filter(
            (spec) =>
              spec?.specialization !==
              "Other Subject (choose this option to register your subject)"
          )
          .map((spec) => spec?.specialization);
        const children = specOpt0.concat(specOpt1);
        setSpecializationOptions(children);
        setFilteredData(children);
      }
    } else {
      return toast.error("Please try again!!");
    }
  };

  const getPublishingEditorForJournal = async () => {
    dispatch({ type: "SET_LOADER", payload: true });
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      journal: journalName,
      page: 1,
      limit,
    };
    const response = await getPEForJournalHomePage(headers, params);
    dispatch({ type: "SET_LOADER", payload: false });
    if (response?.status === 200) {
      setPublishEditorData(response?.data?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: true });
      return toast.error("Please try again");
    }
  };

  const handlePageChange = (data) => {
    return setPage(data?.activePage);
  };

  const userDetails = (user) => {
    const info = {
      __html: `<b>${user?.title}</b>` + " " + `<b>${user?.fullName},</b>` + `<span> ` + user?.affiliation?.affiliations?.[0]?.name + `</span>`
    }
    // console.log(user);
    return info; 
  }

  useEffect(() => {
    if (journalId) {
      getJournalDetail();
    } return;
  }, [journalId]);

  useEffect(() => {
    if (journalName) {
      getPublishingEditorForJournal();
    } return;
  }, [journalName, page]);

  return (
    <>
      <div className="tagbody">
        <AakashgangaHeader />
        <JournalMainBanner
          imgUrl={
            societyJournal === "Society Journal"
              ? SocietyJournalImg
              : journalData?.masthead
          }
        />
        <div className="menu-center-part">
          <div className="journalShadow">
            <div className="ag_menu justify">
              <ul className="ag_menu ag_menuSub justify tagul">
                {societyJournal === "Society Journal" ? null : (
                  <li className="tagli">
                    <a
                      href="#"
                      class="custom-journal taga"
                      onClick={toggleSearchDropdown}
                    >
                      Disciplines <img src="/assets/images/downarrow.png" alt="Down Arrow" />
                    </a>

                    {showSearchDropdown && (
                      <div className="tagliSubNave">
                        <div className="disciplinesbg">
                          <div class="form-group has-search">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input
                              type="text"
                              class="form-control input30"
                              placeholder="Type to search"
                              value={searchQuery}
                              onChange={(e) => handleSearch(e.target.value)}
                            />
                          </div>
                          <ul className="disciplinesscroll">
                            {filteredData.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </li>
                )}

                <li className="active tagli custom-journal">
                  <Link
                    to="/open-access"
                    onClick={(e) => handleRedirect(e, "/open-access")}
                    className="custom-journal taga"
                  >
                    OPEN SCIENCE
                  </Link>
                </li>

                <li>
                  <a href="javascript:;" class="custom-journal taga">
                    E-ISSN{" "}
                    <span className="colr">
                      {" "}
                      {""}
                      {societyJournal === "Society Journal"
                        ? "XXXX-XXXX"
                        : journalData?.issnNumber}
                    </span>
                  </a>
                </li>
                <li>
                  <a href="javascript:;" class="custom-journal taga">
                    ABBREVATION{" "}
                    <span className="colr">
                      {" "}
                      {""}
                      {societyJournal === "Society Journal"
                        ? "XXXXX"
                        : journalData?.abbreviation}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="editorContainer">
            <div className="editorPage">
              <span className="editorHead">Publishing Editors</span>
              <div className="journalBackWrap ">
                <span>Journal Homepage</span>
                {!token ? (
                  <Link
                    to="/login"
                    onClick={(e) => {
                      e.preventDefault();
                      handleRedirect(e, "/login")
                    }}
                    className="ag_btn_red ag_btn_redclr"
                  >
                    Submit Article
                  </Link>
                ) : (
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setUploadArticle(true)
                    }}
                    className="ag_btn_red ag_btn_redclr"
                  >
                    Submit Article
                  </Link>
                )}
              </div>
            </div>

            {/* <div className="allEditor">
              <span className="active">All Editors</span>
            </div> */}
            <div className="editorRevisionWrap  maindiv p-0">
              <ul className="div70 list-inline" style={{lineHeight:1.8}}>
            {publishEditorData?.data?.length > 0 ? (
                  publishEditorData.data.map((item, ind) => (
                    Array.isArray(item.user) && item.user.length > 0 && item.user[0]?.fullName ? (
                      <li key={ind} >
                      <p className="editor_item" dangerouslySetInnerHTML={userDetails(item?.user?.[0])} />
                      </li>
                    ) : (
                      <></>
                    )
                  ))
                ) : (
                  <div className="noDataPublic"><h2>No Publishing Editors Data Found</h2></div>
                )}
              </ul>
              <div className="div30  ">
              <div className="journalslist custom-journalist-class">
                <div className=" align-items-center">
                  <div className="ag_heading_center_wrap our-partner mt-0 info-custom">
                    <h2 className="tagh2">Information</h2>
                  </div>
                </div>
                <ul className="journalInfoList">
                  <li class="custom-li-journal tagli">
                    <Link
                       to={`/mega-journals/${id}`}
                      className={activeType === "description" ? "active" : null}
                      onClick={() => setActiveType("description")}
                    >
                      Description
                    </Link>
                  </li>
                  <li class="custom-li-journal tagli">
                    <Link
                      to={"/access-articles"}
                      className={
                        activeType === "access-articles" ? "active" : null
                      }
                      onClick={() => setActiveType("access-articles")}
                    >
                      Access Articles
                    </Link>
                  </li>

                  <li class="custom-li-journal tagli">
                    <Link
                      to="/author-submission-guidelines"
                      target="_blank"
                      className={
                        activeType === "author-guidelines" ? "active" : null
                      }
                    >
                      Author Guidelines
                    </Link>
                  </li>

                  
                    <li class="custom-li-journal tagli">
                      <Link
                      className={activeType === "pEditors" ? "active" : null}
                        to={`/explore-journals/${id}/publishing-editor`}
                        state={{ journalName: journalData?.journalName, journalId: journalData?._id }}
                        onClick={handleScrollTop}
                      >
                        Publishing Editors
                      </Link>
                    </li>
                 

                  {societyJournal === "Society Journal" ? null : (
                    <li class="custom-li-journal tagli">
                      <Link
                        to="/how-we-publish"
                        target="_blank"
                        className={
                          activeType === "publishing-model" ? "active" : null
                        }
                      >
                        Publishing Model
                      </Link>
                    </li>
                  )}

                  <li class="custom-li-journal tagli">
                    <Link
                      className={
                        activeType === "content-type" ? "active" : null
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveType("content-type");
                        setShow(true);
                      }}
                    >
                      Content Type
                    </Link>
                  </li>
                  <li class="custom-li-journal tagli">
                    <Link
                      className={activeType === "recommend" ? "active" : null}
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveType("recommend");
                        setShowRec(true);
                        setModalPageName(pageName);
                        setJournalModalName(journalName);
                      }}
                    >
                      Recommend
                    </Link>
                  </li>
                  <li class="custom-li-journal tagli">
                    <Link
                      className={activeType === "advertising" ? "active" : null}
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveType("advertising");
                        setShowAdvertising(true);
                      }}
                    >
                      Advertise With Us
                    </Link>
                  </li>
                  <li class="custom-li-journal tagli">
                    <Link
                      to="/journal/get-in-touch"
                      target="_blank"
                      className={
                        activeType === "get-in-touch" ? "active" : null
                      }
                    >
                      Get in Touch
                    </Link>
                  </li>
                </ul>
              </div>
              
            </div>


            </div>
            {totalPages > 1 && (
              <div className="editorPagi">
                <span>
                  <img
                    src={pg01}
                    onClick={(event, data) => handlePageChange(data)}
                  />
                  PREVIOUS
                </span>
                <div className="numberWrap">
                  <div className="paginationWrap">
                    <Pagination
                      boundaryRange={1}
                      firstItem={null}
                      lastItem={null}
                      siblingRange={1}
                      activePage={<b className="active">{page}</b>}
                      onPageChange={(event, data) => handlePageChange(data)}
                      totalPages={totalPages}
                    />
                  </div>
                </div>
                <span>
                  Next
                  <img
                    src={pg02}
                    onClick={(event, data) => handlePageChange(data)}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
        <NewsLetter />
        <SubmitArticleModal
          uploadArticle={uploadArticle}
          setUploadArticle={setUploadArticle}
        />
        <AakashgangaFooter />
      </div>
    </>
  );
};

export default PublishEditor;
