import React, { Suspense } from "react";
import {
  useLocation,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
 Outlet,
} from "react-router-dom";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { useDispatch, useSelector } from "react-redux";
import { PATH } from "./path";
import {
  protectedRouteNavigators,
  publicRouteNavigators,
  allUserRouteNavigators,
} from "./routeNavigator";

import Header from "../layout/header";
import { getAllMasterData } from "../store/apiCalls/fetchmaster";
import { LOGOUT_STATUS_SUCCESS } from "../store/actions/loginActions";
import { FetchMasterData } from "../fetchMasterData";
import { GET_MASTERDATA_DETAILS } from "../store/actions/masterDataActions";
import ScrollToTop from "../components/common/ScrollToTop";
 import AakashgangaFooter from "../components/global/FooterComponent";
 /*eslint-disable*/
 import AakashgangaHeader from "../components/global/HeaderComponent";

function checkWriteArticlePage()
{
    let isArticleWritePage=false;
    const router=useLocation()
    if(router.pathname.includes('/personaldetails') || router.pathname.includes('/previewprofile') || router.pathname.includes('/manage-payment') || router.pathname.includes('/dashboard/edit-article') || router.pathname.includes('/dashboard/submit-article'))
    isArticleWritePage=true;
    return isArticleWritePage
}
function checkTokenExpairation()
{
    const dispatch = useDispatch();
    const currentTime = new Date().getTime();
    if(!localStorage.tokenExpireTime)
    return false;
    if(currentTime>=localStorage.tokenExpireTime)
    {
      localStorage.clear();
      dispatch({ type:LOGOUT_STATUS_SUCCESS });
     return false;
    }
  return true;
}

function redairectDashboard()
{
  const getPEType = localStorage.getItem('getPEType');
  const { user } = useSelector(
    (state) => state?.LoginReducer
  );

  if(user?.role==='Publish Editor' || getPEType === "PE")
  {
    localStorage.setItem("dropdownToggleVal", "PublishingEditor");
    if (getPEType === "PE") {
      return "/dashboard/publishing-editor?type=publisheditor";
    } else {
      return PATH.AAKASHGANGA_HOMEPAGE;
    }
  }
  
  else if(user?.role==='Peer Reviewer')
  {
    localStorage.setItem("dropdownToggleVal", "Author");
    return PATH.AAKASHGANGA_HOMEPAGE;
  }
   else
  {
    localStorage.setItem("dropdownToggleVal", "Author");
    return PATH.AAKASHGANGA_HOMEPAGE;
  }
}
function ProtectedRoute(props) {
  const tokenStatus=checkTokenExpairation();
  const { isLoggedIn } = props;
  const router = useLocation()
  return (isLoggedIn && tokenStatus)? (
    <>
    {
      router.pathname.includes('/personaldetails') || router.pathname.includes('/previewProfile') ? (
        <Header />
      ) : (
        <AakashgangaHeader />
      )
    }
      <ScrollToTop />
      <div>
        <Outlet />
      </div>
      {checkWriteArticlePage()===false && 
      <AakashgangaFooter />
      }
    </>
  ) : ( 
    <Navigate to={PATH.LOGIN} /> 
  );
}

function PublicRoute(props) {
  const { isLoggedIn, isSetupProfile } = props;
  return isLoggedIn ? (
    <Navigate
      to={
        isSetupProfile === true
          ? redairectDashboard()
          : PATH.PERSONAL_DETAILS
      }
      state={{ openSuccessLoginModal: true }}
    />
  ) : (
    <Outlet />    
  )
}

function CustomRoutes() {
  const dispatch = useDispatch();
  const sucessHandler = (fetchedData) => {
    dispatch({
      type: GET_MASTERDATA_DETAILS,
      payload: fetchedData?.data,
    });
  };
  const fetchdata = async () => {
    return getAllMasterData();
  };
  FetchMasterData(fetchdata, sucessHandler);

  const isLoggedIn = useSelector(
    (state) => state?.LoginReducer?.userLoginDetails?.token ?? null
  );

  const isSetupProfile = useSelector(
    (state) => state?.LoginReducer?.user?.publishProfile ?? null
  );
  return (
    <BrowserRouter>
      <Suspense fallback={<h1>Loading...</h1>}>
        <Routes>
      
          <Route
            element={
              <PublicRoute
                isLoggedIn={isLoggedIn}
                isSetupProfile={isSetupProfile}
              />
            }
          >
            {publicRouteNavigators.map((prop) => {
              return (
                <Route
                  path={prop.path}
                  name={prop.name}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  element={
                    <prop.PropComponent
                      {...(prop?.props ?? <LinkedInCallback />)}
                    />
                  }
                  key={prop.name}
                  exact
                />
              );
            })}
          </Route>
         
          <Route
            element={
              <ProtectedRoute
                isLoggedIn={isLoggedIn}
                isSetupProfile={isSetupProfile}
              />
            }
          > 
            {protectedRouteNavigators.map((prop) => {
              
              return (
                <Route
                  path={prop.path}
                  name={prop.name}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  element={
                    <div className="inner-pages">
                      <prop.PropComponent {...prop?.props} />
                    </div>
                  }
                  key={prop.name}
                  exact
                />
              );
            })}
          </Route>
          {allUserRouteNavigators.map((prop) => {
            return (
              <Route
                path={prop.path}
                name={prop.name}
                // eslint-disable-next-line react/jsx-props-no-spreading
                element={
                  <div className="inner-pages">
                    <prop.PropComponent {...prop?.props} />
                  </div>
                }
                key={prop.name}
                exact
              />
            );
          })}

          <Route exact path="/linkedin" element={<LinkedInCallback />} />
          
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
export default CustomRoutes;
