import { BASE_URL } from "../config";

export const fieldType = { tel: 10, otp: 1, pin: 6 };
export const titleOptions = ["Dr.", "Mr.", "Mrs.", "Ms."];
export const titleOptionsNew = [{key:"Dr.",value:"Dr."}, {key:"Sr. Prof.",value:"Sr. Prof."}, {key:"Prof.",value:"Prof."}, {key:"Asst. Prof.",value:"Asst. Prof."}, {key:"Assoc. Prof.",value:"Assoc. Prof."}, {key:"Ms",value:'Ms'}, {key:"Mr",value:"Mr"}];
export const editorTitleOptionsNew = [{key:"Dr.",value:"Dr."}, {key:"Sr. Prof.",value:"Sr. Prof."}, {key:"Prof.",value:"Prof."}, {key:"Asst. Prof.",value:"Asst. Prof."}, {key:"Assoc. Prof.",value:"Assoc. Prof."}, {key:"Ms",value:'Ms'}, {key:"Mr",value:"Mr"}]

export const feedbackTextValues = {
  1: "Oh no! You are upset. Sorry to hear this. Please help us fix it",
  2: "So sorry to know that you are not pleased. Tell us why",
  3: "We are sorry you're not completely happy. How can we help?",
  4: "You are happy and we are smiling. Tell us what we did right!",
  5: "YAY!! This is what we live for. Tell us what delighted you!"
};
export const phoneField = "phoneNumber";
export const universitySchoolCompanyName = [
  "Stanford",
  "MIT",
  "Boston University",
  "Lancaster University",
];
export const stream = ["Subject", "Science", "Arts", "Business Studies"];
export const specialization = [
  "Select Specialization Field",
  "Human Resource",
  "Marketing",
  "Finance",
];
export const qualification = [
  "Qualification/Level",
  "Graduation",
  "Post Graduation",
];
export const workCategories = ["Select Category", "Dean"];
export const TypeOfArticle = [
  "Select Type Of Article",
  "Review Article",
  "Submit Article",
  "Edit Article",
];
export const RedirectURI = `http://localhost:8989/linkedin`;
export const affiliatedDepartments = ["Affiliated Department", "Dummy"];
export  const splitName=(fullName)=>{
  const [first] = fullName.split(' ')
  return first
}
export const profileIMG = (url = "") => {
  const imageName = url ? url.split("/").pop() : "";
  const imgURL = url && imageName ? `${BASE_URL}static/${imageName}` : "";
  return imgURL;
};
export const article = [
  {
    id: 1,
    heading: "Introduction",
    data: "",
  },
  {
    id: 2,
    heading: "Background",
    data: "",
  },
  {
    id: 3,
    heading: "Methodology",
    data: "",
  },
  {
    id: 4,
    heading: "Results",
    data: "",
  },
  {
    id: 5,
    heading: "Discussion",
    data: "",
  },
  {
    id: 6,
    heading: "Conclusion",
    data: "",
  },
  {
    id: 7,
    heading: "References",
    data: "",
  },
];

export const contactTitleOptions = [
  { key: "Title", value: "" },
  {key:"Dr.",value:"Dr."},
  {key:"Sr. Prof.",value:"Sr. Prof."},
  {key:"Prof.",value:"Prof."},
  {key:"Asst. Prof.",value:"Asst. Prof."},
  {key:"Assoc. Prof.",value:"Assoc. Prof."},
  {key:"Ms",value:'Ms'},
  {key:"Mr",value:"Mr"}
]

export const codeOptions = [
  {key: 'Code', value: ''},
  { key: '+91', value: '91'}
]

export const publicationYear = [
  { key: 'All', value: "All" },
  { key: '2021', value: "2021" },
  { key: '2022', value: "2022" },
  { key: '2023', value: "2023" },
  { key: '2024', value: "2024" },
  { key: '2025', value: "2025" },
]

export const identityOptions = [
  { key: "How do you identify yourself?*", value: "" },
  { key: "Researcher", value: "Researchers" },
  { key: "Funder", value: "Funder" },
  { key: "Research Institution", value: "Research Institute" },
  { key: "Society", value: "Society" },
  { key: "Author", value: "Author" },
  { key: "Vendor", value: "Vendor" },
  { key: "Corporate", value: "Corporate" },
  { key: "Management", value: "Management" },
  { key: "Others", value: "Others" },
]

export const issueOptions = [
  { key: "Registration", value: "Registration" },
  { key: "Submission Process", value: "Submission Process" },
  { key: "Peer Review", value: "Peer Review" },
  { key: "Plagiarism", value: "Plagiarism" },
  { key: "Publication Ethics", value: "Publication Ethics" },
  { key: "Copyright", value: "Copyright" },
  { key: "Others", value: "Others" },
]

export const draftArticle = [ 'TITLE', 'STATUS', 'ARTICLE TYPE', 'CREATED ON', 'LAST EDITED', 'ACTIONS'];
export const underReviewArticle = ['TITLE', '', 'ARTICLE TYPE', 'SUBMITTED', 'STATUS'];
export const approvedArticle = ['TITLE', '', 'ARTICLE TYPE', 'APPROVED', 'ACTIONS'];
export const rejectedArticle = ['TITLE', 'ARTICLE TYPE', 'REJECTED', 'ACTIONS'];
export const publishedArticle = ['TITLE', '', 'ARTICLE TYPE', 'SUBMITTED', 'PUBLISHED', "ACTION"];

export const peRequest = ["TITLE", "ARTICLE TYPE", "RECEIVED", "DUE BY", "ACTIONS"];
export const peAccepted = ["TITLE", "ARTICLE TYPE", "ACCEPTED", "DUE BY", "ACTIONS"]
export const seAccepted = ["APPOINTED", "ARTICLE TYPE", "ASSIGN DATE", "DUE BY", "AGING", "ACTIONS"]
export const peReview = ["TITLE", "ARTICLE TYPE", "SENT", "STATUS"];
export const pePeerReview = ["TITLE", "ARTICLE TYPE", "REVIEW RECEIVED", "PEER REVIEWER", "ACTIONS"];
export const peApproved = ["TITLE", "ARTICLE TYPE", "REVIEW RECEIVED", "APPROVED", "ACTIONS"];
export const peRevision = ["TITLE", "ARTICLE TYPE", "REVIEW RECEIVED", "SENT", "ACTIONS"];
export const peRejected = ["TITLE", "ARTICLE TYPE", "REVIEW RECEIVED", "REJECTED", "ACTIONS"];

export const prRequest = ["TITLE", "ARTICLE TYPE", "RECEIVED", "DUE BY", "ACTIONS"];
export const prAccepted = ["TITLE", "ARTICLE TYPE", "ACCEPTED", "DUE BY", "ACTIONS"];
export const  prReviewed = ["TITLE", "ARTICLE TYPE", "ACCEPTED", "REVIEW SUBMITTED", "ACTIONS"];
export const prNotAccepted = ["TITLE", "ARTICLE TYPE", "REVIEW RECEIVED", "NOT ACCEPTED", "ACTIONS"];

export const SUB_HEADINGS = {
  APC_HEADER: "Art<span>i</span>cle Processing Charges",
  AUTHOR_SUBMISSION_HEADER: "Author Subm<span>i</span>ssion Guidelines",
  GUIDELINE_FOR_PEER_REVIEWERS: "Guidelines For Peer Rev<span>i</span>ewers",
  HOW_WE_PUBLISH: "How We Publ<span>i</span>sh",
  ETHICS_POLICY: "Publishing Ethics Policy",
  ABOUT_AAKASHGANGA: "About Aakashganga",
  SITEMAP: "Sitemap",
  WELCOME_TO_MEGA_JOURNALS:
    "Welcome To Our Mega Journals:<br />Ampl<span>i</span>fy Your Research Impact",
  WE_REALLY_SUPPORT_OPEN_SCIENCE: "We Really Support Open Science",
};

export const FEATURED_CARD_HEADINGS = {
  ORCID: "Orcid",
  ARCHIVING: "Archiving",
};

export const CONTENT_HEADING = {
  UNDERSTANDING_APC: "Understanding Article Processing Charges (APC)",
  APC_INDIAN: "Article Processing Charge For Indian Nationals",
  APC_FOREGIN: "Article Processing Charge For Foreign Nationals",
  ADD_AMENDMENTS_TO_CURRENT_ARTICLE:
    "Adding Amendments To Your Current Article",
  UPDATE_ARTICLE: "Updating Your Article",
  IMPORTANT_NOTE: "Important To Read",
  AFFORDABLE_COST: "Why Are Our APC Costs Affordable?",
  GENERAL_INFO: "General Information",
  PUBLISHING: "Publishing on Aakashganga",
  REGISTER_ON_PLATFORM: "Register on the Platform",
  HOW_TO_REGISTER: "Know How to Register",
  ARTICLE_SUBMISSION_PROCESS:
    "Article Submission Process on Aakashganga Platform",
  MANUSCRIPT: "Manuscript Length",
  ARTICLE_TYPE: "Article Type",
  LANGUAGE_EDITING: "Language Editing",
  LANGUAGE_STYLE: "Language Style",
  SEO: "Search Engine Optimization (SEO)",
  AUTHOR_AND_AFFILIATIONS: "Authors and Affiliations",
  AI: "Artificial Intelligence",
  ABSTRACT: "Abstract",
  ACKNOWLEDGEMENTS: "Acknowledgements",
  FIGURE_AND_TABLE: "Figure and Table Guidelines",
  IMAGES: "Images",
  FILE_FORMATS: "Format and Colour Image Mode",
  ACCESSIBILITY: "Accessibility",
  SUPPLEMENTARY: "Supplementary Material",
  REFERENCE: "References",
  AMENDMENT: "Amendment",
  PROCESS: "Process",
  VERSION: "Versions",
  ROLES_OF_PEERS: "Roles And Responsibilities Of Peer Reviewers",
  PRE_PUBLISHING_REVIEW: "Pre-Publishing Double Anonymous Peer Review",
  POST_PUBLISHING_REVIEW: "Post Publishing Open Peer Review",
  APC: "Article Processing Charge (APC)",
  RAPID_PUBLISHING: "Rapid & Transparent Publishing",
  STATE_OF_ART_TECH: "State-of-Art Technology Platform",
  PUBLISHING_MODEL: "Our Publishing Model",
  RESEARCH_ARTICLES: "Our Publication Model For Research Articles",
  ARTICLE_SUBMISSION_STEPS: "Article Submission Steps",
};

