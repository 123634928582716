import { BASE_APP } from "../../config";
import { React, useEffect, useState } from "react";
import "../../assets/scss/sections/_contact_us.scss";
import ContactUsComponent from "../../components/contactUs";
import sample_04 from "../../assets/img/sample/sample_04.jpg";
import sample_03 from "../../assets/img/sample/sample_03.jpg";

import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const ContactUs = () => {  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleChatBot = (e) => {
    e.preventDefault();
    window.zE("messenger", "open");
  };
 
useEffect(() => {
    
  }, []);

  function successfullyContact()
  {
    setShow(true)
  }

  return (
    <>
      <div>
        <div className="contactSection innerPages">
          <div className="containWrap">
            <div className="contactRow">
              <div className="contactLeft">
                <h1>
                  <span className="underLine">Contact</span> <b>Us</b>
                </h1>
                <ContactUsComponent page={'contactus'} contactSuccess={successfullyContact}/>
                
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header className="justify-content-center pb-0 closeButtonStyle"  closeButton>
                    <Modal.Title className="modal-title">
                      <p className="good-news">Message Sent</p>
                    </Modal.Title>
                  </Modal.Header> 

                  <Modal.Body className="pt-0">
                    <p>Your message has been successfully sent.<br /> We will get back to you soon...</p>
                  </Modal.Body>

                  <Modal.Footer className="pt-3 pb-3 contactModalFooter">
                    <button
                      className="button button-fill button-sm"
                      onClick={handleClose}
                    >
                      Okay
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div className="contactRight">
                <div className="checkOut">
                  <h2>Also Check Out</h2>
                  <div className="checkBox">
                    <div className="checkBox-img">
                      <img src={sample_03} alt="#" />
                    </div>
                    <div className="checkBox-info">
                      <h3 className="text-center">Become A Seller</h3>
                      <p>
                        Forget the old rules. Join the largest network of
                        independent professionals. Find great work. Your terms,
                        Your time.
                      </p>
                    </div>
                    <a href={`${BASE_APP}/Sevahaat/registration`}>
                      REGISTER NOW
                    </a>
                  </div>
                  <div className="checkBox">
                    <div className="checkBox-img">
                      <img src={sample_04} alt="#" />
                    </div>
                    <div className="checkBox-info">
                      <h3 className="text-center">Meet the Dream Catchers</h3>
                      <p>
                        Who are brave enough to dream of a different future for
                        what is today referred to as, the developing world.
                      </p>
                    </div>
                    <Link to="/teams">THE TEAM</Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="pullUp"
              onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <b className="icon-arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
