import React, { useRef } from "react";
import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { identityOptions, issueOptions, titleOptionsNew } from "../constants";
import Select from "./common/Select";
import { validatePhoneNumber } from "../utils";
import { BASE_URL } from "../config";

const ContactUsComponent = ({ page, contactSuccess }) => {
  const dispatch = useDispatch();
  const formRef = useRef();

  const initialValues = {
    title: "",
    fullName: "",
    emailId: "",
    code: "",
    mobile: "",
    identity: "",
    othersData: "",
    description: "",
    issueType: "",
    othersIssue: "",
  };
  const setParams = (values) => {
    const params = {
      title: values.title,
      fullName: values.fullName,
      email: values.emailId,
      code: values.code,
      phoneNumber: values.mobile,
      identify:
        formRef.current?.values?.identity === "Others"
          ? values.othersData
          : values.identity,
      helpData: values.description,
      issueType:
        formRef?.current?.values?.issueType === "Others"
          ? values?.othersIssue
          : values?.issueType,
    };
    return params;
  };
  const validate = Yup.object({
    fullName: Yup.string().required("*Full Name is required"),
    emailId: Yup.string()
      .email("*Email is invalid")
      .required("*Email is required"),
    identity: Yup.string().required("*Identity is required"),
    othersData: Yup.string().when("identity", {
      is: (value) => value === "Others",
      then: (schema) => schema.required("*Please specify your identity"),
    }),
    description: Yup.string().required("*Description is required"),
    mobile: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Mobile Number is Invalid"
      )
      .min(10, "Mobile Number is Invalid"),
  });
  const contactUsData = async (values) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const response = await axios.post(
      `${BASE_URL}AakashGanga/contactUs`,
      setParams(values)
    );
    dispatch({ type: "SET_LOADER", payload: false });
    if (response?.status === 200) {
      contactSuccess();
    } else {
      toast.error('Please try again!!')
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      innerRef={formRef}
      validationSchema={validate}
      onSubmit={(values, { resetForm }) => {
        contactUsData(values);
        resetForm();
      }}
    >
      {(props) => (
        <Form>
          <div className="contactForm">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="row mb-5">
                  <div className="col-3">
                    <div className="fieldWrap-mobile">
                      <Select
                        handleChange={(value) =>
                          props.setFieldValue("title", value)
                        }
                        selectedValue={props?.values.title}
                        options={titleOptionsNew?.map((val) => val?.key)}
                        optionValues={titleOptionsNew?.map((val) => val?.value)}
                        labelClass={props?.values?.title ? "" : "hidden"}
                        label="Title"
                        placeholder="Title"
                        name="title"
                      />
                    </div>
                  </div>
                  <div className="col-9 ps-0">
                    <div className="fieldWrap">
                      <label
                        className={props?.values?.fullName ? "" : "hidden"}
                      >
                        {"Full Name "}
                      </label>
                      <input
                        type="text"
                        className="fieldForm"
                        placeholder="Full Name*"
                        onChange={(event) =>
                          props.setFieldValue("fullName", event.target.value)
                        }
                        name="fullName"
                        value={props.values.fullName}
                      />
                      {props?.errors?.fullName && props?.touched.fullName ? (
                        <div className="error-field">
                          {props?.errors?.fullName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="fieldIcon mb-5">
                  <div className="fieldWrap">
                    <label className={props?.values?.emailId ? "" : "hidden"}>
                      {"Email Id "}
                    </label>
                    <b  className="icon-email icon-emailClass emailStyle" />
                    <input
                      type="email"
                      className="fieldForm"
                      placeholder="Email ID*"
                      onChange={(event) =>
                        props.setFieldValue("emailId", event.target.value)
                      }
                      name="emailId"
                      value={props.values.emailId}
                    />
                    {props?.errors?.emailId && props?.touched.emailId ? (
                      <div className="error-field">
                        {props?.errors?.emailId}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row mb-5">
                  <div className="col-3">
                    <div className="mobileCode">
                      <label>+91</label>
                    </div>
                  </div>
                  <div className="col-9 ps-0">
                    <div className="fieldIcon">
                      <div className="fieldWrap-mobile">
                        <label
                          className={props?.values?.mobile ? "" : "hidden"}
                        >
                          Mobile Number
                        </label>
                        <b  className="icon-call emailStyle" />
                        <input
                          type="tel"
                          className="fieldForm"
                          placeholder="Mobile Number"
                          onChange={(event) =>
                            props.setFieldValue(
                              "mobile",
                              validatePhoneNumber(event.target.value)
                            )
                          }
                          name="mobile"
                          value={props.values.mobile}
                          maxLength={10}
                        />
                        {props?.errors?.mobile && props?.touched.mobile ? (
                          <div className="error-field">
                            {props?.errors?.mobile}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-5">
                  <div className="fieldWrap">
                  {/* <label
                          className={props?.values?.mobile ? "" : "hidden"}
                        >
                          Mobile Number
                        </label> */}
                    <Select
                      handleChange={(value) =>
                        props.setFieldValue("identity",value)
                      }
                      label="Identity"
                      selectedValue={props?.values.identity}
                      options={identityOptions?.map((val) => val?.key)}
                      optionValues={identityOptions?.map((val) => val?.value)}
                      labelClass={props?.values?.identity?"":"hidden"}
                      
                      placeholder="Identity"
                      name="identity"
                    />
                    {props?.errors?.identity && props?.touched.identity ? (
                      <div className="error-field">
                        {props?.errors?.identity}
                      </div>
                    ) : null}
                  </div>
                  {props?.values?.identity === "Others" ? (
                    <div  className="otherIssuesMargin">
                      <input
                        type="text"
                        onChange={(event) =>
                          props.setFieldValue("othersData", event.target.value)
                        }
                        value={props?.values?.othersData}
                        name="othersData"
                        className="fieldForm"
                        placeholder="Please enter"
                      />
                    </div>
                  ) : null}
                  {props?.errors?.othersData && props?.touched.othersData ? (
                    <div className="error-field">
                      {props?.errors?.othersData}
                    </div>
                  ) : null}
                </div>
              </div>

              {page === "needHelp" ? (
                <div className="col-12 col-md-6">
                  <div className="mb-5">
                    <div className="fieldWrap-mobile">
                      <Select
                        handleChange={(value) =>
                          props.setFieldValue("issueType", value)
                        }
                        selectedValue={props?.values.issueType}
                        options={issueOptions?.map((val) => val?.key)}
                        optionValues={issueOptions?.map((val) => val?.value)}
                        labelClass={props?.values?.issueType ? "" : "hidden"}
                        label="Issue Type"
                        placeholder="Select Issue Type"
                        name="issueType"
                      />
                    </div>
                    {props?.values?.issueType === "Others" ? (
                      <div className="otherStyleMargin">
                        <input
                          type="text"
                          onChange={(event) =>
                            props.setFieldValue(
                              "othersIssue",
                              event.target.value
                            )
                          }
                          value={props?.values?.othersIssue}
                          name="othersIssue"
                          className="fieldForm"
                          placeholder="Please enter"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div className="col-12">
                <label  className="labelForm helpStyleClass">
                  How can we help you?<sup>*</sup>
                </label>
                <textarea
                  className="fieldForm"
                  placeholder="Let us know your concern and we will get back to you ASAP"
                  onChange={(event) =>
                    props.setFieldValue("description", event.target.value)
                  }
                  name="description"
                  value={props.values.description}
                />
                {props?.errors?.description && props?.touched.description ? (
                  <div className="error-field">
                    {props?.errors?.description}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="text-center pt-4 mb-0">
              <button
                type="submit"
                className="button button-fill button-sm"
                disabled={!(props.isValid && props.dirty)}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default ContactUsComponent;
