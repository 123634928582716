import React, { useState, useEffect } from "react";
import { Pagination } from "semantic-ui-react";
import Dropdown from "react-bootstrap/Dropdown";
import active_check from "../../../svg/verifiedCheck.svg";
import alertSvg from "../../../assets/img/icons/alert_info.svg";
import Confetti from "react-confetti";
import grant from "../../../assets/img/icons/grant.svg";
import active_info from "../../../svg/active-info.svg";
import notyet from "../../../assets/img/icons/notarticle.svg";
import DashboardLayout from "../../../layout/dashboard";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import jwt_decode from "jwt-decode";
import dots from "../../../assets/img/icons/dots.svg";
import downloadBlue from "../../../assets/img/icons/downloadBlue.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  getArticleDetails,
  getAllGrants,
  getGrantsDetails,
  applyForGrant,
  getGrantsDetailsByName,
  acceptRejectArticle,
} from "../../../store/apiCalls/dashboard";
import {
  draftArticle,
  underReviewArticle,
  approvedArticle,
  rejectedArticle,
  publishedArticle,
} from "../../../constants";
import { FetchSimiilarityReport } from "../../../containers/autherFlow/submitArticle/queries";
import { getDateFormat, downLoadFile, getNameFromUrl } from "../../../utils";
import backArrow from "../../../assets/img/icons/blueArrow.svg";
import successImg from "../../../assets/img/icons/sus.svg";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import RequestAmmended from "../../../components/Modal/RequestAmmended";
import UpdateArticleRequest from "../../../components/Modal/UpdateArticleRequest";
import ReasonModal from "../../autherFlow/prdashboard/ReasonModal";
import NeedHelpModal from "../../../components/Modal/NeedHelpModal";

const MyArticle = () => {
  const userNationality = useSelector((state) => state?.LoginReducer?.user?.nationality);
  const dispatch = useDispatch();
  const location = useLocation();
  let status = location?.state;
  const [showButton, setShowButton] = useState(true);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const limit = 10;
  const [nationality, setNationality] = useState("");
  const [clicked, setClicked] = useState("");
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [amendmentUpdateTrigger, setAmendmentUpdateTrigger] = useState(false);
  const [title, setTitle] = useState(-1);
  const [articleType, setArticleType] = useState(-1);
  const [createdOn, setCreatedOn] = useState(-1);
  const [lastEdited, setLastEdited] = useState(-1);
  const [submitted, setSubmitted] = useState(-1);
  const [received, setReceived] = useState(false);
  const [publishArticle, setpublishArticle] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [availableGrants, setavailableGrants] = useState(false);
  const [applyGrant, setapplyGrant] = useState(false);
  const [wordDecCount, setWordDecCount] = useState(0);
  const [articleId, setArticleId] = useState("");
  const [amendmentData, setAmendmentData] = useState("");
  const [noMoreAmendment, setNoMoreAmendment] = useState(false);
  const [showNotAccepted, setShowNotAccepted] = useState(false);
  const [reasonComments, setReasonComments] = useState("");
  const [reason, setReason] = useState("");
  const [reasonHandle, setReasonHandle] = useState("");
  const [prReviewReport, setPrReviewReport] = useState({});
  const [rejectionArticleId, setRejectionArticleId] = useState("");
  const [amendmentPopup, setamendmentPopup] = useState(false);
  const [amendmentSharingPopup, setamendmentSharingPopup] = useState(false);
  const [updateArticlePopup, setUpdateArticlePopup] = useState(false);
  const [updateSharingPopup, setUpdateSharingPopup] = useState(false);
  const [articleUpdateRequest, setArticleUpdateRequest] = useState("");
  const [articleUpdateNewVersion, setArticleUpdateNewVersion] = useState(false);
  const [showNewArticleRequest, setShowNewArticleRequest] = useState(false);
  const [alreadyUpdatedPopup, setAlreadyUpdatedPopup] = useState(false);
  const [paymentSuccessful, setpaymentSuccessful] = useState(false);
  const [publishedSuccessfully, setpublishedSuccessfully] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [page, setPage] = useState(1);
  const { id } = jwt_decode(localStorage.usertoken);
  const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  const [articleData, setArticleData] = useState([]);
  const [grantData, setGrantData] = useState([]);
  const [grantDetail, setGrantDetail] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [articleTotalData, setArticleTotalData] = useState([]);
  const [articleName, setArticleName] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [amendmentArticleId, setAmendmentArticleId] = useState("");
  const [journalName, setJournalName] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [fundingStatus, setFundingStatus] = useState("");
  const [showWorkSaved, setShowWorkSaved] = useState({
    articleId: "",
    PID: "",
    status: "",
    finalPayment: "",
  });
  const [articleDetailData, setArticleDetailData] = useState({
    articleId: "",
    applicantName: "",
    article: "",
  });

  const sucessHandlerSimillarityReport = (articleDetails) => {
    if (articleDetails.status === 200) {
      downLoadFile(articleDetails.data.url, "report.pdf");
    }
    dispatch({ type: "SET_LOADER", payload: false });
  };
  const { mutate: downloadSimiliratyReport } = FetchSimiilarityReport(
    sucessHandlerSimillarityReport
  );
  const handleClose = () => setShowDetail(false);
  let totalPages = Math.ceil(
    articleTotalData?.numberRecordBaseOnFilter / limit
  );
  const MAX_WORDS = 30;
  const wordLimit = 300;
  const [formData, setFormData] = useState({
    proposal: "",
    foundReceived: false,
  });
  const [formErrors, setFormErrors] = useState({
    proposal: "",
  });
  const validateForm = () => {
    let valid = true;
    const errors = {};
    if (!formData?.proposal) {
      errors.proposal = "This field is required";
      valid = false;
    } else if (formData?.proposal.split(" ")?.length > wordLimit) {
      errors.proposal = `Description should not exceed ${wordLimit} words`;
      valid = false;
    }
    setFormErrors(errors);
    return valid;
  };
  const handleChangeCheckbox = (event) => {
    const { name, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };
  const handleChangeProposal = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setWordDecCount(value.trim().split(/\s+/)?.length);
  };

  const fetchArticleData = async () => {
    dispatch({ type: "SET_LOADER", payload: true });
    const params = () => {
      const data = {
        userId: id,
        status,
        page,
        limit,
      };
      if (clicked === "CREATED ON" && createdOn) {
        data.createdOn = createdOn;
      }
      if (clicked === "TITLE" && title) {
        data.title = title;
      }
      if (clicked === "ARTICLE TYPE" && articleType) {
        data.articleType = articleType;
      }
      if (
        (clicked === "LAST EDITED" ||
          clicked === "APPROVED" ||
          clicked === "REJECTED" ||
          clicked === "PUBLISHED") &&
        lastEdited
      ) {
        data.lastEdited = lastEdited;
      }
      if (clicked === "SUBMITTED" && submitted) {
        data.submitted = submitted;
      }
      return data;
    };

    const response = await getArticleDetails(headers, params());
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setArticleData(response?.data?.data?.article);
      setArticleTotalData(response?.data?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: true });
      return toast.error("Please try again");
    }
  };

  const fetchAllGrantsData = () => {
    dispatch({ type: "SET_LOADER", payload: true });
    const fetchdata = async () => {
      const params = {
        status: "live",
        page: page,
        limit: limit,
      };
      return getAllGrants(headers, params);
    };

    fetchdata()
      .then((grantData) => {
        if (grantData.status === 200 && grantData?.data?.data) {
          dispatch({ type: "SET_LOADER", payload: false });
          setGrantData(grantData.data.data);
        }
      })
      .catch((err) => console.log(err));
    dispatch({ type: "SET_LOADER", payload: false });
  };

  const getGrantDetailReceived = (name) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const fetchdata = async () => {
      const params = {
        grantName: name,
      };
      return getGrantsDetailsByName(headers, params);
    };

    fetchdata()
      .then((grantDetailData) => {
        if (grantDetailData.status === 200 && grantDetailData?.data?.data) {
          dispatch({ type: "SET_LOADER", payload: false });
          setGrantDetail(grantDetailData?.data?.data);
          setReceived(true);
        }
      })
      .catch((err) => console.log(err));
    dispatch({ type: "SET_LOADER", payload: false });
  };
  const getGrantDetailByName = (name) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const fetchdata = async () => {
      const params = {
        grantName: name,
      };
      return getGrantsDetailsByName(headers, params);
    };

    fetchdata()
      .then((grantDetailData) => {
        if (grantDetailData.status === 200 && grantDetailData?.data?.data) {
          dispatch({ type: "SET_LOADER", payload: false });
          setGrantDetail(grantDetailData?.data?.data);
          setShowDetail(true);
          setShowButton(false);
        }
      })
      .catch((err) => console.log(err));
    dispatch({ type: "SET_LOADER", payload: false });
  };
  const getGrantDetail = (id) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const fetchdata = async () => {
      const params = {
        grantId: id,
      };
      return getGrantsDetails(headers, params);
    };

    fetchdata()
      .then((grantDetailData) => {
        if (grantDetailData.status === 200 && grantDetailData?.data?.data) {
          dispatch({ type: "SET_LOADER", payload: false });
          setGrantDetail(grantDetailData?.data?.data);
          setavailableGrants(false);
          setShowDetail(true);
        }
      })
      .catch((err) => console.log(err));
    dispatch({ type: "SET_LOADER", payload: false });
  };
  const applyForGrants = (formData) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const fetchdata = async () => {
      const params = {
        articleId: articleDetailData?.articleId,
        grantName: grantDetail?.grantName,
        grantId: grantDetail?._id,
        applicantName: articleDetailData?.applicantName,
        article: articleDetailData?.article,
        grantValue: grantDetail?.grantValue,
        proposal: formData?.proposal,
        fundindRecevied: formData?.foundReceived,
      };
      return applyForGrant(headers, params);
    };

    fetchdata()
      .then((grantApplyData) => {
        if (grantApplyData?.data?.sucess === true) {
          dispatch({ type: "SET_LOADER", payload: false });
          setapplyGrant(false);
          setShow(true);
        }
      })
      .catch((err) => console.log(err));
    dispatch({ type: "SET_LOADER", payload: false });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setFundingStatus("");
      applyForGrants(formData);
    }
  };

  const handleRedirect = (e, path = "/dashboard") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };

  const handlePageChange = (data) => {
    return (
      setPage(data.activePage), window.scrollTo({ top: 0, behavior: "smooth" })
    );
  };

  const sortingData = (header) => {
    if (header === "CREATED ON") {
      if (createdOn == -1) {
        setCreatedOn(1);
      } else {
        setCreatedOn(-1);
      }
    } else if (header === "ARTICLE TYPE") {
      if (articleType == -1) {
        setArticleType(1);
      } else {
        setArticleType(-1);
      }
    } else if (header === "TITLE") {
      if (title == -1) {
        setTitle(1);
      } else {
        setTitle(-1);
      }
    } else if (
      header === "LAST EDITED" ||
      header === "APPROVED" ||
      header === "REJECTED" ||
      header === "PUBLISHED"
    ) {
      if (lastEdited == -1) {
        setLastEdited(1);
      } else {
        setLastEdited(-1);
      }
    } else if (header === "SUBMITTED") {
      if (submitted === -1) {
        setSubmitted(1);
      } else {
        setSubmitted(-1);
      }
    }
  };

  const handleArticleUpdate = (article) => {
    return setArticleUpdateRequest(article);
  };

  const handleArticleNewRequest = (article) => {
    return setArticleUpdateNewVersion(article);
  };

  const getSimillarityReport = async (indexId, d_key) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      params: { similarityCheckId: indexId, d_key },
      headers: headers,
    };
    downloadSimiliratyReport(queryParams);
  };

  const handlePublishArticle = async (_id, peId, status, finalPayment) => {
    setShowWorkSaved({
      articleId: _id,
      PID: peId,
      status: true,
      finalPayment: finalPayment,
    });
  };
  const sucessHandlerUpdatePayment = (info) => {
    if (info.status === 200) {
      fetchArticleData();
    }
  };

  const publishArticleAPICall = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: articleId,
      peStatus: "published",
      peId: showWorkSaved?.PID,
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      setShowWorkSaved({
        articleId: "",
        PID: "",
        status: false,
        finalPayment: "",
      });
      navigate("/myArticle/published", { state: "published" });
    } else {
      return toast.error("Something went wrong. Please contact with admin");
    }
  };

  const downloadContract = (url) => {
    if (url) {
      let filename = getNameFromUrl(url);
      if (filename.includes("?")) {
        let finalFilename = filename?.split("?")[0];
        downLoadFile(url, `${finalFilename}`);
      } else {
        downLoadFile(url, `${filename}`);
      }
    }
  };

  useEffect(() => {
    fetchArticleData();
    fetchAllGrantsData();
  }, [
    page,
    status,
    title,
    articleType,
    lastEdited,
    createdOn,
    submitted,
    updateTrigger,
    amendmentUpdateTrigger,
  ]);

  useEffect(() => {
    setPage(1);
  }, [status]);

  
  return (
    <DashboardLayout>
      <p className="draftTitle">
        {status === "draft"
          ? "Drafts"
          : status === "submit"
          ? "Under Review"
          : status === "approved"
          ? "Approved"
          : status === "rejected"
          ? "Rejected"
          : status === "published"
          ? "Published"
          : null}
        <span>
          {(status === "approved" && userNationality === "withInIndia")
            ? "Do not forget to use mudras available in your Mudra Kosh and also apply any running discounts on the platform."
            : null}
        </span>
      </p>
      <div className="draftTable">
        <table className="w-100">
          {articleData && articleData?.length > 0 && (
            <thead>
              <tr>
                {status === "draft"
                  ? draftArticle.map((draftHeader) => (
                      <th
                        className={
                          draftHeader === "ACTIONS"
                            ? "sortingWrap author-draft-th"
                            : "sortingWrap "
                        }
                        onClick={() => {
                          setClicked(draftHeader);
                          sortingData(draftHeader);
                        }}
                      >
                        <span className={draftHeader==="ACTIONS"?"justify-content-center":"justify-content-center"}>
                          {draftHeader=== "ACTIONS"?"ACTION":draftHeader}
                          {draftHeader === "ACTIONS" ? null : (
                            <b className="sort" />
                          )}
                        </span>
                      </th>
                    ))

                  : status === "submit"
                  ? underReviewArticle.map((underHeader) => (
                      <th
                        className={
                          underHeader === "STATUS"
                            ? "sortingWrap author-underReview-th"
                            : "sortingWrap "
                        }
                        onClick={() => {
                          setClicked(underHeader);
                          sortingData(underHeader);
                        }}
                      >
                        <span className="justify-content-center">
                          {underHeader}
                          {underHeader === "" ? null : (
                            <b className="sort" />
                          )}
                          {/* <b className="sort" /> */}
                        </span>
                      </th>
                    ))
                  : status === "approved"
                  ? approvedArticle.map((approvedHeader) => (
                      <th
                        className={
                          approvedHeader === "ACTIONS"
                            ? "sortingWrap author-approved-th"
                            : "sortingWrap"
                        }
                        onClick={() => {
                          setClicked(approvedHeader);
                          sortingData(approvedHeader);
                        }}
                      >
                        <span className="justify-content-center">
                          {approvedHeader}
                          {approvedHeader === "ACTIONS" || approvedHeader === "" ? null : (
                            <b className="sort" />
                          )}
                        </span>
                      </th>
                    ))
                  : status === "rejected"
                  ? rejectedArticle.map((rejectedHeader) => (
                      <th
                        className={
                          rejectedHeader === "ACTIONS"
                            ? "sortingWrap author-rejected-th"
                            : "sortingWrap"
                        }
                        onClick={() => {
                          setClicked(rejectedHeader);
                          sortingData(rejectedHeader);
                        }}
                      >
                        <span>
                          {rejectedHeader}
                          {rejectedHeader === "ACTIONS" ? null : (
                            <b className="sort" />
                          )}
                        </span>
                      </th>
                    ))
                  : publishedArticle.map((publishedHeader) => (
                      <th
                        className="sortingWrap"
                        onClick={() => {
                          setClicked(publishedHeader);
                          sortingData(publishedHeader);
                        }}
                      >
                        <span>
                          {publishedHeader}
                          {publishedHeader === "" ? null : (
                            <b className="sort" />
                          )}
                        </span>
                      </th>
                    ))}
              </tr>
            </thead>
          )}
          <tbody>
            {articleData && articleData?.length > 0 ? (
              articleData?.map((article) => {
                return (
                  <>
                    <tr className="empty">
                      <td colspan="5"></td>
                    </tr>
                    <tr className="align-top" key={article._id}>
                      <td><div className="d-block" >
                            <span
                              className={
                                article?.plagirismCheck === "underProcess"
                                  ? "opacity-5"
                                  : null
                              }
                            >
                              <Link
                              
                                to={{
                                  pathname:
                                    status !== "published"
                                      ? `/dashboard/article/${article?._id}/review-article`
                                      : null,
                                }}
                                className="fw-bold"
                                state={{
                                  _id:
                                    status === "draft" || status === "submit"
                                      ? article?._id
                                      : article?.articleId,
                                }}
                                onClick={
                                  status === "published"
                                    ? (e) =>
                                        handleRedirect(
                                          e,
                                          `/viewPublishedArticle/${article?.articleId}`
                                        )
                                    : null
                                }
                              >
                                {status === "draft" || status === "submit"
                                  ? article?.title
                                  : article?.article?.[0]?.title==""?"Not started":article?.article?.[0]?.title}
                                  
                              </Link>
                            </span>
                          </div></td>
                      <td className={status === "draft"?"pb-5":"pb-5"}>
                        <div className="min-h-110">
                        {article?.article?.[0]?.grantDetails?.grantStatus ===
                          "reject" &&
                          status === "approved" &&
                          article?.grant?.[0]?.isDeleted === false && (
                            <span className="textDetailsWrap textRed">
                              Grant Application Not Accepted{" "}
                              <b
                                onClick={() => [
                                  setShowReject(true),
                                  setReason(
                                    article?.article?.[0]?.grantDetails?.reason
                                  ),
                                ]}
                              >
                                Reason
                              </b>
                            </span>
                          )}
                        {status === "approved" &&
                          article?.grant?.[0]?.isDeleted === true && (
                            <span className="textDetailsWrap textRed">
                              Grant Application Not Accepted{" "}
                              <b
                                onClick={() => [
                                  setShowReject(true),
                                  setReason("This Grant has been deleted"),
                                ]}
                              >
                                Reason
                              </b>
                            </span>
                          )}
                        {article?.article?.[0]?.grantDetails?.grantStatus ===
                          "approved" &&
                          status === "approved" &&
                          article?.grant?.[0]?.isDeleted === false && (
                            <span className="textDetailsWrap textGreen">
                              Grant Application Accepted
                            </span>
                          )}
                        {article?.article?.[0]?.grantDetails?.grantStatus ===
                          "pending" &&
                          status === "approved" &&
                          article?.grant?.[0]?.isDeleted === false && (
                            <span className="textDetailsWrap textOrange">
                              Applied For Grant
                            </span>
                          )}
                        {status === "draft" ? (
                          <>
                          {article?.submissionDetail?.journalType === "6454a6390d88e62fa10503bf" && article?.title === undefined?<>
                            <span className="t-type mr-0" style={{maxWidth:'465px',lineHeight:'24px'}}>
                              {article?.paymentBySociety?.apcPayBySociety && !article?.paymentBySociety?.apcPayHaveCoupon && (<>
                              <p className="font-bold pb-1 text-center" >
                                {article?.initialPayment === true && article?.finalPayment === false ?
                                <>Society has paid the registration fee <span className="red-text blink_me">*</span></>
                                :article?.initialPayment === true && article?.finalPayment === true ?
                                <>Society has paid full APC <span className="red-text blink_me">*</span></>
                                :"Submission Journey in Progress"}
                              </p>
                              </>)}
                              {article?.revokeCoupon?.revoked && <>
                                <p className="font-bold pb-1 text-center" >Coupon Revoked</p>
                              </>}
                              <p className="fs-16 text-center pe-4" style={{color:'#000'}}>{article?.plagirismCheck === "notDone"
                                ? article?.articleStatus === "sentForRevision" ? "Sent For Revision" : (article?.paymentBySociety?.apcPayBySociety && !article?.paymentBySociety?.apcPayHaveCoupon && article?.initialPayment)? "Please complete the submission journey" : article?.revokeCoupon?.revoked?"": article?.articleStatus=="New"?"New article in progress": article?.articleStatus==="You will complete your submission journey after society has made payment."?"Complete your submission journey after society pays APC.": article?.articleStatus
                                : null}
                                </p>
                            </span>
                            </>:<>
                            <span className="t-type mr-0 text-center d-block lh-sm">
                              {article?.plagirismCheck === "notDone"
                                ? article?.articleStatus === "sentForRevision" ? "Sent For Revision" : article?.articleStatus== "You will complete your submission journey after society has made payment." ? <>You will complete your submission journey <br/> after society has made payment.</> : article?.articleStatus
                                : null}
                            </span>
                            </>}
                            </>
                        ) : null}
                        {status === "approved" && article?.submissionDetail?.journalType === "6454a6390d88e62fa10503bf" ? (
                         <span className="t-type mr-0" style={{maxWidth:'465px',lineHeight:'24px'}}>
                         {article?.paymentBySociety?.apcPayBySociety && !article?.paymentBySociety?.apcPayHaveCoupon && (<> 
                         <p className="font-bold pb-1 text-center" >
                           {article?.initialPayment === true && article?.finalPayment === true ?
                           <>Article approved by society editor <span className="red-text blink_me">*</span></>
                           :<>Article approved by society editor <span className="red-text blink_me">*</span></>}
                         </p>
                         <p className="fs-16 text-center pe-4" style={{color:'#000'}}>
                         {article?.initialPayment === true && article?.finalPayment === true ?
                          <>Society has paid final APC. Proceed to publish</> : null}
                          </p>
                        
                         </>)}
                        
                         {/* <p className="fs-16 text-center pe-4" style={{color:'#000'}}>{(article?.paymentBySociety?.apcPayBySociety && !article?.paymentBySociety?.apcPayHaveCoupon && article?.initialPayment)? "Please complete the submission journey" : article?.revokeCoupon?.revoked?"": article?.articleStatus=="New"?"New article in progress": article?.articleStatus==="You will complete your submission journey after society has made payment."?"Complete your submission journey after society pays APC.": article?.articleStatus}
                           </p> */}
                       </span>
                        ):null}
                        <div className="firstDataBox">
                          {status === "draft" && (
                            <div className="draftArticleWrap">
                              {
                             
                              article?.plagirismCheck === "underProcess" ? (
                                <div className="similarityCheck progressText">
                                  SIMILARITY INDEX CHECK IN PROGRESS
                                </div>
                              ) : article?.plagirismCheck === "Done" &&
                                (article?.similarityCheckPercentage ||
                                  article?.similarityCheckPercentage == 0) ? (
                                <div
                                  className={
                                    article?.similarityCheckPercentage > 15
                                      ? "failedText similarityCheck"
                                      : "successText similarityCheck"
                                  }
                                >
                                  {article?.similarityCheckPercentage > 15
                                    ? "HIGH SIMILARITY MATCHING"
                                    : "SUCCESSFUL SIMILARITY INDEX CHECK"}{" "}
                                  -{" "}
                                  <span>
                                    {article?.similarityCheckPercentage}%
                                    {article?.similarityCheckPercentage > 15 ? (
                                      <div className="customTool">
                                        {["bottom"].map((placement) => (
                                          <OverlayTrigger
                                            key={placement}
                                            placement={placement}
                                            overlay={
                                              <Popover>
                                                <Popover.Body>
                                                  <div className="popoverToolWrap">
                                                    <p>
                                                      The indicated percentage
                                                      score shows the similarity
                                                      index of your article. If
                                                      it exceeds 15%, you will
                                                      need to rework the
                                                      article, to bring down
                                                      this number below 15%
                                                      before the article can be
                                                      further processed. Incase
                                                      of further assistance,
                                                      please write to{" "}
                                                      <a href="mailto:contactus@vikramshilaedu.in">
                                                        contactus@vikramshilaedu.in
                                                      </a>
                                                    </p>
                                                  </div>
                                                </Popover.Body>
                                              </Popover>
                                            }
                                          >
                                            <img
                                              className="pe-cursor ps-1"
                                              src={active_info}
                                              alt="no img"
                                            />
                                          </OverlayTrigger>
                                        ))}
                                      </div>
                                    ) : (
                                      <img
                                        src={active_check}
                                        alt="active-check"
                                      />
                                    )}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          )}
                          {/* <div className="draftArticleWrap">
                            <h2
                              className={
                                article?.plagirismCheck === "underProcess"
                                  ? "opacity-5"
                                  : null
                              }
                            >
                              <Link
                                to={{
                                  pathname:
                                    status !== "published"
                                      ? `/dashboard/article/${article?._id}/review-article`
                                      : null,
                                }}
                                state={{
                                  _id:
                                    status === "draft" || status === "submit"
                                      ? article?._id
                                      : article?.articleId,
                                }}
                                onClick={
                                  status === "published"
                                    ? (e) =>
                                        handleRedirect(
                                          e,
                                          `/viewPublishedArticle/${article?.articleId}`
                                        )
                                    : null
                                }
                              >
                                {status === "draft" || status === "submit"
                                  ? article?.title
                                  : article?.article?.[0]?.title}
                              </Link>
                            </h2>
                          </div> */}
                          <div className={status === "draft"?"position-absolute footTag text-center":"position-absolute footTag text-center"} style={status === "draft"?{width:"115%"}:{}}>
                            {status === "draft" || status === "submit" ? (
                              <>
                                {article?.subjectDetail?.[0]?.subjectName && (
                                  <span
                                    className={
                                      article?.plagirismCheck === "underProcess"
                                        ? "t-tag opacity-5"
                                        : "t-tag"
                                    }
                                  >
                                    {article?.subjectDetail?.[0]?.subjectName}
                                  </span>
                                )}

                                {article?.specializations?.[0]
                                  ?.specialization && (
                                  <span
                                    className={
                                      article?.plagirismCheck === "underProcess"
                                        ? "t-tag opacity-5"
                                        : "t-tag"
                                    }
                                  >
                                    {
                                      article?.specializations?.[0]
                                        ?.specialization
                                    }
                                  </span>
                                )}
                                <span
                                      className={
                                        article?.plagirismCheck ===
                                        "underProcess"
                                          ? "t-tag t-tag-journal opacity-5"
                                          : "t-tag t-tag-journal mt-0"
                                      }
                                    >
                                      {article?.submissionDetail?.journalName}
                                    </span>

                                {article?.submissionDetail?.journalName && (
                                  <div className="t-tag-journalWrap">
                                    
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {article?.subjectDetail?.[0]?.subjectName && (
                                  <span className="t-tag">
                                    {article?.subjectDetail[0]?.subjectName}
                                  </span>
                                )}

                                {article?.specializations?.[0]
                                  ?.specialization && (
                                  <span className="t-tag">
                                    {
                                      article?.specializations?.[0]
                                        ?.specialization
                                    }
                                  </span>
                                )}
                                <span className="t-tag t-tag-journal mt-0">
                                      {
                                        article?.article?.[0]?.submissionDetail
                                          ?.journalName
                                      }
                                    </span>

                                {article?.article?.[0]?.submissionDetail
                                  ?.journalName && (
                                  <div className="t-tag-journalWrap">
                                    
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        </div>
                      </td>
                      <td>
                        <div className="articleType-td ">
                          {article?.typeofarticles
                            ? article?.typeofarticles[0]?.articleName
                            : "--"}
                        </div>
                      </td>
                      {status === "draft" && (
                        <td>{getDateFormat(article?.createdAt)}</td>
                      )}

                      {status === "published" && (
                        <td>{getDateFormat(article?.createdAt)}</td>
                      )}

                      <td className="text-nowrap">{getDateFormat(article?.updatedAt)}</td>

                      {status === "submit" && (
                        <td className="author-underReview-td dd text-nowrap text-center">
                          {article?.articleStatus === "sentForRevision" ? "Sent For Revision" : article?.articleStatus==="New"?"New Article":article?.articleStatus}
                        </td>
                      )}

                      {status === "draft" &&
                      article?.articleStatus !== "First Revision" ? (
                        <td className="author-draft-td">
                          <div className="vertical-footAction">
                         {article?.plagirismCheck === "underProcess" || (article?.paymentBySociety?.apcPayHaveCoupon === false && article?.paymentBySociety?.apcPayBySociety === true && article?.initialPayment === false) || article?.revokeCoupon?.revoked ||
                    (article?.plagirismCheck === "done" && article?.similarityCheckPercentage < 15) ||
                    (article?.stage === "adminctrl" && 
                      article?.similarityCheckPercentage === undefined ) ? null : (
                      <div className="m-0">
                        <Link
                          to={`/dashboard/edit-article/${article?._id}`}
                          className="button button-primary"
                        >
                         {article?.paymentBySociety?.apcPayHaveCoupon === false && article?.paymentBySociety?.apcPayBySociety === true && article?.initialPayment === true && article?.title == undefined?"Complete Submission Journey":"Edit Article"}
                        </Link>
                      </div>
                    )}


                            {status === "draft" &&
                              article?.articleStatus !== "First Revision" &&
                              article.plagirismCheck === "Done" &&
                              article?.plagirismCheck !== "notDone" &&
                              article?.plagirismCheck !== "underProcess" &&
                              article?.similarityCheckPercentage > "0" && (
                                <div>
                                  <button
                                    onClick={() =>
                                      getSimillarityReport(
                                        article.similarityCheckId,
                                        article?.d_key
                                      )
                                    }
                                    className="button-link"
                                  >
                                    View Report
                                  </button>
                                </div>
                              )}
                              {article?.similarityCheckPercentage > 15 ? (
                                <div>
                                <button
                                  className="button-link"
                                  onClick={() => setShowHelpModal(true)}
                                >
                                  Need Help?
                                </button>
                              </div>
                              ) : null}
                          </div>
                        </td>
                      ) : (
                        status === "draft" &&
                        article?.articleStatus === "First Revision" && (
                          <td className="author-draft-td">
                            <div className="vertical-footAction">
                              <div>
                                <Link
                                  to={`/dashboard/edit-article/${article?._id}`}
                                  className="button button-primary"
                                >
                                  Edit Article
                                </Link>
                              </div>
                              <div>
                                <Link
                                  to={{
                                    pathname: `/dashboard/article/${article?._id}/view-report`,
                                  }}
                                  state={{
                                    _id:  article?._id,
                                    rejectionArticleId: article?._id,
                                    articleStatus: "First Revision",
                                  }}
                                  
                                  className="button-link"
                                >
                                  VIEW REPORT
                                </Link>
                              </div>
                              {article?.similarityCheckPercentage > 15 ? (
                                <div>
                                <button
                                  className="button-link"
                                  onClick={() => setShowHelpModal(true)}
                                >
                                  Need Help?
                                </button>
                              </div>
                              ) : null}
                            </div>
                          </td>
                        )
                      )}

                      {status === "approved" && (
                        <td className="author-approved-td">
                          <div className="vertical-footAction">
                            <div className="mt-0">
                            {(article?.user?.[0]?.nationality === "withInIndia") && (
                        article?.article?.[0]?.finalPayment === false &&
                        article?.article?.[0]?.grantDetails?.grantStatus ===
                          "pending" &&
                        article?.grant?.[0]?.isDeleted === false) && (
                          <a
                            href="javascript:void(0)"
                            className="button-link"
                            onClick={() => [
                              getGrantDetailByName(
                                article?.article?.[0]?.grantDetails?.grantName
                              ),
                              setShowButton(false),
                            ]}
                          >
                            Grant Details
                          </a>
                        )}

                              {article?.user?.[0]?.nationality ===
                                "withInIndia" &&
                                article?.article?.[0]?.finalPayment ===
                                  false &&
                                article?.article?.[0]?.grantDetails
                                  ?.grantStatus === "approved" &&
                                article?.grant?.[0]?.isDeleted === false && (
                                  <a
                                    href="javascript:void(0)"
                                    className="button-link"
                                    onClick={() => [
                                      getGrantDetailReceived(
                                        article?.article?.[0]?.grantDetails
                                          ?.grantName
                                      ),
                                      setArticleDetailData({
                                        articleId: article?.article?.[0]?._id,
                                        applicantName:
                                          article?.user[0]?.fullName,
                                        article: article?.article?.[0]?.title,
                                      }),
                                    ]}
                                  >
                                    Grant Received
                                  </a>
                                )}
                              
                              {article?.user?.[0]?.nationality ===
                                "withInIndia" &&
                                ((article?.article?.[0]?.finalPayment ===
                                  false &&
                                  article?.article?.[0]?.grantDetails
                                    ?.grantStatus === "notApplied") ||
                                  article?.article?.[0]?.grantDetails
                                    ?.grantStatus === "reject" ||
                                  article?.grant?.[0]?.isDeleted === true) && (
                                  <a
                                    href="javascript:void(0)"
                                    className="button-link text-nowrap"
                                    onClick={() => [
                                      setavailableGrants(true),
                                      setFundingStatus(
                                        article?.article?.[0]?.fundingDetails
                                          ?.fundingInformation
                                      ),
                                      setShowButton(true),
                                      setArticleDetailData({
                                        articleId: article?.article?.[0]?._id,
                                        applicantName:
                                          article?.user[0]?.fullName,
                                        article: article?.article?.[0]?.title,
                                      }),
                                    ]}
                                  >
                                    Apply For Grant
                                  </a>
                                )}
                            </div>
                            <div>
                              {article?.prReview &&
                                article?.article?.[0]?.finalPayment ===
                                  false && (
                                  <Link
                                    to={{
                                      pathname: `/dashboard/article/${article?._id}/view-report`,
                                    }}
                                    state={{
                                      _id: article?._id,
                                      rejectionArticleId: article?.articleId,
                                      status: status,
                                      title: article?.article?.[0]?.title,
                                      createdAt: article?.createdAt,
                                      updatedAt: article?.updatedAt,
                                      reasonHandle: article?.peRejectedReason,
                                      userType: "Author",
                                    }}
                                    className="button-link"
                                  >
                                    VIEW REPORT
                                  </Link>
                                )}
                            </div>
                            <div>
                              <button
                                className="button button-primary"
                                onClick={() => {
                                  handlePublishArticle(
                                    article?.articleId,
                                    article?.peId?.[0],
                                    "published",
                                    article?.article?.[0]?.finalPayment
                                  ),
                                    setArticleId(article?._id);
                                }}
                              >
                                Publish Now
                              </button>
                            </div>
                          </div>
                        </td>
                      )}

                      {status === "rejected" && (
                        <td className="author-rejected-td">
                          <div className="footAction">
                           
                            <button
                              className="button button-primary"
                              onClick={() => {
                                setShowNotAccepted(true);
                                setArticleId(article?._id);
                                setRejectionArticleId(article?.articleId);
                                setReasonHandle(article?.peRejectedReason);
                                setReasonComments(
                                  article?.peRejectedReasonAdditionalComment
                                );
                                setPrReviewReport(article?.prReview);
                              }}
                            >
                              VIEW REASON
                            </button>
                          </div>
                        </td>
                      )}

                      {status === "published" && (
                        <td>
                          <div className="customDrop moreCustom">
                            <Dropdown>
                              <Dropdown.Toggle variant="success">
                                <img src={dots} alt="#" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    setamendmentPopup(true);
                                    setAmendmentArticleId(article?.articleId);
                                    setArticleName(
                                      article?.article?.[0]?.title
                                    );
                                    setAuthorName(
                                      article?.article?.[0]?.authorDetails
                                        ?.author?.[0]?.fullName
                                    );
                                    setJournalName(
                                      article?.article?.[0]?.submissionDetail
                                        ?.journalName
                                    );
                                    setSubjectName(
                                      article?.subjectDetail?.[0]?.subjectName
                                    );
                                    setAmendmentData(
                                      article?.article?.[0]?.amendmentData
                                    );
                                    setNoMoreAmendment(true);
                                    setNationality(article?.user?.[0]?.nationality)
                                  }}
                                >
                                  Add Amendment
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    handleArticleUpdate(
                                      article?.article?.[0]?.updateRequest
                                    );

                                    handleArticleNewRequest(
                                      article?.article?.[0]
                                        ?.updateRequestApprove
                                    );

                                    {
                                      article?.article?.[0]?.updateRequest &&
                                      article?.article?.[0]
                                        ?.updateRequestApprove
                                        ? setShowNewArticleRequest(true)
                                        : article?.article?.[0]?.updateRequest
                                        ? setAlreadyUpdatedPopup(true)
                                        : setUpdateArticlePopup(true);
                                    }

                                    setUpdateArticlePopup(true);
                                    setAmendmentArticleId(article?.articleId);
                                    setArticleName(
                                      article?.article?.[0]?.title
                                    );
                                    setAuthorName(
                                      article?.article?.[0]?.authorDetails
                                        ?.author?.[0]?.fullName
                                    );
                                    setJournalName(
                                      article?.article?.[0]?.submissionDetail
                                        ?.journalName
                                    );
                                    setSubjectName(
                                      article?.subjectDetail?.[0]?.subjectName
                                    );
                                  }}
                                >
                                  Update Article
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    downloadContract(
                                      article?.article?.[0]?.articleInvoice
                                    );
                                  }}
                                  className={!article?.article?.[0]?.articleInvoice && "opacity-5"}
                                >
                                  Article Invoice{" "}
                                  <img src={downloadBlue} alt="#" />
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    downloadContract(
                                      article?.article?.[0]?.contractLink
                                    );
                                  }}
                                >
                                  Article Contract
                                  <img src={downloadBlue} alt="#" />
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      )}
                    </tr>
                  </>
                );
              })
            ) : (
              <div className="tabContentWrap">
                <div className="notArticle">
                  <figure>
                    <img src={notyet} alt="No Article" />
                  </figure>
                  {status === "draft" ? (
                    <div className="au_draft">
                      <h2>You don’t have any drafts waiting!</h2>
                      <p className="d-inline-block w-100">
                        It is time to write a new research article
                        <span>
                          We await strokes of your brilliance and colours of
                          your ideas, shaping the course of knowledge.
                        </span>
                      </p>
                    </div>
                  ) : status === "submit" ? (
                    <div className="au_submit">
                      <h2>No articles under review!</h2>
                      <p className="d-inline-block w-100">
                        Yep, you heard it right. It's like a clean slate in
                        there!
                        <span>
                          Think of this as a chance to recharge those brain
                          cells with our music{" "}
                          <u>
                            <b>playlists</b>
                          </u>
                          , or read up new ideas on our{" "}
                          <u>
                            <b>blog</b>
                          </u>
                          ...
                        </span>
                      </p>
                    </div>
                  ) : status === "approved" ? (
                    <div className="au_approved">
                      <h2>Access All approved articles here</h2>
                      <p className="d-inline-block w-100">
                        All your published content at one central location
                        <span>
                          Thank you for choosing us as your Publishing Partner.
                          We look forward to seeing your continued success...
                        </span>
                      </p>
                    </div>
                  ) : status === "rejected" ? (
                    <div className="au_rejected">
                      <h2>No rejected articles here!</h2>
                      <p className="d-inline-block w-100">
                        Articles that do not get accepted for publishing will
                        show here
                        <span>
                          Rejection is an integral part of your growth as a
                          researcher! Don't be afraid to embrace it, learn from
                          it, and come out even stronger! Happy Writing...
                        </span>
                      </p>
                    </div>
                  ) : status === "published" ? (
                    <div className="au_published">
                      <h2>No Published articles are home yet!</h2>
                      <p className="d-inline-block w-100">
                        Your personal library showcasing your valuable
                        contributions{" "}
                        <span>
                          We take pride in your work and will always ensure it
                          is within your reach. It is a testament to your hard
                          work and dedication...😊
                        </span>
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </tbody>
        </table>
      </div>

      <div className="backFooterBox">
        <div className="backBtnWrap">
          <a onClick={(e) => handleRedirect(e, "/dashboard")}>
            <span>Back</span>
            <img src={backArrow} />
          </a>
        </div>
        {totalPages > 1 && (
          <div className="paginationWrap">
            <Pagination
              boundaryRange={1}
              firstItem={null}
              lastItem={null}
              siblingRange={1}
              activePage={page}
              prevItem={{ content: <b class="icon-arrow-left" /> }}
              nextItem={{ content: <b class="icon-arrow-right" /> }}
              onPageChange={(event, data) => handlePageChange(data)}
              totalPages={totalPages}
            />
          </div>
        )}
      </div>
      <Modal
        show={publishArticle}
        size="md"
        id="unicheck-results__modal"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">Publish Article</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={(e) => setpublishArticle(false)}
          />
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
          <div className="grantDetails submitArticle pl-2 pr-2">
            <div className="row">
              <div className="col-md-12">
                <p className="mb-2">Are you ready to Step into Greatness?</p>
                <p>
                  Please pay a publishing fee of INR 6000 (will depend on
                  Anonymous or Post Open Peer Review) to see the published
                  version of your article.
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-4 mt-3">
          <button
            className="button button-primary"
            onClick={() => confirmToSubmitArticle()}
            type="button"
          >
            CONTINUE TO PAYMENT
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDetail}
        dialogClassName="modal-dialog-centered modal-lg authorModal"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">Grant Details</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="grantDetails">
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b>Grant Name</b>
                  <span className="textGreen">{grantDetail?.grantName}</span>
                </div>
              </div>
              
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="infotext">
                  <b>Available For</b>
                  <span className="textGreen">{grantDetail?.availableFor}</span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="infotext">
                  <b>Grant Value</b>
                  <span className="textGreen">
                    INR {grantDetail?.grantValue}
                  </span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="infotext">
                  <b className="pb-2">&nbsp;</b>
                  Per Individual
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="infotext">
                  <b>Start Date</b>
                  <span className="textGreen">
                    <td> {getDateFormat(grantDetail?.createdAt)}</td>
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="infotext">
                  <b>
                    End Date
                    <div className="customTool">
                      {["bottom"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Popover>
                              <Popover.Body>
                                <div className="popoverToolWrap">
                                  <p className="mb-3">
                                    The start and end dates represent the period
                                    for which the Grant is available with the
                                    platform. You can use the Grant only at the
                                    time of making the final payment.
                                  </p>
                                  <p>
                                    Please note that the Grant maybe requested
                                    by multiple researchers at the same time.
                                    Therefore, we recommend that you make the
                                    balance payment at the earliest.
                                  </p>
                                  <p>
                                    Do note that the Grant approval lapses with
                                    the lapse of your approved article should
                                    you not make the balance payment. You may be
                                    asked to reapply for the Grant and it is
                                    possible the Grant though made available to
                                    you now, is not available at a later date.
                                  </p>
                                  <div className="helpPopupText pb-0 pt-2">
                                    Need help? Write to us at
                                    <a href="mailto:contactus@vikramshilaedu.in">
                                      contactus@vikramshilaedu.in
                                    </a>
                                  </div>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            className="pe-cursor ps-1"
                            src={active_info}
                            alt="no img"
                          />
                        </OverlayTrigger>
                      ))}
                    </div>
                  </b>
                  <span className="textGreen">
                    <td> {getDateFormat(grantDetail?.endDate)}</td>
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b>Description</b>
                  <span className="">{grantDetail?.description}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b>
                    Eligibility{" "}
                    
                  </b>
                  <span className="">{grantDetail?.eligibility}</span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-4">
          {showButton === true && (
            <button
              class="button button-primary"
              onClick={() => [setShowDetail(false), setapplyGrant(true)]}
            >
              Apply For Grant
            </button>
          )}

          <div class="helpPopupText mt-4">
            Need help? Write to us at<a href="">contactus@vikramshilaedu.in</a>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={received}
        onHide={""}
        dialogClassName="modal-dialog-centered modal-lg authorModal"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">
            Grant Received <img src={grant} />
          </h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={(e) => setReceived(false)}
          />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="grantDetails">
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b>Grant Name</b>
                  <span className="textBlue">{grantDetail?.grantName}</span>
                </div>
              </div>
             
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b>Article </b>
                  <span className="textGreen">
                    {articleDetailData?.article}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b>Description</b>
                  <span className="textGreen">{grantDetail?.description}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b>Eligibility</b>
                  <span className="textGreen">{grantDetail?.eligibility}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b className="knowMore">
                    Know More
                    <div className="customTool">
                      {["bottom"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Popover>
                              <Popover.Body>
                                <div className="popoverToolWrap">
                                  <p className="mb-3">
                                    The start and end dates represent the period
                                    for which the Grant is available with the
                                    platform. You can use the Grant only at the
                                    time of making the final payment.
                                  </p>
                                  <p>
                                    Please note that the Grant maybe requested
                                    by multiple researchers at the same time.
                                    Therefore, we recommend that you make the
                                    balance payment at the earliest.
                                  </p>
                                  <p>
                                    Do note that the Grant approval lapses with
                                    the lapse of your approved article should
                                    you not make the balance payment. You may be
                                    asked to reapply for the Grant and it is
                                    possible the Grant though made available to
                                    you now, is not available at a later date.
                                  </p>
                                  <div className="helpPopupText pb-0 pt-2">
                                    Need help? Write to us at
                                    <a href="mailto:contactus@vikramshilaedu.in">
                                      contactus@vikramshilaedu.in
                                    </a>
                                  </div>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            className="pe-cursor ps-1"
                            src={active_info}
                            alt="no img"
                          />
                        </OverlayTrigger>
                      ))}
                    </div>
                  </b>

                  <ul className="knowList">
                    <li>
                      <p>
                        <i>Grant Received On</i>
                        <small>{getDateFormat(grantDetail?.updatedAt)}</small>
                      </p>

                      <p>
                        <i>Amount Received</i>
                        <small>INR {grantDetail?.grantValue}</small>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-4">
          <button
            class="button button-primary"
            onClick={(e) => setReceived(false)}
          >
            DONE
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={availableGrants}
        size="sm"
        id="unicheck-results__modal"
        tabIndex={-1}
        dialogClassName="right authorModal"
      >
        <Modal.Header className="customHeader">
          <h5 className="modal-title"> Available Grants</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={(e) => setavailableGrants(false)}
          />
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
          <div className="draftTable">
            <table className="w-100">
              <thead>
                <tr>
                  <th className="text-left">Grant Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {grantData &&
                  grantData?.length > 0 &&
                  grantData?.map((data) => {
                    return (
                      <>
                        <tr className="empty">
                          <td colspan="2"></td>
                        </tr>
                        <tr>
                          <td>{data?.grantName ? data?.grantName : "--"}</td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              className="readMore"
                              onClick={() => getGrantDetail(data?._id)}
                            >
                              {" "}
                              DETAILS
                            </a>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={applyGrant}
        dialogClassName="modal-dialog-centered modal-lg authorModal"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header className="modal-header">
            <h5 className="modal-title">Grant Application</h5>
            <button
              type="button"
              className="btn-close"
              ariaLabel="Close"
              onClick={(e) => setapplyGrant(false)}
            />
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="grantDetails">
              <div className="row">
                <div className="col-md-12">
                  <div className="infotext">
                    <b>Grant Name</b>
                    <span className="textGreen">{grantDetail?.grantName}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="infotext">
                    <b>Applicant Name</b>
                    <span className="textGreen">
                      {articleDetailData?.applicantName}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="infotext">
                  <b>Article </b>
                  <span className="textGreen">
                    {articleDetailData?.article}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="infotext">
                    <b>
                      Proposal (word limit -{" "}
                      <small className="wordStyle">300</small>)
                    </b>
                    <textarea
                      className="fieldForm"
                      id="proposal"
                      name="proposal"
                      value={formData.proposal}
                      onChange={handleChangeProposal}
                    />
                    <small className="fieldError">
                      {formErrors.proposal && `${formErrors.proposal}`}
                    </small>
                  </div>
                </div>
              </div>
              {fundingStatus && (
                <div>
                  <div className="infotext">
                    <div className="knowMore">Funding Information</div>
                  </div>
                  <ul className="knowList">
                    <li>
                      <p>{fundingStatus}</p>
                    </li>
                  </ul>
                </div>
              )}
              
            </div>
          </Modal.Body>
          <Modal.Footer className="pb-4">
            <button class="button button-primary" type="submit">
              Submit
            </button>

            <div class="helpPopupText mt-4">
              Need help? Write to us at
              <a href="">contactus@vikramshilaedu.in</a>
            </div>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={paymentSuccessful}
        size="sm"
        id="unicheck-results__modal"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered authorModal"
      >
        <Modal.Header className="modal-header">
          <img src={successImg} />
          <h5 className="modal-title">Payment Successful</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={(e) => setpaymentSuccessful(false)}
          />
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
          <div className="grantDetails submitArticle pl-2 pr-2">
            <div className="row">
              <div className="col-md-12">
                <p className="mb-2">Thank you. Your payment is successful.</p>
                <p>A copy of your payment invoice has been emailed to you.</p>
              </div>
              <div className="col-md-12 mt-4">
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="text-left">Payment Type</td>
                      <td className="text-right">UPI</td>
                    </tr>
                    <tr>
                      <td className="text-left">UPI ID</td>
                      <td className="text-right">johndoe22@okicici</td>
                    </tr>
                    <tr>
                      <td className="text-left">Transaction ID</td>
                      <td className="text-right">826475844855</td>
                    </tr>
                    <tr>
                      <td className="text-left">Amount Paid</td>
                      <td className="text-right">INR 1039.32</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-4 mt-3">
          <button
            className="button button-primary"
            onClick={() => setpaymentSuccessful()}
            type="button"
          >
            DONE
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={publishedSuccessfully}
        size="sm"
        id="unicheck-results__modal"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered authorModal"
      >
        <Modal.Header className="modal-header">
          <img src={successImg} />
          <h5 className="modal-title pl-2 pr-2">
            Article published successfully
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={(e) => setpublishedSuccessfully(false)}
          />
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
          <div className="grantDetails submitArticle pl-2 pr-2">
            <div className="row">
              <div className="col-md-12">
                <p className="mb-2">Here’s a standing ovation for you!</p>
                <p>
                  You just published your open access article and contributed to
                  making the scholarly research results more visible.
                </p>
                <p className="mt-2">Access your article below:</p>
                <div className="infotext publisedUrl">
                  <span className="textBlue">
                    Urban climate change management
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-4 mt-3">
          <button
            className="button button-primary"
            onClick={() => setpublishedSuccessfully()}
            type="button"
          >
            DONE
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} size="md" centered>
        <Confetti width={"450px"} height={"300px"} numberOfPieces={100} />
        <Modal.Header>
          <div class="modal-icon"></div>

          <Modal.Title className="modal-title">
            Thank you for applying
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
          <div className="grantDetails submitArticle pl-2 pr-2">
            <div className="row">
              <div className="col-md-12">
                <p>
                  Your grant application is successfully submitted. Please check
                  your mailbox for further details.
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="button button-primary"
            onClick={() => [setShow(false), fetchArticleData()]}
          >
            That's Great
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showReject}
        size="md"
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        centered
      >
        <Modal.Header>
          <Modal.Title>Grant Application Rejection Reason</Modal.Title>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setShowReject(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="reasonBg">{reason}</div>

          <span className="pt-3 d-block">
            Please revisit the Grant details for more clarity.
          </span>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <button
            className="button button-primary"
            onClick={() => setShowReject(false)}
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal"
        show={showWorkSaved.status}
        id="congratsMobile"
        tabIndex={-1}
        size="md"
      >
        <Modal.Header className="modal-header">
          <div class="modal-icon"> </div>
          <h2 class="modal-title pl-0 pr-0">PUBLISH ARTICLE</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() =>
              setShowWorkSaved({ articleId: "", PID: "", status: "" })
            }
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <p className="mb-1">
            We are glad you have decided to publish your article.
          </p>
          <p>We will now take you to the final payment screen.</p>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          {showWorkSaved?.finalPayment ? (
            <button
              class="button button-primary"
              onClick={() => publishArticleAPICall()}
            >
              CONTINUE
            </button>
          ) : (
            <Link
              to={{
                pathname: `/dashboard/edit-article/${showWorkSaved.articleId}`,
              }}
            >
              <button class="button button-primary">CONTINUE</button>
            </Link>
          )}
        </Modal.Footer>
      </Modal>

      <ReasonModal
        showNotAccepted={showNotAccepted}
        setShowNotAccepted={setShowNotAccepted}
        reasonHandle={reasonHandle}
        reasonComments={reasonComments}
        peActiveTab={status}
        _id={articleId}
        rejectionArticleId={rejectionArticleId}
        prReviewReport={prReviewReport}
        userType="Author"
      />

      <NeedHelpModal
        showHelpModal={showHelpModal}
        setShowHelpModal={setShowHelpModal}
      />

      {amendmentData?.length >= 2 ? (
        <Modal
          size="lg"
          show={noMoreAmendment}
          id="congratsMobile"
          backdrop="static"
          tabIndex={-1}
          className="  authorModal authorModalRed"
          centered
        >
          <Modal.Header className="modal-header">
            <div className="modal-icon">
              {" "}
              <img src={alertSvg} alt="#" />
            </div>
            <h5 className="modal-title">
              Important Update for Your Research Article
            </h5>
            <button
              type="button"
              className="btn-close"
              ariaLabel="Close"
              onClick={() => setNoMoreAmendment(false)}
            ></button>
          </Modal.Header>
          <Modal.Body className="pt-0 pb-0">
            <div className="congratulationsPopup">
              <p>
                We have noticed that you have made amendments to your article on
                two occasions up to this point.
              </p>
              <p>
                In accordance with our publishing guidelines, we permit a
                maximum of two revisions.
              </p>
              <p>
                Please be aware that any further amendments to the current
                article will be considered a new version of the existing
                article. To initiate this update, please accept the button
                below, and we will send you a coupon worth {nationality === "withInIndia" ? "INR 5000" : "USD 250"}, applicable
                against the current publishing price of a new article.
              </p>
              <p>
                We acknowledge the importance of honing your research, and we
                are available to assist you throughout this endeavour. By
                selecting the update option, you ensure that your article
                remains a reflection of your most recent discoveries and
                insights.
              </p>
              <div className="text-center mb-4">
                <button
                  className="button button-primary redBtn"
                  onClick={() => {
                    setNoMoreAmendment(false);
                    setUpdateArticlePopup(true);
                  }}
                >
                  Yes, I Want To Update My Article
                </button>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="pb-0 pt-0">
            <div className="helpPopupText pb-2">
              In case of any queries, please reach us at{" "}
              <a href="mailto:contactus@vikramshilaedu.in">
                contactus@vikramshilaedu.in
              </a>
            </div>
          </Modal.Footer>
        </Modal>
      ) : (
        <RequestAmmended
          amendmentPopup={amendmentPopup}
          setAmendmentUpdateTrigger={setAmendmentUpdateTrigger}
          setamendmentPopup={setamendmentPopup}
          amendmentSharingPopup={amendmentSharingPopup}
          setamendmentSharingPopup={setamendmentSharingPopup}
          amendmentArticleId={amendmentArticleId}
          amendmentData={amendmentData}
          articleName={articleName}
          authorName={authorName}
        />
      )}

      {articleUpdateRequest && articleUpdateNewVersion ? (
        <UpdateArticleRequest
          showNewArticleRequest={showNewArticleRequest}
          setShowNewArticleRequest={setShowNewArticleRequest}
          amendmentArticleId={amendmentArticleId}
        />
      ) : articleUpdateRequest ? (
        <UpdateArticleRequest
          alreadyUpdatedPopup={alreadyUpdatedPopup}
          setAlreadyUpdatedPopup={setAlreadyUpdatedPopup}
          amendmentArticleId={amendmentArticleId}
        />
      ) : (
        <UpdateArticleRequest
          updateArticlePopup={updateArticlePopup}
          setUpdateArticlePopup={setUpdateArticlePopup}
          updateSharingPopup={updateSharingPopup}
          setUpdateSharingPopup={setUpdateSharingPopup}
          amendmentArticleId={amendmentArticleId}
          articleName={articleName}
          authorName={authorName}
          journalName={journalName}
          subjectName={subjectName}
          setUpdateTrigger={setUpdateTrigger}
        />
      )}

      <div
        className="pullUp"
        onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <b className="icon-arrow-right" />
      </div>
    </DashboardLayout>
  );
};

export default MyArticle;
