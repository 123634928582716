import { CONTENT_HEADING } from "../../constants";
import Content from "../global/Content";
import { useNavigate, Link } from "react-router-dom";

const RolesOfPeers = () => {
	const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
	return (
		<Content title={CONTENT_HEADING.ROLES_OF_PEERS}>
			<p className='pera-text text-center subtext_col-wrp tagp widthManage'>
				Peer Reviewers play a critical role in the Pre-Publishing Double
				Anonymous Peer Review
				<p className='pera-text text-center tagp'>
					process of Aakashganga, the Open Access Journal platform.
				</p>{" "}
				<p className="mt-4">Their responsibilities typically include:</p>
			</p>

			<div className='row align-items-center m-0'>
				<div className='col-lg-8'>
					<div className='row align-items-center mb-4 mt-2'>
						<div className='col-lg-3 texmanageBox'>
							<img
								src='assets/images/role-1.png'
								alt='Evaluating Manuscripts'
								className='icon-oimage tagimg'
							/>
						</div>
						<div className='col-lg-8'>
							<h2 className='small-title colorGreen tagh2'>
								Evaluating Manuscripts
							</h2>

							<p className='pera-text tagp'>
								Peer Reviewers will assess the scientific
								quality, validity, and methodology of the
								submitted manuscripts.
							</p>

							<p className='pera-text tagp'>
								This may include but not limited to examining
								the research design, data analysis, results, and
								conclusions to ensure they are robust and
								supported by evidence.
							</p>
						</div>
					</div>

					<div className='row align-items-center mb-4'>
						<div className='col-lg-3 texmanageBox'>
							<img
								src='assets/images/Providing Constructive Feedback.png'
								alt='Providing Constructive Feedback'
								className='icon-oimage tagimg'
							/>
						</div>
						<div className='col-lg-8'>
							<h2 className='small-title colorGreen tagh2'>
								Providing Constructive Feedback
							</h2>

							<p className='pera-text providing tagp'>
								Peer Reviewers are expected to offer
								constructive feedback for the submitted
								manuscript, in the prescribed format.{" "}
							</p>

							<p className='pera-text providing tagp'>
								All remarks will be visible to the Publishing
								Editor however the identity of the Peer Reviewer
								remains anonymous.{" "}
							</p>
						</div>
					</div>
					<div className='row align-items-center mb-4'>
						<div className='col-lg-3 texmanageBox'>
							<img
								src='assets/images/Assessing Originality And Significance.png'
								alt='Assessing Originality And Significance'
								className='icon-oimage tagimg'
							/>
						</div>
						<div className='col-lg-8'>
							<h2 className='small-title colorGreen tagh2'>
								Assessing Originality And Significance
							</h2>

							<p className='pera-text significance tagp'>
								Peer Reviewers will evaluate the contribution
								every article makes to the discipline and where
								possible check for authenticity of process/data.{" "}
							</p>

							<p className='pera-text abstarct tagp'>
								A Mega Journal by definition has a broader scope
								of acceptance as long as the article makes a
								contribution to the discipline.{" "}
							</p>
						</div>
					</div>

					<div className='row align-items-center mb-4'>
						<div className='col-lg-3 texmanageBox'>
							<img
								src='assets/images/Meeting Deadlines.png'
								alt='Meeting Deadlines'
								className='icon-oimage tagimg'
							/>
						</div>
						<div className='col-lg-8'>
							<h2 className='small-title colorGreen tagh2'>Meeting Deadlines</h2>

							<p className='pera-text significance tagp'>
								Reviewers are expected to complete their reviews
								within a reasonable timeframe (as agreed when
								accepting the assignment) to ensure timely
								publication. If they anticipate any delays, they
								should inform the admin of the platform
								promptly.{" "}
							</p>
						</div>
					</div>
				</div>
				<div className='col-lg-4 '>
					<div className="questionDes">
						<p className='pera-text tagp '>
							In case of any questions,<br/>Peer Reviewers should write to{" "}
							<Link className="underline-custom" to="mailto:contactus@vikramshilaedu.in"><strong>contactus@vikramshilaedu.in </strong> </Link>
							{" "}<br/>to resolve their queries.{" "}
						</p>
						<div className='register-button click-here-shastra tagbutton'>
							<button  onClick={() => window.location = "mailto:contactus@vikramshilaedu.in"} >Contact Us</button>
							
						</div>
					</div>
				</div>
			</div>
		</Content>
	);
};

export default RolesOfPeers;
