import React from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/common/Sidebar";
import "./index.scss";

const DashboardLayout = ({ children, hideSidebar = false }) => {
  function checkWriteArticlePage() {
    let isArticleWritePage = false;
    const router = useLocation();
    if (
      router.pathname.includes("/dashboard/edit-article") ||
      router.pathname.includes("/dashboard/submit-article") || router.pathname.includes("/edit-article")
    )
      isArticleWritePage = true;
    return isArticleWritePage;
  }

  return (
    <div
      className={
        checkWriteArticlePage() === false
          ? "inner-pages"
          : " inner-pages full-view-page"
      }
    >
      <div className="centerSection">
        {hideSidebar ? (
          <div className="text-initial">{children}</div>
        ) : (
          <div className="previewContent pageWrap">
            {checkWriteArticlePage() === false && (
              <div className="pageLeft">
                <Sidebar />
              </div>
            )}

            <div
              className={
                checkWriteArticlePage() === false
                  ? "pageRight text-initial"
                  : "pageRight text-initial w-100"
              }
            >
              <div className="innerMergin ffff">{children}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardLayout;
