import { useMutation } from 'react-query';
import { assigneArticleToReview,downloadSimiliratyReport,getJournalDetails,getJournal,getArticleById, saveArticleToDraft, updateArticle, getOrcidID,iniitatePayment,updatePaymentStatus,getArticleWithoutToken,downloadSendReceipt,getALLOfferForPayment,getOfferById,getMudraByUserId,getProfanityList,saveAuthorFeedback,getJournalSubjects,articlePdfDownload} from '../../../store/apiCalls/articles';

import { genericError } from '../../../utils';


function SaveToDraft(sucessHandler) {
    return {
        ...useMutation(saveArticleToDraft, {
            onSuccess: (data) => { sucessHandler(data) },
            onError: (errMsg) => {
                genericError(errMsg.response.data); 
            },
        })
    }
}
function FetchArticleById(sucessHandler) {
    return {
        ...useMutation(getArticleById,
            { 
                onSuccess: (rData) => {
                    sucessHandler(rData)
                },
                onError: (err) => {
                    genericError(err.response.data)
                }
            },
        )
    }
}

function FetchArticleWithoutToken(sucessHandler) {
    return {
        ...useMutation(getArticleWithoutToken,
            { 
                onSuccess: (rData) => {
                    sucessHandler(rData)
                },
                onError: (err) => {
                    genericError(err.response.data)
                }
            },
        )
    }
}

function FetchJournalByKeyWord(sucessHandler) {
    return {
        ...useMutation(getJournal,
            {
                onSuccess: (rData) => {
                    sucessHandler(rData)
                },
                onError: (err) => {
                    genericError(err.response.data)
                }
            },
        )
    }
}

function FetchJournalDetails(sucessHandler) {
    return {
        ...useMutation(getJournalDetails,
            {
                onSuccess: (rData) => {
                    sucessHandler(rData)
                },
                onError: (err) => {
                    genericError(err.response.data)
                }
            },
        )
    }
}
function FetchSimiilarityReport(sucessHandler) {
    return {
        ...useMutation(downloadSimiliratyReport,
            {
                onSuccess: (rData) => {
                    sucessHandler(rData)
                },
                onError: (err) => {
                    genericError(err.response.data)
                }
            },
        )
    }
}

function EditCurrentArticle(sucessHandler) {
    return {
        ...useMutation(updateArticle,
            {
                onSuccess: (rData) => {
                    sucessHandler(rData)
                },
                onError: (err) => {
                    genericError(err.response.data)
                }
            },
        )
    }
}
function findReviewer(sucessHandler) {
    return {
        ...useMutation(assigneArticleToReview, {
            onSuccess: (data) => { sucessHandler(data) },
            onError: (errMsg) => {
                genericError(errMsg.response.data); 
            },
        })
    }
}

function GetOrcidID(sucessHandler) {
    return {
        ...useMutation(getOrcidID, {
            onSuccess: (data) => { sucessHandler(data) },
            onError: (errMsg) => {
                genericError(errMsg.response.data); 
            },
        })
    }
}

function iniitatePayments(sucessHandler) {
    return {
        ...useMutation(iniitatePayment, {
            onSuccess: (data) => { sucessHandler(data) },
            onError: (errMsg) => {
                genericError(errMsg.response.data); 
            },
        })
    }
}

function updatePayment(sucessHandler) {
    return {
        ...useMutation(updatePaymentStatus, {
            onSuccess: (data) => { sucessHandler(data) },
            onError: (errMsg) => {
                genericError(errMsg.response.data); 
            },
        })
    }
}

function downloadOrsendReceipt(sucessHandler) {
    return {
        ...useMutation(downloadSendReceipt, {
            onSuccess: (data) => { sucessHandler(data) },
            onError: (errMsg) => {
                genericError(errMsg.response.data); 
            },
        })
    }
}

function FetchOfferById(sucessHandler) {
    return {
        ...useMutation(getOfferById,
            { 
                onSuccess: (rData) => {
                    sucessHandler(rData)
                },
                onError: (err) => {
                    genericError(err.response.data)
                }
            },
        )
    }
}

function FetchAllOfferForPayment(sucessHandler) {
    return {
        ...useMutation(getALLOfferForPayment,
            { 
                onSuccess: (rData) => {
                    sucessHandler(rData)
                },
                onError: (err) => {
                    genericError(err.response.data)
                }
            },
        )
    }
}

function FetchMudraByUserId(sucessHandler) {
    return {
        ...useMutation(getMudraByUserId,
            { 
                onSuccess: (rData) => {
                    sucessHandler(rData)
                },
                onError: (err) => {
                    genericError(err.response.data)
                }
            },
        )
    }
}

function FetchProfanityList(sucessHandler) {
    return {
        ...useMutation(getProfanityList,
            { 
                onSuccess: (rData) => {
                    sucessHandler(rData)
                },
                onError: (err) => {
                    genericError(err.response.data)
                }
            },
        )
    }
}

function SaveAuthorsFeedback(sucessHandler) {
    return {
        ...useMutation(saveAuthorFeedback, {
            onSuccess: (data) => { sucessHandler(data) },
            onError: (errMsg) => {
                genericError(errMsg.response.data); 
            },
        })
    }
}

function FetchSubjectSpecialization(sucessHandler) {
    return {
        ...useMutation(getJournalSubjects,
            { 
                onSuccess: (rData) => {
                    sucessHandler(rData)
                },
                onError: (err) => {
                    genericError(err.response.data)
                }
            },
        )
    }
}

function downloadArticlePdf(sucessHandler) {
    return {
        ...useMutation(articlePdfDownload, {
            onSuccess: (data) => { sucessHandler(data) },
            onError: (errMsg) => {
                genericError(errMsg.response.data); 
            },
        })
    }
}


export {
    SaveToDraft,
    FetchArticleById,
    FetchArticleWithoutToken,
    FetchJournalByKeyWord,
    EditCurrentArticle,
    FetchJournalDetails,
    FetchSimiilarityReport,
    findReviewer,
    GetOrcidID,
    iniitatePayments,
    updatePayment,
    downloadOrsendReceipt,
    FetchOfferById,
    FetchAllOfferForPayment,
    FetchMudraByUserId,
    FetchProfanityList,
    SaveAuthorsFeedback,
    FetchSubjectSpecialization,
    downloadArticlePdf
}
