import { React, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const NavigatingPE = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(
    (state) => state?.LoginReducer?.userLoginDetails?.token ?? null
  );
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard/publishing-editor?type=publisheditor");
    } else {
      localStorage.setItem("getPEType", "PE");
      navigate("/");
    }
  }, []);

  return <></>;
};

export default NavigatingPE;
