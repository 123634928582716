import React, { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import {
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import copy from "copy-to-clipboard";
import AakashgangaHeader from "../global/HeaderComponent";
import AakashgangaFooter from "../global/FooterComponent";
import NewsLetter from "../global/NewsLetter";
import SubHeader from "../global/SubHeader";
import { SUB_HEADINGS } from "../../constants";
import kalashIcon from "../../assets/img/icons/kalash.png";
import email48 from "../../assets/img/icons/icons-gmail.png";
import whatsapp48 from "../../assets/img/icons/whatsapp-48.png";
import twitter48 from "../../assets/img/icons/twitter-48.png";
import { Link } from "react-router-dom";
import SubmitArticleModal from "../Modal/SubmitArticleModal";
import FeedbackFlow from "../Feedback";
import AcceptModal from "../../containers/autherFlow/prdashboard/AcceptModal";
import { SERVER_URL } from "../../config";
import ReferLoginModal from "../Modal/ReferLoginModal";
import { Helmet } from 'react-helmet';

const Sitemap = () => {
  const shareUrl = SERVER_URL;
  const modalBody = ``;
  const code = useSelector((state) => state?.LoginReducer?.user?.promoCode);
  const title = `I have registered with Aakashganga, a new open access journals platform, built with love in India, for the world.
  As a researcher, I appreciate their vision of removing traditional barriers to publishing research, and democratizing knowledge through their automated digital Open Access Journals Platform to accelerate scientific progress.
  I invite you to join Aakashganga. Use the special invitation code: ${code} to register on the platform and publish your research. Their APCs are one of the lowest in the world, and their smart automated solutions fast track research publishing.`;
  const token = localStorage.getItem("usertoken");
  const [uploadArticle, setUploadArticle] = useState(false);
  const [showReferAndEarn, setShowReferAndEarn] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showLoginRefer, setShowLoginRefer] = useState(false);

  const getStatus = () => {
    setShowFeedbackModal(false);
    setShowAccept(true);
  };
  const getStatusClose = () => {
    setShowFeedbackModal(false);
  };

  const handleCodeCopy = (e) => {
    copy(code);
    e.target.textContent = "Copied";
    e.target.style.setProperty("color", "#404040");
  };

  return (
    <>
     <Helmet>
     <title>Sitemap | Aakashganga Open</title>
     </Helmet>
      <div className="tagbody">
        <AakashgangaHeader />
        <h1 className="seo-related-tag">
          Sitemap
        </h1>
        <main className="main">
          <SubHeader title={SUB_HEADINGS.SITEMAP} />
          <div className="siatemapPage">
            <div className="sitemapBox">
              <div className="sitemapListBox">
                <div className="sitemapList">
                  <h2>PRIMARY NAVIGATION</h2>
                  <ul>
                    <li>
                      <Link to="/registration" target="_blank">
                        Register with Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/login" target="_blank">
                        Login to Aakashganga
                      </Link>
                    </li>
                    <li>
                      {!token ? (
                        <Link to="/login">Publish Your Article</Link>
                      ) : (
                        <Link onClick={() => setUploadArticle(true)}>
                          Publish Your Article
                        </Link>
                      )}
                    </li>
                    <li>
                      <Link to="/apc" target="_blank">
                        Article Publishing Charges
                      </Link>
                    </li>
                    <li>
                      <Link to="/about-megajournals" target="_blank">
                        Mega Journals
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/domestic-societies-partnerships"
                        target="_blank"
                      >
                        Societies, Universities and Institutes
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="sitemapList">
                  <h2>MEGA JOURNALS</h2>
                  <ul>
                    <li>
                      <Link
                        to={`/mega-journals/social-sciences`}
                        target="_blank"
                      >
                        Samaj Shastra
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/mega-journals/business-management`}
                        target="_blank"
                      >
                        Chanakya Shastra
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/mega-journals/ayurveda-and-yoga`}
                        target="_blank"
                      >
                        Charakh Shastra
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/explore-journals/engineering-megajournal-AYSJE`}
                        target="_blank"
                      >
                        Abhiyantran Shastra
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/explore-journals/information-technology-megajournal-SSJIT`}
                        target="_blank"
                      >
                        Soochna Shastra
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/mega-journals/ayurveda-and-yoga`}
                        target="_blank"
                      >
                        Vigyan Shastra
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/explore-journals/Ayurveda-Yoga-megajournal-AJAY`}
                        target="_blank"
                      >
                        Ayuryoga Shastra
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="sitemapList">
                  <h2>SOCIETY JOURNALS</h2>
                </div>

                <div className="sitemapList">
                  <h2>ARTICLES</h2>
                  <ul>
                    <li>
                      <Link
                        to="https://www.vikramshilaedu.in/browse"
                        target="_blank"
                      >
                        Browse
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="sitemapList">
                  <h2>CONTACT US</h2>
                  <ul>
                    <li>
                      <Link to="mailto:contactus@vikramshilaedu.in">
                        contactus@vikramshilaedu.in
                      </Link>
                    </li>
                    <li>
                      {!token ? (
                        <Link to="/login" target="_blank" className="taga">
                          Feedback
                        </Link>
                      ) : (
                        <Link
                          onClick={() => setShowFeedbackModal(true)}
                          className="taga"
                        >
                          Feedback
                        </Link>
                      )}
                    </li>
                    <li>
                      <Link to="/contact-us" target="_blank">
                        Write to Us
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="sitemapList">
                  <h2>SUBSCRIBE</h2>
                  <ul>
                    <li>
                      <Link onClick={() => window.scrollTo(0, 2450)}>
                        Remain updated
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="sitemapList">
                  <h2>SOCIAL MEDIA</h2>
                  <ul>
                    <li>
                      <Link
                        to="https://m.facebook.com/100089485867225/"
                        target="_blank"
                      >
                        Facebook
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://twitter.com/VikramshilaL"
                        target="_blank"
                      >
                        Twitter
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.youtube.com/watch?v=Iewt4-w2R9g"
                        target="_blank"
                      >
                        Youtube
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.linkedin.com/company/vikramshila-education-pvt-ltd/"
                        target="_blank"
                      >
                        LinkedIn
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="sitemapList">
                  <h2>RESOURCES</h2>
                  <ul>
                    <li>
                      <Link
                        to="https://vikramshilaedu.in/browse"
                        target="_blank"
                      >
                        Videos
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://vikramshilaedu.in/GyanKosh"
                        target="_blank"
                      >
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://vikramshilaedu.in/presentations"
                        target="_blank"
                      >
                        PowerPoints
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="sitemapListBox">
                <div className="sitemapList">
                  <h2>HOMEPAGE</h2>
                  <ul>
                    <li>
                      <Link to="/" onClick={() => window.scrollTo(0, 300)}>
                        Publish Your Article
                      </Link>
                    </li>
                    <li>
                      <Link to="/?page=Start-Your-Submission-Journey">
                        Start Your Submission Journey
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={() => window.scrollTo(0, 2050)}>
                        Launch Offer
                      </Link>
                    </li>
                    <li>
                      <Link to="/?page=Seven-Mega-Journals">
                        Seven Mega Journals
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={() => window.scrollTo(0, 3000)}>
                        9 Bold Ways to Publish
                      </Link>
                    </li>
                    <li>
                      <Link to="/?page=Testimonials">
                        Testimonials from Experts
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="sitemapList">
                  <h2>Publish</h2>
                  <ul>
                    <li>
                      <Link to="/apc" target="_blank">
                        Article Publishing Charge
                      </Link>
                    </li>
                    <li>
                      <Link to="/peer-review-models" target="_blank">
                        Peer Review Models
                      </Link>
                    </li>
                    <li>
                      <Link to="/publish-with-us" target="_blank">
                        Publish with Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/how-we-publish" target="_blank">
                        How We Publish
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="sitemapList">
                  <h2>LEARN</h2>
                  <ul>
                    <li>
                      <Link to="/author-submission-guidelines" target="_blank">
                        Submission Guidelines
                      </Link>
                    </li>
                    <li>
                      <Link to="/peer-reviewer-guidelines" target="_blank">
                        Reviewer Guidelines
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/publishing-editor-and-responsibility"
                        target="_blank"
                      >
                        Editor Responsibilities
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://vikramshilaedu.in/resourcecentre"
                        target="_blank"
                      >
                        Resources: Videos, Blog, PPTs
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="sitemapList">
                  <h2>EXPLORE</h2>
                  <ul>
                    <li>
                      <Link to="javascript:;" className="opacity-5">
                        Research Disciplines
                      </Link>
                    </li>
                    <li>
                      <Link to="/open-access" target="_blank">
                        Open Access
                      </Link>
                    </li>
                    <li>
                      <Link to="/about-megajournals" target="_blank">
                        Mega Journals
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/domestic-societies-partnerships"
                        target="_blank"
                      >
                        Societies, Universities, Institutes
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="sitemapList">
                  <h2>PARTICIPATE</h2>
                  <ul>
                    <li>
                      {!token ? (
                        <Link to="/login">Submit an Article</Link>
                      ) : (
                        <Link onClick={() => setUploadArticle(true)}>
                          Submit an Article
                        </Link>
                      )}
                    </li>
                    <li>
                      <Link to="/registration" target="_blank">
                        Register with Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq" target="_blank">
                        Frequently asked questions
                      </Link>
                    </li>
                    {token ? (
                      <li>
                        <Link
                          to="javascript:;"
                          onClick={() => setShowReferAndEarn(true)}
                        >
                          Refer and Earn
                        </Link>
                      </li>
                    ) : (
                      <li>
                        <Link to="javascript:;" onClick={() => setShowLoginRefer(true)}>Refer and Earn</Link>
                      </li>
                    )}
                  </ul>
                </div>

                <div className="sitemapList">
                  <h2>VIKRAMSHILA</h2>
                  <ul>
                    <li>
                      <Link
                        to="https://vikramshilaedu.in/about"
                        target="_blank"
                      >
                        Who We Are
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://vikramshilaedu.in/teams"
                        target="_blank"
                      >
                        The Team
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://vikramshilaedu.in/advisory"
                        target="_blank"
                      >
                        Advisory Board
                      </Link>
                    </li>
                    <li>
                      <Link to="https://vikramshilaedu.in" target="_blank">
                        Vikramshila Education
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </main>

        <NewsLetter />
        <AakashgangaFooter />
        <SubmitArticleModal
          uploadArticle={uploadArticle}
          setUploadArticle={setUploadArticle}
        />
        {showFeedbackModal && (
          <FeedbackFlow
            handleModalClose={() => {}}
            code={""}
            modalStat={""}
            status={getStatus}
            statusClose={getStatusClose}
          />
        )}
        <AcceptModal
          showAccept={showAccept}
          setShowAccept={setShowAccept}
          title={"Your feedback has been successfully received."}
          body={modalBody}
          btnText="Ok"
        />
        <Modal
          show={showReferAndEarn}
          id="referModal"
          tabIndex={-1}
          className="md-x authorReferPopup"
          keyboard={false}
          backdrop="static"
        >
          <div>
            <div className="modal-content border-0 referContent">
              <div className="modal-header pb-0">
                <button
                  type="button"
                  className="btn-close"
                  onClick={(e) => setShowReferAndEarn(false)}
                  aria-label="Close"
                />
                <h5 className="modal-title referText">Refer and Earn</h5>
                <p className="bigSubText">Invite 5 friends to register</p>
                <div className="mudraCount">
                  <span>
                    <b>Earn</b>
                    <br />
                    250 Mudras
                  </span>
                  <i>
                    <img src={kalashIcon} alt="#" />
                  </i>
                </div>
              </div>
              <div className="modal-body pt-0 pb-0">
                <div className="px-2 text-center shareModal">
                  <div className="referStep">
                    <ul>
                      <li>
                        <span>
                          <b className="icon-persons" />
                        </span>
                        Invite 5 friends to register
                      </li>
                      <li>
                        <span>
                          <b className="icon-register" />
                        </span>
                        Your friends register
                      </li>
                      <li>
                        <span>
                          <b className="icon-wallet" />
                        </span>
                        You receive 250 Mudras{" "}
                      </li>
                    </ul>
                  </div>
                  <p className="receiveText">
                    Your friends also receive 250 Mudras
                    <br />
                    each as joining bonus after registration.
                  </p>
                  <div className="socialShare">
                    <small>Invite via</small>
                    <ul className="socialRefer">
                      <li>
                        <EmailShareButton
                          separator=" "
                          subject="Your friend has invited you to join Mission Aakashganga"
                          body={title}
                          url=" "
                          shareUrl={title}
                        >
                          <img src={email48} alt="#" />
                        </EmailShareButton>
                      </li>

                      <li>
                        <WhatsappShareButton
                          url={shareUrl}
                          title={title}
                          separator=" "
                          className="Demo__some-network__share-button"
                        >
                          <img src={whatsapp48} alt="#" />
                        </WhatsappShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          url={shareUrl}
                          title={title}
                          separator=" "
                          className="Demo__some-network__share-button"
                        >
                          <img src={twitter48} alt="#" />
                        </TwitterShareButton>
                      </li>
                    </ul>
                  </div>
                  <div className="divideLine">
                    <b>or</b>
                  </div>
                  <div>
                    <small>Refer using your unique code</small>
                    <div className="codeWrap">
                      <p id="#code" className="pr-color flex-fill">
                        {code}
                      </p>
                      <p
                        onClick={(e) => handleCodeCopy(e)}
                        className="sr-color"
                      >
                        <b id="code" className="icon-copy sr-color" />
                        &nbsp;&nbsp;Copy Code
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer pt-0 pb-0">
                <div class="questionBox">
                  <span>
                    Receiving 250 Mudras is conditional to 5 individuals
                    registering with us using your referral code
                  </span>
                  <span>
                    You will be able to check your reward in the “Mudrakosh”
                    section
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <ReferLoginModal
          showLoginRefer={showLoginRefer}
          setShowLoginRefer={setShowLoginRefer}
        />
      </div>
    </>
  );
};

export default Sitemap;
