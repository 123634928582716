import React from "react";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";

const ReferLoginModal = ({ showLoginRefer, setShowLoginRefer }) => {
  const navigate = useNavigate();
  
  return (
    <Modal
      show={showLoginRefer}
      size="md-x"
      tabIndex={-1}
      dialogClassName="modal-dialog-centered modal-md authorModal md-x"
    >
      <Modal.Header className="modal-header pb-0">
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={(e) => setShowLoginRefer(false)}
        />
      </Modal.Header>
      <Modal.Body className="pb-0 pt-0">
        <div className="grantDetails pl-2 pr-2 mb-3">
          <div className="writeYourContent pb-0">
            <p className="mb-0">
              <i className="yellowTextWelcome">
                Please login or register to use the <u>Refer and Earn</u> Scheme
                and earn Mudras (INR) in your Aakashganga Wallet.
              </i>
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <button
          class="button button-primary"
          onClick={() => {
            setShowLoginRefer(false);
            navigate("/login");
          }}
        >
          Login
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReferLoginModal;
