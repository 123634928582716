import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Modal, Button } from "react-bootstrap";
import { subscribeHomePgaeNotification } from "../../store/apiCalls/profileDetails";

const SubscribeModalAlert = ({ subscribeModalAlert, setsubscribeModalAlert}) => {
  const initialValues = {
    emailId: "",
  };
  const formRef = useRef();
  const validate = Yup.object({
    emailId: Yup.string()
      .email("*Email is invalid")
      .required("*Email is required"),
  });
  const [show, setShow] = useState(false);
  const [subscribedShow, setSubscribedShow] = useState(false);
  const contactUsData = async (values) => {
    const resp = await subscribeHomePgaeNotification({
      email: values?.emailId,
    });
    if (resp.data?.success) {
      setShow(true);
      // setEmail("");
    } else {
      setSubscribedShow(true);
      // setEmail("");
    }
  };
  return (
    <div>
      <Modal
        show={subscribeModalAlert}
        size="md"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered authorModal"
      >
      <Modal.Header className="modal-header pb-0">
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={(e) => setsubscribeModalAlert(false)}
        />
        <Modal.Title className="modal-title pl-0">
              <h2 className="fontsizeshastra ml-4">Subscribe</h2>
            </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0 pt-0">
        <div className="grantDetails pl-2 pr-2 mb-3">
          <div className="writeYourContent pb-0 pt-0">
            <p style={{fontSize:"18px",color:"black",lineHeight:"1.3"}}>Get top-notch content straight to your inbox. Just the good stuff, no spam. Subscribe!</p>
          <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  innerRef={formRef}
                  validationSchema={validate}
                  onSubmit={(values, { resetForm }) => {
                    contactUsData(values);
                    resetForm();
                  }}
                >
                  {(props) => (
                    <Form>
                      <div className="ag_newsletter_input">
                        <input
                        className="taginput"
                          type="email"
                          placeholder="Enter your email"
                          onChange={(event) =>
                            props.setFieldValue("emailId", event.target.value)
                          }
                          name="emailId"
                          value={props.values.emailId}
                        />
                        {props?.errors?.emailId && props?.touched.emailId ? (
                          <small><div className="error-field newsStyle">
                            {props?.errors?.emailId}
                          </div></small>
                        ) : null}
                        <button type="submit">
                          <img
                          className="tagimg"
                            src="/assets/images/newsletter-btn.svg"
                            alt="NewsLetter"
                          />
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <Modal
        dialogClassName="modal-dialog-centered"
        onHide={(e) => setShow(false)}
        show={show}
        className="success-modal md-x"
        id="subscribed-msg"
        tabIndex={-1}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header
          closeButton
          className="justify-content-center pb-1 px-0 pt-0"
        >
          <Modal.Title className="subscribingTitle">Thank you for subscribing</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="subscribingModal">
            <p>We are excited to have you join our community.</p> 
            <p>You have been successfully added to our list.</p>
            <span>We will be in touch soon. Keep Safe, Keep Smiling.</span>
            
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center d-grid mt-0 pb-4 pt-2">
          <Button className="button-primary subscribingBtn" onClick={(e) => setShow(false)}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        dialogClassName="modal-dialog-centered"
        onHide={(e) => setSubscribedShow(false)}
        show={subscribedShow}
        className="success-modal"
        id="subscribed-msg"
        tabIndex={-1}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header
          closeButton
          className="justify-content-center pb-1 px-0 pt-0"
        >
          <Modal.Title className="subscribedStyle">
            <p className="good-news">GOOD NEWS!</p> You are already subscribed
            with Mission AakashGanga
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="justify-content-center d-grid">
          <Button
            className="button-primary"
            onClick={(e) => setSubscribedShow(false)}
          >
            Thanks, I got it
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SubscribeModalAlert;
