import React, { useRef,useState, useEffect } from "react";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Pagination } from "semantic-ui-react";
import { SERVER_URL } from "../config";
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share"; 
import SocietyJournalImg from "../assets/assets_html/images/society homepage.png";
import pg01 from "../assets/assets_html/images/perv_arrow.png";
import pg02 from "../assets/assets_html/images/next_arrow.png";
import NewsLetter from "../components/global/NewsLetter";
import coin from "../assets/img/icons/coinT.svg";
import JournalMainBanner from "../components/journals/JournalMainBanner";
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import Modal from "react-bootstrap/Modal";
import YoutubeIcon from "../assets/assets_html/images/Youtube-Icon.svg";
import {
  getJournalDetailById,
  getPEForJournalHomePage, 
} from "../store/apiCalls/dashboard";
import { getJournalSubjects } from "../store/apiCalls/articles";
import SubmitArticleModal from "../components/Modal/SubmitArticleModal";
import { toast } from "react-toastify";
import { Accordion } from "react-bootstrap";
import { Helmet } from 'react-helmet';
const EditorialBoard = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const journalName = location?.state?.journalName;
  const journalId = location?.state?.journalId;
  // console.log(journalId);  
  const token = localStorage.getItem("usertoken");
  const { id, discipline } = params;
  const navigate = useNavigate();
  const limit = 10;
  const [page, setPage] = useState(1);
  const [activeType, setActiveType] = useState("editorial-board");
  // const societyJournal = location?.state?.societyJournal;
  const societyJournal = "Society Journal";
  const [specializationOptions, setSpecializationOptions] = useState([]);
  const [show, setShow] = useState(false);
  const [showRec, setShowRec] = useState(false);
  const [showAdvertising, setShowAdvertising] = useState(false);

  const pageName = location?.state?.pageName;
  // const journalName = location?.state?.journalName;
  const SHARE_URL = `${SERVER_URL}/explore-journals/${id}`;
  const [modalPageName, setModalPageName] = useState(pageName);
  const [modalJournalName, setJournalModalName] = useState(journalName);

  const [publishEditorData, setPublishEditorData] = useState([]);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [uploadArticle, setUploadArticle] = useState(false);
  let totalPages = Math.ceil(publishEditorData?.totalCount / limit);
  const [boardOrder, setboardOrder] = useState([
    {
      1: "",
      2: "",
      3: "",
      4: "",
    },
  ]);
  const title = `I recommend that you checkout ${modalJournalName}. The process of submission is fully automated and it offers affordable APCs, and a very short turnaround time.`;
  const handleCodeCopy = (e) => {
    copy(SHARE_URL);
    e.target.textContent = "Copied";
    e.target.style.setProperty("color", "#ffffff");
  };
  const addNewOrder = () => {
    setboardOrder(prevState => {
      const newOrder = { ...prevState[0] }; // Create a copy of the first object in the array
      const nextKey = Object.keys(newOrder).length + 1; // Calculate the next key number
      newOrder[nextKey] = ""; // Add the new key with an empty string value
      return [newOrder]; // Return the updated array with the new object
    });
  };
  const isMounted = useRef(false);

  const updateBoardOrder = (key, value) => {
    // console.log("here --- ",key+" "+ value);
    setboardOrder((prevBoardOrder) =>
      prevBoardOrder.map((item) => ({
        ...item,
        [key]: value, 
      }))
    );
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    // Add the "th" suffix to the day
    let daySuffix = "th";
    if (day === 1 || day === 21 || day === 31) {
        daySuffix = "st";
    } else if (day === 2 || day === 22) {
        daySuffix = "nd";
    } else if (day === 3 || day === 23) {
        daySuffix = "rd";
    }

    return ` ${month} ${day},  ${year}`;
}
  const renderPosition = (data) => {
  const resultArray = data.split('-');
  // console.log("resultArray",resultArray[1]) 
    if (data == "associateEditors") {
      return (
        <>
          <Accordion className="editorial-board-ds pe-5" defaultActiveKey="6">
            {journalData?.advisoryBoard?.length > 0 ? journalData?.advisoryBoard && (
              <>
                <Accordion.Item className="pointer-events-none" eventKey="6">
                  <Accordion.Header>Associate Editor {journalData?.advisoryBoard?.length>1?"/s":null}</Accordion.Header>
                  <Accordion.Body>
                    {journalData?.advisoryBoard?.map((item, ind) => (
                      <>
                        <p className="mb-0 fw-bold">{item?.title} {item?.fullName}</p>
                        <p className="mb-0  designation">{item?.designation}</p>
                        <p className="mb-2">{item?.workPlace}</p>
                      </>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </>
            ) : null}
          </Accordion>
        </>
      );
    } else if (data == "assistantEditor") {
      return (
        <>
          <Accordion className="editorial-board-ds pe-5" defaultActiveKey="0">
            {journalData?.editor == null ? null : (
              <Accordion.Item className="pointer-events-none" eventKey="0">
                <Accordion.Header>Assistant Editor {journalData?.editor?.length>1?"/s":null}</Accordion.Header>
                <Accordion.Body>
                 {journalData?.editor?.map((item, ind) => (
                      <>
                        <p className="mb-0 fw-bold">{item?.title} {item?.fullName}</p>
                        <p className="mb-0  designation">{item?.designation}</p>
                        <p className="mb-2">{item?.nameOfUniversity}</p>
                      </>
                    ))}
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </>
      );
    } else if (data == "managingEditor") {
      return (
        <>
          <Accordion className="editorial-board-ds pe-5" defaultActiveKey="3">
            {journalData?.managingEditor == null ? null : (
              <Accordion.Item className="pointer-events-none" eventKey="3">
                <Accordion.Header>Managing Editor {journalData?.managingEditor?.length>1?"/s":null}</Accordion.Header>
                <Accordion.Body>
                {journalData?.managingEditor?.map((item, ind) => (
                      <>
                        <p className="mb-0 fw-bold">{item?.title} {item?.fullName}</p>
                        <p className="mb-0  designation">{item?.designation}</p>
                        <p className="mb-2">{item?.nameOfUniversity}</p>
                      </>
                    ))}
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </>
      );
    } else if (data == "members"){
      return (
        <>
        <Accordion className="editorial-board-ds pe-5" defaultActiveKey="0">
          {journalData?.members?.length > 0 ? journalData?.members && (
            <>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Editorial Board Members</Accordion.Header>
                <Accordion.Body>
                  {journalData?.members.map((item, ind) => (
                    <>
                      <p className="mb-0 "><span className="fw-bold">{item?.title} {item?.fullName}</span>, {item?.workPlace}</p>
                      {/* <p className="mb-0  designation">{item?.designation}</p> */}
                      <p className="mb-2 "></p>
                    </>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </>
          ) : null}
        </Accordion>
        </>
      )
    } else if(resultArray.length == 2){
      
      return (
        <>
        
          {journalData?.editorialAssistant?.length > 0 ? journalData?.members && (
              <>
              
                 <Accordion className="editorial-board-ds pe-5" defaultActiveKey="0">
                <Accordion.Item eventKey="0" className="pointer-events-none">
                  <Accordion.Header>{journalData?.editorialAssistant?.[resultArray[1]]?.fieldName}</Accordion.Header>
                  <Accordion.Body>
                    
                      <>
                        <p className="mb-0 fw-bold">{journalData?.editorialAssistant?.[resultArray[1]]?.title} {journalData?.editorialAssistant?.[resultArray[1]]?.fullName}</p>
                        <p className="mb-0  designation">{journalData?.editorialAssistant?.[resultArray[1]]?.designation}</p>
                        <p className="mb-2 ">{journalData?.editorialAssistant?.[resultArray[1]]?.workPlace}</p>
                      </>
                    
                  </Accordion.Body>
                </Accordion.Item>
                </Accordion>
                
              </>
            ) : null}
          
        </>
      )
    }
  };

  const handleScrollTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const [journalData, setJournalData] = useState([]);
  const [filteredData, setFilteredData] = useState(specializationOptions);

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = specializationOptions.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );
    const specSearch = [...filtered];
    setFilteredData(specSearch);
  };

  const toggleSearchDropdown = () => {
    setShowSearchDropdown(!showSearchDropdown);
  };

  const getJournalDetail = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      journal:journalName
    };
    const response = await getJournalDetailById(headers, params);
    if (response?.status === 200) {
      if (!isMounted.current) {
        isMounted.current = true;
        response?.data?.data?.editorialAssistant?.forEach(() => {
            addNewOrder();
        });
         
      }
      
      setJournalData(response?.data?.data);
      getAllSubject(response?.data?.data?.journalName);
      if (response?.data?.data?.managingEditor?.[0]?.order) {
        updateBoardOrder(response?.data?.data?.managingEditor?.[0]?.order, "managingEditor");
      }
      if (response?.data?.data?.advisoryBoard?.[0]?.order) {
        updateBoardOrder(response?.data?.data?.advisoryBoard?.[0]?.order, "associateEditors");
      }
      if (response?.data?.data?.editor?.[0]?.order) {
        updateBoardOrder(response?.data?.data?.editor?.[0]?.order, "assistantEditor");
      }
      if (response?.data?.data?.members?.[0]?.order) {
        updateBoardOrder(response?.data?.data?.members?.[0]?.order, "members");
      }
      if (response?.data?.data?.editorialAssistant?.[0]?.order) {
        response?.data?.data?.editorialAssistant.forEach((value, index) => {
          // Use `value` and `index` here
          updateBoardOrder(value?.order, `editorialAssistant-${index}`);
        });
        // updateBoardOrder(response?.data?.data?.editorialAssistant?.[0]?.order, "editorialAssistant");
      }
      // updateBoardOrder(1, "assistantEditor"); 
      // if(boardOrder?.[0]?.[1] == '') 
      //   {
      //     getJournalDetail();
      //   }
      

    } else return;
   

  };
 
  const getAllSubject = async (decId) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      params: { journalName: decId },
      headers: headers,
    };
    const response = await getJournalSubjects(queryParams);
    if (response?.status === 200) {
      let newRecord = response?.data?.data?.specilisation;
      if (decId !== "AyurYoga – The Mega Journal of Ayurveda and Yoga") {
        let specOpt = newRecord
          ?.filter(
            (spec) =>
              spec?.specialization !==
              "Other Subject (choose this option to register your subject)"
          )
          .map((spec) => spec?.specialization);
        setSpecializationOptions(specOpt);
        setFilteredData(specOpt);
      } else {
        let specOpt0 = newRecord?.[0]
          ?.filter(
            (spec) =>
              spec?.specialization !==
              "Other Subject (choose this option to register your subject)"
          )
          .map((spec) => spec?.specialization);

        let specOpt1 = newRecord?.[1]
          ?.filter(
            (spec) =>
              spec?.specialization !==
              "Other Subject (choose this option to register your subject)"
          )
          .map((spec) => spec?.specialization);
        const children = specOpt0.concat(specOpt1);
        setSpecializationOptions(children);
        setFilteredData(children);
      }
    } else {
      return toast.error("Please try again!!");
    }
  };

  const getPublishingEditorForJournal = async () => {
    dispatch({ type: "SET_LOADER", payload: true });
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      journal: journalName,
      page: 1,
      limit,
    };
    const response = await getPEForJournalHomePage(headers, params);
    dispatch({ type: "SET_LOADER", payload: false });
    if (response?.status === 200) {
      setPublishEditorData(response?.data?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: true });
      return toast.error("Please try again");
    }
    //  console.log(publishEditorData);
  };

  const handlePageChange = (data) => {
    return setPage(data?.activePage);
  };

  const userDetails = (user) => {
    const info = {
      __html: user?.title + " " + `<b>${user?.fullName}</b>` + ", " + user?.affiliation?.affiliations?.[0]?.name
    }
    return info;
  }

  useEffect(() => {
    if (journalId) {
      getJournalDetail();
    } return;
  }, [journalId]);

  useEffect(() => {
    // console.log("journalData",journalData);

    if (journalName) {
      getPublishingEditorForJournal();
    } return;

  }, [journalName, page]);
  // useEffect(() => { 
  //   console.log("rrrrr", journalData); 
    
   
  // },[EditorialBoard]);


  useEffect(() => {
    // Call getJournalDetail on component mount
    getJournalDetail();
  }, []);
  
  useEffect(() => {
    // Log or handle boardOrder updates here
    // console.log(boardOrder);
    // You can add any additional logic that depends on boardOrder here
  }, [boardOrder]);
  // console.log(location?.state);

  return (
    <>
    <Helmet>
     <title>Editorial Board | Society Journals | Aakashganga Open
     </title>
     </Helmet>
      <div className="tagbody">
        <AakashgangaHeader />
        <Link to={`/society-journals/${discipline}/${id}`} > <JournalMainBanner
          imgUrl={
            societyJournal === "Society Journal"
              ? journalData?.masthead
              : journalData?.masthead
          }
        /></Link>
        <div className="menu-center-part ">
          <div className="journalShadow">
            <div className="ag_menu justify">
            <ul className="ag_menu ag_menuSub justify tagul">
                {societyJournal === "Society Journal" ? null : (
                  <li className="tagli">
                    <a
                      href="#"
                      class="custom-journal taga"
                      onClick={toggleSearchDropdown}
                    >
                      Disciplines <img src="/assets/images/downarrow.png" alt="#" />
                    </a>

                    {showSearchDropdown && (
                      <div className="tagliSubNave">
                        <div className="disciplinesbg">
                          <div class="form-group has-search">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input
                              type="text"
                              class="form-control input30"
                              placeholder="Type to search"
                              value={searchQuery}
                              onChange={(e) => handleSearch(e.target.value)}
                            />
                          </div>
                          <ul className="disciplinesscroll">
                            {filteredData.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </li>
                )}
                <li>
                  <a href="javascript:;" class="custom-journal taga">
                  {/* DISCIPLINE{" "} */}
                    <span className=" text-black text-uppercase" style={{}}>
                      {" "}
                      {""}
                      {societyJournal === "Society Journal"
                        ? journalData?.subjectData?.[0]?.subject?.subjectName
                        : journalData?.subjectData?.[0]?.subject?.subjectName}
                    </span>
                  </a>
                </li>
                
                {/* <li>
                  <a href="javascript:;" class="custom-journal taga">
                  Subject{" "}
                    <span className="colr">
                      {" "}
                      {""}
                      {societyJournal === "Society Journal"
                        ? journalData?.subjectData?.[0]?.specialization?.[0]?.specialization
                        : journalData?.subjectData?.[0]?.specialization?.[0]?.specialization}
                    </span>
                  </a>
                </li> */}
                <li className="active tagli custom-journal">
                  <Link
                    to="/open-access"
                    onClick={(e) => handleRedirect(e, "/open-access")}
                    className="custom-journal taga"
                  >
                    OPEN SCIENCE
                  </Link>
                </li>
                <li>
                  <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" class="custom-journal taga">
                 <img style={{width: '100px', maxWidth: '100%', transform:'none'}} src={coin} alt="#" />
                    {/* <span className="colr">
                      {" "}
                      {""}
                      {societyJournal === "Society Journal"
                        ? journalData?.subjectData?.[0]?.subject?.subjectName
                        : journalData?.subjectData?.[0]?.subject?.subjectName}
                    </span> */}
                  </a>
                </li>
                <li>
                  <a href="javascript:;" class="custom-journal taga">
                    E-ISSN{" "}
                    <span className="colr">
                      {" "}
                      {""}
                      {societyJournal === "Society Journal"
                        ? journalData?.issnNumber
                        : journalData?.issnNumber}
                    </span>
                  </a>
                </li>
                <li>
                  <a href="javascript:;" class="custom-journal taga">
                    ABBR{" "}
                    <span className="colr">
                      {" "}
                      {""}
                      {societyJournal === "Society Journal"
                        ? journalData?.abbreviation
                        : journalData?.abbreviation}
                    </span>
                  </a>
                </li>
                <li>
                {journalData?.doiurl=="" || journalData?.doiurl== undefined || journalData?.doiurl== null?null:(<>
                      
                      <span className="colr ">
                        <a className="text-lowercase" href={`https://doi.org/${journalData?.doiurl}`}>https://doi.org/{journalData?.doiurl}</a>
                      </span>
                      </>)}
                </li>
                {/* <li>
                  <a href="javascript:;" class="custom-journal taga">
                  FIRST PUBLISHED{" "}
                    <span className="colr">
                      {" "}
                      {""}
                      <div dangerouslySetInnerHTML={{__html:formatDate(journalData?.createdAt)}}></div>
                     
                    </span>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="editorContainer">
            <div className="editorPage">
              <span className="editorHead">Editorial Board</span>
              <div className="journalBackWrap ">
              <Link to={`/society-journals/${discipline}/${id}`} ><span >Society Homepage</span></Link>
                {!token ? (
                  <Link
                    to="/login"
                    onClick={(e) => {
                      e.preventDefault();
                      handleRedirect(e, "/login")
                    }}
                    className="ag_btn_red ag_btn_redclr"
                  >
                    Submit Article
                  </Link>
                ) : (
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setUploadArticle(true)
                    }}
                    className="ag_btn_red ag_btn_redclr"
                  >
                    Submit Article
                  </Link>
                )}
              </div>
            </div>

            <div className="bg journals-main-div d-flex">
              <div className="div70">
                <Accordion className="editorial-board-ds pe-5" defaultActiveKey="0">
                  {journalData?.editorInChief == null ? null : (
                    <Accordion.Item className="pointer-events-none" eventKey="0">
                      <Accordion.Header>Editor In Chief</Accordion.Header>
                      <Accordion.Body>
                      {journalData?.editorInChief?.map((item, ind) => (
                      <>
                        <p className="mb-0 fw-bold">{item?.editortitle} {item?.fullName}</p>
                        <p className="mb-0  designation">{item?.designation}</p>
                        <p className="mb-2">{item?.nameOfUniversity}</p>
                      </>
                    ))}
                        {/* <p className="mb-0 fw-bold">{journalData?.editorInChief?.title} {journalData?.editorInChief?.fullName}</p>
                        <p className="mb-0  designation">{journalData?.editorInChief?.designation}</p>
                        <p className="mb-0 ">{journalData?.editorInChief?.workPlace}</p> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
 


                {boardOrder.map((board, index) => (
                  <div key={index}>
                    {Object.keys(board).map((key) => (
                      <div key={key}>
                        {/* <span>Order {key}:</span>  */}
                        {renderPosition(board[key])}
                        {/* {board[key]} */}
                      </div>
                    ))}
                  </div>
                ))}


                
               
              </div>

              <div className="div30 ">
                <div className="journalslist custom-journalist-class">
                  <div className=" align-items-center">
                    <div className="ag_heading_center_wrap our-partner mt-0 info-custom">
                      <h2 className="tagh2">Information</h2>
                    </div>
                  </div>
                  <ul className="journalInfoList">
                    <li class="custom-li-journal tagli">
                      <Link
                        to={`/society-journals/${id}`}
                        className={activeType === "description" ? "active" : null}
                        onClick={() => setActiveType("description")}
                      >
                        Description
                      </Link>
                    </li>
                    <li class="custom-li-journal tagli">
                      <Link
                        to={`/society-journals/${id}`}
                        className={activeType === "description" ? "active" : null}
                        onClick={() => setActiveType("description")}
                      >
                        Aims and Scope
                      </Link>
                    </li>
                    <li class="custom-li-journal tagli">
                      <Link
                        to="javascript:;"
                        className={activeType === "editorial-board" ? "active" : null}
                        onClick={() => setActiveType("editorial-board")}
                      >
                        Editorial Board
                      </Link>
                    </li>
                    <li class="custom-li-journal tagli">
                    <Link
                      to={"/access-society"}
                      state={{ journalName: journalData?.journalName, journalId: journalData?._id }}
                      className={
                        activeType === "access-articles" ? "active" : null
                      }
                      onClick={() => setActiveType("access-articles")}
                    >
                      Access Articles
                    </Link>
                  </li>

                  <li class="custom-li-journal tagli">
                    <Link
                      to="/author-submission-guidelines"
                      target="_blank"
                      className={
                        activeType === "author-guidelines" ? "active" : null
                      }
                    >
                      Author Guidelines
                    </Link>
                  </li>              

                    <li class="custom-li-journal tagli">
                      <Link
                        className={
                          activeType === "content-type" ? "active" : null
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveType("content-type");
                          setShow(true);
                        }}
                      >
                        Content Type
                      </Link>
                    </li>
                   
                    <li class="custom-li-journal tagli">
                      <Link
                        className={activeType === "recommend" ? "active" : null}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveType("recommend");
                          setShowRec(true);
                          setModalPageName(pageName);
                          setJournalModalName(journalName);
                        }}
                      >
                        Recommend
                      </Link>
                    </li>
                    <li class="custom-li-journal tagli">
                      <Link
                        className={activeType === "advertising" ? "active" : null}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveType("advertising");
                          setShowAdvertising(true);
                        }}
                      >
                        Advertise With Us
                      </Link>
                    </li>
                    <li class="custom-li-journal tagli">
                      <Link
                         to="/registration"
                        // target="_blank"
                        className={
                          activeType === "author-guidelines" ? "active" : null
                        }
                      >
                      Become a Reviewer
                      </Link>
                    </li>
                    <li class="custom-li-journal tagli">
                      <Link
                        to="/journal/get-in-touch"
                        target="_blank"
                        className={
                          activeType === "get-in-touch" ? "active" : null
                        }
                      >
                        Get in Touch
                      </Link>
                    </li>
                  </ul>
                </div>
                {societyJournal === "Society Journal" ? null : (
                  <div className="journalslist martop-10 main-class-publish">
                    <div className=" align-items-center publish-article-custom">
                      <div className="publishArtWrap">
                        <p>
                          Publish Multidisciplinary and Interdisciplinary articles
                          with just one click.
                        </p>
                        {!token && (
                          <Link
                            to="javascript:;"
                            onClick={() =>
                              isMobile ? setMobileAlert(true) : navigate("/login")
                            }
                            className="ag_btn_red taga"
                          >
                            Publish Article
                          </Link>
                        )}
                        {token && (
                          <Link
                            onClick={() => {
                              isMobile
                                ? setMobileAlert(true)
                                : setUploadArticle(true);
                            }}
                            className="ag_btn_red taga"
                          >
                            Publish Article
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="journalslist martop-10 main-class-publish">
                  {/* <div className=" align-items-center">
                    <div className="ag_heading_center_wrap our-partner mt-0 info-custom">
                      <h2 className="fontsizedate tagh2">Keep Up To Date</h2>
                    </div>
                  </div> */}
                  <div className="footer-align-bottom">
                    {/* <h2 className="follw custom-24 tagh2">Follow Us</h2>
                    <ul className="ag_ftr_socials tagul">

                      <li className="tagli">
                        <a
                          href="https://www.youtube.com/watch?v=Iewt4-w2R9g"
                          target="_blank"
                          className="taga youtubeIcon"
                        >
                          <img
                            src={YoutubeIcon}
                            alt="Youtube Icon"
                            className="tagimg"
                          />
                        </a>
                      </li>
                    </ul> */}
                  </div>
                  <h2 className="font22 custom-24 tagh2 text-center">Email Alerts</h2>
                  {/* <p>Sign up to receive Email alerts</p> */}
                  <Link
                    to="/registration"
                    onClick={(e) => handleRedirect(e, "/registration")}
                    className="ag_btn_red signup-btn-journal taga"
                  >
                    SIGN UP
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="editorRevisionWrap">
              {publishEditorData?.data?.length > 0 ? publishEditorData?.data && publishEditorData?.data?.map((item, ind) => (
                <div className="editorRevision">
                  <span>{ind + 1}.</span>
                  <p dangerouslySetInnerHTML={userDetails(item?.user?.[0])} />
                </div>
              )) : (
                <div className="noDataPublic"><h2>No Publishing Editors Data Found</h2></div>
              )}


            </div> */}
            {totalPages > 1 && (
              <div className="editorPagi">
                <span>
                  <img
                    src={pg01}
                    onClick={(event, data) => handlePageChange(data)}
                  />
                  PREVIOUS
                </span>
                <div className="numberWrap">
                  <div className="paginationWrap">
                    <Pagination
                      boundaryRange={1}
                      firstItem={null}
                      lastItem={null}
                      siblingRange={1}
                      activePage={<b className="active">{page}</b>}
                      onPageChange={(event, data) => handlePageChange(data)}
                      totalPages={totalPages}
                    />
                  </div>
                </div>
                <span>
                  Next
                  <img
                    src={pg02}
                    onClick={(event, data) => handlePageChange(data)}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
        <NewsLetter />
        <SubmitArticleModal
          uploadArticle={uploadArticle}
          setUploadArticle={setUploadArticle}
        />
          <Modal
          show={showAdvertising}
          size="lg"
          backdrop="static"
          keyboard={false}
          centered
          aria-labelledby="contained-modal-title-vcenter markitPopupRecomendBox"
          className="markitPopupRecomendBox"
        >
          <Modal.Header
            className="justify-content-center closeButtonStyle"
          >
            <Modal.Title className="modal-title pl-0">
              <h2 className="fontsizeshastra ml-2">Advertise With Us</h2>
            </Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowAdvertising(false);
                setActiveType("description");
              }}
              aria-label="Close"
            />
          </Modal.Header>
          <Modal.Body className="pt-0 pb-2">
            <div className="advertisingModal">
              <img src="/assets/images/advertising.png" alt="advertising" />
              <div className="advertisingContent">
                <span className="colorBlack">
                  For advertising inquiries, write to us at
                </span>{" "}
                <a href="mailto:contactus@vikramshilaedu.in">
                  contactus@vikramshilaedu.in
                </a>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show}
          // onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter "
          centered
          className="markitPopupBox"
        >
          <Modal.Header
            className="justify-content-center closeButtonStyle"
            closeButton
            onClick={() => setShow(false)}
          >
            <Modal.Title className="modal-title pl-0">
              <h2 className="fontsizemod ml-2">
                Aakashganga currently accepts the following types of research
                articles for publishing
              </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0 pb-2">
            <div className="px-2 text-center">
              <p className="f-14">
                <ul className="contentModal">
                  <li className="section">
                    <strong>Original Research:</strong> These are original
                    research studies presenting new findings and insights in any
                    field of science or medicine.
                  </li>
                  <li className="efforts">
                    <strong>Review Articles:</strong> These provide
                    comprehensive and critical reviews of existing research,
                    summarizing and analysing the current state of knowledge on
                    a specific topic.
                  </li>
                  <li className="efforts">
                    <strong>Systematic Review:</strong> A comprehensive and
                    structured method of synthesizing and analyzing existing
                    research studies and evidence on a specific research
                    question or topic.
                  </li>
                  <li className="efforts">
                    <strong>Methodology Articles:</strong> These describe new
                    methods or techniques developed for research or data
                    analysis. Methodology articles focus on the technical
                    aspects of research and often include detailed protocols or
                    algorithms.
                  </li>
                  <li className="efforts">
                    <strong>Data Articles:</strong> These focus primarily on
                    data sets, describing and providing access to large data
                    collections or databases. Data articles may include data
                    descriptions, data analyses, and data visualization.
                  </li>
                  <li className="efforts">
                    <strong>Case Reports:</strong> These describe unique or
                    interesting medical or clinical cases that contribute to the
                    understanding of a particular disease, treatment, or
                    condition.
                  </li>
                  <li className="efforts">
                    <strong>Short Reports:</strong> These are concise research
                    papers presenting new and significant findings but with a
                    shorter format compared to full research articles.
                  </li>
                  <li className="efforts">
                    <strong>Perspectives or Opinions:</strong> These articles
                    express the authors’ perspectives, opinions, or commentaries
                    on a specific scientific, social, or ethical issue.
                  </li>
                  <li className="efforts">
                    <strong>Conceptual Articles:</strong> These articles propose
                    new concepts, frameworks, or theoretical models, often based
                    on existing research or literature.
                  </li>
                  <li className="efforts">
                    <strong>Commentaries:</strong> Commentaries offer critical
                    analysis or perspective on previously published research or
                    current issues within a field.
                  </li>
                  <li className="efforts">
                    <strong>Book Reviews:</strong> These articles evaluate and
                    critique recently published books relevant to the scope of
                    the journal.
                  </li>
                </ul>
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="pt-3 pb-3 contactModalFooter"></Modal.Footer>
        </Modal>

        <Modal
          show={showRec}
          onHide={() => setShowRec(false)}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="markitPopupRecomendBox"
        >
          <Modal.Header
            className="justify-content-center closeButtonStyle"
            closeButton
            onClick={() => setActiveType("description")}
          >
            <Modal.Title className="modal-title">
              <h2 className="fontsizeshastra">Recommend {modalPageName}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0 pb-2 text-left recommendModalBox">
            <strong>Share it on:</strong>{" "}
            <ul className="ag_ftr_socials contentModal">
              <li>
                <FacebookShareButton
                  url={SHARE_URL}
                  title={title}
                  hashtags={"aakashganga"}
                >
                  <b>
                    <img
                      src="/assets/images/icon-facebook.svg"
                      alt="Facebook Icon"
                    />
                  </b>
                </FacebookShareButton>
              </li>
              <li>
                <TwitterShareButton
                  url={SHARE_URL}
                  title={title}
                  hashtag={"aakashganga"}
                >
                  <b>
                    <img
                      src="/assets/images/icon-twitter.svg"
                      alt="Twitter Icon"
                    />
                  </b>
                </TwitterShareButton>
              </li>
              <li>
                <LinkedinShareButton url={SHARE_URL} title={title}>
                  <b>
                    <img
                      src="/assets/images/icon-linkedIn.svg"
                      alt="LinkedIn Icon"
                    />
                  </b>
                </LinkedinShareButton>
              </li>
            </ul>
            <hr className="journalHr" />
            <strong>Copy Link:</strong>{" "}
            <div className="dis">
              <div class="ag_newsletter_input widrec">
                <input
                  type=""
                  value={SHARE_URL}
                  placeholder="Copy Link"
                ></input>
              </div>
              <a
                href="javascript:;"
                onClick={handleCodeCopy}
                className=" ag_btn_red ag_btn_redclr copy"
              >
                Copy
              </a>
            </div>
          </Modal.Body>
          <Modal.Footer className="pt-3 pb-3 contactModalFooter"></Modal.Footer>
        </Modal>
        <AakashgangaFooter />
      </div>
    </>
  );

}

export default EditorialBoard;