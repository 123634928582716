import React, { useEffect, useState } from "react";
import { Pagination } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import notyet from "../../../assets/img/icons/notarticle.svg";
import { recommendedArticles, getLatestArticles, getMostViewedArticle, getLastBrowseArticle, getJournalDetail, getJournalBySpecialization } from "../../../store/apiCalls/dashboard";
import { getSubjectSpecialization } from '../../../store/apiCalls/profileDetails';
import { getJournalSubjects } from "../../../store/apiCalls/articles";
import RelatedArticles from "../../../components/viewArticle/relatedArticles";
import Select from "../../../components/common/Select";
import AakashgangaHeader from "../../../components/global/HeaderComponent";
import AakashgangaFooter from "../../../components/global/FooterComponent";
import SearchDropdown from "../../../components/SearchDropdown/SearchDropdown";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";
import backArrow from "../../../assets/img/icons/blueArrow.svg";
import { Helmet } from 'react-helmet';

function articleLanding() {
    const navigate = useNavigate();
    const masterData = useSelector((state) => state.MasterDataReducer.MasterData);
    const [collapseActive, setIsActive] = useState(false);
    const userDetails = useSelector((state) => state?.LoginReducer?.user);
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const [recommendedPage, setRecommendedPage] = useState(1);
    const [latestPage, setLatestPage] = useState(1);
    const [mostViewedPage, setMostViewedPage] = useState(1);
    const dispatch = useDispatch();
    const [oldToNew, setOldToNew] = useState(-1);
    const [articleData, setArticleData] = useState([]);
    const [mostViewedArticleData, setMostViewedArticle] = useState([]);
    const [lastBrowseArticleData, setLastBrowseArticle] = useState([]);
    const limit = 3;
    const recomdLimit = 5;
    const MAX_WORDS = 30;
    const [recommendedList, setRecommendedList] = useState([]);
    const [journalData, setJournalData] = useState([]);
    const [journalOptions, setJournalOptions] = useState([]);
    const [allDisciplineData, setAllDisciplineData] = useState([]);
    const [disciplineOptions, setDisciplineOptions] = useState([]);
    const [disciplineOption, setDisciplineOption] = useState([]);
    const [specializationOptions, setSpecializationOptions] = useState([]);

    const [sideMenuDiscipline, setSideMenuDiscipline] = useState('')
    const [sideMenuType, setSideMenuType] = useState('')
    const [sideMenuYear, setSideMenuYear] = useState('')
    const [selectedName, setSelectedName] = useState('')

    const [articleTypeToggle, setArticleTypeToggle] = useState(false);
    const [publicationYearToggle, setPublicationYearToggle] = useState(false);
    const [journalToggle, setJournalToggle] = useState(true);
    const [disciplineToggle, setDisciplineToggle] = useState(true);
    const [readingListData, setReadingListData] = useState({
        discipline: "",
        subject: [],
        type: [],
    });

    const [filter, setFilter] = useState(false);
    const [recommendedDivSct, setRecommendedDivSct] = useState(false);
    const [totalRecomndedList, setTotalRecomndedList] = useState("");
    const [latestArticlesCount, setLatestArticlesCount] = useState("");
    const [mostViewedArticleCount, setMostViewedArticleCount] = useState("");
    let recommendedTotalPages = Math.ceil(totalRecomndedList / recomdLimit);
    let latestArticlesTotalPages = Math.ceil(latestArticlesCount / limit);
    let mostViewedArticleTotalPages = Math.ceil(mostViewedArticleCount / limit);

    const publicationYear = [
        { key: 'All', value: "All" },
        { key: '2021', value: "2021" },
        { key: '2022', value: "2022" },
        { key: '2023', value: "2023" },
        { key: '2024', value: "2024" },
        { key: '2025', value: "2025" },
    ]
    const sidePublicationYear = [
        { key: '2021', value: "2021" },
        { key: '2022', value: "2022" },
        { key: '2023', value: "2023" },
        { key: '2024', value: "2024" },
        { key: '2025', value: "2025" }];

    const fetchLatestArticlesData = () => {
        dispatch({ type: "SET_LOADER", payload: true });
        const fetchdata = async () => {
            const params = {
                page: latestPage,
                limit: limit,
                userId: userDetails?._id,
            };
            return getLatestArticles(headers, params);
        };
        fetchdata()
            .then((ArticleRes) => {
                if (ArticleRes.status === 200 && ArticleRes?.data) {
                    dispatch({ type: "SET_LOADER", payload: false });
                    setArticleData(ArticleRes?.data);
                    setLatestArticlesCount(ArticleRes?.data?.totalCount);
                }
            })
            .catch((err) => console.log(err));
        dispatch({ type: "SET_LOADER", payload: false });
    };

    const getRecommendedArticles = async () => {
        dispatch({ type: "SET_LOADER", payload: true });
        setRecommendedList([''])
        const params = () => {
            const data = {
                userId: userDetails?._id,
                page: recommendedPage,
                limit: recomdLimit,
            };
            if (readingListData?.journal != '') {
                data.journal = readingListData?.journal,
                    data.journalRequest = true
            }
            if (readingListData?.discipline != '' || sideMenuDiscipline) {
                data.subject = sideMenuDiscipline ? sideMenuDiscipline : readingListData?.discipline
                if (sideMenuDiscipline) {
                    data.subjectRequest = true
                }
            }
            if (readingListData?.subject != '') {
                data.specialization = readingListData?.subject
                    ?.map((sub) => {
                        return specializationOptions?.find((val) => val?.specialization === sub)
                            ?._id;
                    })
                    .join(",")
            }
            if (sideMenuType || readingListData?.type != '') {
                data.type = sideMenuType != '' ? sideMenuType : readingListData?.type
                    .map((sub) => {
                        return masterData?.typeOfArticledata?.find(
                            (val) => val?.articleName === sub
                        )?._id;
                    })
                    .join(",")
                if (sideMenuType) {
                    data.typeRequest = true
                }
            }
            if (sideMenuYear || readingListData?.year) {
                data.publishYear = sideMenuYear ? sideMenuYear : readingListData?.year === "All" ? "" : readingListData?.year
                if (sideMenuYear) {
                    data.publishYearRequest = true
                }
            }

            if (oldToNew) {
                data.sorting = oldToNew;
            }
            return data;
        };
        const response = await recommendedArticles({}, params());
        if (response?.status === 200) {
            dispatch({ type: "SET_LOADER", payload: false });
            setTotalRecomndedList(response?.data?.totalCount);
            setRecommendedList(response?.data?.data);
        } else {
            dispatch({ type: "SET_LOADER", payload: true });
            return toast.error("Please try again!!");
        }
    };

    const fetchMostViewedArticlesData = () => {
        dispatch({ type: "SET_LOADER", payload: true });
        const fetchdata = async () => {
            const params = {
                page: mostViewedPage,
                limit: limit,
                userId: userDetails?._id,
            };
            return getMostViewedArticle({}, params);
        };
        fetchdata()
            .then((res) => {
                if (res.status === 200 && res?.data) {
                    dispatch({ type: "SET_LOADER", payload: false });
                    setMostViewedArticle(res?.data?.data);
                    setMostViewedArticleCount(res?.data?.totalCount);
                }
            })
            .catch((err) => console.log(err));
        dispatch({ type: "SET_LOADER", payload: false });
    };

    const fetchLastBrowseArticleData = () => {
        dispatch({ type: "SET_LOADER", payload: true });
        const fetchdata = async () => {
            const params = {
                userId: userDetails?._id,
            };
            return getLastBrowseArticle(headers, params);
        };
        fetchdata()
            .then((res) => {
                if (res.status === 200 && res?.data) {
                    dispatch({ type: "SET_LOADER", payload: false });
                    setLastBrowseArticle(res?.data?.data?.data);
                }
            })
            .catch((err) => console.log(err));
        dispatch({ type: "SET_LOADER", payload: false });
    };
    const handleRedirect = (e, path) => {
        if (e !== "") e.preventDefault();
        window.scroll({ top: 0, behavior: "smooth" });
        navigate(path);
    };
    const resumeArticle = () => {
        const articleId = lastBrowseArticleData?.[0]?.article?.[0]?._id
        handleRedirect("", `/viewPublishedArticle/${articleId}`);
    };

    const getResponseOptions = () => {
        let journal = [];
        let allDiscipline = []
        journalData &&
            journalData.forEach((element) => {
                journal.push(element);
                allDiscipline.push(element.subjectData)
            });
        setJournalOptions(journal);
        return setJournalOptions(journal);
    };
    const getAllDiscipleResponse = () => {
        let allDiscipline = []
        journalData &&
            journalData.forEach((element) => {
                allDiscipline.push(element.subjectData[0].subject)
            });
        return setAllDisciplineData(allDiscipline);
    };

    const getJournalData = async () => {
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const params = {
            journalType: masterData?.jouranlData?.[0]?._id,
        };
        const response = await getJournalDetail(headers, params);
        if (response?.status === 200) {
            setJournalData(response?.data?.data);
        } else return;
    };
    const getJournalSubjectByJornlId = async (JrnlName) => {
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const queryParams = {
            params: { journalName: JrnlName },
            headers: headers,
        };
        const response = await getJournalSubjects(queryParams);
        if (response?.status === 200) {
            setSpecializationOptions(response?.data?.data?.specilisation);
        } else return;
    };
    const getSpecializationBySubjt = async () => {
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const params = {
            subjectId: sideMenuDiscipline ? sideMenuDiscipline : readingListData?.discipline
        };
        const response = await getSubjectSpecialization(headers, params);
        if (response?.status === 200) {
            return setSpecializationOptions(response?.data?.data.data);
        } else {
            return toast.error("Please try again!!");
        }
    };
    const getJournalDiscipline = () => {
        const masterSubject = [];
        journalData?.forEach((element, k) => {
            const subject = [];
            element?.subjectData?.forEach((element, p) => {
                const data = element.subject;
                subject.push(data);
            });
            masterSubject[element.journalName] = subject;
        });
        return setDisciplineOptions(masterSubject);
    }


    const handleSelectChange = (value, type) => {
        setRecommendedDivSct(true);
        let newReadingListData = { ...readingListData };
        if (type === "journal") {
            newReadingListData = { ...readingListData, subject: [] };
            setSpecializationOptions(specializationOptions[value]);
            setDisciplineOption(disciplineOptions[value]);
            getJournalSubjectByJornlId(value)
        }
        setSideMenuDiscipline('')
        setSideMenuType('')
        setSideMenuYear('')
        return setReadingListData({ ...newReadingListData, [type]: value });
    };
    const handleYearSelectChange = (value, type) => {
        let newReadingListData = { ...readingListData };
        return setReadingListData({ ...newReadingListData, [type]: value });
    }
    const handleSelectDisciplineChange = (value, type) => {
        let newReadingListData = { ...readingListData };
        if (type === "discipline") {
            setRecommendedDivSct(true);
            newReadingListData = { ...readingListData, discipline: "" };
        }
        return setReadingListData({ ...newReadingListData, [type]: value });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const cloneSubject = { ...readingListData };
        if (name === "subject") {
            if (cloneSubject.subject.includes(value)) {
                cloneSubject.subject.splice(cloneSubject.subject.indexOf(value), 1);
            } else {
                if (cloneSubject.subject.length < 3) {
                    cloneSubject.subject.push(value);
                }
            }
        } else if (name === "type") {
            if (cloneSubject?.type.includes(value)) {
                cloneSubject?.type?.splice(cloneSubject?.type?.indexOf(value), 1);
            } else {
                if (cloneSubject?.type.length < 3) {
                    cloneSubject.type.push(value);
                }
            }
        }
        setReadingListData(cloneSubject);
    }
    const handleSideTypeChange = (name, value) => {
        setRecommendedDivSct(true);
        setReadingListData({
            // journal: "",
            type: [],
        })
        setSideMenuType(value)
        getRecommendedArticles()
    }
    const toggleType = () => {
        if (articleTypeToggle == true) {
            setArticleTypeToggle(false)
        }
        else {
            setArticleTypeToggle(true)
        }
    }
    const togglePublicationYear = () => {
        if (publicationYearToggle == true) {
            setPublicationYearToggle(false)
        }
        else {
            setPublicationYearToggle(true)
        }
    }
    const toggleJournal = () => {
        if (journalToggle == true) {
            setJournalToggle(false)
        }
        else {
            setJournalToggle(true)
        }
    }
    const toggleDiscipline = () => {
        if (disciplineToggle == true) {
            setDisciplineToggle(false)
        }
        else {
            setDisciplineToggle(true)
        }
    }

    const handleSideMenuChange = (name, value, valueName) => {
        let e ='RelatedArticle'
        setRecommendedDivSct(true);
        setSelectedName(valueName);
        if (name === "discipline") {
            setReadingListData({
                journal: "",
                subject: [],
                type: [],
            })
            setSideMenuType('')
            setSideMenuYear('')
            setSideMenuDiscipline(value)
        }
        else if (name === "type") {
            setReadingListData({
                journal: "",
                subject: [],
                type: [],
            })
            setSideMenuDiscipline('')
            setSideMenuYear('')
            setSideMenuType(value)
        }
        else if (name === "year") {
            setReadingListData({
                journal: "",
                year: "",
                subject: [],
                type: [],
            })
            setSideMenuDiscipline('')
            setSideMenuType('')
            setSideMenuYear(value)
        }
        const element = document.getElementById(`viewScroll${e}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const getSpecializationData = async () => {
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const params = {
            journalName: readingListData?.journal ? readingListData?.journal : ""
        };
        const response = await getJournalBySpecialization(headers, params);
        let journalName = response?.data?.data?.data?.[0]?.journalName;
        let result = journalName.substring(0, 8);
        if (response?.status === 200) {
            if (result === "AyurYoga") {
                let firstArray = response?.data?.data?.specilisation?.[0];
                let anotherArray = response?.data?.data?.specilisation?.[1];
                let finalArray = [...firstArray, ...anotherArray]
                setSpecializationOptions(finalArray);
            } else {
                setSpecializationOptions(response?.data?.data?.specilisation)
            }
        } else return;
    };

    const clearFilter = () => {
        setFilter(true);
        setReadingListData({
            journal: readingListData.journal,
            specialization: [],
            subject: [],
            type: [],
            year: "",
        });
    };
    const handleBack = () => {
        setFilter(true);
        setReadingListData({
            journal: "",
            specialization: [],
            subject: [],
            type: [],
            year: "",
        });
        setSideMenuDiscipline('')
        setSideMenuType('')
        setSideMenuYear('')
        setRecommendedDivSct(false)
        window.scroll({ top: 0, behavior: "smooth" });
    }
    useEffect(() => {
        getJournalData();
    }, [masterData]);

    useEffect(() => {
        getJournalDiscipline();
        getResponseOptions();
        getAllDiscipleResponse();
    }, [journalData]);

    useEffect(() => {
        if (readingListData?.discipline || sideMenuDiscipline) {
            getSpecializationBySubjt();
        }
    }, [readingListData, sideMenuDiscipline])

    useEffect(() => {
        getRecommendedArticles();
    }, [recommendedPage, oldToNew, readingListData.journal, sideMenuDiscipline, sideMenuType, sideMenuYear]);

    useEffect(() => {
        fetchLatestArticlesData();
    }, [latestPage]);
    
    useEffect(() => {
        fetchLastBrowseArticleData();
    }, [])
    
    useEffect(() => {
        fetchMostViewedArticlesData();
    }, [mostViewedPage])
    // console.log("articleRes",articleData?.data?.[1]?.subjectDetail?.[0]?.subjectName);
    return <>
        <Helmet>
          <title>Access Articles on Aakashganga </title>
          <meta name="description" content="Browse and access articles on Aakashganga Open to explore a diverse range of scholarly content. Our collection of open access articles covering various disciplines and topics." />

          <meta property="og:title" content="Access Articles on Aakashganga" data-react-helmet="true" />
          <meta property="og:description" content="Browse and access articles on Aakashganga Open to explore a diverse range of scholarly content. Our collection of open access articles covering various disciplines and topics." data-react-helmet="true" />
          <meta property="og:url" content="https://www.aakashgangaopen.in/access-articles" data-react-helmet="true" />

          <link rel="canonical" href="https://www.aakashgangaopen.in/access-articles" data-react-helmet="true" />
          <meta name="robots" content="index, follow" />
        </Helmet>
        <AakashgangaHeader />
        <div className="centerSection">
            <div className="previewContent pageWrap">
                <div className="pageLeft">
                    <div class="pageMenu text-initial">
                        <div className="browseBy">
                            BROWSE BY
                        </div>
                        <h2 className={journalToggle == true ? "sideTitle subnav active" : "sideTitle subnav"} onClick={toggleJournal}>Journal<i></i></h2>
                        <div className="pageMenu-link">
                            <ul className={journalToggle == true ? "" : "d-none"}>
                                {journalOptions && journalOptions?.map((val) => {
                                    return (
                                        <>
                                            <li onClick={(value) => handleSelectChange(val?.journalName, "journal")}><a class={readingListData?.journal == val?.journalName ? "active" : ""}>{val?.journalName}</a></li>
                                        </>
                                    )
                                })}
                            </ul>
                        </div>
                        <h2 className={disciplineToggle == true ? "sideTitle subnav active " : "sideTitle subnav"} onClick={toggleDiscipline}>Discipline<i></i></h2>
                        <div className="pageMenu-link">
                            <ul className={disciplineToggle == true ? "" : "d-none"}>
                                {allDisciplineData && allDisciplineData?.map((val) => {
                                    return (
                                        <>
                                            <li onClick={(value) => handleSideMenuChange("discipline", val?._id, val?.subjectName)}><a class={sideMenuDiscipline == val?._id ? "active" : ""}>{val?.subjectName}</a></li>
                                        </>
                                    )
                                })}
                            </ul>
                        </div>

                        <h2 className={articleTypeToggle == true ? "sideTitle subnav active " : "sideTitle subnav"} onClick={toggleType}>Type<i></i></h2>
                        <div className="pageMenu-link">
                            <ul className={articleTypeToggle == true ? "" : "d-none"}>
                                {masterData?.typeOfArticledata?.map(
                                    (article) => {
                                        return (
                                            <>
                                                <li onClick={(value) => handleSideMenuChange("type", article._id, article?.articleName)}><a class={sideMenuType == article?._id ? "active" : ""}>{article?.articleName}</a></li>
                                            </>
                                        )
                                    })}
                            </ul>
                        </div>

                        <h2 className={publicationYearToggle == true ? "sideTitle subnav active " : "sideTitle subnav"} onClick={togglePublicationYear}>Publication Year<i></i></h2>
                        <div className="pageMenu-link border-bottom-0">
                            <ul className={publicationYearToggle == true ? "yearLink" : "d-none"}>
                                {sidePublicationYear?.map((item) => {
                                    return (
                                        <>
                                            <li onClick={(value) => handleSideMenuChange("year", item?.value, item?.value)}>
                                                <Link class={sideMenuYear == item?.value ? "active" : ""}>{item?.value}</Link></li>
                                        </>
                                    )
                                }
                                )}
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="pageRight text-initial">
                    <div className="innerMergin">
                        <div className="articleLanding">
                            {
                                !recommendedDivSct ? (<>
                                    <div className="articleHead">
                                        <h2>Latest articles for you</h2>
                                        <p>The most recent articles that have been published online that you might enjoy</p>
                                    </div>
                                    <div className="row recommendedMain" >
                                        {articleData && articleData?.data?.length > 0 ? (
                                            articleData?.data?.map((articleRes, index) => {
                                                const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
                                                const contentWithoutSpecialChars = () => {
                                                    return articleRes?.abstract?.replace(
                                                        specialCharPattern,
                                                        ""
                                                    );
                                                };
                                                return (
                                                    <div className="col-md-4">
                                                        <RelatedArticles
                                                            index={index}
                                                            published={articleRes?.updatedAt}
                                                            id={articleRes?.readinglistId}

                                                            title={articleRes?.title}
                                                            slug={articleRes?.slug}
                                                            userTitle={
                                                                articleRes?.user?.[0]?.title
                                                            }
                                                            userName={
                                                                articleRes?.user?.[0]?.fullName
                                                            }
                                                            {...articleRes?.authorDetails?.author?.map((autName) => {
                                                                return (
                                                                    <>
                                                                        {autName.title}
                                                                        {autName.fullName}
                                                                    </>
                                                                );
                                                            })}
                                                            contentWithoutSpecialChars={contentWithoutSpecialChars}
                                                            typeOfArticle={articleRes?.typeofarticles?.[0]?.articleName}
                                                            journalName={
                                                                articleRes?.submissionDetail?.journalName
                                                            }
                                                            journalType={
                                                                articleRes?.journalType?.[0]?.journalType
                                                            }
                                                            subjectName={articleRes?.subjectDetail?.[0]?.subjectName}
                                                            specialization={articleRes?.specializations?.[0]?.specialization}
                                                            fetchReadingList={fetchLatestArticlesData}
                                                            readingList={articleData}
                                                            articleId={articleRes?._id}
                                                            readingflag={articleRes?.readingflag}
                                                        />
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className="tabContentWrap">
                                                <div className="notArticle">
                                                    <figure>
                                                        <img src={notyet} alt="No Article" />
                                                    </figure>
                                                    <h2>No articles yet!</h2>

                                                </div>
                                            </div>
                                        )}
                                        {latestArticlesTotalPages > 1 && (
                                            <div className="paginationWrap mt-4">
                                                <Pagination
                                                    boundaryRange={1}
                                                    firstItem={null}
                                                    lastItem={null}
                                                    siblingRange={1}
                                                    activePage={latestPage}
                                                    prevItem={{ content: <b class="icon-arrow-left" /> }}
                                                    nextItem={{ content: <b class="icon-arrow-right" /> }}
                                                    onPageChange={(event, data) => setLatestPage(data.activePage)}
                                                    totalPages={latestArticlesTotalPages}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {lastBrowseArticleData && lastBrowseArticleData?.length > 0 && (
                                        <div className="lastBrowsed" >
                                            <div className="lastBrowsedHead">
                                                <h2>Continue reading the article you last browsed</h2>
                                                <a className="button button-primary" onClick={() => resumeArticle(true)}>Resume</a>
                                            </div>


                                            <div className="row">
                                                {lastBrowseArticleData && lastBrowseArticleData?.length > 0 ? (
                                                    lastBrowseArticleData?.map((contnueRdArtcl, index) => {
                                                        const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
                                                        const contentWithoutSpecialChars = () => {
                                                            return contnueRdArtcl?.article?.[0]?.abstract?.replace(specialCharPattern, "");
                                                        };

                                                        return (
                                                            <div className="col-md-12">
                                                                <RelatedArticles
                                                                    index={index}
                                                                    published={contnueRdArtcl?.updatedAt}
                                                                    id={contnueRdArtcl?.readinglistId}
                                                                    title={contnueRdArtcl?.article?.[0]?.title}
                                                                    userTitle={
                                                                        contnueRdArtcl?.user?.[0]?.title
                                                                    }
                                                                    userName={
                                                                        contnueRdArtcl?.user?.[0]?.fullName
                                                                    }
                                                                    {...contnueRdArtcl?.authorDetails?.author?.map((autName) => {
                                                                        return (
                                                                            <>
                                                                                {autName.title}
                                                                                {autName.fullName}
                                                                            </>
                                                                        );
                                                                    })}
                                                                    contentWithoutSpecialChars={contentWithoutSpecialChars}
                                                                    typeOfArticle={contnueRdArtcl?.typeofarticles?.[0]?.articleName}
                                                                    journalName={
                                                                        contnueRdArtcl?.article?.[0]?.submissionDetail
                                                                            ?.journalName
                                                                    }
                                                                    subjectName={contnueRdArtcl?.subjectDetail?.[0]?.subjectName}
                                                                    specialization={contnueRdArtcl?.specializations?.[0]?.specialization}
                                                                    fetchReadingList={fetchLastBrowseArticleData}
                                                                    readingList={lastBrowseArticleData}
                                                                    articleId={lastBrowseArticleData?.[0]?.article?.[0]?._id}
                                                                    readingflag={contnueRdArtcl?.readingflag}
                                                                />
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div className="tabContentWrap">
                                                        <div className="notArticle">
                                                            <figure>
                                                                <img src={notyet} alt="No Article" />
                                                            </figure>
                                                            <h2>No articles yet!</h2>

                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className="articleHead">
                                        <h2>Articles recommended for you</h2>
                                        <p>Based on your areas of interest, this list has been especially created for you</p>
                                    </div>

                                    <div className="row">
                                        {recommendedList && recommendedList?.length > 0 ? (
                                            recommendedList?.map((recomndArtcl, index) => {
                                                const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
                                                const contentWithoutSpecialChars = () => {
                                                    return recomndArtcl?.abstract?.replace(specialCharPattern, "");
                                                };
 
                                                return (
                                                    <div className="col-md-4">
                                                        <RelatedArticles
                                                            index={index}
                                                            published={recomndArtcl?.updatedAt}
                                                            id={recomndArtcl?.readinglistId}

                                                            title={recomndArtcl?.title}
                                                            slug={recomndArtcl?.slug}
                                                            userTitle={
                                                                recomndArtcl?.user?.[0]?.title
                                                            }
                                                            userName={
                                                                recomndArtcl?.user?.[0]?.fullName
                                                            }
                                                            {...recomndArtcl?.authorDetails?.author?.map((autName) => {
                                                                return (
                                                                    <>
                                                                        {autName.title}
                                                                        {autName.fullName}
                                                                    </>
                                                                );
                                                            })}
                                                            contentWithoutSpecialChars={contentWithoutSpecialChars}
                                                            typeOfArticle={recomndArtcl?.typeofarticles?.[0]?.articleName}
                                                            journalName={
                                                                recomndArtcl?.submissionDetail?.journalName
                                                            }
                                                            journalType={
                                                                recomndArtcl?.journalType?.[0]?.journalType
                                                            }
                                                            subjectName={recomndArtcl?.subjectDetail?.[0]?.subjectName}
                                                            specialization={recomndArtcl?.specializations?.[0]?.specialization}
                                                            fetchReadingList={fetchLatestArticlesData}
                                                            readingList={articleData}
                                                            articleId={recomndArtcl?._id}
                                                            pageName="articleLanding"
                                                        />
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="tabContentWrap">
                                                <div className="notArticle">
                                                    <figure>
                                                        <img src={notyet} alt="No Article" />
                                                    </figure>
                                                    <h2>No articles yet!</h2>

                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>) : (<>
                                </>)
                            }
                            <div className="articleHead mt-4">
                                <h2>Most viewed articles</h2>
                                <p>Articles with the highest readership in the most recent months</p>
                            </div>

                            <div className="row recommendedMain">
                                {mostViewedArticleData && mostViewedArticleData?.length > 0 ? (
                                    mostViewedArticleData?.map((mstVwdArticle, index) => {
                                        const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
                                        const contentWithoutSpecialChars = () => {
                                            return mstVwdArticle?.abstract?.replace(specialCharPattern, "");
                                        };
                                        const truncateText = (text) => {
                                            const words = text?.split(" ");
                                            if (words?.length > MAX_WORDS) {
                                                return words.slice(0, MAX_WORDS).join(" ") + "...Read More";
                                            }
                                            return text;
                                        };
                                        const truncatedText = truncateText(contentWithoutSpecialChars());
                                        return (
                                            <div className="col-md-4">
                                               <RelatedArticles
                                                            index={index}
                                                            published={mstVwdArticle?.updatedAt}
                                                            id={mstVwdArticle?.readinglistId}

                                                            title={mstVwdArticle?.title}
                                                            slug={mstVwdArticle?.slug}
                                                            userTitle={
                                                                mstVwdArticle?.user?.[0]?.title
                                                            }
                                                            userName={
                                                                mstVwdArticle?.user?.[0]?.fullName
                                                            }
                                                            {...mstVwdArticle?.authorDetails?.author?.map((autName) => {
                                                                return (
                                                                    <>
                                                                        {autName.title}
                                                                        {autName.fullName}
                                                                    </>
                                                                );
                                                            })}
                                                            contentWithoutSpecialChars={contentWithoutSpecialChars}
                                                            typeOfArticle={mstVwdArticle?.typeofarticles?.[0]?.articleName}
                                                            journalName={
                                                                mstVwdArticle?.submissionDetail?.journalName
                                                            }
                                                            journalType={
                                                                mstVwdArticle?.journalType?.[0]?.journalType
                                                            }
                                                            subjectName={mstVwdArticle?.subjectDetail?.[0]?.subjectName}
                                                            specialization={mstVwdArticle?.specializations?.[0]?.specialization}
                                                            fetchReadingList={fetchLatestArticlesData}
                                                            readingList={articleData}
                                                            articleId={mstVwdArticle?._id}
                                                        />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="tabContentWrap">
                                        <div className="notArticle">
                                            <figure>
                                                <img src={notyet} alt="No Article" />
                                            </figure>
                                            <h2>No articles yet!</h2>

                                        </div>
                                    </div>
                                )}
                                {mostViewedArticleTotalPages > 1 && (
                                    <div className="paginationWrap mt-4">
                                        <Pagination
                                            boundaryRange={1}
                                            firstItem={null}
                                            lastItem={null}
                                            siblingRange={1}
                                            activePage={mostViewedPage}
                                            prevItem={{ content: <b class="icon-arrow-left" /> }}
                                            nextItem={{ content: <b class="icon-arrow-right" /> }}
                                            onPageChange={(event, data) => setMostViewedPage(data.activePage)}
                                            totalPages={mostViewedArticleTotalPages}
                                        />
                                    </div>
                                )}
                            </div>
                            {
                                recommendedDivSct ? (<>

                                    <div className="redingFilterWrap">
                                        <div className="commanBox">
                                            <div className="redingFilterArea">
                                                {
                                                    sideMenuDiscipline ? (<></>) :
                                                        (<>

                                                            <div className="redingFilterList">
                                                                <div className="fieldWrap selectBox">
                                                                    {readingListData?.journal ? (<>
                                                                        <Select
                                                                            handleChange={(value) =>
                                                                                handleSelectDisciplineChange(value, "discipline")
                                                                            }
                                                                            selectedValue={readingListData?.discipline}
                                                                            options={disciplineOption?.map((val) => val?.subjectName)}
                                                                            optionValues={disciplineOption?.map(
                                                                                (val) => val?._id
                                                                            )}
                                                                            labelClass={readingListData?.discipline ? "" : "hidden"}
                                                                            label="Discipline"
                                                                            placeholder="Discipline"
                                                                            name="Discipline"
                                                                        />
                                                                    </>) : (<>
                                                                        <Select
                                                                            handleChange={(value) =>
                                                                                handleSelectDisciplineChange(value, "discipline")
                                                                            }
                                                                            selectedValue={readingListData?.discipline}
                                                                            options={allDisciplineData.map((val) => val?.subjectName)}
                                                                            optionValues={allDisciplineData?.map(
                                                                                (val) => val?._id
                                                                            )}
                                                                            labelClass={readingListData?.discipline ? "" : "hidden"}
                                                                            label="Discipline"
                                                                            placeholder="Discipline"
                                                                            name="Discipline"
                                                                        />
                                                                    </>)}
                                                                </div>
                                                            </div>
                                                        </>)}
                                                <div className="redingFilterList" id={`viewScrollRelatedArticle`}>
                                                    <div className="fieldWrap selectBox">
                                                        <SearchDropdown
                                                            options={specializationOptions?.filter((spec) => spec?.specialization !== "Other Subject (choose this option to register your subject)").map((val) => val?.specialization)}
                                                            onChange={handleChange}
                                                            selectedValue={readingListData?.subject}
                                                            name="subject"
                                                            placeholder={
                                                                readingListData?.subject?.length === 0 ||
                                                                    !readingListData?.subject
                                                                    ? "Subject"
                                                                    : readingListData?.subject?.length > 1
                                                                        ? readingListData?.subject[0] +
                                                                        " +" +
                                                                        String(readingListData?.subject.length - 1)
                                                                        : readingListData?.subject[0]
                                                            }
                                                            label="Subject"
                                                            labelClass={
                                                                readingListData?.subject?.length !== 0 ? "" : "hidden"
                                                            }
                                                            removeAsterisk={true}
                                                        />
                                                    </div>
                                                </div>

                                                {(readingListData?.journal || sideMenuType) ? (<></>) : (<>
                                                    <div className="redingFilterList">
                                                        <div className="fieldWrap selectBox">
                                                            <div className="fieldWrap selectBox">
                                                                <SearchDropdown
                                                                    options={masterData?.typeOfArticledata?.map(
                                                                        (article) => article?.articleName
                                                                    )}
                                                                    onChange={handleChange}
                                                                    selectedValue={readingListData?.type}
                                                                    name="type"
                                                                    placeholder={
                                                                        readingListData?.type?.length === 0 ||
                                                                            !readingListData?.type
                                                                            ? "Article Type"
                                                                            : readingListData?.type?.length > 1
                                                                                ? readingListData?.type[0] +
                                                                                " +" +
                                                                                String(readingListData?.type.length - 1)
                                                                                : readingListData?.type[0]
                                                                    }
                                                                    label="Article Type"
                                                                    labelClass={
                                                                        readingListData?.type?.length !== 0 ? "" : "hidden"
                                                                    }
                                                                    removeAsterisk={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)}
                                                {sideMenuYear ? (<></>) : (<>
                                                    <div className="redingFilterList ">
                                                        <div className="fieldWrap selectBox">
                                                            <Select
                                                                handleChange={(value) => handleYearSelectChange(value, "year")}
                                                                selectedValue={readingListData?.year}
                                                                options={publicationYear?.map((item) => item?.key)}
                                                                optionValues={publicationYear?.map((item) => item?.value)}
                                                                labelClass={readingListData?.year ? "" : "hidden"}
                                                                label="Publication Year"
                                                                placeholder="Publication Year"
                                                                name="year"
                                                            />
                                                        </div>
                                                    </div>
                                                </>)}
                                                <div className="redingFilterList actionWrap">
                                                    <span className="filterText">Filter</span>
                                                    <b onClick={clearFilter}>Clear All</b>
                                                    <Link
                                                        class="button button-primary"
                                                        to=""
                                                        onClick={getRecommendedArticles}
                                                    >
                                                        Apply
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 sortWrap">
                                            <div className="customDrop">
                                                <Dropdown className="removeArrow">
                                                    <Dropdown.Toggle variant="success">Sort By</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            className={oldToNew === -1 ? "active" : ""}
                                                            onClick={() => {
                                                                setOldToNew(-1);
                                                            }}
                                                        >
                                                            New to Old
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            className={oldToNew === 1 ? "active" : ""}
                                                            onClick={() => {
                                                                setOldToNew(1);
                                                            }}
                                                        >
                                                            Old to New
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    {readingListData?.journal ? (<>
                                        <div className="articleHead">
                                            <h2>Articles In {readingListData?.journal}</h2>
                                           
                                        </div>
                                    </>) : (<>{(sideMenuDiscipline || sideMenuType ||sideMenuYear)? (<>
                                        <div className="articleHead">
                                            <h2>Articles On {selectedName}</h2>
                                        </div>
                                        </>):(<>{sideMenuType? (<>
                                            <div className="articleHead">
                                            <h2>Articles On {selectedName}</h2>
                                        </div>
                                        </>):(<>{sideMenuYear?(<>
                                            <div className="articleHead">
                                            <h2>Articles On {selectedName}</h2>
                                        </div>
                                        </>):(<></>)})</>)} </>)}</>)}

                                    <div className="row">
                                        {recommendedList && recommendedList?.length > 0 ? (
                                            recommendedList?.map((recomndArtcl, index) => {
                                                const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
                                                const contentWithoutSpecialChars = () => {
                                                    return recomndArtcl?.abstract?.replace(specialCharPattern, "");
                                                };

                                                return (
                                                    <div className="col-md-12">
                                                        <RelatedArticles
                                                            index={index}
                                                            published={recomndArtcl?.updatedAt}
                                                            id={recomndArtcl?.readinglistId}
                                                            title={recomndArtcl?.title}
                                                            userTitle={
                                                                recomndArtcl?.user?.[0]?.title
                                                            }
                                                            userName={
                                                                recomndArtcl?.user?.[0]?.fullName
                                                            }
                                                            {...recomndArtcl?.authorDetails?.author?.map((autName) => {
                                                                return (
                                                                    <>
                                                                        {autName.title}
                                                                        {autName.fullName}
                                                                    </>
                                                                );
                                                            })}
                                                            contentWithoutSpecialChars={contentWithoutSpecialChars}
                                                            typeOfArticle={recomndArtcl?.typeofarticles?.[0]?.articleName}
                                                            journalName={
                                                                recomndArtcl?.submissionDetail?.journalName
                                                            }
                                                            subjectName={recomndArtcl?.subjectDetail?.[0]?.subjectName}
                                                            specialization={recomndArtcl?.specializations?.[0]?.specialization}
                                                            fetchReadingList={getRecommendedArticles}
                                                            readingList={recommendedList}
                                                            articleId={recomndArtcl?._id}
                                                            pageName="articleLanding"
                                                            readingflag={recomndArtcl?.readingflag}
                                                        />
                                                    </div>


                                                );
                                            })
                                        ) : (
                                            <div className="tabContentWrap">
                                                <div className="notArticle">
                                                    <figure>
                                                        <img src={notyet} alt="No Article" />
                                                    </figure>
                                                    <h2>No articles yet!</h2>

                                                </div>
                                            </div>

                                        )}
                                    </div>

                                    {recommendedTotalPages > 1 && (
                                        <div className="paginationWrap mt-4">
                                            <Pagination
                                                boundaryRange={1}
                                                firstItem={null}
                                                lastItem={null}
                                                siblingRange={1}
                                                activePage={recommendedPage}
                                                prevItem={{ content: <b class="icon-arrow-left" /> }}
                                                nextItem={{ content: <b class="icon-arrow-right" /> }}
                                                onPageChange={(event, data) => setRecommendedPage(data.activePage)}
                                                totalPages={recommendedTotalPages}
                                            />
                                        </div>
                                    )}

                                </>) : (<>
                                </>)
                            }

                        </div>
                        {
                            recommendedDivSct ? (<>
                                <div className="backBtnWrap mt-5">
                                    <a onClick={handleBack}>
                                        <span>Back</span>
                                        <img src={backArrow} alt="#" />
                                    </a>
                                </div>
                            </>) : (<>
                                <div className="backBtnWrap mt-5">
                                    <a onClick={() => window.history.back()}>
                                        <span>Back</span>
                                        <img src={backArrow} alt="#" />
                                    </a>
                                </div>
                            </>)
                        }
                    </div>
                </div>
            </div>
        </div>
        <AakashgangaFooter />
    </>

}
export default articleLanding
