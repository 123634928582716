import React from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router";

const ConfirmationalModal = ({
  setShowReject,
  showConfirmation,
  setShowConfirmation,
  title,
  body,
  leftBtnTxt,
  rightBtnTxt,
  peActiveTab,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showConfirmation}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header">
          <h2 class="modal-title">{title}</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowConfirmation(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div
            className="aaforAss"
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          />
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-gray bold mr-3"
            onClick={() => {
              setShowConfirmation(false);
              setShowReject(true);
            }}
          >
            {leftBtnTxt}
          </span>
          {peActiveTab === "draft" ? (
            <button
              class="button button-primary"
              onClick={() => {
                setShowConfirmation(false);
                navigate("/dashboard/publishing-editor");
              }}
            >
              {rightBtnTxt}
            </button>
          ) : (
            <button
              class="button button-primary"
              onClick={() => {
                setShowConfirmation(false);
              }}
            >
              {rightBtnTxt}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmationalModal;
