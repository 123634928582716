import React from "react";
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import JoinPublishingCommunityForm from "../components/sellPageForeign/JoinPublishingCommunityForm";
import { Helmet } from 'react-helmet';
const GetInTouch = () => {
  return (
    <>
    <Helmet>
     <title>Get in Touch | Aakashganga Open Journals</title>
     </Helmet>
    <div className="tagbody">
      <AakashgangaHeader />
      <div className="markitingContact">
        <div className="contactSection innerPages">
          <div className="containWrap">
            <div className="contactLeft">
              <h1>
                <span className="">Get In Touch</span>
              </h1>
              <div className="getTouchMarket">
                <JoinPublishingCommunityForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AakashgangaFooter />
    </div>
    </>
  );
};

export default GetInTouch;
