
import React, {  useState } from 'react';
import Select from "../common/Select";


const TableOfContent = ({ pagename,details}) => {
    
    const [seleted, setseleted] = useState('');
    function handleauthorInput(e)
    {
        setseleted(e)
        const element = document.getElementById(`viewScroll${e}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    return (
        <div className="fieldWrap mt-4 mb-4">
        <label className="labelForm">
            Table of Contents
        </label>
        <div className="selectBox">
        <Select
            selectedValue={seleted}
            options={details.map((val) => val)}
            optionValues={details.map((val) => val)}
            name={`tbl`}
            placeholder=""
            handleChange={(e) =>
                handleauthorInput(e)
            }
            
            mandatory={false}
            
            
            /> 
        </div>
    </div>
   
    );
};
export default TableOfContent