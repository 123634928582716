import { useNavigate, Link } from "react-router-dom";
import { aboutAkashGangaData } from "../components/sellPageForeign/data";
import PeraTextData from "../components/global/PeraTextData";
/*eslint-disable*/
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import JoinPublishingCommunityForm from "../components/sellPageForeign/JoinPublishingCommunityForm";
import { Helmet } from 'react-helmet';

function SellPage() {
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const handleContent = (type) => {
    const element = document.getElementById(`viewScroll${type}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClick = () => {
    window.scrollTo({
      top: 4500,
      behavior: "smooth",
    });
  };
  return (
    <>
     <Helmet>
          <title>Indian Societies Partnership|  Aakashganga Indian open access journals platform</title>
          <meta name="description" content="Learn how Aakashganga, an Indian open access journal platform, supports this initiative by providing world-class publishing infrastructure at Indian prices." />

          <meta property="og:title" content="Indian Societies Partnership|  Aakashganga Indian open access journals platform" data-react-helmet="true" />
          <meta property="og:description" content="Learn how Aakashganga, an Indian open access journal platform, supports this initiative by providing world-class publishing infrastructure at Indian prices." data-react-helmet="true" />
          <meta property="og:url" content="https://www.aakashgangaopen.in/domestic-societies-partnerships" data-react-helmet="true" />

     <link rel="canonical" href="https://www.aakashgangaopen.in/domestic-societies-partnerships" data-react-helmet="true" />
    </Helmet>
      <div className="tagbody">
        <AakashgangaHeader />
        <h1 className="seo-related-tag">
          Empowering Universities, Institutes, and Societies
        </h1>
        <main className="main  ">
          <div className="">
            <div className="container">
              <div className="sell-banner-wrp">
                <div className="sell-banner">
                  <img
                    className="tagimg"
                    src="assets/images/university.gif"
                    alt="Universities, Societies"
                  />
                </div>
                <div className="sell-banner-text">
                  <h3 className="title tagh3">
                    Publ<span>i</span>sh Your Open
                    <br />
                    Access Journal With Us
                  </h3>
                  <p className="tagp">
                    Empowering Universities, Institutes, and Societies
                  </p>
                  <div className="ag_special_offer_btn customSellpageflexdirection">
                    <button className="ag_btn_red taga" onClick={handleClick}>
                      Connect Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="container container-full Transforming-btm-col">
              <div className="ag_heading_center_wrap mt-5">
                <h2 className="tagh2">
                  Transform<span>i</span>ng Scholarly Research Together
                </h2>
              </div>
              <div className="Transforming-btm-text text-center mb-5">
                <h4 className="tagh4">
                  Are you a University, Society or an Institute looking for{" "}
                  <p className="tagp">
                    World Class Journal Publishing Infrastructure at Affordable
                    Prices?
                  </p>
                </h4>
                <p className="pera-text tagp">
                  Well, look no further! We are here to be your ultimate
                  publishing partners, offering you end-to-end services to
                  achieve your Journal Publishing Objective.
                </p>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row about-sec align-items-center">
              <div className="col-lg-6">
                <h4 className="tagh4 mb-0">About Aakashganga</h4>
                <PeraTextData data={aboutAkashGangaData} />

                <div className="ag_special_offer_btn mt-3 customSellpageflexdirection">
                  <a className="ag_btn_red taga" onClick={handleClick}>
                    Connect Now
                  </a>
                </div>
              </div>
              <div className="col-lg-6 text-center">
                <img
                  src="assets/images/sell-logo.png"
                  className="sell_logo tagimg"
                  alt="Aakashganga Logo"
                />
                <span>Bringing Your Research Ideas To Life</span>
              </div>
            </div>
          </div>

          <div className="">
            <div className="specific-issue">
              <div className="specificc-issue-img">
                <img
                  src="assets/images/speccificc-issue.png"
                  alt="Speccificc-Issue"
                  className="tagimg"
                />
              </div>
              <div className="specific-issue-text">
                <h3 className="tag3">
                  Specific Issues Confronting Indian Journal Publishing
                </h3>
              </div>
            </div>
          </div>

          <div className="container nation-one">
            <div className="ag_heading_center_wrap mt-5 mb-5">
              <h2 className="tagh2">One Nation One Subscription</h2>
            </div>
            <div className="row mt-5 mb-4 align-items-center">
              <div className="col-lg-6">
                <div className="nation-wrp">
                  <div className="nation-img">
                    <img
                      src="assets/images/nation-one.png"
                      className="tagimg"
                      alt="One Nation One Subscription"
                    />
                  </div>
                  <div className="nation-text text-center"></div>
                </div>
              </div>
              <div className="col-lg-6 nation-right-col">
                <p className="pera-text tagp">
                  India is in the final stages of rolling out a path breaking
                  policy titled ‘One Nation, One Subscription.
                </p>
                <p className="pera-text tagp">
                  “One Nation One Subscription” is an innovative policy launched
                  by the Government of India with the aim of revolutionizing the
                  access and availability of research publications in the
                  country.
                </p>
                <p className="pera-text tagp">
                  It underscores the government’s commitment to fostering an
                  inclusive and vibrant research ecosystem in India.
                </p>
                <p className="pera-text tagp">
                  The policy aims to eliminate disparities in access to
                  knowledge and provide a level playing field for researchers
                  nationwide regardless of the university they study in.
                </p>
                <p className="pera-text tagp">
                  It encourages institutions and societies to choose Indian
                  publishers as their preferred publishing partners, supporting
                  the development and recognition of homegrown research
                  platforms.
                </p>
                <p className="pera-text tagp">
                  The policy is scheduled to be implemented from 1 st January
                  2024.
                </p>
              </div>
            </div>
          </div>

          <div className="container choosing-main-col">
            <div className="ag_heading_center_wrap mt-5 mb-5">
              <h2 className="tagh2">Choosing An Indian Publisher</h2>
            </div>
            <div className="row choosing-main-col-row my-5 px-lg-5 px-3 customSellpagejustify">
              <div className="col-lg-7 choosing-main-text">
                <p className="pera-text mb-4 tagp">
                  Aakashganga is a mission dedicated to providing world class
                  journal publishing Infrastructure at Indian prices.
                </p>
                <p className="pera-text mb-4 tagp">
                  By choosing the Indian open access journals platform,
                  Aakashganga, Universities, institutions and societies can not
                  only align themselves with the national research agenda but
                  also benefit from the comprehensive support, enhanced
                  visibility, and cost-effective publishing options offered by
                  us.
                </p>
                <p className="pera-text mb-4 tagp">
                  The platform is designed to meet your needs for speed,
                  affordability, transparency since it is built on a fully
                  automated (95%) workflow.
                </p>
                <p className="pera-text mb-4 tagp">
                  <b>Let us help realize your Journal Publishing objectives.</b>
                </p>
                <div className="ag_special_offer_btn mt-4 customSellpageflexdirection">
                  <a
                    href="javascript:;"
                    className="ag_btn_red taga"
                    onClick={handleClick}
                  >
                    Connect Now
                  </a>
                </div>
              </div>
              <div className="col-lg-4 sellImgPosition">
                <div className="choosingg-main-img customSellpageboxclr">
                  <div className="nation-wrp">
                    <div className="nation-img">
                      <img
                        src="assets/images/publisher.png"
                        alt="Indian Open Access Journal Platform"
                        className="tagimg"
                      />
                    </div>
                    <div className="nation-text text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container container-full">
            <div className="ag_heading_center_wrap mt-4 mb-4">
              <h2 className="tagh2">Still not convinced? Read on</h2>
            </div>

            <div className="row mt-4 convinced">
              <div className="col-lg-12 convinced-img-wrp convinced-col-1 ">
                <div className="convinced-img-col">
                  <img
                    src="assets/images/convinced-1.png"
                    className="tagimg"
                    alt="Convinced_Home"
                  />
                </div>
                <div className="convinced-right-text convinced-text">
                  <div className="convinced-text-col">
                    <div className="leftSpace leftSpace01">
                      <h4 className="tagh4">
                        A Future Ready Home For Your Journals
                      </h4>
                      <p className="welev wid-mar tagp">
                        We leverage technology to bring you a smooth, effortless
                        and transparent publishing experience. Our automated
                        workflow simplifies complex manual tasks and will meet
                        all your current and future journal publishing needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 convinced-img-wrp convinced-col-2">
                <div className="convinced-left-text convinced-text">
                  <div className="convinced-text-col">
                    <div className="leftSpace">
                      <h4 className="tagh4">Global Visibility</h4>
                      <p className="global tagp">
                        Each journal within our network is meticulously curated
                        and aligned with quality standards that contribute to a
                        favourable outlook for indexing. This is done through a
                        dedicated focus on content quality, rigorous peer-review
                        processes, and compliance with industry best practices,
                        reaching researchers worldwide.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="convinced-img-col">
                  <img
                    src="assets/images/convinced-2.png"
                    className="tagimg"
                    alt="Global Visiblity"
                  />
                </div>
              </div>

              <div className="col-lg-12 convinced-img-wrp convinced-col-3">
                <div className="convinced-img-col">
                  <img
                    src="assets/images/convinced-3.png"
                    className="tagimg"
                    alt="Peer Reviewers"
                  />
                </div>
                <div className="convinced-right-text convinced-text">
                  <div className="convinced-text-col">
                    <div className="leftSpace03">
                      <h4 className="tagh4">Peer Reviewers a Plenty</h4>
                      <p className="review tag4">
                        One of the biggest hurdles societies face is finding
                        reliable and efficient Peer Reviewers. It is a common
                        pain point. Say goodbye to that worry because we have a
                        vast network of expert reviewers ready to contribute
                        their expertise to your research.
                      </p>
                      <p className="review tagp">
                        What’s more, we offer both{" "}
                        <strong>Pre Publication Double Anonymous</strong> , and{" "}
                        <strong>Post Publication Open Review </strong>{" "}
                        subscribing to the opportunities promoted by Open
                        Science.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 convinced-img-wrp convinced-col-4">
                <div className="convinced-left-text convinced-text">
                  <div className="convinced-text-col">
                    <div className="leftSpace04">
                      <h4 className="tagh4">Exercise Full Control</h4>
                      <p className="firmly tagp">
                        We firmly believe in putting you in the driver’ seat.
                        With us, you have full control. You can effortlessly
                        manage submissions, handpick, and oversee your editorial
                        board, and experience lightning-fast publishing speed
                        and turnaround time.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="convinced-img-col">
                  <img
                    src="assets/images/convinced-4.png"
                    className="tagimg"
                    alt="Exercise Full Control"
                  />
                </div>
              </div>
            </div>

            <div className="ag_heading_center_wrap community-col sell-btm-form mt-5 mb-5">
              <h2 className="tagh2">
                Please fill out this simple form and take the first step in
                <br />
                joining hands with one of the fastest growing
                <br /> open access journal publishing community.
              </h2>
            </div>
            <JoinPublishingCommunityForm />
          </div>

          <div className="container">
            <div className="mt-4 mb-5 text-center">
              <p className="pera-text tagp">
                For more questions on our processes, policies and other aspects,
                please refer to our{" "}
                <Link to="/faq" target="_blank">
                  <strong className="underline ">FAQ section.</strong>
                </Link>
              </p>
            </div>
            <div className="row my-5 align-items-center foreign-university">
              <div className="col-lg-6 ">
                <div className="nation-wrpSell">
                  <div className="nation-wrp">
                    <div className="nation-img">
                      <img
                        src="assets/images/university.png"
                        alt="University"
                        className="customsellwidth tagimg sellPageStyle"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 foreign-university-text">
                <h4 className="tagh4">
                  Are You A Foreign University, Society Or Institute?
                </h4>
                <div className="ag_special_offer_btn customSellpageflexdirection">
                  <Link
                    to="/foreign-societies-partnerships"
                    target="_blank"
                    className="ag_btn_red taga"
                  >
                    Click Here
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
        <AakashgangaFooter />
      </div>
    </>
  );
}

export default SellPage;
