import AakashgangaHeader from "../../components/global/HeaderComponent";
import AakashgangaFooter from "../../components/global/FooterComponent";
import error01 from "../../assets/img/error/error_desktop_new_white.svg";
import error02 from "../../assets/img/error/error_desktop_new_white.svg";

const Error = () => {
  const handleChatBot = (e) => {
    e.preventDefault();
    window.zE("messenger", "open");
  };

  return (
    <>
      <AakashgangaHeader />
      <div className="resourceSection errorPge innerPages">
        <div className="containWrap">
          <div className="errorWrap">
            <div className="errorImage">
              <span className="errorDesktop">
                <img src={error01} alt="error" />
              </span>
              <span className="errorMobile">
                <img src={error02} alt="error" />
              </span>
            </div>
            <div className="errorContent">
              <p>
                Congratulations! You are one of the elite few who has managed to
                find our 404 page.
              </p>
              <p>
                Don’t worry - this is not a dead end. Just use the navigation at
                the top or the footer to see more options.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="error-footer">
        <AakashgangaFooter />
      </div>
    </>
  );
};

export default Error;
