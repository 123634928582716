import React, { useRef, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import alertSearch from "../../assets/img/icons/alertSearch.svg";
import doneSvg from "../../assets/img/icons/done02.svg";
import {
  updateArticleRequestApi,
} from "../../store/apiCalls/dashboard";
import UpdateTrinkaScript from "../../assets/js/Trinka-new-test";
import { toast } from "react-toastify";
import { FetchProfanityList } from "../../containers/autherFlow/submitArticle/queries";
import { checkGrammar } from "../../utils";
import GrammarSpellModal from "./GrammarSpellModal";

const UpdateArticleRequest = ({
  updateArticlePopup,
  setUpdateArticlePopup,
  updateSharingPopup,
  setUpdateSharingPopup,
  alreadyUpdatedPopup,
  setAlreadyUpdatedPopup,
  showNewArticleRequest,
  setShowNewArticleRequest,
  amendmentArticleId,
  articleName,
  authorName,
  journalName,
  subjectName,
  setUpdateTrigger,
}) => {
  const updateRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  const { id } = jwt_decode(localStorage.usertoken);
  const [disabled, setDisabled] = useState(false);
  const [updateArticle, setUpdateArticle] = useState({ reason: "" });
  const [profanityWords, setProfanityWords] = useState([]);
  const [profanityWordsFound, setProfanityWordsFound] = useState([]);
  const [spellCheckModal, setSpellCheckModal] = useState(false);
  const [profanityAlert, setprofanityAlert] = useState({
    isOPen: false,
    type: 0,
  });

  const onUpdateArticleRequest = async () => {
    let error = false;
    dispatch({ type: "SET_LOADER", payload: true });
    const response = await checkGrammar(updateRef.current?.value);
    dispatch({ type: "SET_LOADER", payload: false });
    const result = response?.data?.url?.sentences;
    result?.map((sentence) => {
      sentence.result?.forEach((item) => {
        if (
          (item?.cta_present?.[0] === true && (item?.error_category?.[0] === "Word/Phrase choice" && item?.error_category?.[0] === "Spellings & Typos" && item?.error_category?.[0] === "Verbs" && item?.error_category?.[0] === "Subject-verb agreement")) ||
          (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Word/Phrase choice") ||
          (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Spellings & Typos") ||
          (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Verbs") ||
          (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Subject-verb agreement")
        ) {
          error = true;
          setSpellCheckModal(true);
        } else {
          setSpellCheckModal(false);
        }
      });
    });
    if (error === true) {
      setSpellCheckModal(true);
      return;
    } else {
      setSpellCheckModal(false);
      if (validateForm()) {
        dispatch({ type: "SET_LOADER", payload: true });
        const params = {
          articleId: amendmentArticleId,
          reason: updateRef.current?.value,
          articleName: articleName,
          subject: subjectName,
          journalName: journalName,
          authorName: authorName,
          userId: id,
        };
        const response = await updateArticleRequestApi(headers, params);
        if (response?.status === 200) {
          dispatch({ type: "SET_LOADER", payload: false });
          setUpdateArticlePopup(false);
          setUpdateSharingPopup(true);
          setUpdateTrigger(true);
          setUpdateArticle({ reason: "" });
        } else {
          dispatch({ type: "SET_LOADER", payload: false });
          return toast.error("Please try again");
        }
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    const commonDetl = updateArticle.reason;
    let isProfinityWord = false;
    const profanityWordList = [];
    for (var i = 0; i < profanityWords.length; i++) {
      if (isExactMatch(commonDetl?.toLowerCase(), profanityWords[i])) {
        isProfinityWord = true;
        profanityWordList.push(profanityWords[i]);
      }
    }
    setProfanityWordsFound(profanityWordList);
    if (isProfinityWord === true) {
      valid = false;
      setprofanityAlert({ isOPen: true, type: 0 });
    }
    return valid;
  };

  const isExactMatch = (str, match) => {
    return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
  };

  const escapeRegExpMatch = function (s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  };

  const sucessHandlerProfanity = (profanity) => {
    if (profanity.status === 200) {
      setProfanityWords(profanity.data.data);
    }
  };

  async function getProfanity() {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      headers: headers,
    };
    getProfanityList(queryParams);
  }
  const { mutate: getProfanityList } = FetchProfanityList(
    sucessHandlerProfanity
  );

  useEffect(() => {
    const script = document.createElement("script");
      script.src = UpdateTrinkaScript;
      script.id = 'updateTrinkaId'
      script.async = true;
      if (!document.getElementById('updateTrinkaId')) {
        document.body.appendChild(script);
      }
    getProfanity();
  }, []);

  useEffect(() => {
    setDisabled(!updateArticle?.reason);
  }, [updateArticle]);

  return (
    <>
      <Modal
        dialogclassName="modal-dialog-centered modal-lg"
        show={updateArticlePopup}
        size="lg"
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">UPDATE ARTICLE REQUEST</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setUpdateArticlePopup(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="amendmentPopup">
            <div className="row">
              <div className="col-md-5">
                <div className="fieldWrap">
                  <label class="labelForm">Author Name</label>
                  <div className="inputTextBox">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter"
                      readOnly
                      value={authorName}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="fieldWrap">
                  <label class="labelForm">Article Name</label>
                  <div className="inputTextBox">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter"
                      readOnly
                      value={articleName}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="fieldWrap">
                  <label class="labelForm">Journal Name</label>
                  <div className="inputTextBox">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter"
                      readOnly
                      value={journalName}
                      title={journalName}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="fieldWrap">
                  <label class="labelForm">Subject</label>
                  <div className="inputTextBox">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter"
                      readOnly
                      value={subjectName}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="fieldWrap">
                  <label class="labelForm amendedTextArea">
                    <span>Reason for update</span>                    
                  </label>
                  <div className="inputTextBox">
                    <textarea
                      className="form-control"
                      type="text"
                      ref={updateRef}
                      contentEditable={true}
                      placeholder="Please enter the reason for update"
                      onChange={(e) => {
                        setUpdateArticle({
                          ...updateArticle,
                          reason: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <button
            className="button button-primary"
            type="button"
            onClick={onUpdateArticleRequest}
            disabled={disabled}
          >
            SUBMIT
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogclassName="modal-sm modal-dialog  "
        size="md"
        show={updateSharingPopup}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h5 className="modal-title">YOUR REQUEST IS SUBMITTED</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setUpdateSharingPopup(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="amendmentSharing pl-3 pr-2 mr-1">
            <p>
              We would like to inform you that there will
              <br />
              be an additional charge for updating your article.
            </p>

            <p>
              <i>
                Please note that all new updated versions of original articles
                will only go through the
                <br />
                <b>Post Open Peer Review.</b>
              </i>
            </p>

            <p>
              Please check your inbox for payment details. We will respond
              within <u>seven working days.</u>
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <button
            className="button button-primary sm ml-3"
            onClick={(e) => setUpdateSharingPopup(false)}
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogclassName="modal-sm modal-dialog  "
        size="md"
        show={alreadyUpdatedPopup}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h5 className="modal-title">
            YOUR REQUEST IS
            <br />
            ALREADY SUBMITTED
          </h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setAlreadyUpdatedPopup(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="amendmentSharing">
            <p class="text-center">
              We will respond within <u>seven working days.</u>
            </p>

            <p>
              If you are checking for an update after seven days, we recommend
              that you check your email for a response.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <button
            className="button button-primary sm ml-3"
            onClick={(e) => setAlreadyUpdatedPopup(false)}
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogclassName="modal-sm modal-dialog  "
        size="md"
        show={showNewArticleRequest}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">NEW ARTICLE VERSION REQUEST</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setShowNewArticleRequest(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="amendmentSharing">
            <p>
              Please proceed to the article to update it and create a new
              version.
            </p>
            <p>
              You have received an offer code in your inbox that should be used
              during the final payment.
            </p>
            <p>
              <i>
                Please note that all new updated versions of original articles
                will only go through the{" "}
                <b className="fontWe600">Post Open Peer Review</b>.
              </i>
            </p>
            <p>Would you like to proceed now?</p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-gray bold mr-3"
            onClick={() => setShowNewArticleRequest(false)}
          >
            NO
          </span>
          <button
            className="button button-primary ml-3"
            onClick={(e) => {
              setShowNewArticleRequest(false);
              navigate(`/manage-payment/${amendmentArticleId}?type=newVersion`);
            }}
          >
            YES
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        dialogclassName=""
        show={profanityAlert.isOPen}
        id="congratsMobile"
        tabIndex={-1}
        className={
          profanityAlert.type === 0
            ? "authorModal md-x"
            : "authorModal rightSide profanity-content-right"
        }
        centered
      >
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            <img src={alertSearch} alt="#" />
          </div>
          <h5 className="modal-title">PROFANITY check Results</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={(e) => setprofanityAlert({ isOPen: false, type: 0 })}
          ></button>
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0 pl-2 pr-2">
          <div className="grantDetails alertPopup">
            <div className="checkResultText">
              <strong>
                Thank you for sharing your update request for this article.{" "}
              </strong>
              <p>
                Before your request is processed, please make sure that the
                following words are removed :
              </p>
            </div>

            <div className="offensiveBox">
              <ol>
                {profanityWordsFound.map((wrd, index) => (
                  <li>{wrd}</li>
                ))}
              </ol>
            </div>
            <div className="mt-4 mb-3">
              {profanityAlert.type === 0 && (
                <button
                  className="button button-primary"
                  type="button"
                  onClick={(e) => setprofanityAlert({ isOPen: false, type: 0 })}
                >
                  Make Amendments
                </button>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="pb-0">
          <div className="helpPopupText pb-3">
            Need help? Write to us at
            <a href="mailto:contactus@vikramshilaedu.in">
              contactus@vikramshilaedu.in
            </a>
          </div>
        </Modal.Footer>
      </Modal>

      <GrammarSpellModal
        spellCheckModal={spellCheckModal}
        setSpellCheckModal={setSpellCheckModal}
        mainContent={"Thank you for sharing your update request for submission."}
        upperContent={"We have noticed that there are still some grammar and language issues that need your attention."}
        middleContent={"You will find them highlighted in the update request for correction."}
        lowerContent={"Before your update request is processed, please make sure that the highlighted issues are fixed."}
      />
    </>
  );
};

export default UpdateArticleRequest;
