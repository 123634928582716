import { useState } from "react";
import { CONTENT_HEADING } from "../../constants";
import OpenAccessModal from "../Modal/OpenAccessModal";
import Content from "../global/Content";
import { Link } from "react-router-dom";

const GeneralInfo = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Content title={CONTENT_HEADING.GENERAL_INFO}>
        <p className="pera-text tagp">
          Welcome to the Author Submission Guidelines.
        </p>
        <p className="pera-text platformm tagp">
          The Aakashganga platform is an{" "}
          <Link
            to="javascript:;"
            onClick={() => setShow(true)}
            className="underline blackbold"
          >
            Open Access Platform,
          </Link>{" "}
          centred on advancing and benefiting society by offering unrestricted
          access to the most recent research. Our mission is to help expedite
          the pace of discovery and establish a more equitable knowledge system
          accessible to all.
        </p>
        <p className="pera-text science tagp">
          We do this by making scholarly research and literature available
          freely for all by following the ‘Open Science’ principle, which
          implies that there are no financial, legal or technical barriers to
          accessing it. This means that anyone can freely read, download, copy,
          distribute, print, search for and search within the information, or
          use it in education or in any other way within the legal agreements.
        </p>
        <p className="pera-text tagp">
          At the core of the open culture lies a straightforward legal tool:{" "}
          <strong>
            the Creative Commons Attribution (CC BY NC SA) license.
          </strong>
        </p>
        <p className="pera-text tagp mb-5">
          For more information on Open Access, please read the section,{" "}
          <Link
            to="/how-we-publish"
            target="_blank"
            className="underline blackbold"
          >
            How We Work
          </Link>
        </p>
      </Content>
      <OpenAccessModal show={show} setShow={setShow} />
    </>
  );
};

export default GeneralInfo;
