import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import share from "../../assets/img/icons/share.svg";
import tags_active from "../../assets/img/icons/tags_active.svg";
import { getDateFormat } from "../../utils";
import { removeFromReadingList, getJournalDetailById } from "../../store/apiCalls/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,Link } from "react-router-dom";

import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share"; 
import open from "../../assets/img/icons/open.svg";
import copylink from "../../assets/img/icons/copylink.svg";
import linkedin_share from "../../assets/img/icons/linkedin_share.svg";
import twitter_share from "../../assets/img/icons/twitter_share.svg";
import facebook_share from "../../assets/img/icons/facebook_share.svg";
import mendeley from "../../assets/img/icons/mendeley.svg";
import { toast } from "react-toastify";
import { addArticleToReadingList } from "../../store/apiCalls/dashboard";
import tags from "../../assets/img/icons/tags.svg";
import copy from "copy-to-clipboard";
import { SERVER_URL } from "../../config";

const AllArticleRelatedarticles = ({
  published,
  id,
  title,
  userTitle,
  userName,
  slug,
  contentWithoutSpecialChars,
  typeOfArticle,
  journalName,
  journalType,
  reviewType,
  subjectName,
  specialization,
  fetchReadingList,
  articleId,
  readingflag,
  pageName,
}) => {
  const MAX_WORDS = 10;
  const MAX_TITLE_WORDS = 5;
  const SHARE_URL = `${SERVER_URL}/viewPublishedArticle/${articleId}`;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDeleted, setShowDeleted] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [copied, setCopied] = useState("");
  const [journalData, setJournalData] = useState([]);
  const userDetails = useSelector((state) => state?.LoginReducer?.user);
  const token = localStorage.getItem("usertoken");

  const titleStr = title;
  const titleParts = titleStr.split(':');

  const handleRemoveReadingList = async () => {
    dispatch({ type: "SET_LOADER", payload: true });
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: id,
      isDeleted: true,
    };
    const response = await removeFromReadingList(headers, params);
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      fetchReadingList();
      setShowDeleted(false);
    } else {
      dispatch({ type: "SET_LOADER", payload: false });
      return toast.error("Please try again");
    }
  };
  const getJournalDataSociety = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      journal: journalName,
    };
    const response = await getJournalDetailById(headers, params);
    if (response?.status === 200) {
        // console.log("getJournalDataSociety==>",response?.data?.data);
        setJournalData(response?.data?.data);
    } else return;
};
const formatUrl = (inputString) => {
  if (!inputString) {
    return ''; // Return an empty string or handle the case appropriately
  }

  // Remove leading and trailing spaces
  let formattedString = inputString.trim().toLowerCase();

  // Replace spaces within the string with hyphens
  formattedString = formattedString.replace(/\s+/g, '-');

  return formattedString;
}
const handlejournalNameforurl = (journalType,typeOfArticle) => {
  var fname = "";
  if (journalType=="Mega Journal" && typeOfArticle=="Conference Papers") {
    fname = formatUrl("Conference Paper");
  }else{
    fname = formatUrl(journalType);
  }

  // setjournalNameforurl(fname);
  return fname;
}
const handlesubject = (para) => {
  const subjectMapping = {
    "Medicine and Health Sciences": "Medicine",
    "Business, Management and Communication": "Business Mmanagement",
    "Social Science and Humanities": "Social science",
    "Science": "science",
    "Engineering": "engineering",
    "Information Technology": "information-technology",
    "Ayurveda": "ayurveda"
  };

  const fname = subjectMapping[para] || para;
  // console.log("subjectName", subjectName);
  // setsubjectNameforurl(fname);
  return fname;
};
const handlesubjectName = (para) => {
  const subjectMapping = {
    "Medicine and Health Sciences": "medicine",
    "Business, Management and Communication": "business-management",
    "Social Science and Humanities": "social-science",
    "Science": "science",
    "Engineering": "engineering",
    "Information Technology": "information-technology",
    "Ayurveda": "ayurveda"
  };

  const fname = subjectMapping[para] || para;
  // console.log("subjectName", subjectName);
  // setsubjectNameforurl(fname);
  return formatUrl(fname);
};

  const handleRedirect = (e, path) => {
    if (e !== "") e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const handleClick = () => {
    // handleRedirect("", `/viewPublishedArticle/${articleId}`);
    handleRedirect("", `/${handlejournalNameforurl(journalType,typeOfArticle)}/${handlesubjectName(subjectName)}/article/${slug}`); 
  };
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const copyToClipboard = (code) => {
    copy(code);
    return toast.success("Copied");
  };
  const handleMendeleyClick = () => {
    copy(SHARE_URL);
    window.open(
      "https://www.mendeley.com/reference-manager/library/all-references",
      "_blank"
    );
    addShareCount();
  };
  const addArticleToReadingLists = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      articleId: articleId,
      userId: userDetails?._id,
    };
    const response = await addArticleToReadingList(headers, params);
    if (response?.status === 200) {
      fetchReadingList();
    } else {
      return toast.error("Please try again!!");
    }
  };
  const extractFirstWord = (str) => {
    const match = str.match(/^[^–]+/);
    return match ? match[0].trim() : "";
};
useEffect(() => {
  getJournalDataSociety();
}, [journalName])  

  //  console.log("journalData", journalData);

  return (
    <>
      <div className={pageName == "access-articles" || pageName == "all-articles" ? 'recommendedBox newlook ml-0 mr-0 bgColorNone w-100 mb-4' : 'recommendedBox newlook ml-0 mr-0 bgColorNone w-100'} >
        <div className="recommendedHead">
          <div className="d-flex gap-4 left-part">
            <span>
              <img src={open} className="pr-2" alt="#" />
              Open Access
            </span>
            <span>
              {typeOfArticle && <span >{typeOfArticle}</span>}
            </span>
            <span>
              First published: {getDateFormat(published)}
            </span>
            <span>
            {subjectName=="Business, Management and Communication"?"Business and Management":subjectName}
            </span>
          </div>
          {/* <div className="recommendedShare">
            <i className="shareLogo">
              <img src={share} alt="#" />
              <div className="publisNav">
                <a onClick={handleMendeleyClick}>
                  <b>
                    <img src={mendeley} alt="#" />
                  </b>
                  Mendeley
                </a>
                <TwitterShareButton
                  url={SHARE_URL}
                  title={title}
                  hashtag={"vikramshila"}
                >
                  <a>
                    <b>
                      <img src={twitter_share} alt="#" />
                    </b>
                    Twitter
                  </a>
                </TwitterShareButton>
                <FacebookShareButton
                  url={SHARE_URL}
                  title={title}
                  hashtags={"vikramshila"}
                >
                  <a>
                    <b>
                      <img src={facebook_share} alt="#" />
                    </b>
                    Facebook
                  </a>
                </FacebookShareButton>
                <LinkedinShareButton url={SHARE_URL} title={title}>
                  <a>
                    <b>
                      <img src={linkedin_share} alt="#" />
                    </b>
                    LinkedIn
                  </a>
                </LinkedinShareButton>
                <a onClick={() => copyToClipboard(SHARE_URL)}>
                  <b>
                    <img src={copylink} alt="#" />
                  </b>
                  Copy Link
                </a>
              </div>
            </i>
            {pageName === "search" && token && (
              <i>
                <img
                  src={readingflag == true ? tags_active : tags}
                  onClick={() =>
                    readingflag == true
                      ? setShowDeleted(true)
                      : addArticleToReadingLists()
                  }
                />
              </i>
            )}
            {pageName === "search" && !token && (
              <i>
                <img
                  src={readingflag == true ? tags_active : tags}
                  onClick={() => navigate("/login")}
                  alt="#"
                />
              </i>
            )}
            {pageName !== "search" && (
              <i>
                <img
                  src={readingflag == true ? tags_active : tags}
                  onClick={() =>
                    readingflag == true
                      ? setShowDeleted(true)
                      : addArticleToReadingLists()
                  }
                />
              </i>
            )}
          </div> */}
          <div className="readmorebtn text-uppercase" style={{borderBottom:"2px solid"}}>
          

          </div>
        </div>
        <div className="recommendedTitle">
        <h2 className="py-2 justify-content-between d-flex" >
          {/* {titleParts[0]}
          {titleParts[1] && <>:<br />{titleParts[1]}</>} */}
          <span onClick={() => handleClick(true)}>{title}</span>
          {journalType === "Society Journal" && (
            <div className="blue-small-btn d-inline-block fs-6 mr-4">
              <Link
            className="text-white"
            // to={`/${handlejournalNameforurl(journalType, typeOfArticle)}s/${handlesubjectName(subjectName)}${journalType === "Society Journal" ? "/" : ""}${journalType === "Society Journal" ? formatUrl(journalName) : ""}`}
            onClick={(e) => handleRedirect(e, `/${handlejournalNameforurl(journalType, typeOfArticle)}s/${handlesubjectName(subjectName)}${journalType === "Society Journal" ? "/" : ""}${journalType === "Society Journal" ? formatUrl(journalName) : ""}`)}
          >{journalData?.abbreviation}</Link>
            </div>
          )}
        </h2>
          {/* {title?.split(" ").length > MAX_WORDS ? (
            <h2
            className="py-2"
              onClick={() => handleClick(true)}
              dangerouslySetInnerHTML={{
                __html:
                  title?.split(" ")?.slice(0, MAX_TITLE_WORDS).join(" ") +
                  "....",
              }}
            ></h2>
          ) : (
            <h2
              className="py-2"
              onClick={() => handleClick(true)}
              dangerouslySetInnerHTML={{
                __html: title?.split(" ")?.slice(0, MAX_TITLE_WORDS).join(" "),
              }}
            ></h2>
          )} */}
          {/* <h3>{userTitle + " " + userName}</h3> */}
          
          <h3 className="lh-base text-capitalize" >
            {userName?.map((autName, index) => {
              return (
                <>{index == 0 ? "" : (<>,&nbsp;</>)}
                  {autName.title + " " + autName.fullName}
                  {autName?.orcidId == "" || autName?.orcidId == undefined || autName?.orcidId == null ? null: (
                    <>
                      <a target="_blank" className="m-1 " href={`https://orcid.org/${autName?.orcidId}`}><img src="https://www.aakashgangaopen.in/static/media/id.d81da1c9dab3ce3fa306.svg" /></a>
                    </>) }
                </>
              );
            })}
          </h3>
          
         
          {pageName == "all-articles" && (
            <>
            <div className="row py-3">
              <div className="col-md-6">
              <div className="blue-small-btn cursor-pointer" onClick={toggleExpand}> {isExpanded ? 'Hide Abstract' : 'Preview Abstract'} </div>
              </div>
              <div className="col-md-6">
              
              </div>
            </div>
            </>
          )}
           {pageName == "access-articles" || pageName == "all-articles" ? (<>
            <div className={isExpanded ? 'py-3' : 'd-none'}>
            <p className={isExpanded ? 'pe-w-text-justify' : 'd-none'} dangerouslySetInnerHTML={{
                __html: contentWithoutSpecialChars,
              }}></p>
              
              
            </div>
          </>) : null}
          {true ? (<>
            <div className="py-0 row">
              <div className="col-md-6">
                <p className="reviewtype">{reviewType.replace(/\bonly\b/g, '')}</p>
              </div>
              <div className="col-md-6">
              <Link
            className="d-block mr-0 ms-auto readmorebtn "
            style={{width:"fit-content"}}
            // to={`/${handlejournalNameforurl(journalType, typeOfArticle)}s/${handlesubjectName(subjectName)}${journalType === "Society Journal" ? "/" : ""}${journalType === "Society Journal" ? formatUrl(journalName) : ""}`}
            onClick={(e) => handleRedirect(e, `/${handlejournalNameforurl(journalType, typeOfArticle)}s/${handlesubjectName(subjectName)}${journalType === "Society Journal" ? "/" : ""}${journalType === "Society Journal" ? formatUrl(journalName) : ""}`)}
          >
            {journalType === "Society Journal" ? journalType : extractFirstWord(journalName)}
          </Link>
              </div>
            </div>
          </>) : null}
          {/* <p className="reviewtype">{reviewType.replace(/\bonly\b/g, '')}</p> */}
        </div>
      </div>
      <Modal
        dialogClassName="modal-dialog-centered modal-sm authorModal"
        show={showDeleted}
        id="congratsMobile"
        tabIndex={-1}
        className="authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <div class="modal-icon"> </div>
          <h2 class="modal-title">Confirmation</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowDeleted(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="allGoodText01">
            <p>
              Are you sure you want to remove this article from the reading
              list?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <span
            class="btn-link-gray bold mr-3"
            onClick={() => handleRemoveReadingList()}
          >
            CONFIRM
          </span>
          <button
            className="button button-primary"
            type="button"
            onClick={(e) => {
              pageName === "search"
                ? setShowDeleted(false)
                : (setShowDeleted(false),
                  window.history.back())
            }}
          >
            No, GO BACK
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AllArticleRelatedarticles;
