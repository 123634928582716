import React from "react";
import Modal from "react-bootstrap/Modal";

const ConflictInterestModal = ({
  showAdditionalDetails,
  setShowAdditionalDetails,
  articleData,
  type,
  setShowArticleDetails
}) => {
  return (
    <Modal
      dialogClassName="modal-dialog-centered modal-md authorModal"
      show={showAdditionalDetails}
      id="congratsMobile"
      tabIndex={-1} 
    >
      <Modal.Header className="modal-header">
       
        <h2 class="modal-title additionalTitle">{
          type === "articledetails" ? (
            <b className="" onClick={() => {
              setShowArticleDetails(true)
              setShowAdditionalDetails(false)
            }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
            <path d="M0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65686 0.928931L0.292893 7.29289ZM20 7L1 7L1 9L20 9L20 7Z" fill="#167092"/>
            </svg></b>
          ) : null
        } ADDITIONAL DETAILS</h2>
        {type === "articledetails" ? null : (
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={(e) => setShowAdditionalDetails(false)}
          />
        )}
      </Modal.Header>

      <Modal.Body className="pt-0">
        <div className="aaforAss text-left pl-4 pr-2">
          {articleData?.statements?.commercial
            ?.commercialOrfinancialConflictOfInterest === "2" ? (
            <div className="mt-3">
              <b className="commanTitle02 mt-2 pb-3">CONFLICT OF INTEREST</b>
              <p className="mt-2">{articleData?.statements?.commercial?.text}</p>
            </div>
          ) : (
            <div className="mt-3">
              <b className="commanTitle02 mt-2 pb-3">CONFLICT OF INTEREST</b>
              <p className="mt-2">
                The authors declare that the research was conducted in the absence of any commercial or financial relationships that could be construed as a potential conflict of interest.
              </p>
            </div>
          )}

          {articleData?.statements?.AakashGangaJournal?.versionOfManuscript ===
            "yes" ? (
            <div className="mt-3">
              <b className="commanTitle02 mt-2 pb-3">MANUSCRIPT VERSION</b>
              <p className="mt-2">{articleData?.statements?.AakashGangaJournal?.text}</p>
            </div>
          ) : (
            <div className="mt-3">
              <b className="commanTitle02 mt-2 pb-3">MANUSCRIPT VERSION</b>
              <p className="mt-2">
                No earlier version of this manuscript has been submitted to another Journal.
              </p>
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="modal-footer">
        <button
          class="button button-primary"
          onClick={() => setShowAdditionalDetails(false)}
        >
          Okay
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConflictInterestModal;
