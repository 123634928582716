import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

const MobileModalAlert = ({ mobileAlert, setMobileAlert}) => {
  return (
    <div>
      <Modal
        show={mobileAlert}
        size="md"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered authorModal"
      >
      <Modal.Header className="modal-header pb-0">
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={(e) => setMobileAlert(false)}
        />
        <Modal.Title className="modal-title pl-0">
              <h2 className="fontsizeshastra ml-4">Submission Guidelines</h2>
            </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0 pt-0">
        <div className="grantDetails pl-2 pr-2 mb-3">
          <div className="writeYourContent pb-0 pt-0">
            <p className="mb-0 text-left">
              <p className="yellowTextWelcome  blackColorTestRegst"><p className="d-block pb-3 mb-0">For submitting your article, Please use a laptop or desktop as Aakashganga is not yet optimised for mobile use.</p></p>
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    </div>
  );
};

export default MobileModalAlert;
