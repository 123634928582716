import React from "react";
import Modal from "react-bootstrap/Modal";
import doneSvg from "../../../assets/img/icons/done02.svg";

const AcceptModal = ({
  showAccept,
  setShowAccept,
  title,
  body,
  btnText,
}) => {
  return (
    <Modal
      dialogClassName="modal-dialog-centered modal-md authorModal md-x"
      show={showAccept}
      id="congratsMobile"
      tabIndex={-1}
      className="md-x"
    >
      <Modal.Header className="modal-header">
        <div class="modal-icon">
          {" "}
          <img src={doneSvg} alt="#" />
        </div>
        <h2 class="modal-title">{title}</h2>
        <button
          type="button"
          class="btn-close"
          ariaLabel="Close"
          onClick={() => setShowAccept(false)}
        ></button>
      </Modal.Header>

      <Modal.Body className="pt-0">
        <div className="aaforAss"
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        />
      </Modal.Body>

      <Modal.Footer className="modal-footer">
        <button
          class="button button-primary"
          onClick={() => setShowAccept(false)}
        >
          {btnText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptModal;
