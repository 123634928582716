import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import moment from "moment";
import notyet from "../../assets/img/icons/notarticle.svg";
import {
  getUserNotification,
  updateNotification,
} from "../../store/apiCalls/dashboard";

const Notifications = () => {
  const { id } = jwt_decode(localStorage.usertoken);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationData, setNotificationData] = useState([]);

  const getNotifications = async () => {
    const limit = 10;

    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      page: 1,
      limit,
      userId: id,
    };
    const response = await getUserNotification(headers, params);
    if (response?.status === 200) {
      handleUpdateNotification(true);
      setNotificationData(response?.data);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleUpdateNotification = async (read) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      userId: id,
      isRead: read,
    };
    await updateNotification(headers, params);
  };
  return (
    <div className="navNotify">
      <a>
        <b
          className={showNotification ? "bellBox active" : "bellBox "}
          onClick={() => {
            !showNotification ? getNotifications() : "";
            setShowNotification(!showNotification);
          }}
        />
        {notificationData?.read ? <span /> : ""}
      </a>

      {showNotification && (
        <div className="notifigationBox">
          <div className="notificationHead">
            <h2>Notifications</h2>
            <span
              className={
                !notificationData?.read
                  ? "button-link-gray mr-0"
                  : "button-link"
              }
              onClick={() => {
                handleUpdateNotification(false);
                getNotifications();
              }}
            >
              Mark All as Unread
            </span>
            <span
              className={
                notificationData?.read ? "button-link-gray mr-0" : "button-link"
              }
              onClick={() => {
                handleUpdateNotification(true);
                getNotifications();
              }}
            >
              Mark All as Read
            </span>
          </div>
          <div className="notiWrapBox">
            <ul>
              {notificationData?.data && notificationData?.data?.length > 0 ? (
                notificationData?.data.map((notif) => (
                  <li
                    className={
                      notificationData?.read
                        ? "notiListWrap new_noti"
                        : "notiListWrap"
                    }
                  >
                    <div className="notiListArea">
                      <span>{notif?.notificationType}</span>
                    </div>
                    <span className="notiTime text-right">
                      {moment(new Date(notif?.createdAt))
                        .fromNow()
                        .split(" ")?.[0] !== "a"
                        ? moment(new Date(notif?.createdAt))
                            .fromNow()
                            .split(" ")?.[0]
                        : "1"}
                      {moment(new Date(notif?.createdAt))
                        .fromNow()
                        .split(" ")?.[1] === "days"
                        ? "d"
                        : moment(new Date(notif?.createdAt))
                            .fromNow()
                            .split(" ")?.[1] === "day"
                        ? "d"
                        : moment(new Date(notif?.createdAt))
                            .fromNow()
                            .split(" ")?.[1] === "minutes"
                        ? "m"
                        : moment(new Date(notif?.createdAt))
                            .fromNow()
                            .split(" ")?.[1] === "minute"
                        ? "m"
                        : moment(new Date(notif?.createdAt))
                            .fromNow()
                            .split(" ")?.[1] === "hours"
                        ? "h"
                        : moment(new Date(notif?.createdAt))
                            .fromNow()
                            .split(" ")?.[1] === "hour"
                        ? "h"
                        : ""}
                    </span>
                  </li>
                ))
              ) : (
                <div className="tabContentWrap">
                  <div className="notArticle">
                    <figure>
                      <img src={notyet} alt="No Article" />
                    </figure>
                    <h2>Currently, there are no notifications to show</h2>
                  </div>
                </div>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
