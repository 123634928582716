import React, { useState } from "react";
/*eslint-disable*/
import {isMobile} from 'react-device-detect';
import { useNavigate, Link } from "react-router-dom";
import SubmitArticleModal from "../Modal/SubmitArticleModal";
import MobileModalAlert from "../Modal/MobileModalAlert";

const MainBanner = () => {
  const [uploadArticle, setUploadArticle] = useState(false);
  const [mobileAlert, setMobileAlert] = useState(false);
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const token = localStorage.getItem("usertoken");
  return (
    <div className="ag_main_wrap ag_banner_wrap">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12 col-12 hero">

            <div className="hameMianBanner">
              <div className="hameMianContent">
                <span>Welcome to the</span>
                <h1>Future <i>of</i> Research</h1>
                {/* <p className="content">The new <b>Open Access</b> Journal platform for India and the World</p> */}
                <p className="content"> India’s First Discipline-Specific Mega Journals and <b>Open Access</b> Journal 
                Hosting Platform</p>
              
                <div className="ag_content_text ">
                  {!token && (
                    <Link  to="javascript:;"
                      onClick={() =>isMobile?setMobileAlert(true):navigate("/login")}
                      className="ag_btn_red"
                    >
                      Publish Your Article
                    </Link>
                  )} 
                   {token && (
                    <Link
                    onClick={(e) => {
                      e.preventDefault();
                      isMobile ? setMobileAlert(true):setUploadArticle(true);
                    }}
                      className="ag_btn_red"
                    >
                      Publish Your Article
                    </Link>
                  )}
                  <p className="tagp">
                    Inclusive, Affordable And <i>Open For All</i>
                  </p>
                </div>
              </div>
              <div className="videocontainer">
                {isMobile ? (
                  <img
                  className="mobile-image tagimg"
                  src="assets/images/aakashganga-mob-resposive-banner.gif"
                  alt="Banner-Main"
                />
                ) :
                (
                  <img
                  className="desktop-image tagimg"
                  src="assets/images/aakashgangagifnew2.gif"
                  alt="Banner-Main"
                />
                )
                }                
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubmitArticleModal uploadArticle={uploadArticle} setUploadArticle={setUploadArticle} />
      <MobileModalAlert mobileAlert={mobileAlert} setMobileAlert={setMobileAlert} />
    </div>
  );
};

export default MainBanner;
