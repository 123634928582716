/*eslint-disable*/
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
const knowledgeData = [
  {
    title: "Presentations",
    imaUrl: "assets/images/blog-1.png",
    description:
      "Short on time but high on curiosity? Our crisp, <a href='https://vikramshilaedu.in/presentations' target='_blank' class='unlockLink'>expert-led presentations</a> will get you up to speed in no time.",
  },
  {
    title: "Videos",
    imaUrl: "assets/images/blog-2.png",
    description:
      "Enhance your understanding with <a href='https://vikramshilaedu.in/browse' class='unlockLink' target='_blank'>engaging video content.</a> It explains Aakashganga lucidly.",
  },
  {
    title: "Blogs",
    imaUrl: "assets/images/blog-3.png",
    description:
      "Looking for a good read? <a href='https://vikramshilaedu.in/GyanKosh/' class='unlockLink' target='_blank'>Our blogs are a treasure trove of insights,</a> tips, and the latest buzz in scholarly publishing.",
  },
  {
    title: "FAQs",
    imaUrl: "assets/images/blog-4.png",
    description:
      "Got questions? We've got answers. <a href='/faq' target= '_blank' class='unlockLink'>The FAQs section</a> is your go-to guide for all things Aakashganga.",
  },
];

const UnlockKnowledge = () => {
  const navigate = useNavigate();
  return knowledgeData.map((item) => (
    <div className="col-xl-6 col-lg-6 col-sm-6">
      <div className="ag_resourcehub_box aos-init" data-aos="zoom-in-up">
      <img className="tagimg" src={item.imaUrl} alt={item.title} />
        <h3 className="tagh3">{item.title}</h3>
        <p className="tagp">{parse(`${item.description}`)}</p>
      </div>
    </div>
  ));
};
export default UnlockKnowledge;
