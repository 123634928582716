import React from "react";
import Modal from "react-bootstrap/Modal";

const OpenAccessModal = ({ show, setShow }) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(true)}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter "
      centered
      className="markitPopupBox"
    >
      <Modal.Header
        className="justify-content-center closeButtonStyle"
        onClick={() => setShow(false)}
        closeButton
      >
        <Modal.Title className="modal-title pl-0">
          <h2 className="fontsizemod ml-2">Gold Open Access (OA):</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 pb-2">
        <div className="px-2 gold_open_access">
          <p>
            Gold Open Access refers to the unrestricted online availability of
            scholarly articles, allowing anyone with an internet connection to
            access and read the final published version for free.
          </p>
          <p>
            The final published version (Version of Record) of an article is
            permanently and freely available online for universal access. It
            usually involves an Article Publishing Charge (APC).
          </p>
          <div className="benefitsBox">
            <strong>Benefits of Gold OA: </strong>
          </div>
          <p className="f-14">
            <ul className="">
              <li className="section">
                <strong>Sharing Research:</strong> Immediate dissemination upon
                publication.
              </li>
              <li className="efforts">
                <strong>Copyright Retention:</strong> Authors maintain ownership
                of their work after publication.
              </li>
              <li className="efforts">
                <strong>Flexible Licensing:</strong> Allows publication under a
                license with minimal or no usage restrictions, ensuring proper
                credit while permitting reuse.
              </li>
            </ul>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="pt-3 pb-3 contactModalFooter"></Modal.Footer>
    </Modal>
  );
};

export default OpenAccessModal;
