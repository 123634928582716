import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { acceptRejectArticle } from "../../../store/apiCalls/dashboard";
import { toast } from "react-toastify";
import active_info from "../../../svg/active-info.svg";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const RejectForPR = ({ showReject, setShowReject, articleId, modalTitle, articleType }) => {
  let navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonInput, setReasonInput] = useState("");
  const [additionalComment, setAdditionalComment] = useState("");

  const handleClose = () => setShowReject(false);
  const handleChangeReason = (e) => {
    setReason(e.target.value);
  };

  const onChangeOtherReason = (e) => {
    setReasonInput(e.target.value)
  }

  const onChangeReason = (e) => {
    setAdditionalComment(e.target.value);
  };

  const handleAcceptRejectArticle = async (status) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: articleId,
      peStatus: status,
      reason: reason === "Other" ? reasonInput : reason,
      peRejectedReasonAdditionalComment: additionalComment
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      setShowReject(false);
      return navigate("/dashboard/publishing-editor");
    } else {
      return toast.error("Please try again!!");
    }
  };

  useEffect(() => {
    if (reason === "Other") {
      setDisabled(!reasonInput)
    } else {
      setDisabled(!reason)
    }
  }, [reason, reasonInput])

  return (
    <Modal
      keyboard={false}
      backdrop="static"
      show={showReject}
      onHide={handleClose}
      className="md-x authorModal"
    >
      <Modal.Header className="modal-header">
        <h5 className="modal-title">
          {modalTitle === "REJECT_PUBLISH"
            ? "REJECT TO PUBLISH"
            : "REJECT FOR PEER REVIEW"}
        </h5>
        <button
          type="button"
          className="btn-close"
          ariaLabel="Close"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body className="pb-0">
        <div className="rejectModal">
          <div className="row">
            <div className="col-md-12">
              <p>Please specify your reason</p>
              
            </div>
           {/* { */}
            {/* // modalTitle === "REJECT_PUBLISH" ? ( */}
              {/* <> */}
            
              {articleType==="only post-publication open review"?(<>
                {/* <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Does not fit and aim and scope of the Journal"}
                  checked={reason === "Does not fit and aim and scope of the Journal"}
                />
                <b></b>
                <span>Does not fit and aim and scope of the Journal</span>
              </label>
            </div> */}
              </>):(<>
              <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Peer review report unfavorable"}
                  checked={reason === "Peer review report unfavorable"}
                />
                <b></b>
                <span>Peer review report unfavorable</span>
              </label>
            </div>
            </>)}
            {/* </>
            ) : null */}
          {/* } */}
             

<div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Poor Fit with the Journal's Scope"}
                  checked={reason === "Poor Fit with the Journal's Scope"}
                />
                <b></b>
                <span>Poor Fit with the Journal's Scope</span>
              </label>
            </div>
            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Failure to Follow Submission Guidelines"}
                  checked={reason === "Failure to Follow Submission Guidelines"}
                />
                <b></b>
                <span>Failure to Follow Submission Guidelines</span>
              </label>
            </div>
            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Lack of Novelty or Originality"}
                  checked={reason === "Lack of Novelty or Originality"}
                />
                <b></b>
                <span>Lack of Novelty or Originality</span>
              </label>
            </div>

            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Methodological or Quality Issues"}
                  checked={reason === "Methodological or Quality Issues"}
                />
                <b></b>
                <span>Methodological or Quality Issues</span>
              </label>
            </div>
            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Unsubstantiated Claims or Inconclusive Finding"}
                  checked={reason === "Unsubstantiated Claims or Inconclusive Finding"}
                />
                <b></b>
                <span>Unsubstantiated Claims or Inconclusive Finding</span>
              </label>
            </div>
            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Ethical Concerns"}
                  checked={reason === "Ethical Concerns"}
                />
                <b></b>
                <span>Ethical Concerns</span>
              </label>
            </div>
            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Inadequate Literature Review"}
                  checked={reason === "Inadequate Literature Review"}
                />
                <b></b>
                <span>Inadequate Literature Review</span>
              </label>
            </div>
            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Lack of Proper Citations"}
                  checked={reason === "Lack of Proper Citations"}
                />
                <b></b>
                <span>Lack of Proper Citations</span>
              </label>
            </div>
            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Poor Relevance to the Field or Current Issues"}
                  checked={reason === "Poor Relevance to the Field or Current Issues"}
                />
                <b></b>
                <span>Poor Relevance to the Field or Current Issues</span>
              </label>
            </div>

            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Other"}
                  checked={reason === "Other"}
                />
                <b></b>
                <span>Any other reason</span>
              </label>
            </div>
            {reason === "Other" && (
              <div className="col-md-12">
                <textarea
                  className="inputField"
                  placeholder="Write here..."
                  onChange={(e) => onChangeOtherReason(e)}
                  value={reasonInput}
                  maxLength={100}
                ></textarea>
              </div>
            )}
            <div className="rejectPr">
              <span><b>Publishing Editor Comments</b></span>
              <textarea
                className="inputField"
                placeholder="Please add your comment here..."
                onChange={(e) => onChangeReason(e)}
                value={additionalComment}
                maxLength={100}
              ></textarea>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="">
        <button
          class="button button-primary"
          type="submit"
          onClick={() => {
            // handleAcceptRejectArticle("rejectedApproval");
            handleAcceptRejectArticle("rejected");
          }}
          disabled={disabled}
        >
          SUBMIT
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectForPR;
