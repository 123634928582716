import ApiEndPoint from "../../../ApiEndpoints";
import Api from "../../../ApiConfig";

const baseURL = ApiEndPoint.AakashGanga;

export const saveArticleToDraft = (params) => {
    const { headers, payload } = params
    const finalUrl = baseURL + ApiEndPoint.SaveArticle
    return Api.postData(finalUrl, headers, payload);
};
export const getArticleById = (data) => {
    const { headers, params } = data
    const finalUrl = baseURL + ApiEndPoint.GetArticleById
    return Api.getData(finalUrl, headers, params);
}
export const getJournal = (data) => {
    const { headers, params } = data
    const finalUrl = baseURL + ApiEndPoint.SURCHGENRAL
    return Api.getData(finalUrl, headers, params);
}
export const getJournalDetails = (data) => {
    const { headers, params } = data; 
    const finalUrl = baseURL + ApiEndPoint.GET_JOURNAL_DETAIL
    return Api.getData(finalUrl, headers, params);
}

export const updateArticle = (data) => {
    const { headers, params, payload } = data
    const finalUrl = baseURL + ApiEndPoint.UpdateArticle
    return Api.postData(finalUrl, headers, payload, params);
}

export const downloadSimiliratyReport = (queryParams) => {
    const { headers, params } = queryParams; 
    const finalUrl = baseURL + ApiEndPoint.DOWNLOAD_SIMILARITY_REPORT
    return Api.getData(finalUrl, headers, params);
}

export const assigneArticleToReview = (params) => {
    const { headers, payload } = params
    const finalUrl = baseURL + ApiEndPoint.FIND_REVIEWER
    return Api.postData(finalUrl, headers, payload);
};

export const getOrcidID = (data) => {
    const { headers, params, payload } = data
    const finalUrl = 'https://orcid.org/oauth/token';
    return Api.postData(finalUrl, headers, payload, params);
}

export const iniitatePayment= (params) => {
    const { headers, payload } = params
    const finalUrl = baseURL + ApiEndPoint.INITIATE_PAYMENT
    return Api.postData(finalUrl, headers, payload);
};


export const updatePaymentStatus= (params) => {
    const { headers, payload } = params
    const finalUrl = baseURL + ApiEndPoint.UpdatePaymentStatus
    return Api.postData(finalUrl, headers, payload);
};


export const getArticleWithoutToken = (data) => {
    const { params } = data
    const finalUrl = baseURL + ApiEndPoint.GetArticleWithoutToken
    return Api.getData(finalUrl,{}, params);
}

export const downloadSendReceipt= (params) => {
    const { headers, payload } = params
    const finalUrl = baseURL + ApiEndPoint.DOWNLOAD_OR_SEND_RECEIPT
    return Api.postData(finalUrl, headers, payload);
};


export const getALLOfferForPayment = (data) => {
    const { headers, params } = data
    const finalUrl = baseURL + ApiEndPoint.GET_ALL_OFFER
    return Api.getData(finalUrl, headers, params);
}

export const getOfferById = (data) => {
    const { headers, params } = data
    const finalUrl = baseURL + ApiEndPoint.GET_OFFER_BY_ID
    return Api.getData(finalUrl, headers, params);
}

export const getMudraByUserId = (data) => {
    const { headers, params } = data
    const finalUrl = baseURL + ApiEndPoint.GET_USER_MUDRAS
    return Api.getData(finalUrl, headers, params);
}

export const getAPCValues = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_APC_VALUE
    return Api.getData(finalUrl, headers, params);
};

export const getProfanityList = (data) => {
    const { headers, params } = data
    const finalUrl = baseURL + ApiEndPoint.GET_PROFANITY_WORDS
    return Api.getData(finalUrl, headers, params);
}

export const saveAuthorFeedback= (params) => {
    const { headers, payload } = params
    const finalUrl = baseURL + ApiEndPoint.SUBMIT_AUTHOR_FEEDBACK
    return Api.postData(finalUrl, headers, payload);
};

export const submitOpenReviewForArticle = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.SUBMIT_OPEN_REVIEW;
    return Api.postData(finalUrl, headers, params)
}

export const getOpenReviewForArticle = (queryParams) => {
    const { params } = queryParams; 
    const finalUrl = baseURL + ApiEndPoint.GET_OPEN_REVIEW_FOR_ARTICLE;
    return Api.getData(finalUrl, {}, params)
}

export const submitFlagReview = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.SUBMIT_FLAG_REVIEW;
    return Api.postData(finalUrl, headers, params)
}

export const getJournalSubjects = (queryParams) => {
    const { params } = queryParams; 
    const finalUrl = baseURL + ApiEndPoint.GET_JOURNAL_SUBJECT_SPECIALIZATION;
    return Api.getData(finalUrl, {}, params)
}

export const createNewArticleVersion = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.CREATE_NEW_ARTICLE_VERSION;
    return Api.getData(finalUrl, headers, params)
}

export const articlePdfDownload= (params) => {
    const { headers, payload } = params
    const finalUrl = baseURL + ApiEndPoint.GET_ARTICLE_PDF_DOWNLOAD
    return Api.postData(finalUrl, headers, payload);
};
