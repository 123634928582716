import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { aboutAkashGangaData } from "../components/sellPageForeign/data";
import { useDispatch } from "react-redux";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import PeraTextData from "../components/global/PeraTextData";
import { BASE_URL } from "../config";
/*eslint-disable*/
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import JoinPublishingCommunityForm from "../components/sellPageForeign/JoinPublishingCommunityForm";
import { Helmet } from 'react-helmet';
import { toast } from "react-toastify";

function conferencePapers() {
  const dispatch = useDispatch();
  const [initialValues, setinitialValues] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const inputhandle = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    // Make a copy of the current state
    const newInitialValues = { ...initialValues };

    // Update the appropriate field
    if (name === "name") {
      newInitialValues.fullName = value;
    } else if (name === "email") {
      newInitialValues.email = value;
    } else if (name === "phone") {
      newInitialValues.phoneNumber = value;
    } else if (name === "nameuni") {
      newInitialValues.university = value;
    } else if (name === "desig") {
      newInitialValues.designation = value;
    } else if (name === "message") {
      newInitialValues.message = value;
    }

    

    // Update the state
    setinitialValues(newInitialValues);
  };
  
  const contactUsData = async () => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Basic phone number validation (10 digits)
    const phoneRegex = /^\d{10}$/;

    // Define the fields you want to validate for being non-blank
    const requiredFields = ["fullName", "email", "phoneNumber", "university", "message"];

    // Validate that no fields are blank
    for (const field of requiredFields) {
      if (!initialValues[field] || !initialValues[field].trim()) {
        toast.error(`Please enter a valid ${field}.`);
        return;
      }
    }

    // Validate email
    if (!emailRegex.test(initialValues.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    // Validate phone number
    if (!phoneRegex.test(initialValues.phoneNumber)) {
      toast.error("Please enter a valid phone number.");
      return;
    }

    dispatch({ type: "SET_LOADER", payload: true });

    try {
      const response = await axios.post(
        `${BASE_URL}AakashGanga/contactUsAkashganga`,
        initialValues
      );
      dispatch({ type: "SET_LOADER", payload: false });
     
      if (response?.status == 200) {
        setShow(true);
      } else {
        toast.error("Please try again!!");
      }
    } catch (error) {
      dispatch({ type: "SET_LOADER", payload: false });
      toast.error("An error occurred. Please try again!!");
    }
  };
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const handleContent = (type) => {
    const element = document.getElementById(`viewScroll${type}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClick = () => {
    window.scrollTo({
      top: 5750,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Helmet>
         <title>Publish Conference Papers with Aakashganga Open</title>
         
      </Helmet>
      <div className="">
        <AakashgangaHeader />
        <h1 className="seo-related-tag">
          Empowering Universities, Institutes, and Societies
        </h1>
        <main className="main  ">
          <div className="">
            <div className="container">
              <div className="sell-banner-wrp h-auto">
                <div className="sell-banner h-auto">
                  <img
                    className="tagimg"
                    src="assets/images/conference-papers-main-banner.png"
                    alt="Universities, Societies"
                  />
                </div>
                <div className="sell-banner-text mw-830" >
                  <h3 className="title tagh3">
                    Publ<span>i</span>sh Your Conference Papers

                  </h3>
                  <p className="tagp">
                    Share, Validate, and Preserve Scholarly<br />
                    Research Presented at Academic<br />
                    Conferences.
                  </p>
                  <div className="ag_special_offer_btn customSellpageflexdirection">
                    <button className="ag_btn_red taga" onClick={handleClick}>
                      Connect Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="container container-full Transforming-btm-col">
              <div className="ag_heading_center_wrap mt-5">
                <h2 className="tagh2">
                  Most Conference papers do not <br />turn into full research papers
                </h2>
              </div>

            </div>
          </div>

          <div className="container">
            <div className="row about-sec align-items-center">
              <div className="col-lg-12">
                <p className="pera-text automated tagp text-justify"><strong>Problem:</strong> Conferences are a great place to showcase one's research. However, not every research paper gets published in prestigious academic journals, which can limit a researcher's reach and recognition.</p>
                <p className="pera-text automated tagp text-justify">Conferences across the country attract hundreds of WIP (work-in-progress) research papers. These are accepted for presentation and reviewed in a semi-public setting. Most of these papers do not turn into full research papers and are not submitted formally to any publisher (or journal). This does not ensure a digital footprint for the article losing its chance for higher visibility, citability and credit for research.</p>
                <p className="pera-text automated tagp text-justify">Further, many ideas presented at conferences are plagiarized but because of the lack of a digital footprint, the originator of the conference paper has no recourse to justice.</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row about-sec ">

              <div className="col-lg-6 text-center">

                <img
                  src="assets/images/conference-image-2.png"
                  className="sell_logo tagimg w-100 mw-100 ds-object-fit-cover"
                  alt="Aakashganga Logo"
                />
              </div>
              <div className="col-lg-6">
                <p className="pera-text automated tagp text-justify"><strong>We bring a solution:</strong> The Aakashganga Open platform is equipped to receive conference papers as a new category of published papers with multiple benefits providing extended reach and influence</p>
                <p className="pera-text automated tagp text-justify"><strong>Publish your conference papers:</strong> on the Aakashganga Open platform, showcasing original research that unveils new discoveries and insights across the seven Mega Journals on the platform. We understand that conferences provide a platform for researchers, scholars, and practitioners to share their latest research findings, theories, methodologies, and innovations with a wider audience. This dissemination of knowledge fosters collaboration and facilitates the advancement of the field.</p>
              </div>
            </div>
          </div>
          <section class="section-two pt-1 ">
            <div class="container">

              <div className="ag_heading_center_wrap mt-5">
                <h2 className="tagh2">
                  Publish Your Conference Paper With Us
                </h2>
              </div>
              <div className="row mt-5">
                <div className="col-md-6 pe-4">
                  <p className="pera-text automated tagp text-center fs-4"><strong className="fw-bold">1. Publish In Our Mega Journals</strong></p>
                  <p className="pera-text automated tagp text-justify">Publish in our seven mega journals with post-publishing open review. This allows comprehensive peer review of your research article, ensuring highest quality of scholarly content while maximizing the visibility of your research.</p>
                </div>
                <div class="col-md-6 ps-4">
                  <p className="pera-text automated tagp text-center fs-4"><strong className="fw-bold">2. Host Your Conference Page</strong></p>
                  <p className="pera-text automated tagp text-justify">Host your conference online with your own dedicated Landing Page, tailored to your needs. The conference homepage will serve as a centralized hub for easy access, enabling efficient dissemination to your audience.</p>
                </div>
              </div>
            </div>
          </section>

          <section class="section-three pt-1">
            <div class="container">
              <div className="ag_heading_center_wrap mt-4 pt-3">
                <h2 className="tagh2">
                  We Offer Two Partnership Options
                </h2>
              </div>
              <div class="row mt-5">
                <div class="col-md-12">
                  <div class="section-box text-center py-5">
                    <h1>Together, let's pave the way for a brighter, more <br /> accessible future for research dissemination.</h1>
                  </div>
                </div>
              </div>
              <div class="row justify-content-around mt-5">
                <div class="col-md-6 pe-4">
                  <div class="card p-4 shadow border-0 h-100">
                    <img src="assets/images/partner-directly.png" alt="" />
                    <div class="card-body">
                      <div className="ag_heading_center_wrap ">
                        <h3 className="tagh2 theme-blue-color fw-semibold">Partner directly With us</h3>
                      </div>
                      <p className="pera-text automated tagp text-justify">Conference organizers can formalize a commitment with us to pay for the publishing of the conference papers. We offer a fast publishing turnaround time of 30 days to publish your conference paper.</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 ps-4">
                  <div class="card p-4 shadow border-0">
                    <img src="assets/images/discount-code.png" alt="" />
                    <div class="card-body">
                      <div className="ag_heading_center_wrap ">
                        <h3 className="tagh2 theme-blue-color fw-semibold">We provide a discount code</h3>
                      </div>
                      <p className="pera-text automated tagp text-justify">Organizers get a discount code to publicize about Aakashganga in their conference promotional material. Anyone from the conference can use the code to publish their paper. The coupon would be valid for 30 days post the completion of the conference. </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row py-3 mt-5">
                <div class="col-md-12 text-center">
                  <h2><strong className="fs-1">Ready to Publish?</strong></h2>
                  <button onClick={handleClick} class="ag_btn_red taga d-block mx-auto fs-3">Get In Touch Now</button>
                </div>
              </div>
            </div>
          </section>

          <section class="cost-to-publish mt-5">
            <div class="container">
              <div className="ag_heading_center_wrap mt-5">
                <h2 className="tagh2">
                  How much is the cost to publish?
                </h2>
              </div>
              <div className="row justify-content-center mt-5">
                <div className="col-md-5 pe-4">
                  <div className="card shadow border-0 overflow-hidden rounded-5">
                    <div className="card-head text-center rounded-top p-4">
                      <h4 className="mb-0 fs-2">INDIVIDUAL PAPERS</h4>
                    </div>
                    <div className="card-body p-4">
                      <div className="div-one d-flex justify-content-between">
                        <p className="card-text blue  fs-5 fw-semibold">Article Processing Cost</p>
                        <p className="card-text blue  fs-5 fw-semibold"><strike>INR 4999</strike></p>
                      </div>
                      <div class="div-two d-flex justify-content-between py-2">
                        <p className="fw-semibold  fs-5">Introductory Discount</p>
                        <p className="fw-semibold fs-5">INR 2000</p>
                      </div>
                      <div className="div-three d-flex justify-content-between fs-3">
                        <p className="mb-0 card-text"><strong>Current Offer</strong></p>
                        <p className="mb-0 card-text"><strong>INR 2999</strong></p>
                      </div>
                      <div className="div-four">
                        <hr />
                      </div>
                      <p className="text-center"><strong className="fs-4 mb-2 d-block "> Important Information</strong></p>
                      <small className="pera-text automated tagp d-block fs-6 lh-base fst-italic">*Aakashganga Open has no hidden charges. There are no maintenance charges. We provide our own server space to host articles, and their promotion is our responsibility too.</small>
                      <small className="pera-text automated tagp d-block fs-6 lh-base fst-italic">*Our system integrates well with your organization's website as well as LMS systems.</small>
                      <small className="pera-text automated tagp d-block fs-6 lh-base fst-italic">*All paper uploads are done directly on the platfrom. Every copyright License agreement is signed online.</small>
                      <small className="pera-text automated tagp d-block fs-6 lh-base fst-italic">*We have a dedicated helpdesk to help resolve your queries.</small>
                    </div>
                    <div class="card-footer text-end bg-white border-0">
                      <a href="#" class="text-decoration-none fs-6 fst-italic">*Terms and conditions apply</a>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 ps-4">
                  <div class="card shadow border-0 overflow-hidden rounded-5 h-100">
                    <div class="card-head text-center rounded-top p-4">
                      <h4 class="mb-0 fs-2">LANDING PAGE</h4>
                    </div>
                    <div class="card-body p-4">
                      <div class="div-one">
                        <p className="card-text blue text-center pb-4 fs-30px fw-semibold"> Hosting Charges</p>
                      </div>
                      <div class="div-two mb-2">

                      </div>
                      <div class="div-three d-flex justify-content-between fs-3">
                        <p class="mb-0 card-text-one"><strong>Current Offer</strong></p>
                        <p class="mb-0 card-text-one"><strong> INR 50,000</strong></p>
                      </div>
                      <div class="div-four">
                        <hr />
                      </div>
                      <p class="text-center"><strong className="fs-4 mb-2 d-block">Important Information</strong></p>
                      <small className="pera-text automated tagp d-block fs-6 lh-base fst-italic">*Aakashganga Open has no hidden charges. There are no maintenance charges. We provide our own server space to host articles, and their promotion is our responsibility too.</small>
                      <small className="pera-text automated tagp d-block fs-6 lh-base fst-italic">*Our system integrates well with your organization's website as well as LMS systems.</small>
                      <small className="pera-text automated tagp d-block fs-6 lh-base fst-italic">*All paper uploads are done directly on the platfrom. Every copyright License agreement is signed online.</small>
                      <small className="pera-text automated tagp d-block fs-6 lh-base fst-italic">*We have a dedicated helpdesk to help resolve your queries.</small>
                    </div>
                    <div class="card-footer text-end bg-white border-0 ">
                      <a href="#" class="text-decoration-none fs-6 fst-italic">*Terms and conditions apply</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section-five pt-3 mt-4">
            <div class="container">
              <div className="ag_heading_center_wrap mw-830 mt-3 w-100">
                <h2 className="tagh2">
                  Publish Your Conference Papers to Extend Your Reach And Influence
                </h2>
              </div>
              <div class="row  mt-5">
                <div class="col-md-6 pe-4">
                  <p className="pera-text automated tagp">At Aakashganga Open, we prioritize quality and convenience for our partners. As part of our service package, we offer:</p>
                  <p className="pera-text automated tagp"><strong>Fully Automated Submission Journey and Paper Publishing Workflow:</strong> Being 100% automated eliminates the need for manual intervention leading to fast turn-around time of publishing within 30 days </p>
                  <p className="pera-text automated tagp"><strong>A CrossRef generated and Indexed Unique DOI:</strong> Each published paper receives a unique Digital Object Identifier (DOI), ensuring traceability and citability. This creates a permanent digital footprint of the article thus ensuring copyright.</p>
                  <p className="pera-text automated tagp"><strong>Google scholar Indexing:</strong> Increase the visibility, impact, and accessibility of your research within the scholarly community and beyond.</p>
                  <p className="pera-text automated tagp"><strong>Profanity, Spelling, and Grammar Check:</strong> Ensure the correctness and professionalism of your content through thorough proofreading.</p>
                  <p className="pera-text automated tagp"><strong>Similarity Index Check:</strong> Safeguard against plagiarism with our comprehensive similarity index checks.</p>
                  <p className="pera-text automated tagp"><strong>Post Publishing Open Peer Review:</strong> Publish your paper immediately and promote transparency by allowing reviewers' comments and identities to be visible to both authors and readers, improving the credibility of scholarly research.</p>
                  <p className="pera-text automated tagp"><strong>Own Your Journey:</strong> Besides this, the entire paper upload journey is in author’s direct control without any manual intervention with their own dashboards. </p>
                </div>
                <div class="col-md-6 ps-4">
                  <img src="assets/images/reach-and-influence.png" style={{ aspectRatio: "1 / 1.44" }} class="w-100 shadow" />
                </div>
              </div>
            </div>
          </section>

          <section class="section-six pt-3 mt-3">
            <div class="container">
              <div className="ag_heading_center_wrap mw-830 mt-2">
                <h2 className="tagh2">
                  Why Publish Your Conference Papers <br /> with Aakashganga Open
                </h2>
              </div>
              <div class="row mt-5">
                <div class="col-md-12">
                  <p className="pera-text automated tagp"><strong>Conferences often publish their research papers with us for several reasons:</strong></p>
                  <p className="pera-text automated tagp"><strong>Dissemination of Knowledge:</strong> Publishing conference papers on Aakashganga Open allows for the widespread dissemination of your latest research findings. This ensures that your research reaches a broader audience beyond the attendees of the conference, including researchers, practitioners, policymakers, and the general public.</p>
                  <p className="pera-text automated tagp"><strong>Citation and Recognition:</strong> Publishing your conference papers will provide you with a citable publication that can contribute to your academic record. Aakashganga Open helps you enhance your visibility, reputation, and academic credentials.</p>
                  <p className="pera-text automated tagp"><strong>Archiving Research:</strong> By publishing with us, your conference will contribute to the archival of research findings and scholarly discourse. This will help preserve valuable research for future reference, replication, and further investigation.</p>
                  <p className="pera-text automated tagp"><strong>Promotion of Conference Proceedings:</strong> Publishing conference papers will help promote the conference itself by showcasing the quality and relevance of presented research. This may attract more participants and increase your conference's impact and prestige in the academic community.</p>
                  <p className="pera-text automated tagp"><strong>Contribution to Scholarly Literature:</strong> Conference papers contribute to the broader scholarly literature by adding new knowledge, insights, methodologies, and perspectives to the field. This enriches the academic discourse and facilitates the advancement of knowledge.</p>
                  <p className="pera-text automated tagp"><strong>Compliance with Funding and Accreditation Requirements:</strong> In some cases, funding agencies, academic institutions, or accreditation bodies may require researchers to publish their conference papers as part of the reporting or evaluation process. Publishing conference papers helps researchers fulfill these requirements and demonstrate the impact of their research activities.</p>
                </div>
              </div>
            </div>
          </section>

          <section class="section-seven mt-5">
            <div class="container">
              <div className="ag_heading_center_wrap mw-830 mt-5">
                <h2 className="tagh2">
                  Start Publishing Your Conference Papers
                </h2>
              </div>
              <h5 class="mt-4 text-center">Fill the form below to begin a conversation!</h5>

              <div class="row mt-5 justify-content-center">
                <div class="col-md-10">
                  <div class="box p-5 rounded-4">
                    <form >
                      <div class="mb-3 mt-3">
                        <label for="name" class="form-label fs-5 fw-semibold">Name</label>
                        <input type="text" class="form-control" onChange={inputhandle} id="name" placeholder="Enter Name" name="name" />
                      </div>
                      <div class="mb-3 mt-3">
                        <label for="email" class="form-label fs-5 fw-semibold">Email</label>
                        <input type="email" class="form-control" onChange={inputhandle} id="email" placeholder="Enter email" name="email" />
                      </div>
                      <div class="mb-3 mt-3">
                        <label for="phone" class="form-label fs-5 fw-semibold">Phone Number</label>
                        <div class="input-group">
                          <span class="input-group-text bg-white">
                            <select name="select" class="border-0">
                              <option value="93">+93 (Afghanistan)</option>
                              <option value="355">+355 (Albania)</option>
                              <option value="213">+213 (Algeria)</option>
                              <option value="376">+376 (Andorra)</option>
                              <option value="244">+244 (Angola)</option>
                              <option value="54">+54 (Argentina)</option>
                              <option value="374">+374 (Armenia)</option>
                              <option value="61">+61 (Australia)</option>
                              <option value="43">+43 (Austria)</option>
                              <option value="994">+994 (Azerbaijan)</option>
                              <option value="973">+973 (Bahrain)</option>
                              <option value="880">+880 (Bangladesh)</option>
                              <option value="375">+375 (Belarus)</option>
                              <option value="32">+32 (Belgium)</option>
                              <option value="501">+501 (Belize)</option>
                              <option value="229">+229 (Benin)</option>
                              <option value="975">+975 (Bhutan)</option>
                              <option value="591">+591 (Bolivia)</option>
                              <option value="267">+267 (Botswana)</option>
                              <option value="55">+55 (Brazil)</option>
                              <option value="673">+673 (Brunei)</option>
                              <option value="359">+359 (Bulgaria)</option>
                              <option value="226">+226 (Burkina Faso)</option>
                              <option value="257">+257 (Burundi)</option>
                              <option value="855">+855 (Cambodia)</option>
                              <option value="237">+237 (Cameroon)</option>
                              <option value="1">+1 (Canada)</option>
                              <option value="238">+238 (Cape Verde)</option>
                              <option value="235">+235 (Chad)</option>
                              <option value="56">+56 (Chile)</option>
                              <option value="86">+86 (China)</option>
                              <option value="57">+57 (Colombia)</option>
                              <option value="269">+269 (Comoros)</option>
                              <option value="242">+242 (Congo)</option>
                              <option value="506">+506 (Costa Rica)</option>
                              <option value="385">+385 (Croatia)</option>
                              <option value="53">+53 (Cuba)</option>
                              <option value="357">+357 (Cyprus)</option>
                              <option value="420">+420 (Czech Republic)</option>
                              <option value="45">+45 (Denmark)</option>
                              <option value="253">+253 (Djibouti)</option>
                              <option value="593">+593 (Ecuador)</option>
                              <option value="20">+20 (Egypt)</option>
                              <option value="503">+503 (El Salvador)</option>
                              <option value="240">+240 (Equatorial Guinea)</option>
                              <option value="291">+291 (Eritrea)</option>
                              <option value="372">+372 (Estonia)</option>
                              <option value="251">+251 (Ethiopia)</option>
                              <option value="679">+679 (Fiji)</option>
                              <option value="358">+358 (Finland)</option>
                              <option value="33">+33 (France)</option>
                              <option value="241">+241 (Gabon)</option>
                              <option value="220">+220 (Gambia)</option>
                              <option value="995">+995 (Georgia)</option>
                              <option value="49">+49 (Germany)</option>
                              <option value="233">+233 (Ghana)</option>
                              <option value="30">+30 (Greece)</option>
                              <option value="1-473">+1-473 (Grenada)</option>
                              <option value="502">+502 (Guatemala)</option>
                              <option value="224">+224 (Guinea)</option>
                              <option value="245">+245 (Guinea-Bissau)</option>
                              <option value="592">+592 (Guyana)</option>
                              <option value="509">+509 (Haiti)</option>
                              <option value="504">+504 (Honduras)</option>
                              <option value="36">+36 (Hungary)</option>
                              <option value="354">+354 (Iceland)</option>
                              <option value="91" selected>+91 (India)</option>
                              <option value="62">+62 (Indonesia)</option>
                              <option value="98">+98 (Iran)</option>
                              <option value="964">+964 (Iraq)</option>
                              <option value="353">+353 (Ireland)</option>
                              <option value="972">+972 (Israel)</option>
                              <option value="39">+39 (Italy)</option>
                              <option value="1-876">+1-876 (Jamaica)</option>
                              <option value="81">+81 (Japan)</option>
                              <option value="962">+962 (Jordan)</option>
                              <option value="7">+7 (Kazakhstan)</option>
                              <option value="254">+254 (Kenya)</option>
                              <option value="686">+686 (Kiribati)</option>
                              <option value="850">+850 (North Korea)</option>
                              <option value="82">+82 (South Korea)</option>
                              <option value="965">+965 (Kuwait)</option>
                              <option value="996">+996 (Kyrgyzstan)</option>
                              <option value="856">+856 (Laos)</option>
                              <option value="371">+371 (Latvia)</option>
                              <option value="961">+961 (Lebanon)</option>
                              <option value="266">+266 (Lesotho)</option>
                              <option value="231">+231 (Liberia)</option>
                              <option value="218">+218 (Libya)</option>
                              <option value="423">+423 (Liechtenstein)</option>
                              <option value="370">+370 (Lithuania)</option>
                              <option value="352">+352 (Luxembourg)</option>
                              <option value="261">+261 (Madagascar)</option>
                              <option value="265">+265 (Malawi)</option>
                              <option value="60">+60 (Malaysia)</option>
                              <option value="960">+960 (Maldives)</option>
                              <option value="223">+223 (Mali)</option>
                              <option value="356">+356 (Malta)</option>
                              <option value="692">+692 (Marshall Islands)</option>
                              <option value="222">+222 (Mauritania)</option>
                              <option value="230">+230 (Mauritius)</option>
                              <option value="52">+52 (Mexico)</option>
                              <option value="691">+691 (Micronesia)</option>
                              <option value="373">+373 (Moldova)</option>
                              <option value="377">+377 (Monaco)</option>
                              <option value="976">+976 (Mongolia)</option>
                              <option value="382">+382 (Montenegro)</option>
                              <option value="212">+212 (Morocco)</option>
                              <option value="258">+258 (Mozambique)</option>
                              <option value="95">+95 (Myanmar)</option>
                              <option value="264">+264 (Namibia)</option>
                              <option value="674">+674 (Nauru)</option>
                              <option value="977">+977 (Nepal)</option>
                              <option value="31">+31 (Netherlands)</option>
                              <option value="64">+64 (New Zealand)</option>
                              <option value="505">+505 (Nicaragua)</option>
                              <option value="227">+227 (Niger)</option>
                              <option value="234">+234 (Nigeria)</option>
                              <option value="389">+389 (North Macedonia)</option>
                              <option value="47">+47 (Norway)</option>
                              <option value="968">+968 (Oman)</option>
                              <option value="92">+92 (Pakistan)</option>
                              <option value="680">+680 (Palau)</option>
                              <option value="507">+507 (Panama)</option>
                              <option value="675">+675 (Papua New Guinea)</option>
                              <option value="595">+595 (Paraguay)</option>
                              <option value="51">+51 (Peru)</option>
                              <option value="63">+63 (Philippines)</option>
                              <option value="48">+48 (Poland)</option>
                              <option value="351">+351 (Portugal)</option>
                              <option value="974">+974 (Qatar)</option>
                              <option value="40">+40 (Romania)</option>
                              <option value="7">+7 (Russia)</option>
                              <option value="250">+250 (Rwanda)</option>
                              <option value="685">+685 (Samoa)</option>
                              <option value="378">+378 (San Marino)</option>
                              <option value="966">+966 (Saudi Arabia)</option>
                              <option value="221">+221 (Senegal)</option>
                              <option value="381">+381 (Serbia)</option>
                              <option value="248">+248 (Seychelles)</option>
                              <option value="232">+232 (Sierra Leone)</option>
                              <option value="65">+65 (Singapore)</option>
                              <option value="421">+421 (Slovakia)</option>
                              <option value="386">+386 (Slovenia)</option>
                              <option value="677">+677 (Solomon Islands)</option>
                              <option value="252">+252 (Somalia)</option>
                              <option value="27">+27 (South Africa)</option>
                              <option value="211">+211 (South Sudan)</option>
                              <option value="34">+34 (Spain)</option>
                              <option value="94">+94 (Sri Lanka)</option>
                              <option value="249">+249 (Sudan)</option>
                              <option value="597">+597 (Suriname)</option>
                              <option value="268">+268 (Swaziland)</option>
                              <option value="46">+46 (Sweden)</option>
                              <option value="41">+41 (Switzerland)</option>
                              <option value="963">+963 (Syria)</option>
                              <option value="886">+886 (Taiwan)</option>
                              <option value="992">+992 (Tajikistan)</option>
                              <option value="255">+255 (Tanzania)</option>
                              <option value="66">+66 (Thailand)</option>
                              <option value="670">+670 (Timor-Leste)</option>
                              <option value="228">+228 (Togo)</option>
                              <option value="676">+676 (Tonga)</option>
                              <option value="216">+216 (Tunisia)</option>
                              <option value="90">+90 (Turkey)</option>
                              <option value="993">+993 (Turkmenistan)</option>
                              <option value="688">+688 (Tuvalu)</option>
                              <option value="256">+256 (Uganda)</option>
                              <option value="380">+380 (Ukraine)</option>
                              <option value="971">+971 (UAE)</option>
                              <option value="44">+44 (United Kingdom)</option>
                              <option value="1">+1 (United States)</option>
                              <option value="598">+598 (Uruguay)</option>
                              <option value="998">+998 (Uzbekistan)</option>
                              <option value="678">+678 (Vanuatu)</option>
                              <option value="379">+379 (Vatican City)</option>
                              <option value="58">+58 (Venezuela)</option>
                              <option value="84">+84 (Vietnam)</option>
                              <option value="967">+967 (Yemen)</option>
                              <option value="260">+260 (Zambia)</option>
                              <option value="263">+263 (Zimbabwe)</option>
                            </select>
                          </span>
                          <input type="tel" class="form-control" maxLength={"10"} onChange={inputhandle} name="phone" placeholder="Enter phone number" />
                        </div>
                      </div>
                      <div class="mb-3 mt-3">
                        <label for="nameuni" class="form-label fs-5 fw-semibold">Name of University/ Institute/ Society</label>
                        <input type="text" class="form-control" onChange={inputhandle} id="nameuni" placeholder="Enter Name of University/ Institute/ Society" name="nameuni" />
                      </div>
                      <div class="mb-3 mt-3">
                        <label for="desig" class="form-label fs-5 fw-semibold">Designation</label>
                        <input type="text" class="form-control" onChange={inputhandle} id="desig" placeholder="Enter Designation" name="desig" />
                      </div>
                      <div class="mb-3 mt-3">
                        <label for="message" class="form-label fs-5 fw-semibold">Your Message</label>
                        <textarea class="form-control" name="message" onChange={inputhandle} id="message" placeholder="Write your message"></textarea>
                      </div>
                      <div class="mt-4 text-center">
                        <button type="button" onClick={contactUsData} class="ag_btn_red taga d-block mx-auto fs-3">SEND MESSAGE</button>
                        {/* <button type="submit" class="btn btn-submit px-5 py-3">SEND MESSAGE</button> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-md-12">
                  <div className="partnership-opportunities">
                    <div class="box-two p-3 rounded-2">
                      <div class="row align-items-center">
                        <div class="col-md-9">
                          <p class="text-center">For partnership opportunities, write to us:</p>
                          <p class="text-center mb-0"><strong>contactus@vikramshilaedu.in</strong></p>
                        </div>
                        <div class="col-md-3">
                          <img src="assets/images/we-can-help.png" class="w-100" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>

        </main>
        <AakashgangaFooter />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="md-x"
      >
        <Modal.Header
          className="justify-content-center pb-0 closeButtonStyle"
          closeButton
        >
          <Modal.Title className="modal-title">
            <p className="good-news mb-3">Thank You</p>
            <p className="good-news styleModalTitle mb-0" style={{textTransform:"none"}} >
            We will get back to you <br/>in two working days.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="pt-3 pb-3 contactModalFooter">
          <button
            style={{fontSize: "14px"}}
            className="button button-fill button-sm"
            onClick={handleClose}
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default conferencePapers;
