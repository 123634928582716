import React from "react";
import Modal from "react-bootstrap/Modal";

const NeedHelpModal = ({ showHelpModal, setShowHelpModal }) => {
  return (
    <Modal
      dialogClassName="modal-dialog modal-dialog-centered modal-sm authorModal"
      show={showHelpModal}
      id="congratsMobile"
      tabIndex={-1}
    >
      <Modal.Header className="modal-header">
        <h2 class="modal-title">
          Need Help?
        </h2>
        <button
          type="button"
          class="btn-close"
          ariaLabel="Close"
          onClick={() => setShowHelpModal(false)}
        ></button>
      </Modal.Header>

      <Modal.Body className="pt-0">
        <div className="aaforAss">
          <p>
            For any questions, write to us at <a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a>
          </p>
          <p className="mt-4">
            We will get back to you within <br />48 working hours.
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer className="modal-footer">
        <button
          class="button button-primary"
          onClick={() => setShowHelpModal(false)}
        >
          Okay
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default NeedHelpModal;
