
import React from 'react';

const ContentDetails = ({ pagename,details}) => {

    function handleRefranceCitation(content)
    {
        setTimeout(() => {
            var anchortags = document.querySelectorAll("a[href*='IDTAG=']");
            anchortags.forEach(function(tag) {
            const hrefData=tag.href.split('IDTAG=');
            tag.href =hrefData[0];
            var div = document.createElement("div");
            div.classList.add('refranceLinking')
            div.setAttribute("id", hrefData[1]);
            tag.appendChild(div)
            
        });
          }, "1000");
       
    }

    function removeEmptyTag(content)
    {
        return content
    }

    return (
        <>
        <div id={`viewScroll${details.title}`} className="commonContentScroll"/>
        <div className={`contentPreview ${details.title==='References'? 'References':''}`} >
            <h2>{details.title}</h2>
           
            <div dangerouslySetInnerHTML={{__html: details.title==='References'?(removeEmptyTag(details.desc)):details.desc}}/>
        </div>
        {details.title==='References'?handleRefranceCitation(''):''}
        </>
       
    );
};
export default ContentDetails