import React from 'react'
import SubmitArticle from '../../../components/common/submitArticle' 
import DashboardLayout from '../../../layout/dashboard'

const AddArticle = () => {
  return (
    <DashboardLayout>
      <SubmitArticle />
    </DashboardLayout>
  )
}

export default AddArticle