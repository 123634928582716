import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { Pagination } from "semantic-ui-react";
import DashboardLayout from "../../../layout/dashboard";
import Dropdown from "react-bootstrap/Dropdown";
import notyet from "../../../assets/img/icons/notarticle.svg";
import RelatedArticles from "../../../components/viewArticle/relatedArticles";
import {
  getJournalDetail,
  getReadingList,
  getJournalBySpecialization
} from "../../../store/apiCalls/dashboard";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../components/common/Select";
import { publicationYear } from "../../../constants";
import { Link } from "react-router-dom";
import SearchDropdown from "../../../components/SearchDropdown/SearchDropdown";

const ReadingList = () => {
  const masterData = useSelector((state) => state.MasterDataReducer.MasterData);
  const { id } = jwt_decode(localStorage.usertoken);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const limit = 10;
  const [readingListData, setReadingListData] = useState({
    subject: [],
    type: [],
  });
  const [readingList, setReadingList] = useState([]);
  const [totalReadingList, setTotalReadingList] = useState([]);
  const [oldToNew, setOldToNew] = useState(-1);
  const [filter, setFilter] = useState(false);
  let totalPages = Math.ceil(totalReadingList?.noOfRecordBaseOnFilter / limit);
  const [journalData, setJournalData] = useState([]);
  const [journalOptions, setJournalOptions] = useState([]);
  const [specializationOptions, setSpecializationOptions] = useState([]);

  const fetchReadingList = async () => {
    dispatch({ type: "SET_LOADER", payload: true });
    const params = () => {
      const data = {
        userId: id,
        page,
        limit,
        journal:
          readingListData?.journal === "All" ? "" : readingListData?.journal,
        subject: readingListData?.subject
          ?.map((sub) => {
            return specializationOptions?.find((val) => val?.specialization === sub)
              ?._id;
          })
          .join(","),
        type: readingListData?.type
          .map((sub) => {
            return masterData?.typeOfArticledata?.find(
              (val) => val?.articleName === sub
            )?._id;
          })
          .join(","),
        publishYear:
          readingListData?.year === "All" ? "" : readingListData?.year,
      };

      if (oldToNew) {
        data.oldToNew = oldToNew;
      }
      return data;
    };
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const response = await getReadingList(headers, params());
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setReadingList(response?.data?.data?.data);
      setTotalReadingList(response?.data?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: false });
      return toast.error("Please try again");
    }
  };

  const handleSelectChange = (value, type) => {
    let newReadingListData = { ...readingListData };
  if (type === "journal") {
      newReadingListData = { ...readingListData, subject: []  }
      setSpecializationOptions(specializationOptions[value]);
    }
    return setReadingListData({ ...newReadingListData, [type]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const cloneSubject = { ...readingListData };
    if (name === "subject") {
      if (cloneSubject.subject.includes(value)) {
        cloneSubject.subject.splice(cloneSubject.subject.indexOf(value), 1);
      } else {
        if (cloneSubject.subject.length < 3) {
          cloneSubject.subject.push(value);
        }
      }
    } else if (name === "type") {
      if (cloneSubject?.type.includes(value)) {
        cloneSubject?.type?.splice(cloneSubject?.type?.indexOf(value), 1);
      } else {
        if (cloneSubject?.type.length < 3) {
          cloneSubject.type.push(value);
        }
      }
    }
    setReadingListData(cloneSubject);
  };

  const clearFilter = () => {
    setFilter(true);
    fetchReadingList();
    setReadingListData({
      journal: "",
      subject: [],
      type: [],
      year: "",
    });
  };

  const getResponseOptions = () => {
    let journal = [];
    journalData &&
      journalData.forEach((element) => {
        journal.push(element);
      });
    journal.unshift({ journalName: "All" });
    return setJournalOptions(journal);
  };

  const getJournalData = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      journalType: masterData?.jouranlData?.[0]?._id,
    };
    const response = await getJournalDetail(headers, params);
    if (response?.status === 200) {
      setJournalData(response?.data?.data);
    } else return;
  };

  const getSpecializationData = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      journalName: readingListData?.journal ? readingListData?.journal : ""
    };
    const response = await getJournalBySpecialization(headers, params);
    let journalName = response?.data?.data?.data?.[0]?.journalName;
    let result = journalName.substring(0, 8);
    if (response?.status === 200) {
      if (result === "AyurYoga") {
        let firstArray = response?.data?.data?.specilisation?.[0];
        let anotherArray = response?.data?.data?.specilisation?.[1];
        let finalArray = [...firstArray, ...anotherArray]
        setSpecializationOptions(finalArray);
      } else {
        setSpecializationOptions(response?.data?.data?.specilisation)
      }
    } else return;
  };

  useEffect(() => {
    getJournalData();
  }, [masterData]);

  useEffect(() => {
    getResponseOptions();
  }, [journalData]);

  useEffect(() => {
    if (readingListData?.journal) {
      getSpecializationData();
    }
  }, [readingListData])

  useEffect(() => {
    fetchReadingList();
  }, [page, filter, oldToNew]);

  return (
    <>
      <DashboardLayout>
        <h2 className="commanTitle">My Reading List</h2>
        <div className="redingFilterWrap">
          <div className="commanBox">
            <div className="redingFilterArea">
              <div className="redingFilterList">
                <div className="fieldWrap selectBox">
                  <Select
                    handleChange={(value) =>
                      handleSelectChange(value, "journal")
                    }
                    selectedValue={readingListData?.journal}
                    options={journalOptions?.map((val) => val?.journalName)}
                    optionValues={journalOptions?.map(
                      (val) => val?.journalName
                    )}
                    labelClass={readingListData?.journal ? "" : "hidden"}
                    label="Journal"
                    placeholder="Journal"
                    name="journal"
                  />
                </div>
              </div>
              <div className="redingFilterList">
                <div className="fieldWrap selectBox">
                  <SearchDropdown
                    options={specializationOptions?.filter((spec) => spec?.specialization !== "Other Subject (choose this option to register your subject)").map((val) => val?.specialization)}
                    onChange={handleChange}
                    selectedValue={readingListData?.subject}
                    name="subject"
                    placeholder={
                      readingListData?.subject?.length === 0 ||
                      !readingListData?.subject
                        ? "Subject"
                        : readingListData?.subject?.length > 1
                        ? readingListData?.subject[0] +
                          " +" +
                          String(readingListData?.subject.length - 1)
                        : readingListData?.subject[0]
                    }
                    label="Subject"
                    labelClass={
                      readingListData?.subject?.length !== 0 ? "" : "hidden"
                    }
                    removeAsterisk={true}
                  />
                </div>
              </div>
              <div className="redingFilterList">
                <div className="fieldWrap selectBox">
                  <SearchDropdown
                    options={masterData?.typeOfArticledata?.map(
                      (article) => article?.articleName
                    )}
                    onChange={handleChange}
                    selectedValue={readingListData?.type}
                    name="type"
                    placeholder={
                      readingListData?.type?.length === 0 ||
                      !readingListData?.type
                        ? "Type"
                        : readingListData?.type?.length > 1
                        ? readingListData?.type[0] +
                          " +" +
                          String(readingListData?.type.length - 1)
                        : readingListData?.type[0]
                    }
                    label="Type"
                    labelClass={
                      readingListData?.type?.length !== 0 ? "" : "hidden"
                    }
                    removeAsterisk={true}
                  />
                </div>
              </div>
              <div className="redingFilterList ">
                <div className="fieldWrap selectBox">
                  <Select
                    handleChange={(value) => handleSelectChange(value, "year")}
                    selectedValue={readingListData?.year}
                    options={publicationYear?.map((item) => item?.key)}
                    optionValues={publicationYear?.map((item) => item?.value)}
                    labelClass={readingListData?.year ? "" : "hidden"}
                    label="Publication Year"
                    placeholder="Publication Year"
                    name="year"
                  />
                </div>
              </div>
              <div className="redingFilterList actionWrap">
                <span className="filterText">Filter</span>
                <b onClick={clearFilter}>Clear All</b>
                <Link
                  class="button button-primary"
                  to=""
                  onClick={fetchReadingList}
                >
                  Apply
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 sortWrap">
            <div className="customDrop">
              <Dropdown className="removeArrow">
                <Dropdown.Toggle variant="success">Sort By</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className={oldToNew === -1 ? "active" : ""}
                    onClick={() => {
                      setOldToNew(-1);
                    }}
                  >
                    New to Old
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={oldToNew === 1 ? "active" : ""}
                    onClick={() => {
                      setOldToNew(1);
                    }}
                  >
                    Old to New
                  </Dropdown.Item>
                  <Dropdown.Item href="#">Recommended</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="row">
          {readingList && readingList.length > 0 ? (
            readingList?.map((article, index) => {
              const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
              const contentWithoutSpecialChars = () => {
                return article?.article[0]?.abstract?.replace(
                  specialCharPattern,
                  ""
                );
              };
              return (
                <div className="col-md-6">
                  <RelatedArticles
                    index={index}
                    published={article?.article?.[0]?.updatedAt}
                    id={article?._id}
                    title={article?.article?.[0]?.title}
                    userTitle={
                      article?.article?.[0]?.authorDetails?.author?.[0]?.title
                    }
                    userName={
                      article?.article?.[0]?.authorDetails?.author?.[0]
                        ?.fullName
                    }
                    contentWithoutSpecialChars={contentWithoutSpecialChars}
                    typeOfArticle={article?.typeofarticles?.[0]?.articleName}
                    journalName={
                      article?.article?.[0]?.submissionDetail?.journalName
                    }
                    subjectName={article?.subjectDetail?.[0]?.subjectName}
                    specialization={
                      article?.specializations?.[0]?.specialization
                    }
                    fetchReadingList={fetchReadingList}
                    readingList={readingList}
                    articleId={article?.article?.[0]?._id}
                    readingflag={true}
                  />
                </div>
              );
            })
          ) : (
            <div className="tabContentWrap">
              <div className="notArticle notArticleRedingList">
                <figure>
                  <img src={notyet} alt="No Article" />
                </figure>
                <h2>
                  Your reading list is waiting
                </h2>
                <p className="d-inline-block w-100">
                  All the article that you read on Aakashganga will feature here
                  <span>Access and reference the research articles you read conveniently, making it easier for you to review, analyze, and draw meaningful conclusions from the collective knowledge available in the field.
                </span>
                </p>
                
              </div>
            </div>
          )}
        </div>

        {totalPages > 1 && (
          <div className="paginationWrap mt-4">
            <Pagination
              boundaryRange={1}
              firstItem={null}
              lastItem={null}
              siblingRange={1}
              activePage={page}
              prevItem={{ content: <b class="icon-arrow-left" /> }}
              nextItem={{ content: <b class="icon-arrow-right" /> }}
              onPageChange={(event, data) => setPage(data.activePage)}
              totalPages={totalPages}
            />
          </div>
        )}
      </DashboardLayout>
    </>
  );
};
export default ReadingList;
