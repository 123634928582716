import NewsLetter from "../components/global/NewsLetter";
import { useNavigate, Link } from "react-router-dom";
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import { Helmet } from 'react-helmet';

function PublishingEditorRolesAndResponsibilities() {
	const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const token = localStorage.getItem("usertoken");
	return (
		<>
		 <Helmet>
          <title>Aakashganga Open: Publishing Editor Roles and Responsibilities</title>
          <meta name="description" content="Discover the roles and responsibilities of publishing editors at Aakashganga. Learn the guidelines for the editorial assessment for the submitted peer review article." />

          <meta property="og:title" content="Aakashganga Open: Publishing Editor Roles and Responsibilities" data-react-helmet="true" />
          <meta property="og:description" content="Discover the roles and responsibilities of publishing editors at Aakashganga. Learn the guidelines for the editorial assessment for the submitted peer review article." data-react-helmet="true" />
          <meta property="og:url" content="https://www.aakashgangaopen.in/publishing-editor-roles-and-responsibilities" data-react-helmet="true" />
		</Helmet>
		<div className="tagbody">
		<AakashgangaHeader />
			<h1 className="seo-related-tag">
				Publishing Editor Roles and Responsibilities
			</h1>
			<main className='main'>
				<div className='banner-section'>
					<div className='page-title'>
						<h1 className='title tagh1'>
							Publishing Ed<span>i</span>tor Roles and
							Responsibilities
						</h1>
					</div>
				</div>

				<div className='content-area'>
					<div className='container'>
						<p className='pera-text efforts tagp mt-3'>
							Any Publishing Editor working on the Aakashganga
							Platform has the highest degree of editorial
							independence. We respect what you bring to the table
							and ask all our Mega Journal editors to make every
							reasonable effort to adhere to the following
							guidelines for editorial assessment of articles
							submitted for peer review in the Aakashganga Mega
							Journals:
						</p>

						<h2 className='large-title tagh2'>Open Access Advocacy</h2>

						<p className='pera-text efforts tagp'>
							All editors associated with the Aakashganga platform
							must believe in, and promote the principles of{" "}
							<Link  to="/open-access" target="_blank"><strong className='underline colorBlack'>Open access</strong></Link>{" "}
							and Open science. They should encourage authors to
							share their research data, adopt open licenses, and
							make their findings accessible to the wider research
							community.
						</p>

						<h2 className='large-title tagh2'>Structure of Work</h2>

						<p className='pera-text efforts tagp'>
							All research scholars accepting to be Publishing
							Editors with Aakashganga platform will be the sole
							constituents of the Editorial Board of the
							respective{" "}
							<Link to="/about-megajournals" target="_blank"><strong className='underline colorBlack'>
								Mega Journals.
							</strong></Link>{" "}
							As a publishing editor and de facto board member,
							you will provide guidance, support, and expertise to
							the Mega Journal, its processes. The administrators
							will, from time to time reach out for guidance to
							Publishing Editors including seeking feedback on the
							performance of the platform.
						</p>

						<p className='pera-text tagp'>
							<strong>
								The primary responsibilities of a Publishing
								Editor are as follows:
							</strong>
						</p>

						<p className='pera-text tagp'>
							<span className='blue-text bold600'>
								Research Article Evaluation:
							</span>{" "}
							The primary responsibility of a Publishing Editor is
							to carefully review the articles submitted for
							editorial assessment, and decide on how they move
							ahead in their publishing journey.
						</p>

						<p className='pera-text italic efforts tagp'>
							<span className='red-text bold600'>Please Note:</span> Mega
							Journals by definition have a wider scope of
							acceptance than conventional journals. They evaluate
							the contribution an article makes to the discipline
							as opposed to the uniqueness or novelty of the
							research.
						</p>

						<p className='pera-text tagp'>
							<span className='blue-text bold600'>
								Editorial Assessment:
							</span>{" "}
							Publishing Editors should carefully evaluate the
							research article submitted to the platform. They
							should assess the originality, scientific rigor,
							methodology, significance, and ethical
							considerations of the research.
						</p>

						<p className='pera-text tagp'>
							<span className='blue-text bold600'>
								Peer Review Management: 
							</span> Publishing Editors will decide to process a research
							article through automated workflow on Aakashganga
							platform for Peer Review. They will do this after
							evaluating the merit of the presented research. The
							system will let you know if an author has opted for
							a Pre-Publishing Double Blind Peer Review or a Post
							Publishing Open Review.
						</p>

						<p className='pera-text efforts tagp'>
							All editors have a crucial obligation to maintain
							the confidentiality of the peer-review process. They
							must refrain from disclosing any information
							pertaining to a manuscript to individuals not
							involved in the peer-review process. In cases where
							a submitted manuscript is deemed unsuitable for the
							Mega Journal or falls outside its scope, the editors
							have the authority to reject it without undergoing
							formal peer review.
						</p>

						<p className='pera-text efforts tagp'>
							<span className='blue-text bold600'>
								Editorial Decision-making:
							</span> Publishing Editors will make informed decisions
							regarding the acceptance, revision, or rejection of
							manuscripts based on the results of the peer review
							process and in alignment with the journal’s
							editorial policies and standards.
						</p>

						<p className='pera-text efforts tagp'>
							<span className='blue-text bold600'>Timely Review:</span> Editors should aim to provide timely and
							constructive feedback to the research article after
							receiving the Peer Review report.
						</p>

						<p className='pera-text italic efforts tagp'>
							Please note that position of the member of Editorial
							Board is by invitation only. If you would like to
							join us as an editor, pleases end us an email on{" "}
							<a className="black-colorr" href='mailto:contactus@vikramshilaedu.in'>
								contactus@vikramshilaedu.in
							</a>
						</p>

						<div className='box-content p-5'>
							<p className='pera-text efforts tagp'>
								We deeply appreciate the efforts of our
								Publishing Editors and value their role in
								shaping the future of research. As a thank you
								gesture, we provide a small honorarium in the
								form of <strong>Mudras</strong> (INR) for every article
								they assess editorially. The{" "}
								<strong>Mudras</strong> will be deposited in the{" "}
								<strong>Mudras Kosh</strong> (Wallet) in your
								respective account once the article is
								processed. You may use these{" "}
								<strong>Mudras</strong> to write a new research
								article, or update an existing article on the
								platform.
							</p>

							<p className='pera-text tagp'>
								Please note that Mudras hold no cash value and
								can only be used on the platform.
							</p>
						</div>

						<h2 className='large-title tagh2'>Editorial Independence</h2>

						<p className='pera-text tagp'>
							Publishing Editors should ensure that they are
							making decisions based on the academic quality and
							relevance of submitted manuscripts, without any
							external influence or conflicts of interest.
						</p>

						<h2 className='large-title tagh2'>Ethical Considerations</h2>

						<p className='pera-text efforts tagp'>
							Editors should be vigilant about potential ethical
							concerns, such as plagiarism, use of profane words,
							duplicate submission, data fabrication, or any other
							form of academic misconduct. The platform has a
							built-in system to check similarity indices and use
							of profanity in the article.{" "}
						</p>

						<p className='pera-text tagp'>
							Any data presented in a submitted manuscript follows
							the norms of the license <Link to="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank"><strong className='underline colorBlack'>CC-BY-NS-SA.</strong></Link>
						</p>

						<h2 className='large-title tagh2'>Editorial Transparency</h2>

						<p className='pera-text efforts tagp'>
							We expect all Publishing Editors to be conscious of
							the decisions they make and in rare cases the
							platform could call upon an individual to review
							their decision with Aakashganga admin. Every
							Publishing Editor should fill in the relevant
							section of the Review Form as precisely as possible.
							The platform is automated and hence we expect the
							job of providing rationale simplified and direct.
							Editors are encouraged to make every reasonable
							endeavour to ensure timely processing of
							submissions.
						</p>

						<p className='pera-text tagp'>
							<strong>Please note the following:</strong>
						</p>

						<p className='pera-text tagp'>
							<span className='red-text bold600'>1.</span> All
							communication regarding any queries around the
							editorial assessment or the PEER REVIEW process will
							be handled by the Administrators of the Aakashganga
							platform.{" "}
						</p>

						<p className='pera-text efforts tagp'>
							<span className='red-text bold600'>2.</span> Should there be
							any conflicts arising from the Editorial Assessment,
							Peer Review or Publication process, the resolution
							and the final action taken is the sole
							responsibility of the administrators of the
							Aakashganga Platform. We appreciate the work done by
							Editors and Peer Reviewers and we will do our best
							to protect them from unnecessary conflict for their
							participation in the publishing process.{" "}
						</p>

						<h2 className='large-title tagh2'>
							Process of Article Selection
						</h2>

						<p className='pera-text efforts tagp'>
							For every article submitted to Aakashganga, the
							process of selection of a Publishing Editor for
							editorial assessment is automated without any human
							intervention. The system seeks out the best match
							amongst the various individuals who have opted to be
							Publishing Editors for a specific Mega Journal. Once
							the system allots a Publishing Editor, they (the
							publishing editor) will have a dedicated dashboard
							on which all processes will be recorded. As a
							Publishing Editor you are free to decline to provide
							any editorial assessment for an article. However, we
							request that this option be used only as an
							exception.
						</p>

						<p className='pera-text tagp'>
							<strong>Process of Article Selection – </strong>
							Administrators at Aakashganga are however authorized
							to reassign, or withdraw the invitation to review an
							article (for editorial assessment) without assigning
							any reason whatsoever. Generally where a Publishing
							Editor is unresponsive for a reasonable period of
							time, such action will be necessitated to keep the
							publishing process on track.
						</p>

						<p className='pera-text tagp'>
							Every Publishing Editor accepting our invitation by
							virtue of the acceptance also accepts this process
							that is at the sole discretion of the administrators
							of the Aakashganga platform.
						</p>

						<p className='pera-text italic efforts tagp'>
							<span className='red-text bold600'>Please Note:</span> that
							you could be an Author, Peer Reviewer and a
							Publishing Editor on the platform concurrently. This
							means as a Publishing Editor you are free to publish
							your own article and you may be called upon to
							review an article as a Peer Reviewer.
						</p>

						<p className='pera-text efforts tagp'>
							<span className='red-text bold600'>Acceptance:</span> This
							document is deemed to be accepted and agreed to by
							you, forming a contract with us, wherein you agree
							to all the terms & conditions mentioned above in
							regard to your role as a Publishing Editor; unless
							you inform us in writing of non-acceptance or
							non-agreement of the same.{" "}
						</p>

						<p className='pera-text tagp'>
							For more questions on our processes, policies and
							other aspects, please refer to our <Link  to="/faq" target="_blank"><strong className='underline '>FAQ section.</strong></Link>
						</p>
					</div>
				</div>
			</main>
			{/* NewsLetter Start */}
			<NewsLetter />
			<AakashgangaFooter />
			</div>
			{/* Footer Redline Start */}
		</>
	);
}

export default PublishingEditorRolesAndResponsibilities;
