/*eslint-disable*/
import parse from "html-react-parser";

const PeraTextData = ({ data }) => {
  return (
    <>
    <div className="">
      {data.map((item) => (
        <p className="pera-text automated tagp">{parse(`${item.description}`)}</p>
      ))}
      </div>
    </>
  );
};

export default PeraTextData;
