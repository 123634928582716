import React, { useEffect, useState,useRef } from "react";
import { Pagination } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import notyet from "../../../assets/img/icons/notarticle.svg";
import { recommendedArticles, getLatestArticles, getMostViewedArticle, getLastBrowseArticle, getJournalDetail, getJournalBySpecialization } from "../../../store/apiCalls/dashboard";
import { getSubjectSpecialization } from '../../../store/apiCalls/profileDetails';
import { getJournalSubjects } from "../../../store/apiCalls/articles";
import RelatedArticles from "../../../components/viewArticle/relatedArticles";
import AllArticleRelatedarticles from "../../../components/viewArticle/allArticleRelatedarticles";
import Select from "../../../components/common/Select";
import AakashgangaHeader from "../../../components/global/HeaderComponent";
import AakashgangaFooter from "../../../components/global/FooterComponent";
import SearchDropdown from "../../../components/SearchDropdown/SearchDropdown";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";
import backArrow from "../../../assets/img/icons/blueArrow.svg";
import { Helmet } from 'react-helmet';
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

function allArticle() {
    const navigate = useNavigate();
    const masterData = useSelector((state) => state.MasterDataReducer.MasterData);
    const [collapseActive, setIsActive] = useState(false);
    const location = useLocation();
    const journalName = location?.state?.journalName;
    const journalId = location?.state?.journalId;
    const userDetails = useSelector((state) => state?.LoginReducer?.user);
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const [recommendedPage, setRecommendedPage] = useState(1);
    const [latestPage, setLatestPage] = useState(1);
    const [mostViewedPage, setMostViewedPage] = useState(1);
    const dispatch = useDispatch();
    const [oldToNew, setOldToNew] = useState(-1);
    const [articleData, setArticleData] = useState([]);
    const [mostViewedArticleData, setMostViewedArticle] = useState([]);
    const [lastBrowseArticleData, setLastBrowseArticle] = useState([]);
    const limit = 10;
    const recomdLimit = 5;
    const MAX_WORDS = 30;
    const [recommendedList, setRecommendedList] = useState([]);
    const [journalData, setJournalData] = useState([]);
    const [filterType, setfilterType] = useState([]);
    const [filterDiscipline, setfilterDiscipline] = useState([]);
    const [societyjournalData, setSocietyJournalData] = useState([]);
    const [journalOptions, setJournalOptions] = useState([]);
    const [allDisciplineData, setAllDisciplineData] = useState([]);
    const [disciplineOptions, setDisciplineOptions] = useState([]);
    const [disciplineOption, setDisciplineOption] = useState([]);
    const [specializationOptions, setSpecializationOptions] = useState([]);

    const [activeTab, setActiveTab] = useState("recent");
    const [PageName, setPageName] = useState("all");
    const [sideMenuDiscipline, setSideMenuDiscipline] = useState('')
    const [sideMenuType, setSideMenuType] = useState('')
    const [sideMenuYear, setSideMenuYear] = useState('')
    const [selectedName, setSelectedName] = useState('')

    const [articleTypeToggle, setArticleTypeToggle] = useState(false);
    const [publicationYearToggle, setPublicationYearToggle] = useState(false);
    const [journalToggle, setJournalToggle] = useState(true);
    const [disciplineToggle, setDisciplineToggle] = useState(true);
    const [readingListData, setReadingListData] = useState({
        discipline: "",
        subject: [],
        type: [],
    });

    const [OpenTypedrop, setOpenTypedrop] = useState(false);
    const [OpenDisciplinedrop, setOpenDisciplinedrop] = useState(false);
    const [filter, setFilter] = useState(false);
    const [recommendedDivSct, setRecommendedDivSct] = useState(false);
    const [totalRecomndedList, setTotalRecomndedList] = useState("");
    const [latestArticlesCount, setLatestArticlesCount] = useState("");
    const [mostViewedArticleCount, setMostViewedArticleCount] = useState("");

    let recommendedTotalPages = Math.ceil(totalRecomndedList / recomdLimit);
    let latestArticlesTotalPages = Math.ceil(latestArticlesCount / limit);
    let currentViewArticle = latestPage * limit;
    let currentViewArticleFinal = currentViewArticle > latestArticlesCount ? latestArticlesCount : currentViewArticle;
    let pastViewArticle = currentViewArticle - limit < 1 ? 1 : currentViewArticle - limit;
    let mostViewedArticleTotalPages = Math.ceil(mostViewedArticleCount / limit);

    const publicationYear = [
        { key: 'All', value: "All" },
        { key: '2021', value: "2021" },
        { key: '2022', value: "2022" },
        { key: '2023', value: "2023" },
        { key: '2024', value: "2024" },
        { key: '2025', value: "2025" },
    ]
    const sidePublicationYear = [
        { key: '2021', value: "2021" },
        { key: '2022', value: "2022" },
        { key: '2023', value: "2023" },
        { key: '2024', value: "2024" },
        { key: '2025', value: "2025" }];

    const fetchLatestArticlesData = async (data) => {
        try {
            // Start the loader
            dispatch({ type: "SET_LOADER", payload: true });

            // Prepare parameters for the fetch call
            const params = {
                page: latestPage,
                limit: limit,
                journaltype: data,
                filterdata: filterType,
                filterDiscipline: filterDiscipline,
                societyId: journalId,
                userId: userDetails?._id,
            };

            // Fetch data
            const ArticleRes = await getLatestArticles(headers, params);

            // Check if the response is successful
            if (ArticleRes.status === 200 && ArticleRes?.data) {
                setArticleData(ArticleRes?.data);
                setLatestArticlesCount(ArticleRes?.data?.totalCount);
            }

        } catch (err) {
            // Log the error
            console.log(err);
        } finally {
            // Stop the loader
            dispatch({ type: "SET_LOADER", payload: false });
        }
    };


    const handlefilter = (data) => {
        if (data === "society") {
            setPageName("society");
        } else if (data === "mega") {
            setPageName("mega");
        } else if (data === "conference") {
            setPageName("conference");
        } else if (data === "typefilter") {
            setPageName("typefilter");
            
        } else {
            setPageName("all");
        }
    };

    const handlefilterType = (data) => {
        // Check if the item is already in the filterType array
        if (filterType.includes(data)) {
            // If it is, remove it (uncheck)
            setfilterType(filterType.filter(item => item !== data));
        } else {
            // If it isn't, add it (check)
            setfilterType([...filterType, data]);
        }
        // handlefilter("typefilter")
    };
    const handlefilterDiscipline = (data) => {
        // Check if the item is already in the filterType array
        if (filterDiscipline.includes(data)) {
            // If it is, remove it (uncheck)
            setfilterDiscipline(filterDiscipline.filter(item => item !== data));
        } else {
            // If it isn't, add it (check)
            setfilterDiscipline([...filterDiscipline, data]);
        }
        // handlefilter("typefilter")
    };
    

    useEffect(() => {
        fetchLatestArticlesData(PageName);
    }, [PageName]);

    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            fetchLatestArticlesData("typefilter");
        } else {
            isMounted.current = true;
        }
    }, [filterType]);

    useEffect(() => {
        if (isMounted.current) {
            fetchLatestArticlesData("typefilter");
        } else {
            isMounted.current = true;
        }
    }, [filterDiscipline]);

    // const getRecommendedArticles = async () => {
    //     dispatch({ type: "SET_LOADER", payload: true });
    //     setRecommendedList([''])
    //     const params = () => {
    //         const data = {
    //             userId: userDetails?._id,
    //             page: recommendedPage,
    //             limit: recomdLimit,
    //         };
    //         if (readingListData?.journal != '') {
    //             data.journal = readingListData?.journal,
    //                 data.journalRequest = true
    //         }
    //         if (readingListData?.discipline != '' || sideMenuDiscipline) {
    //             data.subject = sideMenuDiscipline ? sideMenuDiscipline : readingListData?.discipline
    //             if (sideMenuDiscipline) {
    //                 data.subjectRequest = true
    //             }
    //         }
    //         if (readingListData?.subject != '') {
    //             data.specialization = readingListData?.subject
    //                 ?.map((sub) => {
    //                     return specializationOptions?.find((val) => val?.specialization === sub)
    //                         ?._id;
    //                 })
    //                 .join(",")
    //         }
    //         if (sideMenuType || readingListData?.type != '') {
    //             data.type = sideMenuType != '' ? sideMenuType : readingListData?.type
    //                 .map((sub) => {
    //                     return masterData?.typeOfArticledata?.find(
    //                         (val) => val?.articleName === sub
    //                     )?._id;
    //                 })
    //                 .join(",")
    //             if (sideMenuType) {
    //                 data.typeRequest = true
    //             }
    //         }
    //         if (sideMenuYear || readingListData?.year) {
    //             data.publishYear = sideMenuYear ? sideMenuYear : readingListData?.year === "All" ? "" : readingListData?.year
    //             if (sideMenuYear) {
    //                 data.publishYearRequest = true
    //             }
    //         }

    //         if (oldToNew) {
    //             data.sorting = oldToNew;
    //         }
    //         return data;
    //     };
    //     const response = await recommendedArticles({}, params());
    //     if (response?.status === 200) {
    //         dispatch({ type: "SET_LOADER", payload: false });
    //         setTotalRecomndedList(response?.data?.totalCount);
    //         setRecommendedList(response?.data?.data);
    //     } else {
    //         dispatch({ type: "SET_LOADER", payload: true });
    //         return toast.error("Please try again!!");
    //     }
    // };

    // const fetchMostViewedArticlesData = () => {
    //     dispatch({ type: "SET_LOADER", payload: true });
    //     const fetchdata = async () => {
    //         const params = {
    //             page: mostViewedPage,
    //             limit: limit,
    //             userId: userDetails?._id,
    //         };
    //         return getMostViewedArticle({}, params);
    //     };
    //     fetchdata()
    //         .then((res) => {
    //             if (res.status === 200 && res?.data) {
    //                 dispatch({ type: "SET_LOADER", payload: false });
    //                 setMostViewedArticle(res?.data?.data);
    //                 setMostViewedArticleCount(res?.data?.totalCount);
    //             }
    //         })
    //         .catch((err) => console.log(err));
    //     dispatch({ type: "SET_LOADER", payload: false });
    // };

    // const fetchLastBrowseArticleData = () => {
    //     dispatch({ type: "SET_LOADER", payload: true });
    //     const fetchdata = async () => {
    //         const params = {
    //             userId: userDetails?._id,
    //         };
    //         return getLastBrowseArticle(headers, params);
    //     };
    //     fetchdata()
    //         .then((res) => {
    //             if (res.status === 200 && res?.data) {
    //                 dispatch({ type: "SET_LOADER", payload: false });
    //                 setLastBrowseArticle(res?.data?.data?.data);
    //             }
    //         })
    //         .catch((err) => console.log(err));
    //     dispatch({ type: "SET_LOADER", payload: false });
    // };
    const handleRedirect = (e, path) => {
        if (e !== "") e.preventDefault();
        window.scroll({ top: 0, behavior: "smooth" });
        navigate(path);
    };
    const handlescrolltotop = () => {
        window.scroll({ top: 0, behavior: "smooth" });
    }
    const resumeArticle = () => {
        const articleId = lastBrowseArticleData?.[0]?.article?.[0]?._id
        handleRedirect("", `/viewPublishedArticle/${articleId}`);
    };

    const getResponseOptions = () => {
        let journal = [];
        let allDiscipline = []
        journalData &&
            journalData.forEach((element) => {
                journal.push(element);
                allDiscipline.push(element.subjectData)
            });
        setJournalOptions(journal);
        return setJournalOptions(journal);
    };
    const getAllDiscipleResponse = () => {
        let allDiscipline = []
        journalData &&
            journalData.forEach((element) => {
                allDiscipline.push(element.subjectData[0].subject)
            });
        return setAllDisciplineData(allDiscipline);
    };

    const getJournalData = async () => {
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const params = {
            journalType: masterData?.jouranlData?.[0]?._id,
        };
        const response = await getJournalDetail(headers, params);
        if (response?.status === 200) {
            setJournalData(response?.data?.data);
        } else return;
    };
    const getJournalDataSociety = async () => {
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const params = {
            journalId: journalId,
        };
        const response = await getJournalDetail(headers, params);
        if (response?.status === 200) {
            // console.log("getJournalDataSociety==>",response?.data?.data);
            setSocietyJournalData(response?.data?.data[0]);
        } else return;
    };
    const getJournalSubjectByJornlId = async (JrnlName) => {
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const queryParams = {
            params: { journalName: JrnlName },
            headers: headers,
        };
        const response = await getJournalSubjects(queryParams);
        if (response?.status === 200) {
            setSpecializationOptions(response?.data?.data?.specilisation);
        } else return;
    };
    const getSpecializationBySubjt = async () => {
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const params = {
            subjectId: sideMenuDiscipline ? sideMenuDiscipline : readingListData?.discipline
        };
        const response = await getSubjectSpecialization(headers, params);
        if (response?.status === 200) {
            return setSpecializationOptions(response?.data?.data.data);
        } else {
            return toast.error("Please try again!!");
        }
    };
    const getJournalDiscipline = () => {
        const masterSubject = [];
        journalData?.forEach((element, k) => {
            const subject = [];
            element?.subjectData?.forEach((element, p) => {
                const data = element.subject;
                subject.push(data);
            });
            masterSubject[element.journalName] = subject;
        });
        return setDisciplineOptions(masterSubject);
    }


    const handleSelectChange = (value, type) => {
        setRecommendedDivSct(true);
        let newReadingListData = { ...readingListData };
        if (type === "journal") {
            newReadingListData = { ...readingListData, subject: [] };
            setSpecializationOptions(specializationOptions[value]);
            setDisciplineOption(disciplineOptions[value]);
            getJournalSubjectByJornlId(value)
        }
        setSideMenuDiscipline('')
        setSideMenuType('')
        setSideMenuYear('')
        return setReadingListData({ ...newReadingListData, [type]: value });
    };
    const handleYearSelectChange = (value, type) => {
        let newReadingListData = { ...readingListData };
        return setReadingListData({ ...newReadingListData, [type]: value });
    }
    const handleSelectDisciplineChange = (value, type) => {
        let newReadingListData = { ...readingListData };
        if (type === "discipline") {
            setRecommendedDivSct(true);
            newReadingListData = { ...readingListData, discipline: "" };
        }
        return setReadingListData({ ...newReadingListData, [type]: value });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const cloneSubject = { ...readingListData };
        if (name === "subject") {
            if (cloneSubject.subject.includes(value)) {
                cloneSubject.subject.splice(cloneSubject.subject.indexOf(value), 1);
            } else {
                if (cloneSubject.subject.length < 3) {
                    cloneSubject.subject.push(value);
                }
            }
        } else if (name === "type") {
            if (cloneSubject?.type.includes(value)) {
                cloneSubject?.type?.splice(cloneSubject?.type?.indexOf(value), 1);
            } else {
                if (cloneSubject?.type.length < 3) {
                    cloneSubject.type.push(value);
                }
            }
        }
        setReadingListData(cloneSubject);
    }
    // const handleSideTypeChange = (name, value) => {
    //     setRecommendedDivSct(true);
    //     setReadingListData({
    //         // journal: "",
    //         type: [],
    //     })
    //     setSideMenuType(value)
    //     getRecommendedArticles()
    // }
    const toggleType = () => {
        if (articleTypeToggle == true) {
            setArticleTypeToggle(false)
        }
        else {
            setArticleTypeToggle(true)
        }
    }
    const togglePublicationYear = () => {
        if (publicationYearToggle == true) {
            setPublicationYearToggle(false)
        }
        else {
            setPublicationYearToggle(true)
        }
    }
    const toggleJournal = () => {
        if (journalToggle == true) {
            setJournalToggle(false)
        }
        else {
            setJournalToggle(true)
        }
    }
    const toggleDiscipline = () => {
        if (disciplineToggle == true) {
            setDisciplineToggle(false)
        }
        else {
            setDisciplineToggle(true)
        }
    }

    const handleSideMenuChange = (name, value, valueName) => {
        let e = 'RelatedArticle'
        setRecommendedDivSct(true);
        setSelectedName(valueName);
        if (name === "discipline") {
            setReadingListData({
                journal: "",
                subject: [],
                type: [],
            })
            setSideMenuType('')
            setSideMenuYear('')
            setSideMenuDiscipline(value)
        }
        else if (name === "type") {
            setReadingListData({
                journal: "",
                subject: [],
                type: [],
            })
            setSideMenuDiscipline('')
            setSideMenuYear('')
            setSideMenuType(value)
        }
        else if (name === "year") {
            setReadingListData({
                journal: "",
                year: "",
                subject: [],
                type: [],
            })
            setSideMenuDiscipline('')
            setSideMenuType('')
            setSideMenuYear(value)
        }
        const element = document.getElementById(`viewScroll${e}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const getSpecializationData = async () => {
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const params = {
            journalName: readingListData?.journal ? readingListData?.journal : ""
        };
        const response = await getJournalBySpecialization(headers, params);
        let journalName = response?.data?.data?.data?.[0]?.journalName;
        let result = journalName.substring(0, 8);
        if (response?.status === 200) {
            if (result === "AyurYoga") {
                let firstArray = response?.data?.data?.specilisation?.[0];
                let anotherArray = response?.data?.data?.specilisation?.[1];
                let finalArray = [...firstArray, ...anotherArray]
                setSpecializationOptions(finalArray);
            } else {
                setSpecializationOptions(response?.data?.data?.specilisation)
            }
        } else return;
    };

    const clearFilter = () => {
        setFilter(true);
        setReadingListData({
            journal: readingListData.journal,
            specialization: [],
            subject: [],
            type: [],
            year: "",
        });
    };
    const handleBack = () => {
        setFilter(true);
        setReadingListData({
            journal: "",
            specialization: [],
            subject: [],
            type: [],
            year: "",
        });
        setSideMenuDiscipline('')
        setSideMenuType('')
        setSideMenuYear('')
        setRecommendedDivSct(false)
        window.scroll({ top: 0, behavior: "smooth" });
    }
    const specialCharPattern = /[!@#$%^*()?;":{}|\u00a0]/g;

    const contentWithoutSpecialChars = (data) => {
        return data.replace(specialCharPattern, "");
    };
    useEffect(() => {
        getJournalData();
        getJournalDataSociety();
    }, [masterData]);

    useEffect(() => {
        getJournalDiscipline();
        getResponseOptions();
        getAllDiscipleResponse();
    }, [journalData]);

    useEffect(() => {
        if (readingListData?.discipline || sideMenuDiscipline) {
            getSpecializationBySubjt();
        }
    }, [readingListData, sideMenuDiscipline])

    // useEffect(() => {
    //     getRecommendedArticles();
    // }, [recommendedPage, oldToNew, readingListData.journal, sideMenuDiscipline, sideMenuType, sideMenuYear]);

    useEffect(() => {
        fetchLatestArticlesData();
    }, [latestPage]);
    // console.log("masterData?.typeOfArticledata", masterData?.typeOfArticledata);

    // useEffect(() => {
    //     fetchLastBrowseArticleData();
    // }, [])

    // useEffect(() => {
    //     fetchMostViewedArticlesData();
    // }, [mostViewedPage]) 
    // console.log("articleRes", articleData);
    return <>
        <Helmet>
            <title>Browse All Research Articles | Aakashganga Open</title>
        </Helmet>
        <AakashgangaHeader />
        <div className="bg-white">
            <div className="container">
                <div className="sell-banner-wrp h-auto">
                    <div className="sell-banner px-4 h-auto">
                        {PageName == "society" ?
                            <>
                                <img src="assets/images/allsociety-journal.webp" alt="All Articles" class="tagimg" />
                            </> :
                            PageName == "mega" ?
                                <>
                                    <img src="assets/images/allmega-journal.webp" alt="All Articles" class="tagimg" />
                                </> :
                                PageName == "conference" ?
                                    <>
                                        <img src="assets/images/allconference-papers.webp" alt="All Articles" class="tagimg" />
                                    </> :
                                    <>
                                        <img src="assets/images/allarticle.webp" alt="All Articles" class="tagimg" />
                                    </>}

                    </div>
                    <div className="sell-banner-text allarticle-mainheadline">
                        {PageName == "society" ?
                            <>
                                <h3 className="title tagh3 mb-0">All Articles</h3>
                                <span className="sub_headline_allarticle">Society Journals</span>
                            </> :
                            PageName == "mega" ?
                                <>
                                    <h3 className="title tagh3  mb-0">All Articles</h3>
                                    <span className="sub_headline_allarticle withRedtheme">Mega Journals</span>
                                </> :
                                PageName == "conference" ?
                                    <>
                                        <h3 className="title tagh3 mb-0">All Articles</h3>
                                        <span className="sub_headline_allarticle conferenceStyle">Conference Papers</span>
                                    </> :
                                    <>
                                        <h3 className="title tagh3">All Articles</h3>
                                    </>}

                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="callpprlivebox px-4 my-4 limited-width bg-white mw-100 d-flex align-items-center gap-5" >
                                {PageName == "society" ?
                                    <>
                                        <p>Now inviting societies to host their journal with Aakashganga</p>
                                    </> :
                                    PageName == "mega" ?
                                        <>
                                            <p>We are inviting Publishing Editors to join our Mega Journals</p>
                                        </> :
                                        PageName == "conference" ?
                                            <>
                                                <p>Now inviting Publishing Editors and Peer Reviewers to join us</p>
                                            </> :
                                            <>
                                                <p>We are inviting Publishing Editors to join our Mega Journals</p>
                                            </>}

                                <Link to={'/registration'} class="ag_btn_red signup-btn-journal taga m-0" style={{ maxWidth: "260px" }} >Register</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div className="bg-white pt-0 maindiv d-block">

            <div className="container-fluid bg-white">
                <div className="articleHead m-0 py-4">
                    <h2>All Articles</h2>
                    <p>{pastViewArticle}-{currentViewArticleFinal ? currentViewArticleFinal : "1"} of {latestArticlesCount ? latestArticlesCount : "1"} articles</p>
                </div>
                <div className="row justify-content-between" >
                    <div className="col-md-6">
                        <div className="cusSearch">
                            <input
                                type="search"
                                placeholder="Search by Journals, Articles, Authors, or Content Types"
                                // value={search}
                                onChange={(e) => {
                                    //   setSearch(e.target.value);
                                    //   searchJournalArticle(e.target.value);
                                }}
                            // onKeyDown={(e) => { (e.key === "Enter" && search != '') ? (navigate(`/search-result/${search}`), setSearch("")) : "" }}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        {/* <p className="underline_or_head m-0">Journal Homepage</p> */}
                    </div>
                    <div className="col-md-12 pt-4">
                        <p><i className="text-grey">Articles are listed in sequence of most recent publication.</i></p>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-white pt-4">
                <div className="row">
                    <div className="col-md-9" >
                        <div className="customTabs">
                            <Tab.Container defaultActiveKey="recent">


                                <Tab.Content>
                                    {activeTab === "recent" && (
                                        <Tab.Pane eventKey="recent">
                                            <div className="row recommendedMain" >
                                                {articleData && articleData?.data?.length > 0 ? (
                                                    articleData?.data?.map((articleRes, index) => {

                                                        return (
                                                            <div className="col-md-12">
                                                                <AllArticleRelatedarticles
                                                                    index={index}
                                                                    published={articleRes?.updatedAt}
                                                                    id={articleRes?.readinglistId}

                                                                    title={articleRes?.title}
                                                                    slug={articleRes?.slug}
                                                                    userTitle={
                                                                        articleRes?.user?.[0]?.title
                                                                    }
                                                                    pageName={"all-articles"}
                                                                    userName={
                                                                        articleRes?.authorDetails?.author
                                                                    }
                                                                    {...articleRes?.authorDetails?.author?.map((autName) => {
                                                                        return (
                                                                            <>
                                                                                {autName.title}
                                                                                {autName.fullName}
                                                                            </>
                                                                        );
                                                                    })}
                                                                    contentWithoutSpecialChars={contentWithoutSpecialChars(articleRes?.abstract)}
                                                                    typeOfArticle={articleRes?.typeofarticles?.[0]?.articleName}
                                                                    journalName={
                                                                        articleRes?.submissionDetail?.journalName
                                                                    }
                                                                    reviewType={
                                                                        articleRes?.reviewType?.typeOfReview
                                                                    }
                                                                    journalType={
                                                                        articleRes?.journalType?.[0]?.journalType
                                                                    }
                                                                    subjectName={articleRes?.subjectDetail?.[0]?.subjectName}
                                                                    specialization={articleRes?.specializations?.[0]?.specialization}
                                                                    fetchReadingList={fetchLatestArticlesData}
                                                                    readingList={articleData}
                                                                    articleId={articleRes?._id}
                                                                    readingflag={articleRes?.readingflag}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    <div className="tabContentWrap">
                                                        <div className="notArticle">
                                                            <figure>
                                                                <img src={notyet} alt="No Article" />
                                                            </figure>
                                                            <h2>No articles yet!</h2>

                                                        </div>
                                                    </div>
                                                )}
                                                {/* {latestArticlesTotalPages > 1 && ( */}
                                                <div className="paginationWrap mt-4">
                                                    <Pagination
                                                        boundaryRange={1}
                                                        firstItem={null}
                                                        lastItem={null}
                                                        siblingRange={1}
                                                        activePage={latestPage}
                                                        prevItem={{ content: <span><b class="icon-arrow-left pe-2" />Previous</span> }}
                                                        nextItem={{ content: <span>Next<b class="icon-arrow-right ps-2" /></span> }}
                                                        onPageChange={(event, data) => setLatestPage(data.activePage)}
                                                        totalPages={latestArticlesTotalPages}
                                                    />
                                                </div>
                                                {/* )} */}
                                            </div>

                                        </Tab.Pane>
                                    )}

                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div class="journalslist custom-journalist-class">
                            <div class=" align-items-center">
                                <div class="ag_heading_center_wrap mb-3 our-partner mt-0 info-custom">
                                    <h2 class="tagh2">Browse Articles</h2>
                                </div>
                            </div>
                            <ul className="journalInfoList">
                                <li className="mb-0 text-center fw-semibold px-0 tagli">
                                    <a
                                        className={PageName === "Type" ? "theme-orange-color" : "text-black"}
                                        href="javascript:;"
                                        onClick={() => {
                                            handlefilter();
                                            setOpenTypedrop(prevState => !prevState);
                                        }}
                                    >
                                        Type
                                    </a>
                                    <ul className={OpenTypedrop ? `text-left ps-4 ` : `d-none`}>
                                        {masterData?.typeOfArticledata?.map((item, index) => (
                                            <div className="d-flex py-2" key={index}>
                                                <input
                                                    onClick={() => handlefilterType(item?._id)}  // Wrap the function call in an arrow function
                                                    type="checkbox"
                                                    id={`checkbox-${index}`}
                                                    name={item?.articleName}
                                                    value={item?._id}
                                                    checked={filterType.includes(item?._id)} // Control the checked state based on filterType
                                                />
                                                <label className="mb-0 ps-2 fs-14px" htmlFor={`checkbox-${index}`}>{item?.articleName}</label>
                                            </div>
                                        ))}

                                    </ul>
                                </li>
                                <li className="mb-0 text-center fw-semibold px-0 tagli">
                                    <a className={PageName == "society" ? "theme-orange-color" : "text-black"} href="javascript:;" onClick={() => {handlefilter("society"), handlescrolltotop()}}>Societies</a>
                                </li>
                                <li class="mb-0 text-center fw-semibold px-0 tagli">
                                    <a className={PageName == "Discipline" ? "theme-orange-color" : "text-black"} href="javascript:;" onClick={() => {
                                            handlefilter();
                                            setOpenDisciplinedrop(prevState => !prevState);
                                        }}>Discipline</a>
                                        <ul className={OpenDisciplinedrop ? `text-left ps-4 ` : `d-none`}>
                                        {masterData?.subjectdata?.map((item, index) => (
                                            <div className="d-flex py-2" key={index}>
                                                <input
                                                    onClick={() => handlefilterDiscipline(item?._id)} 
                                                    type="checkbox"
                                                    id={`subject-${index}`}
                                                    name={item?.subjectName}
                                                    value={item?._id}
                                                    checked={filterDiscipline.includes(item?._id)}
                                                />
                                                <label className="mb-0 ps-2 fs-14px lh-sm" htmlFor={`subject-${index}`}>{item?.subjectName}</label>
                                            </div>
                                        ))}

                                    </ul>
                                </li>
                                <li className="mb-0 text-center fw-semibold px-0 tagli">
                                    <a className={PageName == "mega" ? "theme-orange-color" : "text-black"} href="javascript:;" onClick={() => {handlefilter("mega"), handlescrolltotop()}}>Mega Journals</a>
                                </li>
                                <li className="mb-0 text-center fw-semibold px-0 tagli">
                                    <a className={PageName == "conference" ? "theme-orange-color" : "text-black"} href="javascript:;" onClick={() => {handlefilter("conference"), handlescrolltotop()}}>Conference Papers</a>
                                </li>
                            </ul>
                        </div>
                        <div class="journalslist martop-10 main-class-publish mb-5">
                            <div class="footer-align-bottom">
                            </div>
                            <h2 class="font22 custom-24 tagh2 text-center">Get in Touch</h2>
                            <a class="ag_btn_red signup-btn-journal taga w-auto" href="/contact-us">Click Here</a>
                        </div>
                        {/* <div className="pageLeft position-relative">
                            <div class="pageMenu text-initial">
                                <div className="browseBy">
                                    BROWSE BY
                                </div>
                                <h2 className={journalToggle == true ? "sideTitle subnav active" : "sideTitle subnav"} onClick={toggleJournal}>Journal<i></i></h2>
                                <div className="pageMenu-link">
                                    <ul className={journalToggle == true ? "" : "d-none"}>
                                        {journalOptions && journalOptions?.map((val) => {
                                            return (
                                                <>
                                                    <li onClick={(value) => handleSelectChange(val?.journalName, "journal")}><a class={readingListData?.journal == val?.journalName ? "active" : ""}>{val?.journalName}</a></li>
                                                </>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <h2 className={disciplineToggle == true ? "sideTitle subnav active " : "sideTitle subnav"} onClick={toggleDiscipline}>Discipline<i></i></h2>
                                <div className="pageMenu-link">
                                    <ul className={disciplineToggle == true ? "" : "d-none"}>
                                        {allDisciplineData && allDisciplineData?.map((val) => {
                                            return (
                                                <>
                                                    <li onClick={(value) => handleSideMenuChange("discipline", val?._id, val?.subjectName)}><a class={sideMenuDiscipline == val?._id ? "active" : ""}>{val?.subjectName}</a></li>
                                                </>
                                            )
                                        })}
                                    </ul>
                                </div>

                                <h2 className={articleTypeToggle == true ? "sideTitle subnav active " : "sideTitle subnav"} onClick={toggleType}>Type<i></i></h2>
                                <div className="pageMenu-link">
                                    <ul className={articleTypeToggle == true ? "" : "d-none"}>
                                        {masterData?.typeOfArticledata?.map(
                                            (article) => {
                                                return (
                                                    <>
                                                        <li onClick={(value) => handleSideMenuChange("type", article._id, article?.articleName)}><a class={sideMenuType == article?._id ? "active" : ""}>{article?.articleName}</a></li>
                                                    </>
                                                )
                                            })}
                                    </ul>
                                </div>

                                <h2 className={publicationYearToggle == true ? "sideTitle subnav active " : "sideTitle subnav"} onClick={togglePublicationYear}>Publication Year<i></i></h2>
                                <div className="pageMenu-link border-bottom-0">
                                    <ul className={publicationYearToggle == true ? "yearLink" : "d-none"}>
                                        {sidePublicationYear?.map((item) => {
                                            return (
                                                <>
                                                    <li onClick={(value) => handleSideMenuChange("year", item?.value, item?.value)}>
                                                        <Link class={sideMenuYear == item?.value ? "active" : ""}>{item?.value}</Link></li>
                                                </>
                                            )
                                        }
                                        )}
                                    </ul>
                                </div>

                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        <AakashgangaFooter />
    </>

}
export default allArticle
