import React, { useEffect, useState } from "react";
import AakashgangaHeader from "../../components/global/HeaderComponent";
import AakashgangaFooter from "../../components/global/FooterComponent";
import { Helmet } from 'react-helmet';

const TermsAkashganga = () => {
  const [activeTab, setActiveTab] = useState("Introduction");

  useEffect(() => {
    $(".faqBox-head").click(function () {
      $(this).closest(".faqBox").siblings(".faqBox").removeClass("active");
      $(this)
        .closest(".faqBox")
        .siblings(".faqBox")
        .find(".faqBox-body")
        .slideUp();
      $(this).closest(".faqBox").toggleClass("active");
    });
  }, [activeTab]);

  const handleContent = (type) => {
    const element = document.getElementById(`viewScroll${type}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isActiveClass = (type = "Introduction") => {
    return activeTab === type ? "active" : "";
  };

  const isFaqBoxActive = (type = "Introduction") => {
    return activeTab === type ? "faqBox active" : "faqBox";
  };

  const handleActiveLink = (e, type = "Introduction") => {
    e.preventDefault();
    setActiveTab(type);
  };

  const handleChatBot = (e) => {
    e.preventDefault();
    window.zE("messenger", "open");
  };

  return (
    <>
      <Helmet>
          <title> Terms and conditions of Aakashganga open access platform</title>
          <meta name="description" content="Explore the comprehensive terms and conditions of Aakashganga open access platform. From communication guidelines to intellectual property rights, discover the policies ensuring a transparent and secure online experience." />

          <meta property="og:title" content="Terms and conditions of Aakashganga open access platform" data-react-helmet="true" />
          <meta property="og:description" content="Explore the comprehensive terms and conditions of Aakashganga open access platform. From communication guidelines to intellectual property rights, discover the policies ensuring a transparent and secure online experience." data-react-helmet="true" />
          <meta property="og:url" content="https://www.aakashgangaopen.in/terms-conditions" data-react-helmet="true" />

        <link rel="canonical" href="https://www.aakashgangaopen.in/terms-conditions"  data-react-helmet="true" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <AakashgangaHeader />
        <div className="authorFaqBox">
          <div class="editorHeadMain">
            <div class="innerMergin">
              <div class="editorHead">
                <div class="editorHeadLeft">
                  <h2>TERMS of use</h2>
                </div>
                <div class="editCenterWrap">
                  <b class="blinkText"></b>
                </div>
              </div>
            </div>
          </div>

          <div className="faqSection">
            <div className="containWrap">
              <div className="faqTabs">
                <h3>Table of Contents</h3>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Introduction");
                      handleContent("Introduction")
                  }}
                  className={isActiveClass("Introduction")}
                >
                  Introduction
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Communications");
                      handleContent("Communications")
                  }}
                  className={isActiveClass("Communications")}
                >
                  Communications
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Copyright");
                      handleContent("Copyright")
                  }}
                  className={isActiveClass("Copyright")}
                >
                  Copyright and License 
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Discounts");
                      handleContent("Discounts")
                  }}
                  className={isActiveClass("Discounts")}
                >
                  Discounts, credits and promotions
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Grievance Officer");
                      handleContent("Grievance Officer")
                  }}
                  className={isActiveClass("Grievance Officer")}
                >
                  Grievance Officer
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Governing law");
                      handleContent("Governing law")
                  }}
                  className={isActiveClass("Governing law")}
                >
                  Governing law, jurisdiction and dispute resolution
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Indemnity");
                      handleContent("Indemnity")
                  }}
                  className={isActiveClass("Indemnity")}
                >
                  Indemnity
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    handleActiveLink(e, "Intellectual Property Rights");
                    handleContent("Intellectual Property Rights");
                  }}
                  className={isActiveClass("Intellectual Property Rights")}
                >
                  Intellectual Property Rights
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Interpretation");
                      handleContent("Interpretation");
                  }}
                  className={isActiveClass("Interpretation")}
                >
                  Interpretation 
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Limitation of Liability");
                      handleContent("Limitation of Liability");
                  }}
                  className={isActiveClass("Limitation of Liability")}
                >
                  Limitation of liability
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Links to other websites")
                      handleContent("Links to other websites");
                  }}
                  className={isActiveClass("Links to other websites")}
                >
                  Links to other websites
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    handleActiveLink(e, "Materials and data policies")
                    handleContent("Materials and data policies");
                  }}
                  className={isActiveClass("Materials and data policies")}
                >
                  Materials and Data policies
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Non-assignment");
                      handleContent("Non-assignment")
                  }}
                  className={isActiveClass("Non-assignment")}
                >
                  Non-assignment
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "No warranties");
                      handleContent("No warranties")
                  }}
                  className={isActiveClass("No warranties")}
                >
                  No Warranties
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Policies");
                      handleContent("Policies")
                  }}
                  className={isActiveClass("Policies")}
                >
                  Policies
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Rights and Permissions");
                      handleContent("Rights and Permissions")
                  }}
                  className={isActiveClass("Rights and Permissions")}
                >
                  Rights and Permissions
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Severability");
                      handleContent("Severability")
                  }}
                  className={isActiveClass("Severability")}
                >
                  Severability
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Supplemental Material");
                      handleContent("Supplemental Material")
                  }}
                  className={isActiveClass("Supplemental Material")}
                >
                  Supplemental Material
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Updates to Terms");
                      handleContent("Updates to Terms")
                  }}
                  className={isActiveClass("Updates to Terms")}
                >
                  Updates to Terms of Use 
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "User account security");
                      handleContent("User account security")
                  }}
                  className={isActiveClass("User account security")}
                >
                  User account security
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                      handleActiveLink(e, "Use of website");
                      handleContent("Use of website")
                  }}
                  className={isActiveClass("Use of website")}
                >
                  Use of our website
                </a>
              </div>

              <div className="faqContent">
                <div className="faqList pt-0">
                  <div className={isFaqBoxActive("Introduction")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Introduction"}`} className="commonContentScroll"/>  
                      <h3> Introduction </h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>Welcome to Vikramshila Research Private Limited! </p>
                      <p>
                        This Terms of Use is an electronic record in the form of
                        an electronic contract formed under the{" "}
                        <i>
                          Information Technology Act, 2000 and the rules made
                          thereunder and the amended provisions pertaining to
                          electronic documents/ records in various statutes as
                          amended by the Information Technology Act, 2000.
                        </i>{" "}
                        This document does not require any physical, electronic or
                        digital signature.
                      </p>
                      <p>
                        This document is published in accordance with the
                        provisions of Rule 3(1) of Information Technology
                        (Intermediaries guidelines) Rules, 2011, under Information
                        Technology Act, 2000 that requires the publishing of rules
                        and regulations, privacy policy and user agreement for
                        access or usage of the website of Vikramshila Research
                        Private Limited located at the URL <a href="https://vikramshilaedu.in">www.vikramshilaedu.in</a>{" "}
                        and its subsidiaries, affiliates and/or its products and
                        services offered through the respective websites and
                        platforms including but not restricted to Aakashganga,
                        Kaashi and GyanGanga.
                      </p>
                      <p>
                        This Terms of Use is a legally binding document between
                        guest users and registered users of our website (‘user/
                        you/your/yourself’) and Vikramshila Research Private
                        Limited (‘Vikramshila/ we/ our/ us’), a company
                        incorporated under the Companies’ Act, 2013, with its
                        registered office at I-1667, 3rd Floor, CR Park, New Delhi
                        – 110 019, India, and describes the terms on which we
                        offer you access to and use of our website and such other
                        services as are incidental and ancillary thereto. These
                        terms will be effective upon your acceptance of the same
                        (directly or indirectly in electronic form by clicking on
                        the “i accept” tab or by means of an electronic record or
                        by use of the website) and will govern the relationship
                        between you and Vikramshila, for your use of our website.
                      </p>
                      <p>
                        These Terms of Use shall be read together with the privacy
                        policy and/ or other terms and conditions with all other
                        notices, disclaimers, guidelines appearing on the website
                        from time to time (collectively referred to as
                        “agreement(s)”) and constitutes the entire agreement upon
                        which you are allowed to access and use our website and
                        other incidental/ancillary services.
                      </p>
                      <p>
                        Please read this Terms of Use carefully. By using our
                        website, you indicate that you understand, agree and
                        consent to this Terms of Use and, also agree to be
                        governed by the laws of India. If you do not agree with
                        the Terms of Use, please do not use this website.{" "}
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Communications")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Communications"}`} className="commonContentScroll"/>
                      <h3>Communications</h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        When you use our website or send emails/communication to
                        us or share data/information with us, you agree,
                        acknowledge and understand that you are communicating with
                        us via. electronic means and you consent to receive
                        communications via. electronic means from us as and when
                        required. We may communicate with you by email or by any
                        other mode of communication, electronic or otherwise.
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Copyright")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Copyright"}`} className="commonContentScroll"/>
                      <h3>Copyright and License </h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        The Copyright in the article submitted by you to us for
                        publication shall be owned by you, based on your
                        representations and warranties. You agree to grant us
                        exclusive rights to publish your article through our
                        website/platform under a CC BY NC 4.0 International
                        (Creative Commons Attribution – Non Commercial 4.0)
                        License.{" "}
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Discounts")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Discounts"}`} className="commonContentScroll"/>
                      <h3>Discounts, credits and promotions</h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        We reserve the right to offer discounts/promotional
                        offers/credits to any user of our choice and shall not be
                        held liable by/to any other user for not offering the
                        same. The discounts/offers/credits (mudras) are made
                        available at our sole discretion and are subject to
                        change/amendment/modification from time to time. We may,
                        at our sole discretion and at any time, discontinue the
                        discounts/offers/credits without assigning any reason or
                        without any prior intimation whatsoever. The participation
                        in discounts/offers/credits is voluntary and it is
                        understood that such voluntary participation by the user
                        shall be deemed to have been made with full acceptance of
                        this Terms of use, including this clause. The
                        discounts/offers/credits (mudras) are complimentary and
                        discretionary and do not grant any rights to you and/or
                        should not be construed as an agreement between us and you
                        to exercise rights over them except as decided by us. We
                        shall also have a right to change the quantum of any
                        discounts/offers/credits (mudras) at our sole discretion,
                        without having to inform you.{" "}
                      </p>
                      <p>
                        The discounts/promotional offers/coupons/credits/mudras,
                        do not mean any cash entitlement and can only be used on
                        our website and platform for products and services only.
                        They are not in any way to be meant to be used outside of
                        the website/platform and/or does not entitle you to any
                        monetary benefit, except as abovementioned under this
                        clause.
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Grievance Officer")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Grievance Officer"}`} className="commonContentScroll"/>
                      <h3>Grievance Officer</h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        In accordance with the Information Technology Act, 2000
                        and the rules made there under and also the Consumer
                        Protection (E-Commerce) Rules, 2020, the name and contact
                        details of the Grievance Officer are provided hereunder:
                      </p>
                      <p>
                        Grievance Officer - Ms. Shafina Segon
                        <br />
                        Address – Vikramshila Research Private Limited
                        <br />
                        I – 1667, 3rd Floor, CR Park, New Delhi – 110 019
                        <br />
                        Email –{" "}
                        <a href="mailto:contactus@vikramshilaedu.in">
                          {" "}
                          contactus@vikramshilaedu.in
                        </a>
                        <br />
                        Time – Mon – Sat [9.30 – 17.30]
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Governing law")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Governing law"}`} className="commonContentScroll"/>
                      <h3>Governing law, jurisdiction and dispute resolution</h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        These Terms of Use shall be governed by and construed in
                        accordance with the laws of India. Any action, suit or
                        other legal proceeding for the resolution of any matter
                        arising under or relating to the access/use of this
                        website/products/services shall be subject to the
                        exclusive jurisdiction of the courts at New Delhi, India.{" "}
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Indemnity")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Indemnity"}`} className="commonContentScroll"/>
                      <h3>Indemnity </h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        You shall indemnify and hold harmless Vikramshila, its
                        owners, officers, directors, employees, contractors and
                        agents from any claim or demand or actions made by any
                        third party or any penalty imposed due to or arising out
                        of your breach of these Terms of Use, our website and
                        other terms and conditions or your violation of any law,
                        rules or regulations or the rights (including infringement
                        of intellectual property rights) of a third party.
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Intellectual Property Rights")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Intellectual Property Rights"}`} className="commonContentScroll"/>
                      <h3>Intellectual Property Rights</h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        Our websites, materials/contents/products/services
                        posted/published/made available through our websites,
                        any supporting software/applications/systems and
                        trademarks/ service marks/logos/any other marks
                        (collectively referred to as ‘Intellectual Property Rights
                        or IPR’) are the exclusive property of Vikramshila or its
                        licensors and are protected by Intellectual Property Laws.
                        No IPR owned by Vikramshila or its licensors should be
                        used without our prior written consent. You acknowledge
                        that the said IPR shall remain the sole and exclusive
                        property of Vikramshila or its licensors and agree that
                        you will not contest the ownership of the said IPR for any
                        reason whatsoever.{" "}
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Interpretation")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Interpretation"}`} className="commonContentScroll"/>
                      <h3>Interpretation </h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        Any reference to the singular includes a reference to the
                        plural and vice versa and any reference to the masculine
                        includes a reference to the feminine and vice versa,
                        unless explicitly provided for otherwise.
                      </p>
                      <p>
                        Headings and captions are used for convenience only and
                        will not affect the interpretation of these Terms of Use.
                      </p>
                      <p>
                        Any reference to a natural person, unless repugnant to the
                        context, includes his/her legal heirs, executors and
                        permitted assignees. Similarly, any reference to a
                        juristic person such as Vikramshila, unless repugnant to
                        the context, includes its affiliates, successors
                        -in-business, and permitted assignees.
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Limitation of Liability")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Limitation of Liability"}`} className="commonContentScroll"/>
                      <h3>Limitation of liability </h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        In no event shall Vikramshila be liable for any indirect,
                        punitive, incidental, special, consequential or any other
                        damages resulting from:
                      </p>
                      <p className="pl-4">
                        - the use or inability to use our website/ services/
                        products;
                        <br />
                        - unauthorized access to or alteration of our website/
                        contents hosted therein;
                        <br />
                        - breach of condition, representations or warranties by
                        the author(s) of contents/provider(s) of services;
                        <br />- any other matter relating to the website including
                        but not limited to damages for loss of use or data,
                        arising out of or in any way connected with the use or
                        performance of the website/its services.
                      </p>
                      <p>
                        Vikramshila shall not be responsible for non-availability
                        of the website/its services during periodic maintenance
                        operations or any unplanned suspension of access to the
                        website. You acknowledge that you understand and agree
                        that any material and/or data uploaded/downloaded from
                        Vikramshila is done entirely at your own discretion and
                        risk and that you will be solely responsible for any
                        damage to your device or loss of data that results from
                        the upload/download of such material and/or data.
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Links to other websites")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Links to other websites"}`} className="commonContentScroll"/>
                      <h3>Links to other websites</h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        Our website may contain links to websites of third parties
                        not owned by Vikramshila. These links are provided for
                        your convenience only and the provision of these links
                        does not mean that those websites are related to or
                        associated with us. Please note that these sites will have
                        their own terms of use and privacy policies, which will
                        govern your usage of such sites. You should check their
                        terms of use before you use their websites. We do not
                        guarantee the content and the security of those sites. We
                        shall not be responsible for any loss, damage, claim or
                        expense caused as a result of you accessing these third
                        party sites. We reserve the right to disable links to and/
                        or from third-party sites to our website/ products/
                        services, although we are under no obligation to do so.
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Materials and data policies")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Materials and data policies"}`} className="commonContentScroll"/>
                      <h3>Materials and Data policies</h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        We are committed to open data and being transparent. We
                        require that you make available all data relevant to the
                        conclusions of the manuscript. We work towards having the
                        correct data available, ensuring increased levels of
                        transparency and reproducibility in our published
                        articles. Relevant materials such as analytic methods,
                        study material, tables etc should preferably be made
                        available through a link; you are encouraged to make this
                        material available upon request to interested researchers,
                        and be stated in the manuscript.
                      </p>
                      <p>
                        You must comply with the General Data Protection
                        Regulation (‘GDPR’) [in case it is applicable to you] and
                        all relevant data protection and privacy legislation in
                        other jurisdictions, including India.
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Non-assignment")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Non-assignment"}`} className="commonContentScroll"/>
                      <h3>Non-assignment </h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        In no event shall you assign or transfer or purport to
                        assign or transfer the contract between you and us to any
                        other person, without our knowledge and written consent.
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("No warranties")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"No warranties"}`} className="commonContentScroll"/>
                      <h3>No Warranties</h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        The website and the services are provided on "as is"
                        basis. We do not make any other representations or
                        warranties of any kind, express or implied, including
                        without limitation that the website or the services will
                        meet your requirements/will always be available,
                        accessible, uninterrupted, timely, secure or operate
                        without error.
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Policies")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Policies"}`} className="commonContentScroll"/>
                      <h3>Policies</h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        We have published all the policies which are applicable
                        for you, on the website/platform and they shall be deemed
                        to be accepted by you. In case you are not agreeable, you
                        should not use the website and/or its products or
                        services.{" "}
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Rights and Permissions")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Rights and Permissions"}`} className="commonContentScroll"/>
                      <h3>Rights and Permissions</h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        You warrant and certify in regard to your article
                        publishing by us, that:
                      </p>
                      <ul>
                        <li>
                          The Contribution is your original work and you have all
                          the relevant rights to enter into an Agreement and to
                          convey concerned rights to us for publication of your
                          article as per the terms agreed upon.
                        </li>
                        <li>
                          You have obtained and enclosed all necessary permissions
                          for the reproduction of any copyright works (e.g.
                          quotes, photographs or other visual material, etc.)
                          contained in your Contribution and not owned by you and
                          that you have acknowledged all the source(s).
                        </li>
                        <li>
                          You have made any payments due for any third party
                          permissions and no further payments are required.
                        </li>
                        <li>
                          The Contribution contains no violation of any existing
                          copyright, other third party rights or any defamatory or
                          untrue statements and does not infringe any rights of
                          others.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Severability")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Severability"}`} className="commonContentScroll"/>
                      <h3>Severability </h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        If any of these terms is determined to be illegal, invalid
                        or otherwise non-enforceable by reason of the laws of any
                        state in which these terms are intended to be effective,
                        then to the extent and within the jurisdiction in which
                        that term is illegal, invalid or non-enforceable, it shall
                        be severed and the remaining terms of use shall survive,
                        remain in full force and effect and continue to be binding
                        and enforceable.
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Supplemental Material")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Supplemental Material"}`} className="commonContentScroll"/>
                      <h3>Supplemental Material</h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        This term refers to all material uploaded on the
                        Aakashganga Platform along with the main research article.
                        This accepted in PDF, JPG, JPEG, PNG and other formats as
                        may be specified on the main upload page of the portal.
                        The author warrants that this material is either original
                        created by the author OR is free from any copyright
                        protection OR where copyright restrictions apply, the
                        author(s) has/have obtained all requisite permissions.
                        Should any dispute arise from the publishing of any
                        supplemental material, Vikramshila Research Pvt Ltd as
                        well all its subsidiaries reserve the right to
                        investigate, determine and if needed remove from the
                        article any and all material uploaded by the author(s),
                        without assigning any reason or cause. Every care will be
                        taken by us to remain fair to the material uploaded,
                        however, the final right to action and cause remains with
                        the platform and all its processes. By uploading the
                        material you are adhering to and accepting the terms under
                        which the said material is uploaded on our platform (s)
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Updates to Terms")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Updates to Terms"}`} className="commonContentScroll"/>
                      <h3>Updates to Terms of Use </h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        We may modify, delete, add and update portion(s) of this
                        Terms of Use from time to time. We suggest that you check
                        our Terms of Use periodically to apprise yourself of the
                        updates. Your continued visit and use of our website
                        thereafter will imply your unconditional acceptance of
                        such revisions to the Terms of Use.
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("User account security")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"User account security"}`} className="commonContentScroll"/>
                      <h3>User account security</h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        You may visit, access and use our website either as a
                        registered user or as a guest user. However, not all
                        sections of the website and products/ services will be
                        accessible to the guest users.
                      </p>
                      <p>
                        You shall be responsible for maintaining the
                        confidentiality of your account credentials involving
                        username and password. You shall be responsible for all
                        activities that occur within your account credentials i.e.
                        under your username and password. You shall ensure that
                        the account information that you provide at the time of
                        registration on our website is complete, accurate and up
                        to date. You understand and agree that if you provide any
                        information that is untrue, inaccurate, not current or
                        incomplete or we have reasonable grounds to suspect that
                        any information that you furnish is untrue, inaccurate,
                        not current or incomplete, we shall have the right to
                        suspend/ terminate your account or refuse access to any or
                        all sections of our website/products/services.
                      </p>
                      <p>
                        You agree to
                        <br />
                        - immediately notify us of any unauthorized use/ breach of
                        your password or account details; and
                        <br />- ensure that you exit from your account at the end
                        of each session.
                      </p>
                      <p>
                        We will not be liable for any loss or damage on account of
                        your failure to comply with this section. You may be held
                        liable for losses incurred by us or any other user of or
                        visitor to the website due to authorized or unauthorized
                        use of your account owing to your failure to keep your
                        account credentials safe, secure and confidential. Use of
                        another user's account information for accessing our
                        website is expressly prohibited.
                      </p>
                    </div>
                  </div>

                  <div className={isFaqBoxActive("Use of website")}>
                    <div className="faqBox-head">
                      <div id={`viewScroll${"Use of website"}`} className="commonContentScroll"/>
                      <h3>Use of our website</h3>
                      <b />
                    </div>
                    <div className="faqBox-body">
                      <p>
                        You acknowledge, agree and undertake that your use of our
                        website shall be strictly in adherence to the following
                        principles:-
                      </p>
                      <p>You shall not</p>
                      <ul>
                        <li>
                          promote illegal activities or conduct in a manner that
                          is abusive, threatening, obscene, defamatory, libellous
                          or otherwise violate the legal rights of any person;{" "}
                        </li>
                        <li>
                          publish, post, upload, modify, distribute or disseminate
                          any inappropriate, defamatory, infringing, obscene,
                          indecent, profane, hateful or unlawful material,
                          information, picture or subject;{" "}
                        </li>
                        <li>
                          publish, post, upload, modify, distribute or disseminate
                          any information that threatens the unity, integrity,
                          defence, security or sovereignty of India, its friendly
                          relations with foreign states, or public order or causes
                          incitement to the commission of any cognizable offence
                          or prevents investigation of any offence or is insulting
                          to any other nation;
                        </li>
                        <li>
                          publish, post, upload, distribute or disseminate any
                          information or data belonging to another person and to
                          which you do not have a right or which is invasive of
                          another’s privacy;
                        </li>
                        <li>
                          try to gain unauthorized access or exceed the scope of
                          authorized access to the website or to profiles, blogs,
                          communities, account information or other fragments of
                          the website or solicit passwords or personal information
                          for commercial or unlawful purposes from other users;
                        </li>
                        <li>
                          post or share any information on this website which is
                          or can be false, inaccurate or misleading in any way;{" "}
                        </li>
                        <li>violate any law for the time being in force;</li>
                        <li>
                          infringe any patent, trademark, copyright or other
                          proprietary rights or third party's trade secrets or
                          rights of publicity or privacy or be fraudulent in any
                          manner;
                        </li>
                        <li>
                          conduct or forward surveys, contests or schemes without
                          our knowledge and consent;{" "}
                        </li>
                        <li>
                          transmit junk mails or unsolicited mass mails or spam
                          mails;
                        </li>
                        <li>
                          use any deep-link, robot, spider or other automatic
                          device, program, algorithm or methodology or similar or
                          equivalent manual process to access, acquire, copy,
                          monitor, alter, share or delete any portion of this
                          website or content, or in any way reproduce or
                          circumvent the structure or presentation of the website
                          to obtain or attempt to obtain any information, data,
                          materials or documents through any means not
                          specifically made available through the website;{" "}
                        </li>
                        <li>
                          post, publish, edit, copy, distribute, transmit,
                          display, perform, reproduce, publish, license, create
                          derivative works from, transfer or sell any information
                          or software obtained from the website without our
                          knowledge and consent.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="pullUp"
                  onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
                >
                  <b className="icon-arrow-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      <AakashgangaFooter />
    </>
  );
};
export default TermsAkashganga;
