import React, { useState} from "react";
import Modal from "react-bootstrap/Modal";
import { CONTENT_HEADING } from "../../constants";
import Content from "../global/Content";
import { useNavigate,Link } from "react-router-dom";


const Publishing = () => {
	const [show, setShow] = useState(false);
	const navigate = useNavigate();
	const handleRedirect = (e, path = "/registration") => {
		e.preventDefault();
		window.scroll({ top: 0, behavior: "smooth" });
		navigate(path);
	  };
	  const token = localStorage.getItem("usertoken");
	return (
		<>
		<Content title={CONTENT_HEADING.PUBLISHING}>
			<p className='pera-text publishplatform tagp'>
				The Aakashganga Platform publishes a number of different{" "}
				<Link 
				
                  onClick={(e) => setShow(true)}
				 className='underline blackbold'>
					article types
				</Link>.
				{" "}
				Our main objective is to make the research publishing journey
				easy, transparent, and affordable for our authors.
			</p>

			<p className='pera-text tagp'>
				We publish Mega Journals and Society/University/Institute
				Journals including reports and conference proceedings.
			</p>

			<p className='pera-text tagp'>
				The Aakashganga platform offers two kinds of Peer Reviews:
			</p>

			<ul className="tagul">
				<li className="tagli">Pre Publishing Double Anonymous Peer Review.</li>
				<li className="tagli">Post Publishing Open Peer Review.</li>
			</ul>
			<p className='pera-text tagp'>
				Please review the details of Aakashganga’s{" "}
				<Link to="/peer-review-models"
                       target="_blank" className='underline blackbold'>
					Peer Review
				</Link>{" "}
				models before you decide to submit your article.
			</p>

			<p className='pera-text italic tagp'>
				<span className='red-text bold600'>Please Note:</span> When you are
				opting for Pre-Publishing Double Anonymous Peer Review, please
				be aware of the following:
			</p>

			<p className='pera-text tagp'>
				<span className='red-text bold600'>1.</span> Your name and affiliation
				will not be made aware to the Peer Reviewer.
			</p>

			<p className='pera-text publishplatform tagp'>
				<span className='red-text bold600'>2.</span> It is your responsibility
				to ensure that in no part of the article, is there any direct
				reference to you or multiple authors of the research article.
			</p>

			<p className='pera-text tagp'>
				<span className='red-text bold600'>3.</span> Please double check
				captions, figures or tables to ensure that your name or names of
				other corresponding authors does not appear in any of these.
			</p>

			<p className='italic-title blue-text tagp'>
				Our System is automated and driven by Artificial Intelligence.{" "}
				<br />
				Therefore, the Peer Reviewer is automatically allocated by the
				system.
			</p>
		</Content>
		 <Modal
		 show={show}
		 //onHide={setShow(false)}
		 backdrop="static"
		 keyboard={false}
		 size="lg"
		 aria-labelledby="contained-modal-title-vcenter "
		 centered
		 className="markitPopupBox"
	   >
		 <Modal.Header
		   className="justify-content-center closeButtonStyle"
		   closeButton
		   onClick={() => setShow(false)}
		 >
		   <Modal.Title className="modal-title pl-0">           
			 <h2 className="fontsizemod ml-2">
			   Aakashganga currently accepts the following types of research
			   articles for publishing
			 </h2>
			  
		   </Modal.Title>
		 </Modal.Header>
		 <Modal.Body className="pt-0 pb-2">
		   <div className="px-2 text-center">
			 <p className="f-14">
			   <ul className="contentModal">
				 <li className="section">
				   <strong>
					 Original Research:
				   </strong>{" "}
					 These are original research studies presenting new findings and insights in any field of science or medicine.
				 </li>
				 <li className="efforts">
				   <strong>Review Articles:</strong>{" "}
				   These provide comprehensive and critical reviews of existing research, summarizing and analysing the current state of knowledge on a specific topic.
				 </li>
				 <li className="efforts">
				   <strong>Systematic Review:</strong>{" "}
				   A comprehensive and structured method of synthesizing and analyzing existing research studies and evidence on a specific research question or topic.
				 </li>
				 <li className="efforts">
				   <strong>Methodology Articles:</strong>{" "}
				   These describe new methods or techniques developed for research or data analysis. Methodology articles focus on the technical aspects of research and often include detailed protocols or algorithms.
				 </li>
				 <li className="efforts">
				   <strong>Data Articles:</strong>{" "}
				   These focus primarily on data sets, describing and providing access to large data collections or databases. Data articles may include data descriptions, data analyses, and data visualization.
				 </li>
				 <li className="efforts">
				   <strong>Case Reports:</strong>{" "}
				   These describe unique or interesting medical or clinical cases that contribute to the understanding of a particular disease, treatment, or condition.
				 </li>
				 <li className="efforts">
				   <strong>Short Reports:</strong>{" "}
				   These are concise research papers presenting new and significant findings but with a shorter format compared to full research articles.
				 </li>
				 <li className="efforts">
				   <strong>Perspectives or Opinions:</strong>{" "}
				   These articles express the authors’ perspectives, opinions, or commentaries on a specific scientific, social, or ethical issue.
				 </li>
				 <li className="efforts">
				   <strong>Conceptual Articles:</strong>{" "}
				   These articles propose new concepts, frameworks, or theoretical models, often based on existing research or literature.
				 </li>
				 <li className="efforts">
				   <strong>Commentaries:</strong>{" "}
				   Commentaries offer critical analysis or perspective on previously published research or current issues within a field.
				 </li>
				 <li className="efforts">
				   <strong>Book Reviews:</strong>{" "}
				   These articles evaluate and critique recently published books relevant to the scope of the journal.
				 </li>
			   </ul>
			 </p>
		   </div>
		 </Modal.Body>
		 <Modal.Footer className="pt-3 pb-3 contactModalFooter"></Modal.Footer>
	   </Modal>
	   </>
	);
};

export default Publishing;
