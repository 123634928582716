import React from "react";

const Advertisement = ({ pagename, details }) => {
  return (
    <div className="adSport">
      <img src={details.img} alt="#" className="demoImg w-100 mt-0" />
    </div>
  );
};
export default Advertisement;
