import { useState } from "react";
import { CONTENT_HEADING } from "../../constants";
import SubmitArticleModal from "../Modal/SubmitArticleModal";
import Content from "../global/Content";
import { useNavigate, Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import MobileModalAlert from "../Modal/MobileModalAlert";

const ImportantNote = () => {
  const [uploadArticle, setUploadArticle] = useState(false);
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const [mobileAlert, setMobileAlert] = useState(false);
  const token = localStorage.getItem("usertoken");
  return (
    <Content title={CONTENT_HEADING.IMPORTANT_NOTE}>
      <div className="row m-0 updating-article">
        <div className="updating-article ">
          <h4
            className="customimpnoteind tagh4"
          >
            Indian Nationals:
          </h4>
          <div className="itrWrap">
            <p className="tagp mt-0">
              <strong>Our normal publishing process:</strong>
            </p>
            <p className="tagp mt-1">
              <span>1.</span> A one-time refundable fee of Rs 1000 is payable to
              access our publishing system. This is simply to retain the sanctity
              of our process and to ensure no bots or unknown content gets into
              our system. This amount is fully refunded to your{" "}
              <strong>Mudra Kosh</strong> (online wallet) if your article is
              rejected.
            </p>
            <p className="tagp">
              <span>2.</span> On acceptance of your article, you will receive a
              note asking you to pay the balance of the APC. This varies depending
              on whether you are eligible to a publishing grant or not.
            </p>
            {/* <p className="tagp ">
              <strong>Launch offer:</strong>
            </p>
            <p className="tagp mt-1">
              <span>1.</span> You will still need to pay the initial fee of Rs.
              1000, however we will refund this when the article if published (or
              rejected).
            </p>
            <p className="tagp">
              <span>2.</span> We are currently waiving the balance amount payable
              – please use the code <span className="blue-text"><u>Welcome2024</u></span>{" "}
              This is an offer that is currently available for a short time only.
              Hurry! Publish with us now.
            </p> */}
            <div className="ag_special_offer_btn customimpnotepublishnew justify-content-center">
              {!token && (
                <Link
                  to="javascript:;"
                  onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")}
                  className="ag_btn_red"
                >
                  Publish Article
                </Link>
              )}
              {token && (
                <Link
                onClick={() => isMobile ? setMobileAlert(true):setUploadArticle(true)}
                  className="ag_btn_red"
                >
                  Publish Article
                </Link>
              )}
            </div>
            <p className="mt-4 tagp">
              <span>3.</span> All other terms and conditions remain unchanged.
            </p>



            <div className="row  m-0 updating-article my-4">

              <h4
                className="customimpnoteind tagh4"
              >
                Foreign Nationals:
              </h4>
              <p className="tagp mt-0">
                <strong>Our normal publishing process:</strong>
              </p>
              <p className="tagp mt-1">
                <span className="onetimerefund">1.</span> A one-time refundable fee
                of US$50 is payable to access our publishing system. This amount is
                to register you on the platform and confirm that you are a human
                accessing the system. This ensures that no bots or unknown content
                gets into our system.
              </p>
              <p className="tagp">
                This amount will be fully refunded to your account’s Mudra Kosh
                (online wallet) if your article is rejected. You can use this amount
                to write a new article or re-submit your article.
              </p>
              <p className="tagp">
                <span>2.</span> On acceptance of your article, you will receive an
                email asking you to pay the balance of the APC.
              </p>
              {/* <p className="tagp ">
                <strong>Launch offer:</strong>
              </p>
              <p className="tagp mt-1">
                <span>1.</span> We are currently offering an opportunity to publish
                free of charge on our platform. However, you will still need to pay
                the initial fee of US$50. We will refund this to your account’s
                Mudra Kosh when the article is published <div className="d-inline-block">(or rejected)</div>.
              </p>
              <p className="tagp">
                <span>2.</span> We are currently waiving the balance amount payable
                – Please use the code{" "}
                <span className="blue-text"><u>Happiness2024</u></span> This is an offer
                that is currently available for a short time only. Hurry! Publish
                with us now.
              </p> */}
              <div
                className="ag_special_offer_btn customimpnotepublishnew justify-content-center"
              >
                {!token && (
                  <Link
                  to="javascript:;"
                  onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")}
                    className="ag_btn_red"
                  >
                    Publish Now
                  </Link>
                )}
                {token && (
                  <Link
                  onClick={() => isMobile ? setMobileAlert(true):setUploadArticle(true)}
                    className="ag_btn_red"
                  >
                    Publish Now
                  </Link>
                )}
              </div>
              <p className="mt-4 tagp">
                <span>3.</span> All other terms and conditions remain unchanged.
              </p>

            </div>
          </div>
        </div>
      </div>
      <SubmitArticleModal uploadArticle={uploadArticle} setUploadArticle={setUploadArticle} />
      <MobileModalAlert mobileAlert={mobileAlert} setMobileAlert={setMobileAlert} />
    </Content>
  );
};

export default ImportantNote;
