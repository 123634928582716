import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import Modal from "react-bootstrap/Modal";
import doneSvg from "../../../../assets/img/icons/done02.svg";
import backArrow from "../../../../assets/img/icons/blueArrow.svg";
import alertSearch from "../../../../assets/img/icons/alertSearch.svg";
import jwt_decode from "jwt-decode";
import DashboardLayout from "../../../../layout/dashboard";
import peEditorCommentTrinkaScript from "../../../../assets/js/Trinka-new-test";
import {
  acceptRejectArticle,
  getPRReport,
  getReportAfterFirstRevision,
  reviewArticle,
} from "../../../../store/apiCalls/dashboard";
import { FetchProfanityList } from "../../../autherFlow/submitArticle/queries";
import { toast } from "react-toastify";
import RejectForPR from "../../../autherFlow/prdashboard/RejectForPR";
import SendForRevision from "../../../autherFlow/prdashboard/SendForRevision";
import ReasonModal from "../../../autherFlow/prdashboard/ReasonModal";
import ContentDetails from "../../../../components/viewArticle/contentDetails";
import ConfirmationalModal from "../../../autherFlow/prdashboard/ConfirmationModal";
import { checkEmptyArticle, checkGrammar } from "../../../../utils";
import { useDispatch } from "react-redux";
import GrammarSpellModal from "../../../../components/Modal/GrammarSpellModal";

const ViewReport = () => {
  const dispatch = useDispatch();
  const { id } = jwt_decode(localStorage.usertoken);
  const peEditorCommentRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const _id = location?.state?._id;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showRevisionConfirmation, setShowRevisionConfirmation] =
    useState(false);
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
  const rejectionArticleId = location?.state?.rejectionArticleId;
  const title = location?.state?.title;
  const createdAt = location?.state?.createdAt;
  const updatedAt = location?.state?.updatedAt;
  const prData = location?.state?.prData;
  const status = location?.state?.status;
  const userType = location?.state?.userType;
  const reasonHandle = location?.state?.reasonHandle;
  const articleStatus = location?.state?.articleStatus;
  const articleFlowId = location?.state?._id;
  const sendForRevision = location?.state?.sendForRevision;
  const [showNotAccepted, setShowNotAccepted] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showWorkSaved, setShowWorkSaved] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [showRevision, setShowRevision] = useState(false);
  const [articleData, setArticleData] = useState({});
  const [articleReportData, setArticleReportData] = useState({});
  const [spellCheckModal, setSpellCheckModal] = useState(false);
  const [prReview, setPrReview] = useState({
    editorAssessment: "",
  });
  const [formErrors, setFormErrors] = useState({
    editorAssessment: "",
  });
  const [profanityWords, setProfanityWords] = useState([]);
  const [profanityWordsFound, setProfanityWordsFound] = useState([]);
  const [profanityAlert, setprofanityAlert] = useState({
    isOPen: false,
    type: 0,
  });
  const getArticleReport = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: _id,
    };
    const response = await getPRReport(headers, params);
    if (response?.status === 200) {
      setArticleReportData(response?.data?.data?.prReview);
      setPrReview({ editorAssessment: response?.data?.data?.prReview?.editorAssessment })
    } else {
      return toast.error("Please try again!!");
    }
  };

  const getReviewArticle = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      articleId: rejectionArticleId,
    };
    const response = await reviewArticle(headers, params);
    if (response?.status === 200) {
      setArticleData(response?.data?.data);
    } else {
      return toast.error("Please try again!!");
    }
  };
  
  const fetchReportAfterRevision = async (_id) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: articleFlowId,
    };
    const response = await getReportAfterFirstRevision(headers, params);
    if (response?.status === 200) {
      setArticleReportData(response?.data?.data?.[0]?.prReview);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleApprovePublish = async (status) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: _id,
      peStatus: status,
      peId: id,
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      setShowAccept(true);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};
    if (!prReview?.editorAssessment) {
      errors.editorAssessment = "This field is required";
      valid = false;
    }

    const commonDetl =
      prReview?.editorAssessment;
    let isProfinityWord = false;
    const profanityWordList = [];
    for (var i = 0; i < profanityWords?.length; i++) {
      if (isExactMatch(commonDetl?.toLowerCase(), profanityWords[i])) {
        isProfinityWord = true;
        profanityWordList?.push(profanityWords[i]);
      }
    }
    setProfanityWordsFound(profanityWordList);
    if (isProfinityWord === true) {
      valid = false;
      setprofanityAlert({ isOPen: true, type: 0 });
    }

    setFormErrors(errors);
    return valid;
  };

  const saveFeedback = async () => {
    let error = false;
    dispatch({ type: "SET_LOADER", payload: true });
    // const response = await checkGrammar(peEditorCommentRef.current?.value);
    dispatch({ type: "SET_LOADER", payload: false });
    // const result = response?.data?.url?.sentences;
    // result?.map((sentence) => {
    //   sentence.result?.forEach((item) => {
    //     if (
    //       (item?.cta_present?.[0] === true && (item?.error_category?.[0] === "Word/Phrase choice" && item?.error_category?.[0] === "Spellings & Typos" && item?.error_category?.[0] === "Verbs" && item?.error_category?.[0] === "Subject-verb agreement")) ||
    //       (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Word/Phrase choice") ||
    //       (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Spellings & Typos") ||
    //       (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Verbs") ||
    //       (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Subject-verb agreement")
    //     ) {
    //       error = true;
    //       setSpellCheckModal(true);
    //     } else {
    //       setSpellCheckModal(false);
    //     }
    //   });
    // });
    if (error === true) {
      setSpellCheckModal(true);
      return;
    } else {
      setSpellCheckModal(false);
    if (validateForm()) {
      const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
      const allParams = () => {
        const data = {
          id: _id,
          peComment: true,
          prReview: {},
        };
        if (prReview?.editorAssessment) {
          data.prReview.editorAssessment = peEditorCommentRef.current?.value;
        }
        if (articleReportData.existingLiterature) {
          data.prReview.existingLiterature = articleReportData.existingLiterature;
        }
        if (articleReportData.abstract) {
          data.prReview.abstract = articleReportData.abstract;
        }
        if (articleReportData.adequateReference) {
          data.prReview.adequateReference = articleReportData.adequateReference;
        }
        if (articleReportData.languageAcceptable) {
          data.prReview.languageAcceptable = articleReportData.languageAcceptable;
        }
        if (articleReportData.arguedAndStructured) {
          data.prReview.arguedAndStructured =
            articleReportData.arguedAndStructured;
        }
        if (articleReportData.majorChanges) {
          data.prReview.majorChanges = articleReportData.majorChanges;
        }
        if (articleReportData.strenghtsAndWeeknesses) {
          data.prReview.strenghtsAndWeeknesses =
            articleReportData.strenghtsAndWeeknesses;
        }
        if (articleReportData.recommendChanges) {
          data.prReview.recommendChanges = articleReportData.recommendChanges;
        }
        if (articleReportData.conflictOfIntrest) {
          data.prReview.conflictOfIntrest = articleReportData.conflictOfIntrest;
        }
        if (articleReportData.recommendation) {
          data.prReview.recommendation = articleReportData.recommendation;
        }
        if (articleReportData.confidentialCommentsToEditor) {
          data.prReview.confidentialCommentsToEditor =
            articleReportData.confidentialCommentsToEditor;
        }
        return data;
      };

      const response = await acceptRejectArticle(headers, allParams());
      if (response?.status === 200) {
        // setShowWorkSaved(true);
        toast.success("Report Saved successfully!");
        getArticleReport();
      } else {
        return toast.error("Please try again!!");
      }
    }
  }
  };

  const isExactMatch = (str, match) => {
    return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
  };
  const escapeRegExpMatch = function (s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  };

  const handleRedirect = (e, path = "/dashboard") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };

  useEffect(() => {
    const script = document.createElement("script");
      script.src = peEditorCommentTrinkaScript;
      script.id = 'peCommentTrinkaId'
      script.async = true;
      if (!document.getElementById('peCommentTrinkaId')) {
        document.body.appendChild(script);
      }
  }, [])

  useEffect(() => {
    if (articleStatus === "First Revision") {
      fetchReportAfterRevision();
      getReviewArticle();
    } else {
      getArticleReport();
      getReviewArticle();
    }
    getProfanity();
  }, []);

  const sucessHandlerProfanity = (profanity) => {
    if (profanity.status === 200) {
      setProfanityWords(profanity.data.data);
    }
  };
  async function getProfanity() {
    //
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      headers: headers,
    };

    getProfanityList(queryParams);
  }
  const { mutate: getProfanityList } = FetchProfanityList(
    sucessHandlerProfanity
  );
  return (
    <>
      <DashboardLayout hideSidebar={true}>
        <div className="pb-4">
          <div className="bg-white">
            <div className="viewHeader">
              <div className="reviewHead">
              <div className="innerMergin">
                <h3 className="title">ARTICLE</h3>
                {status === "approved" ||
                status === "rejected" ||
                status === "sentForRevision" ||
                status === "reviewed" ||
                articleStatus === "First Revision" ? (
                  <div>
                    <span
                      onClick={() => navigate(-1)}
                      className="button-link-gray"
                    >
                      CLOSE
                    </span>
                  </div>
                ) : status === "peerReview" || status === "secondReview" ? (
                  <div className="reviewHeadAction">
                    <span
                      onClick={() => navigate(-1)}
                      className="button-link-gray"
                    >
                      CLOSE
                    </span>
                    <span
                      className="button-link-gray"
                      onClick={() => {setShowConfirmation(true), saveFeedback();}}
                    >
                      Reject to Publish
                    </span>
                    {status === "secondReview"?null:(<>
                    {sendForRevision ? null : (
                      <span
                        className="button-link"
                        onClick={() => setShowRevisionConfirmation(true)}
                      >
                        Send for Revision
                      </span>
                    )}
                    </>)}

                    <button
                      className="button button-primary"
                      onClick={() => {
                        setShowAcceptConfirmation(true);
                        saveFeedback();
                      }}
                    >
                      Approve to Publish
                    </button>
                  </div>
                ) : null}
                </div>
              </div>
            </div>
            <div className="reportLayout">
              <div className="row">
                <div className="col-md-6 reportLayoutLeft reviewArticleReport">
                  
                  <div className="commanBox">
                    <ContentDetails
                      pagename="review-article"
                      details={{ title: "Title", desc: articleData?.title }}
                    />
                  </div>

                  <div className="commanBox">
                    <ContentDetails
                      pagename="review-article"
                      details={{
                        title: "Running Title",
                        desc: articleData?.runningTitle,
                      }}
                    />
                  </div>

                  <div className="commanBox">
                    <ContentDetails
                      pagename="review-article"
                      details={{
                        title: "Abstract",
                        desc: articleData?.abstract,
                      }}
                    />
                  </div>
                  {articleData?.articleData?.articleSection?.map(
                    (article) =>
                      checkEmptyArticle(article?.content) !== "" && (
                        <div className="commanBox">
                          <ContentDetails
                            pagename="review-article"
                            details={{
                              title: article?.title,
                              desc: article?.content,
                            }}
                          />
                        </div>
                      )
                  )}

                  {checkEmptyArticle(articleData?.articleData?.references) !==
                    "" && (
                    <div className="commanBox">
                      <ContentDetails
                        pagename="review-article"
                        details={{
                          title: "References",
                          desc: articleData?.articleData?.references,
                        }}
                      />
                    </div>
                  )}

                  {checkEmptyArticle(
                    articleData?.articleData?.acknowledgement
                  ) !== "" && (
                    <div className="commanBox">
                      <ContentDetails
                        pagename="review-article"
                        details={{
                          title: "Acknowledgements",
                          desc: articleData?.articleData?.acknowledgement,
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  {" "}
                  <div className="reportLayoutRight">
                    <h3 className="titlePree peerReportWrap">
                      peer Review report{" "}
                      <div className="group-button">
                        {userType === "Publishing Editor" &&
                        status === "peerReview" || status === "secondReview" ? (
                          <button
                            className="button-link mr-2"
                            onClick={saveFeedback}
                          >
                            SAVE
                          </button>
                        ) : null}
                      </div>
                      <span
                        className="closeBtn"
                        onClick={() => navigate(-1)}
                      ></span>
                    </h3>
                    <div className="addQuestionWrap">
                      {status === "secondReview"?(<>
                    <div className="editorCommaintWrap mt-3">
                    {userType === "Publishing Editor" &&
                            status === "peerReview" || status === "secondReview" ? (
                              <div className="editorCommaint" >
                                <div className="commanTitle02">
                                  Publishing Editor’s assessment <b className="red-text">*</b>
                                  <em className="text-black fw-medium">(Mandatory)</em>
                                </div>
                                {/* <GrammarlyEditorPlugin> */}
                                  <textarea
                                    className="form-control"
                                    onChange={(e) =>
                                      setPrReview({
                                        editorAssessment: e.target.value,
                                      })
                                    }
                                    value={
                                      prReview?.editorAssessment
                                    }
                                    ref={peEditorCommentRef}
                                  />
                                  <small className="articleFormError">
                                    {formErrors?.editorAssessment && `${formErrors?.editorAssessment}`}
                                  </small>
                                {/* </GrammarlyEditorPlugin> */}
                              </div>
                            ) : (userType === "Publishing Editor" ||
                                userType === "Author") &&
                              status !== "peerReview" ? (
                              <div className="editorCommaint">
                                <div className="commanTitle02">
                                  Publishing Editor’s assessment
                                </div>
                                <span>
                                  {articleReportData?.editorAssessment}
                                </span>
                              </div>
                            ) : null}
                            </div>
                            </>):null}
                      <div className="addQuestionTable">
                        <table>
                          <thead>
                            <tr>
                              <th className="pl-0">QUESTIONNAIRE2</th>
                              <th className="text-center">YES</th>
                              <th className="text-center">NO</th>
                              <th className="text-center">NOT APPLICABLE</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="pl-0">
                                <div className="questionTd">
                                  Does this article contribute to the existing
                                  literature on the subject?
                                </div>
                              </td>
                              <td className="text-center">
                                <label className="customRadio">
                                  <input
                                    type="radio"
                                    name="existingLiterature"
                                    checked={
                                      articleReportData?.existingLiterature ===
                                      "Yes"
                                    }
                                  />
                                  <b></b>
                                </label>
                              </td>
                              <td className="text-center">
                                <label className="customRadio">
                                  <input
                                    type="radio"
                                    name="existingLanguage"
                                    checked={
                                      articleReportData?.existingLiterature ===
                                      "No"
                                    }
                                  />
                                  <b></b>
                                </label>
                              </td>
                              <td className="text-center">
                                <label className="customRadio">
                                  <input
                                    type="radio"
                                    name="existingLanguage"
                                    checked={
                                      articleReportData?.existingLiterature ===
                                      "Not Applicable"
                                    }
                                  />
                                  <b></b>
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td className="pl-0">
                                <div className="questionTd">
                                  Does the Abstract (Summary) clearly and
                                  accurately describe the content of the
                                  article? 
                                </div>
                              </td>
                              <td className="text-center">
                                <label className="customRadio">
                                  <input
                                    type="radio"
                                    name="abstract"
                                    checked={
                                      articleReportData?.abstract === "Yes"
                                    }
                                  />
                                  <b></b>
                                </label>
                              </td>
                              <td className="text-center">
                                <label className="customRadio">
                                  <input
                                    type="radio"
                                    name="abstract"
                                    checked={
                                      articleReportData?.abstract === "No"
                                    }
                                  />
                                  <b></b>
                                </label>
                              </td>
                              <td className="text-center">
                                <label className="customRadio">
                                  <input
                                    type="radio"
                                    name="abstract"
                                    checked={
                                      articleReportData?.abstract ===
                                      "Not Applicable"
                                    }
                                  />
                                  <b></b>
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td className="pl-0">
                                <div className="questionTd">
                                  Is adequate reference made to other work in
                                  the field?
                                </div>
                              </td>
                              <td className="text-center">
                                <label className="customRadio">
                                  <input
                                    type="radio"
                                    name="adequateReference"
                                    checked={
                                      articleReportData?.adequateReference ===
                                      "Yes"
                                    }
                                  />
                                  <b></b>
                                </label>
                              </td>
                              <td className="text-center">
                                <label className="customRadio">
                                  <input
                                    type="radio"
                                    name="adequateReference"
                                    checked={
                                      articleReportData?.adequateReference ===
                                      "No"
                                    }
                                  />
                                  <b></b>
                                </label>
                              </td>
                              <td className="text-center">
                                <label className="customRadio">
                                  <input
                                    type="radio"
                                    name="adequateReference"
                                    checked={
                                      articleReportData?.adequateReference ===
                                      "Not Applicable"
                                    }
                                  />
                                  <b></b>
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td className="pl-0">
                                <div className="questionTd">
                                  Is the language acceptable?
                                </div>
                              </td>
                              <td className="text-center">
                                <label className="customRadio">
                                  <input
                                    type="radio"
                                    name="languageAcceptable"
                                    checked={
                                      articleReportData?.languageAcceptable ===
                                      "Yes"
                                    }
                                  />
                                  <b></b>
                                </label>
                              </td>
                              <td className="text-center">
                                <label className="customRadio">
                                  <input
                                    type="radio"
                                    name="languageAcceptable"
                                    checked={
                                      articleReportData?.languageAcceptable ===
                                      "No"
                                    }
                                  />
                                  <b></b>
                                </label>
                              </td>
                              <td className="text-center">
                                <label className="customRadio">
                                  <input
                                    type="radio"
                                    name="languageAcceptable"
                                    checked={
                                      articleReportData?.languageAcceptable ===
                                      "Not Applicable"
                                    }
                                  />
                                  <b></b>
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="commanTitle02">
                        Give your Feedback here:
                      </div>
                      <>
                          <div className="questionFeedBack">
                            <p>
                              Is the article well-argued and structured? Are the
                              conclusions supported by the argument and the
                              information presented here?
                            </p>
                            <span>
                              {articleReportData?.arguedAndStructured}
                            </span>
                          </div>
                          <div className="questionFeedBack">
                            <p>
                              What are the main strengths and weaknesses of the
                              article?
                            </p>
                            <span>
                              {articleReportData?.strenghtsAndWeeknesses}
                            </span>
                          </div>
                          <div className="questionFeedBack">
                            <p>
                              Does this article need major changes? If yes,
                              please list them below. 
                            </p>
                            <span>{articleReportData?.majorChanges}</span>
                          </div>
                          <div className="questionFeedBack">
                            <p>
                              Please state any conflict(s) of interest that you
                              have in relation to the review of this paper
                              (state “none” if this is not applicable).*
                            </p>
                            <span>{articleReportData?.conflictOfIntrest}</span>
                          </div>

                          <div className="commanTitle02">Recommendation</div>
                          <div className="recomArt">
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="recommendation"
                                checked={
                                  articleReportData?.recommendation ===
                                  "ACCEPT AND PUBLISH"
                                }
                              />
                              <b></b>
                              <span>ACCEPT AND PUBLISH</span>
                            </label>
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="recommendation"
                                checked={
                                  articleReportData?.recommendation ===
                                  "NEEDS MAJOR REVISION"
                                }
                              />
                              <b></b>
                              <span>NEEDS MAJOR REVISION</span>
                            </label>
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="recommendation"
                                checked={
                                  articleReportData?.recommendation ===
                                  "REJECT FOR PUBLISHING"
                                }
                              />
                              <b></b>
                              <span>REJECT FOR PUBLISHING</span>
                            </label>
                          </div>

                          <div className="editorCommaintWrap">
                            {userType === "Publishing Editor" ? (
                              <div className="editorCommaint">
                                <div className="commanTitle02">
                                  Confidential Comments to the publishing Editor
                                </div>
                                <span>
                                  {
                                    articleReportData?.confidentialCommentsToEditor
                                  }
                                </span>
                              </div>
                            ) : userType === "Peer Reviewer" ? (
                              <div className="editorCommaint">
                                <div className="commanTitle02">
                                  Confidential Comments to the editor
                                </div>
                                <span>
                                  {
                                    articleReportData?.confidentialCommentsToEditor
                                  }
                                </span>
                              </div>
                            ) : null}
                            
                            {status === "secondReview"?null:(<>
                            {userType === "Publishing Editor"  &&
                            status === "peerReview" ? (
                              <div className="editorCommaint" >
                                <div className="commanTitle02">
                                  Publishing Editor’s assessment <b className="red-text">*</b>
                                  <em className="text-black fw-medium">(Mandatory)</em>
                                </div>
                                {/* <GrammarlyEditorPlugin> */}
                                  <textarea
                                    className="form-control"
                                    onChange={(e) =>
                                      setPrReview({
                                        editorAssessment: e.target.value,
                                      })
                                    }
                                    value={
                                      prReview?.editorAssessment
                                    }
                                    ref={peEditorCommentRef}
                                  />
                                  <small className="articleFormError">
                                    {formErrors?.editorAssessment && `${formErrors?.editorAssessment}`}
                                  </small>
                                {/* </GrammarlyEditorPlugin> */}
                              </div>
                            ) : (userType === "Publishing Editor" ||
                                userType === "Author") &&
                              status !== "peerReview" ? (
                              <div className="editorCommaint">
                                <div className="commanTitle02">
                                  Publishing Editor’s assessment
                                </div>
                                <span>
                                  {articleReportData?.editorAssessment}
                                </span>
                              </div>
                            ) : null}
                              
                            </>)}
                          </div>
                        {/* </Grammarly> */}
                      </>
                      <div className="d-flex justify-content-end">
                      {userType === "Publishing Editor" &&
                        status === "peerReview" || status === "secondReview" ? (
                          <button
                            className="button-link mr-3"
                            onClick={saveFeedback}
                          >
                            SAVE
                          </button>
                        ) : null}
                    <span
                      onClick={() => navigate(-1)}
                      className="button-link-gray"
                    >
                      CLOSE
                    </span>

                       </div>
                       
                    </div>
                    
                  </div>
                  <div style={{right:"-45px",top:"-30px"}} className="position-relative">
                {status === "approved" ||
                status === "rejected" ||
                status === "sentForRevision" ||
                status === "reviewed" ||
                articleStatus === "First Revision" ? (
                  <>
                  </>
                ) : status === "peerReview" || status === "secondReview" ? (
                  <div className="reviewHeadAction d-flex align-items-center gap-4 text-uppercase justify-content-end">
                   
                    <span
                      className="button-link-gray"
                      onClick={() => {setShowConfirmation(true), saveFeedback();}}
                    >
                      Reject to Publish
                    </span>
                    {status === "secondReview"?null:(<>
                    {sendForRevision ? null : (
                      <span
                        className="button-link cursor-pointer"
                        onClick={() => setShowRevisionConfirmation(true)}
                      >
                        Send for Revision
                      </span>
                    )}
                    </>)}

                    <button
                      className="button button-primary"
                      onClick={() => {
                        setShowAcceptConfirmation(true);
                        saveFeedback();
                      }}
                    >
                      Approve to Publish
                    </button>
                  </div>
                ) : null}
                </div>
                </div>
              </div>
              <div className="backBtnWrap mt-5">
                <a onClick={() => window.history.back()}>
                  <span>Back</span>
                  <img src={backArrow} alt="#" />
                </a>
              </div>
            </div>
            
          </div>
          
        </div>
       
      </DashboardLayout>
      <RejectForPR
        showReject={showReject}
        setShowReject={setShowReject}
        articleId={_id}
        modalTitle={"REJECT_PUBLISH"}
      />
      <ConfirmationalModal
        setShowReject={setShowReject}
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        title={"Confirmation"}
        body={`<div class="text-center allGoodText"><p>Are you sure you want to reject this article to publish?</div></p>`}
        leftBtnTxt={"CONFIRM"}
        rightBtnTxt={"NO, GO BACK"}
      />
      <SendForRevision
        showRevision={showRevision}
        setShowRevision={setShowRevision}
        articleId={_id}
      />
      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showRevisionConfirmation}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header">
          <h2 class="modal-title">CONFIRMATION</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowRevisionConfirmation(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAss">
            <p className="allGoodText text-center">
              Are you sure you want to send this article for revision?
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-gray bold mr-3"
            onClick={() => {
              setShowRevisionConfirmation(false);
              setShowRevision(true);
              saveFeedback();
            }}
          >
            CONFIRM
          </span>
          <button
            class="button button-primary"
            onClick={() => setShowRevisionConfirmation(false)}
          >
            NO, GO BACK
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showAcceptConfirmation}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header">
          <h2 class="modal-title">Good to know</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowAcceptConfirmation(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAss text-center">
            <p className="allGoodText">
              Are you sure you want to approve this article to publish?
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-gray bold mr-3"
            onClick={() => setShowAcceptConfirmation(false)}
          >
            CANCEL
          </span>
          <button
            class="button button-primary"
            onClick={() => {
              handleApprovePublish("approved");
              setShowAcceptConfirmation(false);
              setShowAccept(true);
            }}
          >
            CONFIRM
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogClassName="modal-dialog-centered modal-sm authorModal"
        show={showAccept}
        id="congratsMobile"
        tabIndex={-1}
      >
        <Confetti width={"450px"} height={"350px"} numberOfPieces={100} />
        <Modal.Header className="modal-header">
          <div class="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h2 class="modal-title">Thank You</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => navigate("/dashboard/publishing-editor")}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAss pl-2 pr-2">
            <p className="mb-3">
              We appreciate your contribution
              <br />
              to scholarly research.
            </p>
            <p>
              The author will be notified that their article is approved for
              publication.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-active mr-3"
            onClick={() => navigate("/dashboard/publishing-editor")}
          >
            MY DASHBOARD
          </span>
          <button
            class="button button-primary"
            onClick={() => {
              setShowAccept(false);
              navigate("/dashboard/publishing-editor");
            }}
          >
            OKAY
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogClassName="modal-dialog-centered modal-sm authorModal"
        show={showWorkSaved}
        id="congratsMobile"
        tabIndex={-1}
      >
        <Confetti width={"450px"} height={"350px"} numberOfPieces={100} />
        <Modal.Header className="modal-header">
          <div class="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h2 class="modal-title pl-0 pr-0">YOUR WORK HAS BEEN SAVED</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowWorkSaved(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAssContent">
            <p>
              Find this report in the{" "}
              <Link to="/publishing-editor/peerReview" state={"peerReview"}>
                <u>Peer Review</u>
              </Link>{" "}
              <br />
              section of your dashboard.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-active mr-3"
            onClick={(e) => handleRedirect(e, "/dashboard/publishing-editor")}
          >
            MY DASHBOARD
          </span>
          <button
            class="button button-primary"
            onClick={(e) => {
              setShowWorkSaved(false);
            }}
          >
            Continue
          </button>
        </Modal.Footer>
      </Modal>

      <ReasonModal
        showNotAccepted={showNotAccepted}
        setShowNotAccepted={setShowNotAccepted}
        reasonHandle={reasonHandle}
      />
      {/* Profanity Alert */}
      <Modal
        dialogclassName=""
        show={profanityAlert.isOPen}
        id="congratsMobile"
        tabIndex={-1}
        className={
          profanityAlert.type === 0
            ? "authorModal md-x"
            : "authorModal rightSide profanity-content-right"
        }
        centered
      >
        
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            <img src={alertSearch} alt="#" />
          </div>
          <h5 className="modal-title">PROFANITY check Results</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={(e) => setprofanityAlert({ isOPen: false, type: 0 })}
          ></button>
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0 pl-2 pr-2">
          <div className="grantDetails alertPopup">
            <div className="checkResultText">
              <strong>
                Thank you for sharing your assessment for this article.{" "}
              </strong>
              <p>
                Before your assessment is processed, please make sure that the
                following words are removed :
              </p>
            </div>

            <div className="offensiveBox">
              <ol>
                {profanityWordsFound.map((wrd, index) => (
                  <li>{wrd}</li>
                ))}
              </ol>
            </div>
            <div className="mt-4 mb-3">
              {profanityAlert.type === 0 && (
                <button
                  className="button button-primary"
                  type="button"
                  onClick={(e) => setprofanityAlert({ isOPen: false, type: 0 })}
                >
                  make Amendments
                </button>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="pb-0">
          <div className="helpPopupText pb-3">
            Need help? Write to us at
            <a href="mailto:contactus@vikramshilaedu.in">
              contactus@vikramshilaedu.in
            </a>
          </div>
        </Modal.Footer>
      </Modal>

      <GrammarSpellModal
        spellCheckModal={spellCheckModal}
        setSpellCheckModal={setSpellCheckModal}
        mainContent={"Thank you for sharing your Publishing Editor comment for submission."}
        upperContent={"We have noticed that there are still some grammar and language issues that need your attention."}
        middleContent={"You will find them highlighted in the Publishing Editor comment for correction."}
        lowerContent={"Before your Publishing Editor comment is processed, please make sure that the highlighted issues are fixed."}
      />
    </>
  );
};

export default ViewReport;
