import React from 'react'
import SubmitArticleSociety from '../../../components/common/submitArticleSociety'
import DashboardLayout from '../../../layout/dashboard'

const EditArticleSociety = () => {
  return (
    <DashboardLayout>
      <SubmitArticleSociety />
    </DashboardLayout>
  )
}

export default EditArticleSociety