import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { Pagination } from "semantic-ui-react";
import ArticleListing from "../../../components/dashboard/ArticleListing";
import "./index.scss";
import downarrow from "../../../assets/img/icons/downarrow.svg";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../layout/dashboard";
import {
    getArticleDetails,
    getSearchUsers,
    getPEDetails,
    getSEArticleList,
    sendPrInvitation,
    sendpaymentByPass,
    appointPRRole,
} from "../../../store/apiCalls/dashboard";
import {
    updatePayment,
    iniitatePayments,
  } from "../../autherFlow/submitArticle/queries";
  import alertSvg from "../../../assets/img/icons/alert_info.svg";
  import doneSvg from "../../../assets/img/icons/done02.svg";
  import { createNewArticleVersion, getAPCValues } from "../../../store/apiCalls/articles";
import PEListing from "./PEListing";
// E:\React JS\ag-front\src\components\common\Select.js
import Select from "../../../components/common/Select";
import { getAllMasterData } from "../../../store/apiCalls/fetchmaster";
import { useSelector, useDispatch } from "react-redux";
import { SERVER_URL } from "../../../config";
import RecommendedArticles from "../dashboard/RecommendedArticles";

const SEArticleList = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state?.LoginReducer);
    const [show, setShow] = useState(false);
    const masterData = useSelector((state) => state.MasterDataReducer.MasterData);
    const handleClose = () => setShow(false);
    const [updateRequestList, setUpdateRequestList] = useState(false);
    const [RejectPaymentPop, setrejectPaymentPop] = useState(false);
    const [ConfirmPaymentPop, setconfirmPaymentPop] = useState(false);
    const [amendmentUpdateTrigger, setAmendmentUpdateTrigger] = useState(false);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [addPeerReviewerPop, setaddPeerReviewerPop] = useState(false);
    const { id } = jwt_decode(localStorage.usertoken);
    const [activeTab, setActiveTab] = useState("draft");
    const location = useLocation();
    const [latestPage, setLatestPage] = useState(1);
    const [latestArticlesTotalPages, setlatestArticlesTotalPages] = useState(1);
    const [peActiveTab, setPEActiveTab] = useState("draft");
    const [paymentFailedPopup, setpaymentFailedPopup] = useState({ status: false, txnId: '', card_type: '' });
    const [paymentSuccessPopup, setpaymentSuccessPopup] = useState({ status: false, txnId: '', card_type: '', pymtType: '' });
    const [peData, setPEData] = useState([]);
    const [totalPEData, setTotalPEData] = useState([]);
    const [peerreviewlist, setpeerreviewlist] = useState([
        {
            name: "",
            email: "",
            university: "",
            subject: "",
        },
    ]);
    const [articleData, setArticleData] = useState([]);
    const [search, setSearch] = useState();
    const [searchResult, setSearchResult] = useState([]);
    // console.log(searchResult,"searchResult");
    const [Subjectmaster, setSubjectmaster] = useState([]);

    const [articleTotalData, setArticleTotalData] = useState([]); 
    const [page, setPage] = useState(1);
    const limit = 10;
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const articleId = location?.state?._id

    const [collapseActive, setIsActive] = useState(false);
    const expandClick = (event) => {
        setIsActive((current) => !current);
    }; 
    const sucessHandlerUpdatePayment = (data) => {
        // console.log(data,'sucessHandlerUpdatePayment')
    } 
    const { mutate: updatePaymentStatus } = updatePayment(sucessHandlerUpdatePayment);
    const paymentTypeLabels = {
        intPay: "Initial",
        finalPay: "Final",
        totalPay: "Total",
      };
    
    function updatePaymentData(response, access_key) {
        const paymentPopup = paymentFailedPopup;
        const successPopup = paymentSuccessPopup;
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        let payStatus = false;
    
    
        // Determine the type of payment
        let initialPayment = false;
        let finalPayment = false;
        let totalPayment = false;
    
        if (response.status === 'success') {
            payStatus = true;
            
            successPopup.txnId = response.txnid;
            successPopup.card_type = response.card_type;
            successPopup.pymtType = response.udf2;
            successPopup.status = true;
            setpaymentSuccessPopup({ ...successPopup });
            
            if (response.udf2 === "intPay") {
                initialPayment = true;
            } else if (response.udf2 === "finalPay") {
                finalPayment = true;
            }else if (response.udf2 === "totalPay") {
                totalPayment = true;
            }
        }
    
        const payloads = {
            "strike": true,
            "currencyType": 'INR',
            "paymentMode": response.card_type,
            "txnid": response.txnid,
            "initialPayment": initialPayment,  // Set based on response.udf2
            "finalPayment": finalPayment,      // Set based on response.udf2
            "totalPayment": totalPayment,      // Set based on response.udf2
            "articleId": response.udf1,
            "data": access_key,
            "mode": response.mode,
            "status": response.status,
            "gst": ((18 / 100) * response.amount).toFixed(2),
            "payableAmount": response.net_amount_debit,
            "lastPrice": response.net_amount_debit,
            "typeOfPayment": response.net_amount_debit
        };
    
        const queryParams = {
            payload: payloads,
            headers,
        };
    
        
    
        updatePaymentStatus(queryParams);
        paymentPopup.txnId = response.txnid;
        paymentPopup.card_type = response.card_type;
        if (response.status !== 'success') {
            paymentPopup.status = true;
        }
        setpaymentFailedPopup({ ...paymentPopup });
    }
    
    
    const sucessHandlerPayment = (info) => {

        if (info.status === 200) {
          dispatch({ type: "SET_LOADER", payload: true });
          const script = document.createElement("script");
          script.src = process.env.REACT_APP_PAYMENT_SCRIPT;
          script.id = 'paymentScript'
          script.async = true;
          if (!document.getElementById('paymentScript'))
          document.body.appendChild(script);
          setTimeout(function () {
            dispatch({ type: "SET_LOADER", payload: false });
            var easebuzzCheckout = new EasebuzzCheckout(process.env.REACT_APP_PAYMENT_KEY, process.env.REACT_APP_PAYMENT_MODE)
            var options = {
              access_key: info.data.data, // access key received via Initiate Payment
              onResponse: (response) => {
              
               updatePaymentData(response, info.data.data)
              },
              theme: "#336d91" // color hex
            }
            easebuzzCheckout.initiatePayment(options);
        }, 1000);
        }
        
      };
    const { mutate: iniitatePayment } = iniitatePayments(sucessHandlerPayment);


    const handleAPCData = async (reviewType) => { 
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` }; 
        const params = {
          typeOfApc: "withInIndia",
        };
        const response = await getAPCValues(headers, params);
        return response?.data?.data.filter(item => item.reviewType === reviewType);
    }
    
        
    async function paymentInitiation(articleID, payment_mode, reviewType)  { 

        let getApc = await handleAPCData(reviewType);
        getApc = getApc?.[0];

        const { id } = jwt_decode(localStorage.usertoken);
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const redairectTo = `${SERVER_URL}/dashboard/edit-article/${articleID}`; 
        let amnt="1.00";
        const gst = 1.18; // 18% GST means multiplying by 1.18
        if(payment_mode==="intPay"){
            amnt = (1000 * gst).toFixed(2).toString(); // Multiply and format to 2 decimal places
        }else if(payment_mode==="finalPay"){
            amnt = ((getApc?.introductoryPrice-1000) * gst).toFixed(2).toString();
        }else if(payment_mode==="totalPay"){
            amnt = ((getApc?.introductoryPrice) * gst).toFixed(2).toString();
        }else{

        }
       
        const payloads = { "key": process.env.REACT_APP_PAYMENT_KEY, "amount": amnt, "productinfo": "Create Article Fee", "firstname": user.fullName, "phone": user.phoneNumber, "email": user.email, "surl": `${redairectTo}`, "furl": `${redairectTo}?type=failed`, "salt": process.env.REACT_APP_PAYMENT_SALT, "udf1": articleID, "udf2": payment_mode, "udf3": "", "udf4": "", "udf5": "", "udf6": "", "udf7": "", "udf8": "", "udf9": "", "udf10": "", "userId": id, "articleId": articleID }
        var formBody = [];
        for (var property in payloads) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(payloads[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
    
        const queryParams = {
          payload: formBody,
          headers,
        };
        
        iniitatePayment(queryParams);
      }
    async function getMasterList() {
        const msterlst = await getAllMasterData();
    
        if (msterlst.status === 200) {
            const subjectLists = [];
            const subjectSpecialization = [];
            msterlst.data.subjectdata.forEach((element) => {
              const data = { key: element._id, value: element.subjectName };
              subjectLists.push(data);
              subjectSpecialization[element._id] = element.specializationId;
            });
      
            setSubjectmaster(subjectLists);
            // setSubjectmaster(msterlst.data.specializationdata);
            // console.log("==>",subjectLists);
        //   setspecializationList(msterlst.data.specializationdata);
        //   const journalMaster = [];
        //   const journalType = [];
        //   msterlst.data.jouranlData.forEach((element) => {
        //     const data = { key: element._id, value: element.journalType };
        //     journalType.push(data);
        //     journalMaster[element._id] = element.journalName;
        //   });
    
        //   setgernalMaster(journalMaster);
        //   setgernalType(journalType);
        //   setdesciplineMaster(msterlst.data.disciplineData);
        
      }
    }

    const fetchPRInvitation = async (tab) => {
        dispatch({ type: "SET_LOADER", payload: true });
        const params = {
            userId: id,
            // journalName: "Minorities in World Politics ",
            page: page,
            status: "paymentRequest" ,
            limit,
        };
        const response = await getSEArticleList(headers, params);
        // console.warn(response)
        if (response?.status === 200) {
            dispatch({ type: "SET_LOADER", payload: false });
            setSearchResult(response?.data?.data);
            // setTotalPEData(response?.data?.data); 
        } else {
            dispatch({ type: "SET_LOADER", payload: true });
            return toast.error("Please try again");
        }
    };
    const fetchPublishingEditor = async (tab) => {
        dispatch({ type: "SET_LOADER", payload: true });
        const params = {
            userId: id,
            page: page,
            limit,
            peStatus: tab,
        };
        const response = await getPEDetails(headers, params);
        if (response?.status === 200) {
            dispatch({ type: "SET_LOADER", payload: false });
            setPEData(response?.data?.data?.data);
            setTotalPEData(response?.data?.data);
        } else {
            dispatch({ type: "SET_LOADER", payload: true });
            return toast.error("Please try again");
        }
    };

    const getPEType = (type) => {
        fetchPublishingEditor(type);
        setPEActiveTab(type);
    };
    /*serach by name  */

    const searchByName = async (search) => {
        const activate = true;
        let data = { page, limit, search, activate };
        const response = await getSearchUsers(headers, data);
        setSearchResult(response?.data?.data?.data);
        // console.log(searchResult);
    };

    const fetchArticleData = async (tab) => {
        dispatch({ type: "SET_LOADER", payload: true });
        const params = {
            userId: id,
            status: tab,
            page: page,
            limit: limit,
        };
        const response = await getArticleDetails(headers, params);
        if (response?.status === 200) {
            dispatch({ type: "SET_LOADER", payload: false });
            setArticleData(response?.data?.data?.article);
            setArticleTotalData(response?.data?.data);
        } else {
            dispatch({ type: "SET_LOADER", payload: false });
            return toast.error("Please try again");
        }
    };

    const getArticleType = (type) => {
        fetchArticleData(type); 
        setActiveTab(type);
    };
    async function sentReminder(dataid) {
        // const articleId = window.location.pathname.split("/")[5];
        const data = {
            status: "reminder",
            userId: id,
            id: dataid,
        };
        const response = await sendPrInvitation(headers, data);
        // console.log(response);
        if (response?.status === 201) {
            toast.success("Successfully Sent!!"); 
        } else {
            toast.error("Please try again!!"); 
        }
    }
    async function sentPrInvitation() {
        // const articleId = window.location.pathname.split("/")[5];
        const data = {
            peerreviewlist: peerreviewlist,
            userId: id,
        };
        const response = await sendPrInvitation(headers, data);
        // console.log(response);
        if (response?.status === 201 || response?.status === 200) {
            setaddPeerReviewerPop(false);
        } else {
            toast.error("Please try again!!"); 
        }
    }
    async function paymentByPass(dataid,method) {
        // const articleId = window.location.pathname.split("/")[5];
        const data = {
            // peerreviewlist: peerreviewlist,
            userId: id,
            id: dataid,
            byPass:method
        };
        const response = await sendpaymentByPass(headers, data);
        // console.log(response);
        if (response?.status === 201 || response?.status === 200) {
            setaddPeerReviewerPop(false);
        } else {
            toast.error("Please try again!!"); 
        }
    }
    const invitePEhandleUpdate = (index, updatedFields) => {
        setpeerreviewlist(prevList => {
            const newList = [...prevList];
            newList[index] = { ...newList[index], ...updatedFields };
            return newList;
        });
    };
      // Handle adding a new peer review
      const handleAdd = () => {
        setpeerreviewlist(prevList => [
            ...prevList,
            { name: "", email: "", university: "", subject: "" }
        ]);
    };

    // Handle removing a peer review
    const handleRemove = (index) => {
        setpeerreviewlist(prevList => {
            const newList = [...prevList]; 
            newList.splice(index, 1);
            return newList;
        });
    };
    const formatDate = (dateString) => {
        if (!dateString) return ''; // Handle null or undefined date
        return new Date(dateString).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        });
      };
    // console.log(peerreviewlist)
    const typeOfArticle = (data) => {
        const article = masterData?.typeOfArticledata?.find(article => article?._id === data);
        return article ? article?.articleName : 'ID not found';
      };

    useEffect(() => {
        fetchPRInvitation()
        getMasterList()
    },[]);
    useEffect(() => {
        if (updateTrigger) {
            fetchArticleData("published");
        } else if (amendmentUpdateTrigger) {
            fetchArticleData("published")
        } else {
            fetchArticleData("draft");
        }
        localStorage.setItem("getPEType", "");
        fetchPublishingEditor("draft");
        setIsActive(true);
    }, [page, updateTrigger, amendmentUpdateTrigger, updateRequestList]);
    const profileAs = localStorage.getItem("dropdownToggleVal");

    return (
        <>
            <DashboardLayout>
                <>
                    <div className="d-flex justify-content-between">
                        <p className="draftTitle" onClick={paymentInitiation}> 
                           Payment Requests
                            <span>
                                {/* <b>Please type the name of the desired Peer Reviewer in the search bar below to assign the article to them.</b> */}
                            </span>
                        </p>
                        {/* <span onClick={() => setaddPeerReviewerPop(true)} className="addpeerreviews-btn cursor-pointer">Add Peer Reviewers Here <span className="icon">+</span></span> */}
                    </div>
                    <div>
                        <input placeholder="Search by Name and Email Id"
                            name="search"
                            className="searchpr"
                            style={{ minWidth: "590px", minHeight: "50px" }}
                            value={search}
                            onChange={(event) => [
                                setSearch(event.target.value),
                                searchByName(event.target.value),
                            ]} />
                    </div>
                    <div className="mt-4">
                        <table className="table tableadmin">
                            <thead>
                                <tr>
                                    <th>Author</th>
                                    <th>Status</th>
                                    <th>Request Received</th>
                                    {/* <th>Nationality</th> */}
                                    <th></th>
                                    {/* <th></th> */}
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                               {/* {searchResult?.map((list) => ( */}
                                 {searchResult && searchResult.length > 0 && (
                                    <>
                                      {searchResult.map((item, ind) => (
                                        <>
                                          <tr className="empty"> 
                                            <td colspan="5"></td>
                                          </tr>
                                          <tr>
                                            <td class="firstDataBoxTd">
                                              <div class="firstDataBox">
                                                {/* <span class="t-type mr-0">{item?.status}</span> */}
                                                <div class="draftArticleWrap">
                                                  <div class="authorName mb-3"><b>{item?.user?.[0]?.title} {item?.user?.[0]?.fullName}</b></div>
                                                  {/* <div class="authorAff">test, tesing, New Delhi, Delhi</div> */}
                                                  <h2><Link 
                                                  to={`/dashboard/article/${item?._id}/review-article`}
                                                  state={{
                                                    _id: item?.articleId,
                                                    particularId: item?._id,
                                                    createdAt: item?.createdAt,
                                                    updatedAt: item?.updatedAt,
                                                    peData: item?.peData,
                                                    typeOfReview:
                                                    item?.reviewType?.typeOfReview,
                                                    // status: peActiveTab,
                                                    // articleStatus: "First Revision",
                                                    sendForRevision:
                                                    item?.sendForRevision,
                                                  }}
                                                  
                                                  >{item?.title}</Link></h2>
                                                </div>
                    
                                              </div>
                                            </td>
                                            {/* <td>{typeOfArticle(item?.submissionDetail?.articleType)}</td> */}
                                            <td>Payment Requested by Author <br /> <span className="theme-orange-color fw-bold">Submission Journey Paused</span></td>
                                            {/* <td>{typeOfArticle(item?.submissionDetail?.articleType)}</td> */}
                                            <td>{formatDate(item?.updatedAt)}</td>
                                            <td>
                                                <Link onClick={(e)=> setconfirmPaymentPop(true)} className="button button-primary small-btn-ds" >Confirm Payment </Link>
                                                <br/>
                                                <Link style={{marginLeft:"6px"}} onClick={(e)=> setrejectPaymentPop(true)} class="button button-primary small-btn-ds mt-2" href="#">Reject Payment</Link>
                                            </td>
                                            {/* <td>20</td> */}
                                            <td class="pe-accepted-td">
                                              <div class="vertical-footAction">
                                                <div className="customDrop sideDrop dw-auto">
                                                  <Dropdown>
                                                    <Dropdown.Toggle className="button button-primary w-auto bg-theme-orange" variant="success">
                                                      Action
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                      <Dropdown.Item
                                                        // onClick={(e) => paymentByPass(item?._id,"initial")}
                                                        onClick={(e) =>  paymentInitiation(item?._id,"intPay",item?.reviewType?.typeOfReview)}
                                                        className={item?.initialPayment?`opacity5 dropdown-item m-0`:`dropdown-item m-0 `}
                                                      >
                                                        Pay Initial Payment
                                                      </Dropdown.Item>
                                                      <Dropdown.Item
                                                        as={Link}
                                                        // to="/dashboard/society-editor/pr-listing"
                                                        // onClick={(e) => paymentByPass(item?._id,"final")}
                                                        onClick={(e) =>  paymentInitiation(item?._id,"finalPay",item?.reviewType?.typeOfReview)}
                                                        className={item?.finalPayment?`opacity5 dropdown-item m-0`:`dropdown-item m-0 `}
                                                      >
                                                        Pay Final Payment
                                                      </Dropdown.Item>
                                                      <Dropdown.Item
                                                        as={Link}
                                                        // to="/dashboard/society-editor/pr-listing"
                                                        onClick={(e) =>  paymentInitiation(item?._id,"totalPay",item?.reviewType?.typeOfReview)}
                                                        className={item?.initialPayment?`opacity5 dropdown-item m-0`:`dropdown-item m-0 `}
                                                      >
                                                        Make Full Payment
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                    
                                                </div>
                                                <div></div>
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      ))}
                                    </>
                                  )}
                                    {/* <tr>
                                        <td><span class="text-link">Deepansh sagar</span></td>
                                        <td>Invitation Sent</td>
                                        <td><span>deepansh.techsaga@gmail.com</span><br /><span>8457512568</span></td>
                                        <td>Within India</td>
                                        <td>22 Apr 2024</td>
                                        <td><span class="text-link">Send Reminder</span></td>
                                        <td><span class="text-link">Delete</span></td>
                                    </tr> */}

                            </tbody>
                        </table>
                        <Pagination
                                                        boundaryRange={1}
                                                        firstItem={null}
                                                        lastItem={null}
                                                        siblingRange={1}
                                                        activePage={latestPage}
                                                        prevItem={{ content: <span><b class="icon-arrow-left pe-2" />Previous</span> }}
                                                        nextItem={{ content: <span>Next<b class="icon-arrow-right ps-2" /></span> }}
                                                        onPageChange={(event, data) => setLatestPage(data.activePage)}
                                                        totalPages={latestArticlesTotalPages}
                                                    />
                    </div>
                </>
                <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="modal-dialog-centered modal-lg authorModal"
                >
                    <Modal.Header className="modal-header">
                        <h5 className="modal-title">Grant Details</h5>
                        <button
                            type="button"
                            className="btn-close"
                            ariaLabel="Close"
                            onClick={(e) => handleOTPClose(e)}
                        />
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div className="grantDetails">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="infotext">
                                        <b>Grant Name</b>
                                        <span className="textGreen">
                                            Vikramshila Grant for Authors
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="infotext">
                                        <b>Available for</b>
                                        <span className="textGreen">
                                            Vikramshila Grant for Authors
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="infotext">
                                        <b>URL of Grant available for </b>
                                        <span className="textBlue">
                                            Urban climate change management and society
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="infotext">
                                        <b>Description</b>
                                        <span className="textGray">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                            sed do eiusmod tempor incididunt ut labore et dolore magna
                                            aliqua. Ut enim ad minim veniam, quis labore et dolore
                                            magna aliqua labore et dolore magna...
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="infotext">
                                        <b>Eligibility</b>
                                        <ul className="listText">
                                            <li>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                            </li>
                                            <li>
                                                Sed do eiusmod tempor incididunt ut labore et dolore
                                                magna aliqua
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="infotext">
                                        <b>Know More</b>
                                        <ul className="knowList">
                                            <li>
                                                <p>
                                                    <i>Grant Received On</i>
                                                    <small>12 May 2023</small>
                                                </p>
                                                <p>
                                                    <i>Valid Till</i>
                                                    <small>25 Aug 2023</small>
                                                </p>
                                            </li>

                                            <li>
                                                <p>
                                                    <i>Amount Received</i>
                                                    <small>INR 7000</small>
                                                </p>
                                                <p>
                                                    <i>Amount Remaining</i>
                                                    <small>INR 6500</small>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <i>Transaction ID</i>
                                                    <small>826475844855</small>
                                                </p>
                                                <p>
                                                    <i>Invoice</i>
                                                    <div className="downloadBOx">
                                                        <a href="" download></a>
                                                        <strong>Download</strong>
                                                    </div>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="pb-4">
                        <button
                            class="button button-primary"
                            type="submit"
                            onClick={handleClose}
                        >
                            CLOSE
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={addPeerReviewerPop}
                    onHide={handleClose}
                    dialogClassName="modal-dialog-centered modal-lg authorModal"
                >
                    <Modal.Header className="modal-header">
                        <h5 className="modal-title">Add Peer Reviewers</h5>
                        <button
                            type="button"
                            className="btn-close"
                            ariaLabel="Close"
                            onClick={(e) => setaddPeerReviewerPop(false)}
                        />
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div className="peerreview">
                            {peerreviewlist.map((val, ind) => ( 
                                <div className="" key={ind}>
                                    <div className="d-flex justify-content-between px-3">
                                        <p className="theme-orange-color text-start fs-6 fw-bold mb-3">Peer Reviewer {ind+1}</p>
                                        <p onClick={(e) => handleRemove(ind)} className="theme-blue-color text-start pe-1 fs-6 fw-bold mb-3">Remove</p>
                                    </div>
                                    <div className="row mb-3 px-3">
                                        <div className="col-6">
                                            <label className="labelForm text-black p-0">Name</label>
                                            <input className="form-control" value={val.name} type="text"  onChange={(e) => invitePEhandleUpdate(ind, { name: e.target.value })} placeholder="Full Name" />
                                        </div>
                                        <div className="col-6">
                                            <label className="labelForm text-black p-0">Email ID</label>
                                            <input className="form-control" value={val.email} onChange={(e) => invitePEhandleUpdate(ind, { email: e.target.value })} type="text" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="row mb-4 px-3">
                                        <div className="col-6">
                                            <label className="labelForm text-black p-0">Affiliated Institution/University</label>
                                            <input className="form-control" value={val.university} onChange={(e) => invitePEhandleUpdate(ind, { university: e.target.value })} type="text" placeholder="Affiliated Institution/University" />
                                        </div>
                                        <div className="col-6">
                                            <label className="labelForm text-black p-0" >Subject</label>
                                            <input className="form-control" value={val.subject} onChange={(e) => invitePEhandleUpdate(ind, { subject: e.target.value })} type="text" placeholder="Subject" />
                                            <div className="selectBox">
                                 {/* <Select
                                    // selectedValue={
                                    //   reviewerDetails?.specialization[index].val
                                    // }
                                    options={Subjectmaster.specializationList?.map(
                                      (val) => val?.specialization
                                    )}
                                    optionValues={Subjectmaster.specializationList?.map(
                                      (val) => val?._id
                                    )}
                                    // name={`specialization${index}`}
                                    placeholder="Search and Select"
                                    // handleChange={(e) =>
                                    //   handleSelectChangesReviewer(
                                    //     e,
                                    //     index,
                                    //     "specialization"
                                    //   )
                                    // }
                                    required={true}
                                    mandatory={false}
                                    isSearchable={true}
                                    
                                  /> */}
                                  
                                </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            ))}
                            <a className="d-block text-start fw-bold" onClick={(e) =>{handleAdd()}}>+ Add More Peer Reviewer</a>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="pb-4">
                        <button
                            class="button button-primary"
                            type="submit"
                            onClick={sentPrInvitation}
                        >
                           Submit
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
        size="md"
        show={paymentFailedPopup.status}
        id="congratsMobile"
        tabIndex={-1}
        className=" authorModal authorModalRed"
        centered
      >
        
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={alertSvg} alt="#" />
          </div>
          <h5 className="modal-title mb-0">payment failed</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setpaymentFailedPopup({ status: false, txnId: '' })}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="congratulationsPopup">
            <p className="text-center">Your transaction has failed. Please try again</p>
            {paymentFailedPopup.txnId !== '' && <div className="transactionWrap">
              <b>Transaction ID:</b><span>{paymentFailedPopup.txnId}</span>
            </div>}
            <div className="text-center" >
              <button
                className="button button-primary redBtn"
                onClick={() => setpaymentFailedPopup({ status: false, txnId: '' })}
              >
                try again
              </button>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="pb-3">


          <div className="helpPopupText mt-4">
            Need help? Write to us at<a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        size="md"
        show={paymentSuccessPopup.status}
        // show={true}
        id="congratsMobile"
        tabIndex={-1}
        className=" authorModal "
        centered
      >
        
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h5 className="modal-title mb-0">{paymentTypeLabels[paymentSuccessPopup?.pymtType] || null} Payment Successful.</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setpaymentSuccessPopup({ status: false, txnId: '' })}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="congratulationsPopup">
            <p className="text-center">Payment is received towards <br /> registration of the article.</p>
            {paymentSuccessPopup.txnId !== '' && <div className="transactionWrap">
              <b>Transaction ID:</b><span>{paymentSuccessPopup.txnId}</span>
            </div>}
            <div className="text-center" >
              <button
                className="button button-primary "
                onClick={() => setpaymentSuccessPopup({ status: false, txnId: '' })}
              >
                Download Invoice
              </button>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="pb-3">


          <div className="helpPopupText mt-4">
            Need help? Write to us at<a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        size="md"
        show={RejectPaymentPop}
        id="congratsMobile"
        tabIndex={-1}
        className=" authorModal "
        centered
      >
        
        <Modal.Header className="modal-header">
          {/* <div className="modal-icon">
            {" "}
            <img src={alertSvg} alt="#" />
          </div> */}
          <h5 className="modal-title mb-0">Reject the Payment</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setrejectPaymentPop(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="congratulationsPopup">
            <p className="text-center">By rejecting this payment you are confirming <br/>that this author will not write an article in the journal, which is paid by the Society.</p>
          
            <div className="text-center" >
              <button
                className="button "
                onClick={() => setrejectPaymentPop(false)}
              >
               No, Go Back
              </button>
              <button
                className="button button-primary "
                onClick={() => setrejectPaymentPop(false)}
              >
               Yes, Reject
              </button>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="pb-3">


          <div className="helpPopupText ">
            Need help? Write to us at<a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        size="md"
        show={ConfirmPaymentPop}
        id="congratsMobile"
        tabIndex={-1}
        className=" authorModal "
        centered
      >
        
        <Modal.Header className="modal-header">
          {/* <div className="modal-icon">
            {" "}
            <img src={alertSvg} alt="#" />
          </div> */}
          <h5 className="modal-title mb-0">Confirm Payment</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setconfirmPaymentPop(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="congratulationsPopup">
            <p className="text-center">By clicking on this button, you are confirming <br/>that the APC for the article proposed by <br/>this author will be paid by the society.</p>
            <p className="text-center">Please click the action button in the <br/> dashboard for the next steps.</p>
            <div className="text-center" >
              <button
                className="button "
                onClick={() => setconfirmPaymentPop(false)}
              >
               No, Go Back
              </button>
              <button
                className="button button-primary "
                onClick={() => setconfirmPaymentPop(false)}
              >
               Yes, Confirm
              </button>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="pb-3">


          <div className="helpPopupText ">
            Need help? Write to us at<a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a>
          </div>
        </Modal.Footer>
      </Modal>
            </DashboardLayout>

        </>
    );
};
export default SEArticleList;
