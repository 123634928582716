import { useState } from "react";
import { CONTENT_HEADING } from "../../constants";
import SubmitArticleModal from "../Modal/SubmitArticleModal";
import Content from "../global/Content";
import { useNavigate, Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import MobileModalAlert from "../Modal/MobileModalAlert";

const UnderstandingApc = () => {
  const [uploadArticle, setUploadArticle] = useState(false);
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const [mobileAlert, setMobileAlert] = useState(false);
  const token = localStorage.getItem("usertoken");
  return (
    <>
      <div className="understandWrap">
        <div className="understandWrapContent">
          <p className="pera-text para tagp">
            In the world of academic publishing, Article Processing Charges (APC)
            play a crucial role in ensuring that scholarly research gets published
            and disseminated to a wider audience. APC is a fee charged to authors
            or their affiliated institutions to cover the costs associated with
            the publication process of an article in an open-access or
            subscription-based journal.
          </p>

          <p className="pera-text-att tagp">
            At Aakashganga, we follow the{" "}
            <span className="underline">
              <Link to="/open-access" target="_blank"><strong>Gold Open Access Model of Publishing,</strong></Link>
            </span>{" "}
            and our Article Processing Charges (APCs) are one of the most
            affordable in the world.
          </p>

          <p className="pera-text part tagp">
            As part of our social responsibility, we are committed to advancing
            knowledge and promoting global research collaboration. As a
            researcher, if you value platforms with a broader mission, we are your
            perfect partners.
          </p>
        </div>
      {/* <div className="offerGrab">
        <div className="row justify-content-between align-items-center mt-5 article-launch-offer">
          <div className="ag_special_offer_img col-lg-12 col-12">
            <p
              className="customunderstabingapc tagp"
            >
              <img className="tagimg" src="assets/images/subtraction.svg" alt="Bookmark Icon" /> Grab The
              Special Launch Offer
            </p>
            <div className="ag_special_offer_text shadowOrange m-0">
              <div className="ag_heading_center_wrap">
                <h2 className="tagh2">
                  We are offering a one-time opportunity to<br/>                   
                    publish on the platform free of cost<br/>                  
                  for the next two months
                </h2>
                <span className="publisharticle">
                  Click here to publish your article now.{" "}
                </span>
              </div>
              <div className="ag_special_offer_btn">
                {!token && (
                  <Link to="javascript:;"
                    onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")}
                    className="ag_btn_red"
                  >
                    Publish Article
                  </Link>
                )}
                {token && (
                  <Link
                  onClick={() => {isMobile ? setMobileAlert(true):setUploadArticle(true); }}
                    className="ag_btn_red"
                  >
                    Publish Article
                  </Link>
                )}
              </div>
            </div>
          </div>
          <SubmitArticleModal uploadArticle={uploadArticle} setUploadArticle={setUploadArticle} />
          <MobileModalAlert mobileAlert={mobileAlert} setMobileAlert={setMobileAlert} />
        </div>
        </div> */}
      </div>
    </>
  );
};

export default UnderstandingApc;
