
import React, { useState, useEffect } from 'react';
import pdf from "../../assets/img/icons/pdficon.svg";
import cite from "../../assets/img/icons/citeicon.svg";
import Versions from "../../assets/img/icons/versionicon.svg";
import msg from "../../assets/img/icons/msgicon.svg";
import shareIcon from "../../assets/img/icons/shareicon.svg";
import add from "../../assets/img/icons/addicon.svg";
import copylink from "../../assets/img/icons/copylink.svg";
import linkedin_share from "../../assets/img/icons/linkedin_share.svg";
import twitter_share from "../../assets/img/icons/twitter_share.svg";
import facebook_share from "../../assets/img/icons/facebook_share.svg";
import mendeley from "../../assets/img/icons/mendeley.svg";
import copyIcon from "../../assets/img/icons/copy.svg";
import alertSvg from "../../assets/img/icons/alert_info.svg";
import Modal from "react-bootstrap/Modal";
import { addArticleToReadingList, addShareAndDownloadCount, removeFromReadingList,getAllArticleVersion } from '../../store/apiCalls/dashboard';
import { Link, useLocation, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import  copy  from "copy-to-clipboard";
import tags_active from "../../assets/img/icons/tags_active.svg";
import logo from "../../assets/img/logos/aakashganga_full_logo.png";
import adds from "../../assets/img/icons/adsport.svg";
import { downLoadFile } from '../../utils';
import { downloadArticlePdf } from '../../containers/autherFlow/submitArticle/queries';


import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";
import { ModalBody } from 'react-bootstrap';
import { SERVER_URL } from '../../config';


const ArticleLinks = ({ pagename, details, journalName, setUpdateTrigger }) => {
  const masterData = useSelector((state) => state?.MasterDataReducer?.MasterData);
  const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
  const contentWithoutSpecialChars = () => {
    return details?.abstract?.replace(
      specialCharPattern,
      ""
    );
  };
  // console.log("details",details);
  const router = useLocation();
  // const articleID = router.pathname.split("/")[2];
  const articleID = details.id;
  const SHARE_URL = `${SERVER_URL}/viewPublishedArticle/${articleID}`;
  const userDetails = useSelector((state) => state?.LoginReducer?.user);
  const [showDeleted, setShowDeleted] = useState(false);
  const [showPdfFailed, setShowPdfFailed] = useState(false);
  const dispatch = useDispatch();
  const [historyResult, setHistoryResult] = useState(false);
  const [allArticleVersion, setAllArticleVersion] = useState([]);
  const navigate = useNavigate();

  const addArticleToReadingLists = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      articleId: articleID,
      userId: userDetails?._id,
    };
    const response = await addArticleToReadingList(headers, params);
    if (response?.status === 200) {
      setUpdateTrigger(true)

    } else {
      return toast.error("Please try again!!");
    }
  };
  const addDownloadCount = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: articleID,
      download: true,
    };
    const response = await addShareAndDownloadCount(headers, params);
    if (response?.status === 200) {
    } else {
      return toast.error("Please try again!!");
    }
  };
  const addShareCount = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: articleID,
      share: true,
    };
    const response = await addShareAndDownloadCount(headers, params);
    if (response?.status === 200) {
    } else {
      return toast.error("Please try again!!");
    }
  };
  const getArticleVersions = async () => {
    const params = {
      id: articleID,
    };
    const response = await getAllArticleVersion({}, params);
    if (response?.status === 200) {
      setAllArticleVersion(response?.data?.data)
    } else {
      return toast.error("Please try again!!");
    }
  };

  const getDateFormat = (date) => {
    let newDate = new Date(date);
    let getDate = newDate.getDate();
    let getYear = newDate.getFullYear();
    let getMonth = newDate.toLocaleString("en-US", { month: "short" });
    return getDate + " " + getMonth + " " + getYear;
  };

  const copyToClipboard = (code) => {
    copy(code);
    return toast.success("Copied");
  }
  const handleRemoveReadingList = async () => {
    dispatch({ type: "SET_LOADER", payload: true });
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: details.readinglistId,
      isDeleted: true
    };
    const response = await removeFromReadingList(headers, params);
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setUpdateTrigger(false)
      setShowDeleted(false);

    } else {
      dispatch({ type: "SET_LOADER", payload: false });
      return toast.error("Please try again");
    }
  };
 
  const opnCiteMdl = () => {
    setHistoryResult(true)
};
const sucessHandlerDownLoadPdf = (resp) => {
  if (resp.status === 200) {
      const url = resp?.data?.data
      let filename=details?.title;
      downLoadFile(url, `${filename}.pdf`);
      addDownloadCount()
      window.open(`${url}`, '_blank')
  }
}

function downLoadPdf() {
  const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  const payloads = {"articleId": articleID }
  const queryParams = {
    payload: payloads,
    headers,
  };
  articlePdfDownload(queryParams);
}

const { mutate: articlePdfDownload } = downloadArticlePdf(sucessHandlerDownLoadPdf);

const isLoggedIn = useSelector(
  (state) => state?.LoginReducer?.userLoginDetails?.token ?? null
);
function handleScrollReview(e)
{
    const element = document.getElementById(`viewScroll${e}`);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
   
}
const handleRedirect = (e, path) => {
  if (e !== "") e.preventDefault();
  window.scroll({ top: 0, behavior: "smooth" });
  navigate(path);
};
const redirectPblshdArticle = (articleId) => {
  handleRedirect("", `/viewPublishedArticle/${articleId}`);
  setUpdateTrigger(true);
  setAllArticleVersion([])
};

const handleMendeleyClick=()=>{
  copy(SHARE_URL);
  window.open('https://www.mendeley.com/reference-manager/library/all-references', '_blank')
  addShareCount();
}

const manageJournalName = (journalName) => {
  return journalName?.replace(/\s+/g, '-');
}
const handleReadingItemClick = () => {
  if (isLoggedIn) {
    if (details.readingFlag) {
      setShowDeleted(true);
    } else {
      addArticleToReadingLists();
    }
  } else {
    handleRedirect("", "/login");
  }
};

useEffect(() => {
  getArticleVersions();
},[articleID] );
  return (
    <>

      <div className={pagename == 'viewPublishedArticle' ? "publisActionBtn" : "publisActionBtn opacity-5"} >
        <ul>
          <li onClick={() => {
              downLoadPdf()
            }} 
            className={details?.doiUrl  ? '': 'opacity-5'}
          >
            <img src={pdf} alt="#" /> <i>PDF</i>
          </li>
          <li onClick={opnCiteMdl}>
            <img src={cite} alt="#" /> <i>cite article</i>
          </li>
          <li>
            <img src={Versions} alt="#" /> <i>Versions</i>
            <div className='publisNav'>
              {
                  allArticleVersion?.map((version , index)=>{
                    return(
                      <>
                      <a onClick={() =>redirectPblshdArticle(version._id)}>Version {index+1}</a>
                      </>
                    )
                  })
              }
            </div>
          </li>
          <li  onClick={(e)=>{isLoggedIn?handleScrollReview('Reviews'):handleRedirect("", `/login`)}}>
            <img src={msg} alt="#" /> <i>Open Peer Review</i>
          </li>
          <li>
            <img src={shareIcon} alt="#" /> <i>Share</i>
            <div className='publisNav'>
              <a onClick={handleMendeleyClick}><b><img src={mendeley} alt="#" /></b>Mendeley</a>
              <TwitterShareButton
                url={SHARE_URL}
                title={details?.title}
                hashtag={"vikramshila"}
                onClick={addShareCount}>
                <a>
                  <b>
                    <img src={twitter_share} alt="#" />
                  </b>
                  Twitter
                </a>
              </TwitterShareButton>
              <FacebookShareButton
                url={SHARE_URL}
                title={details?.title}
                hashtags={"vikramshila"}
                onClick={addShareCount}>
                <a>
                  <b>
                    <img src={facebook_share} alt="#" />
                  </b>
                  Facebook
                </a>
              </FacebookShareButton>
              <LinkedinShareButton url={SHARE_URL} title={details?.title} onClick={addShareCount}>
                <a>
                  <b>
                    <img src={linkedin_share} alt="#" />
                  </b>
                  LinkedIn
                </a>
              </LinkedinShareButton>
              <a onClick={() => copyToClipboard(SHARE_URL)}>
                <b>
                  <img src={copylink} alt="#" />
                </b>
                Copy Link
              </a>
            </div>
          </li>
          <li  onClick={handleReadingItemClick}>
            <img src={details.readingFlag == true ? tags_active : add} alt="#" /> <i>Add to Reading List</i>
          </li>
        </ul>
      </div>

      <div className="">
        {
          journalName === "Samaj Shastra – The Mega Journal of Social Sciences" ? (
            <Link to={`/mega-journals/social-sciences`} className="button button-primary">Journal homepage</Link>
          ) : journalName === "Chanakya Shastra – The Mega Journal of Business and Management" ? (
            <Link to={`/mega-journals/business-management`} className="button button-primary">Journal homepage</Link>
          ) : journalName === "Charakh Shastra – The Mega Journal of Medicine" ? (
            <Link to={`/mega-journals/medicine`} className="button button-primary">Journal homepage</Link>
          ) : journalName === "Soochna  Shastra – The Mega Journal of Information Technology" ? (
            <Link to={`/mega-journals/information-technology`} className="button button-primary">Journal homepage</Link>
          ) : journalName === "Vigyan Shastra – The Mega Journal of Sciences" ? (
            <Link to={`/mega-journals/sciences`} className="button button-primary">Journal homepage</Link>
          ) : journalName === "Abhiyantran Shastra – The Mega Journal of Engineering" ? (
            <Link to={`/mega-journals/engineering`} className="button button-primary">Journal homepage</Link>
          ) : journalName === "AyurYoga – The Mega Journal of Ayurveda and Yoga" ? (
            <Link to={`/mega-journals/ayurveda-and-yoga`} className="button button-primary">Journal homepage</Link>
          ) : null
        }
      </div>

      <Modal
        dialogClassName="modal-dialog-centered modal-sm authorModal"
        show={showDeleted}
        id="congratsMobile"
        tabIndex={-1}
      >
        <Modal.Header className="modal-header">
          <div class="modal-icon"> </div>
          <h2 class="modal-title">
          CONFIRMATION
          </h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowDeleted(false)}
          ></button>
        </Modal.Header>
        <ModalBody className='pt-0'>
        <div class="allGoodText01"><p>Are you sure you want to remove this article from the reading list?</p></div>
        </ModalBody>

        <Modal.Footer className="modal-footer">
          <button
            class="button button-primary w-50"
            onClick={() => handleRemoveReadingList()}
          >
            YES, REMOVE
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
            show={historyResult}
            size="md md-x"
            id="unicheck-results__modal"
            tabIndex={-1}
            dialogClassName="modal-dialog-centered authorModal"
            className="md-x"
        >
            <Modal.Header>
                <h5 className="modal-title pr-color">how to cite this article</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={(e) => setHistoryResult(false)}
                    aria-label="Close"
                />
            </Modal.Header>
            <Modal.Body className="pt-0 pb-2">
                <div className="citeModalWrap">
                    <div className="citeText">[{details?.authorDetails?.author?.[0]?.fullName} et al; {details?.title}[{details?.articleVersion}]; ({details?.doiUrl})]</div>

                    <div className="questionBox">
                        <span className='colorBlack'>It is important that the entire text is used (including the DOI).</span>
                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer className="modal-footer pb-4">
                <button
                    className="button-link"
                    onClick={() => copyToClipboard(details?.authorDetails?.author?.[0]?.fullName + " et al;" + details?.title +  "[" + details?.articleVersion+"];" + "(" + details?.doiUrl + ")" )}
                >
                    <img src={copyIcon} alt="#" /> Copy Citation Details
                </button>
            </Modal.Footer>
        </Modal>

        <Modal
          dialogClassName="modal-dialog-centered modal-sm authorModal"
          show={showPdfFailed}
          id="congratsMobile"
          backdrop="static"
          tabIndex={-1}
          centered
        >
          <Modal.Header className="modal-header">
            <div className="modal-icon">
              {" "}
              <img src={alertSvg} alt="#" />
            </div>
            <h5 className="modal-title">
              PDF DOWNLOAD FAILED
            </h5>
            <button
              type="button"
              className="btn-close"
              ariaLabel="Close"
              onClick={() => setShowPdfFailed(false)}
            ></button>
          </Modal.Header>
          <Modal.Body className="pt-0 pb-0">
            <div className="congratulationsPopup">
              <p>
                The pdf of this article cannot be downloaded because the DOI has not yet been approved.
              </p>
              <div className="text-center mb-4">
                <button
                  className="button button-primary redBtn"
                  onClick={() => {
                    setShowPdfFailed(false);
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    </>
  );
};
export default ArticleLinks