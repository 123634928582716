import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import uploadArticleImg from "../../assets/img/icons/write_your_article.svg";

const SubmitArticleModal = ({ uploadArticle, setUploadArticle }) => {
  const navigate = useNavigate();
  const activePath = window.location.pathname;
  const handleRedirect = (e, path) => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };

  return (
    <div>
      <Modal
        show={uploadArticle}
        size="lg"
        id="unicheck-results__modal"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered authorModal"
      >
      <Modal.Header className="modal-header pb-0">
        <h5 className="modal-title uploadTitle pb-0">
          <b className="font36">Write Your Article</b>
          <span>Rigorous, Transparent and Speedy Publishing</span>
        </h5>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={(e) => setUploadArticle(false)}
        />
      </Modal.Header>
      <Modal.Body className="pb-0 pt-0">
        <div className="grantDetails pl-2 pr-2 mb-3">
          <figure className="writeYourImg mt-4 mb-0 ">
            <img src={uploadArticleImg} alt="#" />
          </figure>
          <div className="writeYourContent pb-0">
            <p className="mb-0">
              <i className="yellowTextWelcome">Welcome. </i>
              {activePath.includes("edit-article") === true
                ? "You have a submission in preparation. Do you want to continue from where you left?"
                : "You are about to begin a new submission. You will be able to complete it at any time from 'My Articles' in your dashboard."}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="pb-5">
        <div className="clearfix">
          <button
            className="button button-primary ml-3"
            type="button"
            onClick={(e) => {
              setUploadArticle(false);
              activePath.includes("edit-article") === false
                ? handleRedirect(e, "/dashboard/submit-article")
                : "";
            }}
          >
            {activePath.includes("edit-article") === true
              ? "Continue"
              : "start new submission"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
    </div>
   
  );
};

export default SubmitArticleModal;
