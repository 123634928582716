import React, { useState,useRef } from "react";
import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { validatePhoneNumber } from "../../utils";
import { BASE_URL } from "../../config";
const JoinPublishingCommunityForm = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const formRef = useRef();
  const initialValues = {
    fullName: "",
    emailId: "",
    mobile: "",
    university: "",
    designation: "",
    message: "",
  };
  const setParams = (values) => {
    const params = {
      fullName: values.fullName,
      email: values.emailId,
      phoneNumber: values.mobile,
      university: values.university,
      designation: values.designation,
      message: values.message,
    };
    return params;
  };
  const validate = Yup.object({
    fullName: Yup.string().required("*Full Name is required"),
    emailId: Yup.string()
      .email("*Email is invalid")
      .required("*Email is required"),
    mobile: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Mobile Number is Invalid"
      )
      .min(10, "Mobile Number is Invalid")
      .required("*Mobile Number is required"),
    university: Yup.string().required(
      "Name of University/Institute/Society is required"
    ),
    designation: Yup.string().required("Designation is required"),
    message: Yup.string().required("Message is required"),
  });
  const contactUsData = async (values) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const response = await axios.post(
      `${BASE_URL}AakashGanga/contactUsAkashganga`,
      setParams(values)
    );
    dispatch({ type: "SET_LOADER", payload: false });
    if (response?.status === 200) {
      setShow(true);
    } else {
      toast.error("Please try again!!");
    }
  };
  
  return (
    
    <div className="row my-4 p-4 align-items-center main-form-btm customjoinpublishcomm">
      <div className="col-lg-6 p-0">
        <div className="nation-wrp">
          <div className="nation-img">
            <img src="/assets/images/sell-form.png" className="tagimg" alt="Sell-Form" />
          </div>
        </div>
      </div>
      <div className="col-lg-6 bottom-form-col ml-5">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          innerRef={formRef}
          validationSchema={validate}
          onSubmit={(values, { resetForm }) => {
            contactUsData(values);
            resetForm();
          }}
        >
          {(props) => (
            <Form>
              <div className="form-row">
                <div className="col">
                  <label className="taglabel">
                    Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control taginput"
                    placeholder="Your answer"
                    onChange={(event) =>
                      props.setFieldValue("fullName", event.target.value)
                    }
                    name="fullName"
                    value={props.values.fullName}
                  />
                  {console.log(props?.errors?.fullName)}
                  {props?.errors?.fullName && props?.touched.fullName ? (
                    <small><div className="error-field errorStyle" >{props?.errors?.fullName}</div></small>
                  ) : null}
                </div>
                <div className="col">
                  <label className="taglabel">
                    Email<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control taginput"
                    placeholder="Your answer"
                    onChange={(event) =>
                      props.setFieldValue("emailId", event.target.value)
                    }
                    name="emailId"
                    value={props.values.emailId}
                  />
                  {props?.errors?.emailId && props?.touched.emailId ? (
                      <small><div className="error-field errorStyle">
                        {props?.errors?.emailId}
                      </div></small>
                    ) : null}
                </div>
                <div className="col">
                  <label className="taglabel">
                    Phone Number<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control taginput"
                    placeholder="Your answer"
                    onChange={(event) =>
                      props.setFieldValue(
                        "mobile",
                        validatePhoneNumber(event.target.value)
                      )
                    }
                    name="mobile"
                    value={props.values.mobile}
                    maxLength={10}
                  />
                   {props?.errors?.mobile && props?.touched.mobile ? (
                         <small> <div className="error-field errorStyle" >
                            {props?.errors?.mobile}
                          </div></small>
                        ) : null}
                </div>
                <div className="col">
                  <label className="taglabel">
                    Name of University/Institute/Society
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control taginput"
                    placeholder="Your answer"
                    onChange={(event) =>
                      props.setFieldValue("university", event.target.value)
                    }
                    name="university"
                    value={props.values.university}
                  />
                  {props?.errors?.university && props?.touched.university ? (
                    <small><div className="error-field errorStyle">
                      {props?.errors?.university}
                    </div></small>
                  ) : null}
                </div>
                <div className="col">
                  <label className="taglabel">
                    Designation<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control taginput"
                    placeholder="Your answer"
                    onChange={(event) =>
                      props.setFieldValue("designation", event.target.value)
                    }
                    name="designation"
                    value={props.values.designation}
                  />
                  {props?.errors?.designation && props?.touched.designation ? (
                    <small><div className="error-field errorStyle">
                      {props?.errors?.designation}
                    </div></small>
                  ) : null}
                </div>
                <div className="col">
                  <label className="taglabel">
                    Your Message<span>*</span>
                  </label>
                  <textarea
                    type="textares"
                    className="form-control customjoinyourmsg tagtextarea"
                    placeholder="Write your message"
                    onChange={(event) =>
                      props.setFieldValue("message", event.target.value)
                    }
                    name="message"
                    value={props.values.message}
                 />
                 {props?.errors?.message && props?.touched.message ? (
                  <small><div className="error-field newsStyle">
                    {props?.errors?.message}
                  </div></small>
                ) : null}
                </div>
                <div
                  className="ag_special_offer_btn customjoinwritemsg"
                >
                   <button
                type="submit"
                className="ag_btn_red customjoinconnectnow"
              >
                CONNECT NOW
              </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="md-x"
      >
        <Modal.Header
          className="justify-content-center closeButtonStyle"
          closeButton
        >
          <Modal.Title className="modal-title">
            <p className="good-news mb-3">Your message is on its way to us.</p>
            <p className="good-news styleModalTitle" >
              We will get back to you within two working days.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="pt-3 pb-3 contactModalFooter">
          <button
            className="button button-fill button-sm"
            onClick={handleClose}
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>
    </div>
    
  );
};

export default JoinPublishingCommunityForm;
