import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Pagination } from "semantic-ui-react";
import SocietyJournalImg from "../assets/assets_html/images/society homepage.png";
import pg01 from "../assets/assets_html/images/perv_arrow.png";
import pg02 from "../assets/assets_html/images/next_arrow.png";
import NewsLetter from "../components/global/NewsLetter";
import JournalMainBanner from "../components/journals/JournalMainBanner";
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import YoutubeIcon from "../assets/assets_html/images/Youtube-Icon.svg";
import {
  getJournalDetailById,
  getPEForJournalHomePage,
} from "../store/apiCalls/dashboard";
import { getJournalSubjects } from "../store/apiCalls/articles";
import SubmitArticleModal from "../components/Modal/SubmitArticleModal";
import Accordion from 'react-bootstrap/Accordion';
import { toast } from "react-toastify";

const EditorialBoard = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const journalName = location?.state?.journalName;
  const journalId = location?.state?.journalId;
  const token = localStorage.getItem("usertoken");
  const { id } = params;
  const navigate = useNavigate();
  const limit = 10;
  const [page, setPage] = useState(1);
  const [activeType, setActiveType] = useState("description");
  const societyJournal = location?.state?.societyJournal;
  const [specializationOptions, setSpecializationOptions] = useState([]);
  const [publishEditorData, setPublishEditorData] = useState([]);
  // const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [uploadArticle, setUploadArticle] = useState(false);
  let totalPages = Math.ceil(publishEditorData?.totalCount / limit);
  const handleScrollTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const [journalData, setJournalData] = useState([]);
  const [filteredData, setFilteredData] = useState(specializationOptions);

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = specializationOptions.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );
    const specSearch = [...filtered];
    setFilteredData(specSearch);
  };

  // const toggleSearchDropdown = () => {
  //   setShowSearchDropdown(!showSearchDropdown);
  // };

  const getJournalDetail = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      journalId,
    };
    const response = await getJournalDetailById(headers, params);
    if (response?.status === 200) {
      
      setJournalData(response?.data?.data);
      //getAllSubject(response?.data?.data?.journalName);
    } else return;
  };

  // const getAllSubject = async (decId) => {
  //   const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  //   const queryParams = {
  //     params: { journalName: decId },
  //     headers: headers,
  //   };
  //   const response = await getJournalSubjects(queryParams);
  //   if (response?.status === 200) {
  //     let newRecord = response?.data?.data?.specilisation;
  //     if (decId !== "AyurYoga – The Mega Journal of Ayurveda and Yoga") {
  //       let specOpt = newRecord
  //         ?.filter(
  //           (spec) =>
  //             spec?.specialization !==
  //             "Other Subject (choose this option to register your subject)"
  //         )
  //         .map((spec) => spec?.specialization);
  //       setSpecializationOptions(specOpt);
  //       setFilteredData(specOpt);
  //     } else {
  //       let specOpt0 = newRecord?.[0]
  //         ?.filter(
  //           (spec) =>
  //             spec?.specialization !==
  //             "Other Subject (choose this option to register your subject)"
  //         )
  //         .map((spec) => spec?.specialization);

  //       let specOpt1 = newRecord?.[1]
  //         ?.filter(
  //           (spec) =>
  //             spec?.specialization !==
  //             "Other Subject (choose this option to register your subject)"
  //         )
  //         .map((spec) => spec?.specialization);
  //       const children = specOpt0.concat(specOpt1);
  //       setSpecializationOptions(children);
  //       setFilteredData(children);
  //     }
  //   } else {
  //     return toast.error("Please try again!!");
  //   }
  // };

  const getPublishingEditorForJournal = async () => {
    dispatch({ type: "SET_LOADER", payload: true });
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      journal: journalName,
      page: 1,
      limit,
    };
    const response = await getPEForJournalHomePage(headers, params);
    dispatch({ type: "SET_LOADER", payload: false });
    if (response?.status === 200) {
      setPublishEditorData(response?.data?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: true });
      return toast.error("Please try again");
    }
  };

  const handlePageChange = (data) => {
    return setPage(data?.activePage);
  };

  const userDetails = (user) => {
    const info = {
      __html: user?.title + " " + `<b>${user?.fullName}</b>` + ", " + user?.affiliation?.affiliations?.[0]?.name
    }
    return info;
  }

  useEffect(() => {
    if (journalId) {
      getJournalDetail();
    } return;
  }, [journalId]);

  useEffect(() => {
    if (journalName) {
      getPublishingEditorForJournal();
    } return;
  }, [journalName, page]);

  return (
    <>
      <div className="tagbody">
        <AakashgangaHeader />
        <JournalMainBanner
          imgUrl={
            societyJournal === "Society Journal"
              ? SocietyJournalImg
              : journalData?.masthead
          }
        />
        <div className="menu-center-part">
          <div className="journalShadow">
            <div className="ag_menu justify">
              <ul className="ag_menu ag_menuSub justify tagul">
                <li className="active tagli custom-journal">
                  <Link
                    to="/open-access"
                    onClick={(e) => handleRedirect(e, "/open-access")}
                    className="custom-journal taga"
                  >
                    OPEN SCIENCE
                  </Link>
                </li>

                <li>
                  <a href="javascript:;" class="custom-journal taga">
                    E-ISSN{" "}
                    <span className="colr">
                      {" "}
                      {""}
                      {societyJournal === "Society Journal"
                        ? "XXXX-XXXX"
                        : journalData?.issnNumber}
                    </span>
                  </a>
                </li>
                <li>
                  <a href="javascript:;" class="custom-journal taga">
                    ABBREVATION{" "}
                    <span className="colr">
                      {" "}
                      {""}
                      {societyJournal === "Society Journal"
                        ? "XXXXX"
                        : journalData?.abbreviation}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="editorContainer">
            <div className="editorPage">
              <span className="editorHead editorialHead">Editorial Board</span>
              <div className="journalBackWrap ">
                <span>Society Homepage</span>
                {!token ? (
                  <Link
                    to="/login"
                    onClick={(e) => {
                      e.preventDefault();
                      handleRedirect(e, "/login")
                    }}
                    className="ag_btn_red ag_btn_redclr"
                  >
                    Submit Article
                  </Link>
                ) : (
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setUploadArticle(true)
                    }}
                    className="ag_btn_red ag_btn_redclr"
                  >
                    Submit Article
                  </Link>
                )}
              </div>
            </div>

            <div className="maindiv bg journals-main-div p-0 editorialPage">
              <div className="div30  ">
                <div className="journalslist custom-journalist-class">
                  <div className=" align-items-center">
                    <div className="ag_heading_center_wrap our-partner mt-0 info-custom">
                      <h2 className="tagh2">Information</h2>
                    </div>
                  </div>
                  <ul className="journalInfoList">
                    <li class="custom-li-journal tagli">
                      <Link
                        to="javascript:;"
                        className={activeType === "description" ? "active" : null}
                        onClick={() => setActiveType("description")}
                      >
                        Description
                      </Link>
                    </li>
                    <li class="custom-li-journal tagli">
                      <Link
                        to={"/access-articles"}
                        className={
                          activeType === "access-articles" ? "active" : null
                        }
                        onClick={() => setActiveType("access-articles")}
                      >
                        Access Articles
                      </Link>
                    </li>

                    <li class="custom-li-journal tagli">
                      <Link
                        to="/author-submission-guidelines"
                        target="_blank"
                        className={
                          activeType === "author-guidelines" ? "active" : null
                        }
                      >
                        Author Guidelines
                      </Link>
                    </li>

                    {societyJournal === "Society Journal" ? (
                      <li class="custom-li-journal tagli">
                        <Link
                          to={`/explore-journals/${id}/editorial-board`}
                          onClick={() => {
                            setActiveType("editorial-board");
                          }}
                          state={{ journalName: journalData?.journalName, journalId: journalData?._id }}
                          className={
                            activeType === "editorial-board" ? "active" : null
                          }
                        >
                          Editorial Board
                        </Link>
                      </li>
                    ) : (
                      <li class="custom-li-journal tagli">
                        <Link
                          to={`/explore-journals/${id}/publishing-editor`}
                          state={{ journalName: journalData?.journalName, journalId: journalData?._id }}
                          onClick={handleScrollTop}
                        >
                          Publishing Editors
                        </Link>
                      </li>
                    )}

                    {societyJournal === "Society Journal" ? null : (
                      <li class="custom-li-journal tagli">
                        <Link
                          to="/how-we-publish"
                          target="_blank"
                          className={
                            activeType === "publishing-model" ? "active" : null
                          }
                        >
                          Publishing Model
                        </Link>
                      </li>
                    )}

                    <li class="custom-li-journal tagli">
                      <Link
                        className={
                          activeType === "content-type" ? "active" : null
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveType("content-type");
                          setShow(true);
                        }}
                      >
                        Content Type
                      </Link>
                    </li>
                    <li class="custom-li-journal tagli">
                      <Link
                        className={activeType === "recommend" ? "active" : null}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveType("recommend");
                          setShowRec(true);
                          setModalPageName(pageName);
                          setJournalModalName(journalName);
                        }}
                      >
                        Recommend
                      </Link>
                    </li>
                    <li class="custom-li-journal tagli">
                      <Link
                        className={activeType === "advertising" ? "active" : null}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveType("advertising");
                          setShowAdvertising(true);
                        }}
                      >
                        Advertise With Us
                      </Link>
                    </li>
                    <li class="custom-li-journal tagli">
                      <Link
                        to="/journal/get-in-touch"
                        target="_blank"
                        className={
                          activeType === "get-in-touch" ? "active" : null
                        }
                      >
                        Get in Touch
                      </Link>
                    </li>
                  </ul>
                </div>


                <div className="journalslist martop-10 main-class-publish">
                 
                  <div className="footer-align-bottom">
                    <h2 className="follw custom-24 tagh2">Follow Us</h2>
                    <ul className="ag_ftr_socials tagul">

                      <li className="tagli">
                        <a
                          href="https://www.youtube.com/watch?v=Iewt4-w2R9g"
                          target="_blank"
                          className="taga youtubeIcon"
                        >
                          <img
                            src={YoutubeIcon}
                            alt="Youtube Icon"
                            className="tagimg"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h2 className="font22 custom-24 tagh2">Email Alerts</h2>
                  <p>Sign up to receive Email alerts</p>
                  <Link
                    to="/registration"
                    onClick={(e) => handleRedirect(e, "/registration")}
                    className="ag_btn_red signup-btn-journal taga"
                  >
                    SIGN UP
                  </Link>
                </div>
              </div>
              <div className="div70">

                <div className="content-journal">
                  <div className="editorialAccordion">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Editor In Chief</Accordion.Header>
                        <Accordion.Body>
                          <div className="editorialAccordionContent">
                            <b>Anand Agrawal</b>
                            <span>Professor, Business and Management</span>
                            <p>College Of Business Administration American University In The Emirates Dubai, Saudi Arabia</p>

                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Editor</Accordion.Header>
                        <Accordion.Body>
                          <div className="editorialAccordionContent">
                            <b>Anand Agrawal</b>
                            <span>Professor, Business and Management</span>
                            <p>College Of Business Administration American University In The Emirates Dubai, Saudi Arabia</p>

                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Editor</Accordion.Header>
                        <Accordion.Body>
                          <div className="editorialAccordionContent">
                            <b>Anand Agrawal</b>
                            <span>Professor, Business and Management</span>
                            <p>College Of Business Administration American University In The Emirates Dubai, Saudi Arabia</p>

                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>

                </div>






              </div>
            </div>
            <div className="editorRevisionWrap">
              {publishEditorData?.data?.length > 0 ? publishEditorData?.data && publishEditorData?.data?.map((item, ind) => (
                <div className="editorRevision">
                  <span>{ind + 1}.</span>
                  <p dangerouslySetInnerHTML={userDetails(item?.user?.[0])} />
                </div>
              )) : (
                <div className="noDataPublic"><h2>No Publishing Editors Data Found</h2></div>
              )}


            </div>
            {totalPages > 1 && (
              <div className="editorPagi">
                <span>
                  <img
                    src={pg01}
                    onClick={(event, data) => handlePageChange(data)}
                  />
                  PREVIOUS
                </span>
                <div className="numberWrap">
                  <div className="paginationWrap">
                    <Pagination
                      boundaryRange={1}
                      firstItem={null}
                      lastItem={null}
                      siblingRange={1}
                      activePage={<b className="active">{page}</b>}
                      onPageChange={(event, data) => handlePageChange(data)}
                      totalPages={totalPages}
                    />
                  </div>
                </div>
                <span>
                  Next
                  <img
                    src={pg02}
                    onClick={(event, data) => handlePageChange(data)}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
        <NewsLetter />
        <SubmitArticleModal
          uploadArticle={uploadArticle}
          setUploadArticle={setUploadArticle}
        />
        <AakashgangaFooter />
      </div>
    </>
  );

}

export default EditorialBoard;