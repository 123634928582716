import React from 'react';
/*eslint-disable*/
import OwlCarousel from 'react-owl-carousel';
import "../../../src/assets/assets_html/scss/carousel.scss";
const Partners = () => {
	const options = {
		loop: true,
		margin: 10,
		nav: false,
		dots: false,
		autoplay: true,
		controller: false,
		autoplayTimeout: 1000,
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 3,
			},
			1000: {
				items: 4,
			},
		},
	};
	return (
		<div className='ag_main_wrap ag_partners_wrap'>
			<div className='container'>
				<div className='row align-items-center'>
					<div className='col-12 align-items-center'>
						<div className='ag_heading_center_wrap our-partner mt-5'>
							<h2 className="tagh2">
								<b className='ourPart'>Our</b> Partners
							</h2>
						</div>
					</div>
					<div className='col-12 align-items-center mt-3'>
						<div className='owl-carousel owl-theme ag_partners_slider'>
							<OwlCarousel className="owl-theme" {...options}>
									<div className='item'>
										<img
											src='assets/images/startup-india-hub-logo.svg'
											alt='Startup-India-Hub-Logo'
											className="custompartnerimg tagimg"
										/>
									</div>
									<div className='item'>
										<img
											src='assets/images/msme-logo.svg'
											alt='Msme-Logo'
											className="tagimg"
										/>
									</div>
									<div className='item'>
										<img
											src='assets/images/crossref-seeklogo-logo.svg'
											alt='Crossref-Seeklogo-Logo'
											className="tagimg"
										/>
									</div>
									<div className='item'>
										<img
											src='assets/images/dora-logo.svg'
											alt='Dora-Logo'
											className="tagimg"

										/>
									</div>
									<div className='item'>
										<img
											src='assets/images/logo-for-drillbit.svg'
											alt='Dora-Logo'
											className="tagimg"

										/>
									</div>
									<div className='item'>
										<img
											src='assets/images/open-access-logo.svg'
											alt='Open-Access-Logo'
											className="customopenaccess tagimg"
										/>
									</div>
									<div className='item'>
										<img
											src='assets/images/sdg-publishers.svg'
											alt='Open-Access-Logo'
											className="custompartner-96 tagimg"
										/>
								</div>
							</OwlCarousel>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Partners;
