import { React, useState } from "react";
import "../../assets/scss/sections/_contact_us.scss";
import ContactUsComponent from "../../components/contactUs";
import Sidebar from "../../components/common/Sidebar";
import AcceptModal from "../autherFlow/prdashboard/AcceptModal";

const NeedHelp = () => {
  const [showAccept, setShowAccept] = useState(false);
  const modalBody = `<p class="text-center">We will respond within <u>five working days.</u></p>`;
  const handleChatBot = (e) => {
    e.preventDefault();
    window.zE("messenger", "open");
  };

  const successfullyContact = () => {
    setShowAccept(true);
  };

  return (
    <div className="needHelpPage">
      <div className="contactSection">
        <div className="pageWrap">
          <div className="pageLeft">
            <Sidebar />
          </div>
          <div className="pageRight">
          <div className="innerMergin">
            <div className="contactRow">
              <div className="contactLeft">
                <span className="draftTitle">Contact Us</span>

                <ContactUsComponent
                  page={"writeToUs"}
                  contactSuccess={successfullyContact}
                />

                <AcceptModal
                  showAccept={showAccept}
                  setShowAccept={setShowAccept}
                  title={"Message Sent Successfully"}
                  body={modalBody}
                  btnText="Continue"
                />
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeedHelp;
