import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";

const StarRating = ({ getRatingValue }) => {
  const [rating, setRating] = useState(0);
  const handleRating = (rate) => {
    setRating(rate);
    getRatingValue(rate);
  };
  useEffect(() => {
  }, []);

  return (
    <>
      <Rating
        className="starRatings"
        onClick={handleRating}
        initialValue={rating}
      />
    </>
  );
};
export default StarRating;
