import React from "react";
import ReactPlayer from "react-player/youtube";
import "../../../assets/scss/pdf.scss";

const authorGuidelines = () => {
    return <>
        <div className="guidelinesPageWrap">
            <h2 className="mb-0">General Information</h2>
            <p>Welcome to the Author Submission Guidelines.</p>
            <p>The Aakashganga platform is an Open Access Platform, centred on advancing
                and benefiting society by offering unrestricted access to the most recent
                research. Our mission is to help expedite the pace of discovery and establish a
                more equitable knowledge system accessible to all.</p>
            <p>
                We do this by making scholarly research and literature available freely for all by
                following the 'open access' principle, which implies that there are no financial,
                legal or technical barriers to accessing it. This means that anyone can freely
                read, download, copy, distribute, print, search for and search within the
                information, or use it in education or in any other way within the legal
                agreements.</p>
            <p>At the core of the Open culture lies a straightforward legal tool: <b>the Creative
                Commons Attribution <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" className="colorBlack" target="_blank" >(CC-BY-NC-SA)</a> license</b>.</p>
            <p>
                For more information on Open Access, please read the section, <b>‘How We Work’</b>.</p>
            <h2>Publishing on Aakashganga</h2>
            <p>The Aakashganga Platform publishes a number of different article types. Our
                main objective is to make the research publishing journey easy, transparent, and
                affordable for our authors.</p>
            <p>We publish Mega Journals and Society/University/Institute Journals including
                reports and conference proceedings.</p>
            <p>The Aakashganga platform offers two kinds of Peer Reviews:</p>
            <ul>
                <li>Pre-Publishing Double Anonymous Peer Review</li>
                <li>Post Publishing Open Peer Review.</li>
            </ul>

            <p>Please review the details of Aakashganga’s various <u>peer-review models</u>   before
                you decide to submit your article.</p>
            <h2>Begin Now</h2>
            <p>Manuscripts of articles for research will be submitted on our platform directly.
                The first step towards this is creating an account with us. This is a simple process
                that should take under five minutes to complete.</p>
            <p><i>Please note that the information we gather is never shared with anyone.</i></p>
            <p>Once you have created your account and registered with us, you will be able to
                access our state of the art, and extremely user-friendly article submission</p>
            <strong>interface.</strong>
            <ul>
                <li>Know how to register:</li>
                    <iframe width="560" height="315" className="w-100" src="https://www.youtube.com/embed/w68GPd_pliU?si=GqZAcV60uoc7pfp7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay=1; loop=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="true"></iframe>
                <li>Article Submission Process on <br /> Aakashganga Platform:
                    <a href="https://www.youtube.com/watch">https://www.youtube.com/watch</a></li>
            </ul>

            <p>Ready to submit an article but don’t have an account? Please click here.</p>
            <div className="text-center"><b>Button: Create my Profile Now</b></div>

            <p>From your account, please click the Submit an Article button. This takes you to
                the Submission Details screen. We require all the fields filled so that we know
                exactly what the article is about.</p>
            <p>
                We request that you click on all the ‘I’ icons to read the requirements of each
                section before providing your details. We have tried to provide clear explanations
                and steps at every level of the submission process. In case, you have a question
                that is not answered, please write to us directly at <a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a></p>
            <h2>Manuscript Length</h2>
            <p>We encourage you to closely follow the article word count lengths given on the
                Article Submission page during submission. As a general rule, an article should
                not be less than 8000 words, and more than 10,000 words. The article length
                includes only the main body of the text, footnotes, and all citations within it, and
                excludes the title, running title, abstract, section titles, figure and table captions,
                funding statement, acknowledgments, and references in the bibliography.</p>
            <h2>Article Type</h2>
            <p>We are happy to publish all sorts of articles. To make it simple for you, we have
                provided a simple way in which you identify the type of your article. Currently,
                we publish the following types of articles:</p>
            <ol>
                <li><b>Original Research Articles</b> present new findings from original research
                    (primary and unpublished studies) conducted by the authors in a specific
                    field   of   research.   They   include   confirming   or   disconfirming   results,
                    enabling the refinement or elimination of hypotheses. These articles
                    undergo a thorough peer-review process, ensuring the reliability and
                    validity of the research.</li>
                <li><b>Review Article:</b> Review articles provide an in-depth and balanced
                    perspective on topics that have witnessed significant development or
                    progress in recent years. It aims to summarize, evaluate, and synthesize
                    the findings of multiple studies. They go beyond mere literature
                    summaries and aim to offer a comprehensive overview of the state of the
                    art.</li>
                <li><b>Systematic Review:</b> Systematic reviews are a specific type of research
                    publication that aims to synthesize and summarize the existing evidence
                    on a particular research question or topic. Systematic Review articles
                    present a synthesis of previous research, and use clearly defined methods
                    to identify, categorize, analyse and report aggregated evidence on a
                    specific topic. Included in this article type are meta-syntheses, meta-
                    analyses, mapping reviews, scoping reviews, systematic reviews, and
                    systematic reviews with a meta-analysis.</li>
                <li><b>Case Reports:</b> These describe unique or interesting medical or clinical
                    cases that contribute to the understanding of a particular disease,
                    treatment, or condition. They provide a comprehensive account of specific
                    patients, interventions, diseases, diagnostic procedures, or treatment
                    outcomes that are considered rare, novel, or noteworthy.</li>
                <li><b>Methodology Articles:</b> These describe new methods or techniques
                    developed for research or data analysis. Methodology articles focus on the
                    technical aspects of research and often include detailed protocols or
                    algorithms.</li>
                <li><b>Data Articles:</b> These focus primarily on data sets, describing and
                    providing access to large data collections or databases. Data articles may
                    include data descriptions, data analyses, and data visualization.</li>
                <li><b>Perspectives or Opinions:</b> These articles express the authors'
                    perspectives, opinions, or commentaries on a specific scientific, social, or
                    ethical issue. They should provide the following: 1) Discuss current
                    advances and future directions, 2) Clear presentation of the authors’
                    perspective, 3) Accurate presentation and citations of other authors’ work,
                    4) May include original data as well as personal insights and opinions.
                    <b>(These are only available when you choose Post Publishing Open
                        Review)</b></li>
                <li><b>Conceptual Articles:</b> Conceptual articles in research publishing are
                    scholarly papers that focus on discussing theoretical or conceptual
                    frameworks, models, or ideas related to a specific research topic. Unlike
                    empirical research articles that present data-driven findings, conceptual
                    articles provide a deep analysis and exploration of theoretical concepts,
                    methodologies, or approaches within a particular field of study. These
                    articles contribute to the advancement of knowledge by offering new
                    perspectives, synthesizing existing theories, or proposing innovative
                    conceptual frameworks.</li>
                <li><b>Commentaries:</b> Commentaries in research publishing are a form of
                    scholarly communication that provides expert analysis, critique, and
                    perspective on a specific research topic, article, or field of study. Unlike
                    original research articles or review articles, commentaries are typically
                    shorter and more focused on offering personal insights, opinions, and
                    reflections rather than presenting new empirical data. (These are only
                    available when you choose Post Publishing Open Review)</li>
                <li><b>Book Reviews:</b> Book reviews in research publishing refer to critical
                    assessments and evaluations of published books within a specific
                    academic or scholarly field. These reviews provide insights, analysis, and
                    opinions on the content, methodology, and contribution of the book to the
                    field of study. Book reviews play an important role in scholarly discourse
                    by summarizing the main arguments, strengths, and weaknesses of the
                    book and offering readers a critical perspective. <b>(These are only
                        available when you choose Post Publishing Open Review)</b></li>
            </ol>

            <h2>Language Editing</h2>
            <p>We would like to maintain the highest standards of language and structure
                integrity. For now we have an automated <b>Spelling and Grammar Checker</b> built
                into our submission module. All articles need to be cleared by system before
                they can move forward in the publishing process.</p>
            <p>We also run a profanity check and Similarity Index check before the article can
                move into the submission stage. This is just our way of supporting our authors.
                However, it is possible you may need specific assistance to enhance your article.</p>
            <p>If you feel you need advanced assistance with language editing to improve the
                presentation of your manuscript and help highlight your research, we
                recommend that you get in touch with us at contactus@vikramshilaedu.in . We
                will do our best to get you connected with the right type of editor.</p>
            <p>
                <b>Please note:</b> Taking help for manuscript language editing does not guarantee
                that it will be accepted for publication by the platform. Our editorial assessment
                on the scientific content of a manuscript is completely governed by our
                evaluation of the quality of the article.</p>
            <h2>Language style</h2>
            <p>The default language style at Aakashganga is American English. For any
                questions regarding style, we recommend authors to consult the Chicago Manual
                of Style.</p>
            <h2 className="mb-0">Search engine optimization (SEO)</h2>
            <p>There are many ways to maximize your article’s discoverability and search
                results.</p>
            <p><b>Keywords:</b> Identify relevant keywords and phrases that are commonly used by
                your target audience when searching for content related to your research.
                Remember to include a few of your article's keywords in the title of the article
                and in the article itself.</p>
            <p>Use tools like Google Keyword Planner, SEMrush, or Moz Keyword Explorer to find
                popular and relevant keywords.</p>
            <p>You can use the following strategies to use keywords to your maximum benefit:</p>
            <h2>PRIMARY KEYWORD PLACEMENT:</h2>
            <p> Select one or two primary keywords that
                accurately represent the main focus of your article. Place these keyword
                strategically in the following elements:</p>
            <ul>
                <li><b>Article title:</b> Include your primary keywords in the title, preferably
                    towards the beginning.</li>
                <li><b>Meta tags:</b> Optimize the Meta title and Meta description of your article by
                    incorporating the primary keyword. This helps search engines understand
                    the relevance of your content.</li>
                <li><b>Introduction:</b> Introduce your primary keyword naturally in the opening
                    paragraph of your article. This signals to search engines and readers that
                    your content is relevant to their search.</li>
                <li><b>Keyword integration in content:</b> Throughout the body of your article,
                    incorporate your primary keyword and related keywords in a natural and
                    meaningful way. Avoid keyword stuffing, which is the excessive and
                    unnatural use of keywords, as it can harm your SEO efforts. Instead, focus
                    on these areas:
                    <ol>
                        <li><b>Headings and subheadings:</b> Include relevant keywords in your headings
                            and subheadings to improve the organization and readability of your
                            article. This helps search engines understand the structure of your
                            content.</li>
                        <li><b>Body paragraphs:</b> Integrate keywords into your sentences and
                            paragraphs naturally. Aim for a smooth flow of content while ensuring
                            keyword relevance.</li>
                        <li>Conclusion: Consider including your primary keyword in the conclusion
                            section to reinforce its importance and relevance.</li>
                        <li>Long-tail keywords: Long-tail keywords are more specific and longer
                            phrases that are highly targeted. They often have lower search volume
                            but can attract more qualified traffic. Incorporate long-tail keywords
                            naturally in your content, especially in subheadings and throughout the
                            body of your article.</li>
                        <li>Synonyms and variations: Use synonyms, related terms, and variations
                            of your primary keyword throughout your article. This helps search
                            engines understand the different aspects and contexts of your content.
                            It also provides more opportunities for your article to appear in relevant
                            search queries.</li>
                    </ol>
                </li>
                <li><b>Headers and Formatting:</b> We have simplified the submission process by
                    breaking the article into relevant sections. Each section has a section
                    header (Title, Introduction, Abstract etc). Barring a few mandatory headers
                    such as Introduction, Abstract, Conclusion, all other headers are
                    customisable. You need to merely to click on the Section Header and type
                    in your own customized heading.</li>
                <li><b>Alt tags for images:</b> We have simplified the process of uploading
                    images. Out AI driven platform will automatically assign an image number
                    and prompt you to provide the image caption. The same process is
                    performed when you submit a Table. This not only helps search engines
                    understand the content of your images but also improves their visibility in
                    image searches</li>
            </ul>
            <h2>TITLE OF THE ARTICLE:</h2>
            <p>The title of your research article plays a crucial role in attracting readers and
                improving search engine optimization (SEO). Here are some tips on how to
                smartly use the title to maximize its benefits:</p>
            <p><b>Optimize the article title:</b> Do not use long article titles. Craft a compelling title
                including your primary keyword. Ensure the title encourages users to click on it in
                search results.</p>
            <p><b>Write high-quality content:</b> Focus on addressing the topic comprehensively,
                incorporating relevant keywords naturally throughout the article.</p>
            <p><b>Focus on the main idea:</b> Ensure that your title captures the central idea or the
                most significant findings of your research.</p>
            <p><b>Use active and engaging language:</b> Opt for active verbs and engaging
                language that piques the interest of potential readers and encourages users to
                click on it in search results.</p>
            <p><b>Avoid jargon or technical terms:</b> Opt for language that is accessible to a
                broader audience, including researchers outside your specific field.</p>
            <h2>OPTIMIZE META TAGS:</h2>
            <p>Pay attention to the title and description of your
                article. These are the snippets that appear in search engine results pages
                (SERPs). Include your primary keyword in the Meta title and craft a compelling
                Meta description that entices users to click on your article.</p>
            <h2>OPTIMIZE IMAGES:</h2>
            <p> Include relevant images in your article and optimize them
                for SEO. Use descriptive file names and alt tags that include relevant keywords.
                This helps search engines understand the content of your images and improves
                their visibility in image searches.</p>
            <h2>INTERNAL AND EXTERNAL LINKING:</h2>
            <p>Incorporate links within your article
                carefully to relevant pages of other credible websites. This helps search engines
                understand the context and hierarchy of your content.</p>
            <h2>PROMOTE YOUR ARTICLE:</h2>
            <p>Share your article on social media platforms,
                relevant forums, and professional networks. Encourage others to link to your
                article, and engage with your audience to increase visibility and attract organic
                traffic.</p>
            <p>Cross Mark policy <a href="https://www.crossref.org/">https://www.crossref.org/</a></p>
            <p>Please Read this: <a href="https://www.crossref.org/documentation/crossmark/crossmark-
            policy-page/">https://www.crossref.org/documentation/crossmark/crossmark-
                policy-page/</a> </p>
            <p>CrossMark is a multi-publisher initiative to provide a standard way for readers to
                locate the current version of a piece of content. By applying the CrossMark logo
                Aakashganga is committed to maintaining the content it publishes and to
                alerting readers to changes if and when they occur.</p>
            <p>Clicking on the CrossMark logo will tell you the current status of a document and
                may also give you additional publication record information about the document.</p>
            <h2>Authors and Affiliations</h2>
            <p>All names should be listed together in the order in which these are required to be
                showcased on the article. Please provide exact and correct author names as
                these will be indexed in official archives.</p>
            <p>Articles can only be submitted from the account of the author. If an article has
                multiple authors then the article can be submitted from the account of any of the
                authors. We don’t permit third party submissions of any kind.</p>
            <h2>Artificial Intelligence</h2>
            <p>These guidelines provide acceptable use of generative AI technologies in the
                writing or editing of manuscripts submitted to the Aakashganga platform.</p>
            <h2>AI use by authors</h2>
            <p>We do not allow papers to be submitted with generative AI technology as a co-
                author or author of any manuscript as they do not fulfil the necessary criteria for
                authorship on the Aakashganga platform. Any papers containing such technology
                will be rejected.</p>
            <p>
                If an author has utilized written or visual content generated or edited using a
                generative AI technology, they are responsible for verifying the factual accuracy
                of the content. This includes quotes, citations, references, and figures that have
                been created or edited.</p>
            <p>Additionally, authors must confirm that any written or visual content produced or
                edited with generative AI technology is original and free from plagiarism.</p>
            <p><b>Please note:</b> When an author has incorporated written or visual content
                generated or edited using generative AI technology, they must acknowledge this
                usage in the <u>Acknowledgements</u> section of the manuscript. If applicable, this
                information should also be included in the methods section. The explanation
                should include the name, version, model, and source of the generative AI
                technology.</p>
            We strongly encourage authors to upload all input prompts provided to
            generative AI technology and the corresponding outputs received from the
            technology as supplementary files for the manuscript.
            <h2>Abstract</h2>
            <p>The abstract is an important piece in the Article writing process as it
                communicates the general significance and conceptual advancement of your
                work to a wide readership. All Abstracts on Aakashganga platform should be not
                more than 500 words. They should be concise and should avoid excessive use of
                abbreviations. The abstract should also refrain from citing references, figures, or
                tables. Clinical trial articles should include the unique identifier and URL of the
                publicly-accessible registration website for the trial. A minimum of five keywords
                are required for all article types.</p>
            <p>Keywords are to be entered into the specific section on the left side of the
                submissions page.</p>
            <h2>Acknowledgements</h2>
            <p>This section expresses gratitude towards colleagues, universities, institutions,
                supervisors, laboratories or agencies that have provided valuable contributions
                to the authors' work.</p>
            <p>Please Note: If the content of the current manuscript has been previously
                published, such as in a thesis or preprint, it should be acknowledged here, along
                with proper citation in the reference list.</p>
            <h2 className="mb-0">Figure and Table Guidelines</h2>
            <p>CC BY NC SA License:</p>
            <p>Figures, tables, and images included in the manuscript will be published under a
                Creative Commons CC BY NC SA license. Authors must obtain permission to use
                copyrighted material from external sources, including figures and images that
                have been re-published, adapted, modified, or obtained from the internet. It is
                the authors' responsibility to obtain the necessary licenses, adhere to any
                citation requirements set by third-party rights holders, and bear any additional
                charges associated with their use.</p>
            <p>We require each figure to be submitted individually, in the same order as they
                are referred to in the manuscript. Kindly ensure that each figure is mentioned in
                the text and in numerical order.</p>
            <p>For graphs, there must be a self-explanatory label (including units) along each
                axis.</p>
            <p>Please note that figures not in accordance with the guidelines will cause
                substantial delay during the production process.</p>
            <p>At any point should there be any copyright issues raised with us, we will forward
                the same to the author and request that the issue be resolved. Should the issue
                remain unresolved, the platform, it’s owners and staff reserve the right to delete
                any such material without assigning any reason whatsoever.</p>
            <h2>Images</h2>
            <p>All images must have a resolution of 100 dpi at final size. Check the resolution of
                your figure by enlarging it to 150%. If the image appears blurry, jagged, or has a
                stair-stepped effect, the resolution is too low.</p>
            <h2>Format and Colour Image Mode</h2>
            <p>The following formats are accepted: TIF/TIFF (.tif/.tiff), JPEG (.jpg) and PNG.</p>
            <h2>Accessibility</h2>
            <p>Authors are strongly encouraged to ensure that figures and visual elements in
                their articles are accessible for individuals with visual impairments. Utilizing
                colour effectively can assist individuals with low visual acuity or colour blindness
                in comprehending the article's content.</p>
            <p>These guidelines, which align with the W3C Web Content Accessibility Guidelines
                (WCAG 2.1), the recognized standard for web accessibility best practices, are
                straightforward to implement. <a href="https://www.w3.org/TR/WCAG21/">(https://www.w3.org/TR/WCAG21/)</a></p>
            <p>Avoid using red or green indicators. More than 99% of colour-blind people have a
                red-green colour vision deficiency. People who have low visual acuity or colour
                blindness could find it difficult to read text with low contrast background colour.<br />
                Try using colours that provide maximum contrast.</p>
            <p>
                WC3 recommends the following contrast ratio levels:</p>
            <ul>
                <li>Level AA, contrast ratio of at least 4.5:1</li>
                <li>Level AAA, contrast ratio of at least 7:1</li>
            </ul>

            <p>You can also verify the contrast ratio of your palette with these online ratio
                checkers:</p>
            <p>
                WebAIM: <a href="https://webaim.org/">https://webaim.org/</a></p>
            <p>Color Safe: <a href="http://colorsafe.co/">http://colorsafe.co/</a></p>
            <h2>Supplementary Material</h2>
            <p>During the article submission process, authors can upload supplementary
                material in the section on the article submission page link. Supplementary
                material is essentially defined as one that is not of primary importance to the
                main text of the article or is not to be included in the article due to incompatible
                formats.</p>
            <ol>
                <li>Please upload the supplementary material without tracked changes,
                    highlighted text, or line numbers</li>
                <li>Remember to add the appropriate captions in the file.</li>
                <li>Please refrain from including the title, author list, affiliations, or
                    correspondence in the supplementary files to maintain consistency with
                    the published article.</li>
            </ol>
            <h2>
                Supplementary material Upload Format</h2>
            <p><b>Resolution:</b> 100 DPIs, <b>Colour Mode:</b> RGB</p>
            <p>Data sheet: Word, Excel, PDF, or Zip files<br />
                Presentation: PowerPoint, PDF, or Zip files<br />
                Image: JPEG, PDF, PNG, or TIF/TIFF<br />
                Table: Word, Excel, or PDF<br />
                For supplementary images, the technical requirements are as follows:</p>
            <h2 className="mb-0">References</h2>
            <p>Aakashganga journals use the Harvard (author-date) reference style.</p>
            <p>Harvard (author-date) reference style uses the inclusion of in-text citations that
                provide brief information about the source, followed by a detailed reference list
                at the end of the document.</p>
            <p>In the Harvard (author-date) style, in-text citations consist of the author's last
                name and the publication year of the source, enclosed in parentheses. The
                citations are usually placed within the main text or in footnotes. For example:
                (Mehra, 2020), (Ghosh and Subramanium, 2023).</p>
            <p>Our automatic Insert Citation feature will assist you with the process. Please see
                the section on Inserting Citations for more information.</p>
            <h2>Reference list examples</h2>
            <p>This section helps you understand how to write the references in the relevant
                section of the submission module. If this sounds confusing please ensure the URL
                of the article you quoted is correctly pasted in the References section.</p>
            <strong>Article in an online journal</strong>
            <p>Tahimic, C.G.T., Wang, Y., Bikle, D.D. (2013). Anabolic effects of IGF-1 signaling on
                the skeleton. Front. Endocrinol. 4:6. doi: 10.3389/fendo.2013.00006</p>
            <ol>
                <li>Ensure that all citations within the text, figures, or tables are included in
                    the reference list, and vice versa.</li>
                <li>Provide the names of the first six authors followed by "et al." and, if
                    available, the DOI (Digital Object Identifier). ). Alternately you can provide
                    the Full URL of the article.</li>
                <li>Abbreviate given names of authors to initials (e.g., Mehra, V., Segon, C.S.,
                    etc.).</li>
                <li>In the article, when incorporating verbatim text, enclose it in quotation
                    marks and clearly attribute it to the original source right after the text
                    enclosed in quotation marks. This will ensure this section is not marked
                    negatively in the Similarity Check Index (plagiarism checker).</li>
                <li>Preprints with a DOI or archive URL can be cited and the citation should
                    explicitly indicate that it is a preprint. If a peer-reviewed journal
                    publication of the same preprint exists, prefer citing the official journal
                    publication.</li>
            </ol>
            <div className="text-center"><strong>Button: Submit My Article</strong></div>

            <h2>Amendment</h2>
            <p>An amendment explains any corrections, additions or modifications that have
                been made to the original article. It provides transparency and accountability to
                the research.</p>

            <p>If you wish to add an amendment to your research article, please note the
                following:</p>
            <ol>
                <li>Carrying out One amendment is free-of-charge on the platform. Multiple
                    amendments may or may not attract additional charges. This is at the sole
                    discretion of the management of the platform.</li>
                <li>The amendment request can be launched from your dashboard</li>
                <li>The maximum word limit for carrying out the amendment is 500 words.
                    However, please keep the note precise. If the changes are significant or
                    complex, it will be considered as an updated version of the article. You will
                    then need to resubmit the entire article and pay additional fee. For more
                    information, please write to contactus@vikramshilaedu.in</li>
                <li>Please note that it will take an estimated time of 10 working days to
                    accept and reflect the changes. You will receive a notification via email
                    once the changes have been made.</li>

            </ol>
            <p>
                <b>Please Note:</b> Only Admin has all rights to add multiple amendments.</p>

            <h2 className="mt-4">Process:</h2>
            <p><b>Date and Version:</b> These fields will be automatically captured by the system.
                Title and Introduction: Begin by providing a clear title for the amendment,
                indicating that it is an update or correction to the original article. Briefly explain
                the reason for the amendment, such as an error or omission in the original
                publication. Please note that you cannot change the title of the main
                article when you are making an amendment.</p>
            <p>
                <b>Statement of Amendment:</b> Clearly state the specific section(s) or information
                that requires amendment. Provide a concise summary of the changes being
                made and their significance. This will help readers quickly identify the updated
                content.</p>
            <h2>Versions</h2>
            <p>If you wish to add another version of your open access research article, we kindly
                ask you to follow the guidelines outlined below:</p>
            <ol>
                <li>Currently the new version creation process is handled offline.</li>
                <li>To create a new version, please write an email to
                    contactus@vikramshilaedu.in. Once we receive your request, we will share
                    a discount coupon with you, which you can use to make the final payment
                    for the new version article update.</li>
                <li>You will need to follow the complete submission process again to create the
                    new article version</li>
                <li>Version Identification: This will be captured by the system and
                    automatically reflect in the final version.</li>
                <li>Revision Statement: Include a concise statement that highlights the
                    changes made in the new version. This could involve addressing feedback
                    from reviewers, updating results or conclusions, expanding the
                    methodology, or any other modifications that enhance the quality or
                    relevance of your research.</li>
                <li>Timestamp: The date will be captured by the system when the article is
                    submitted. </li>
                <li>Citations and References: In case the new version contains significant
                    changes, it is essential to update citations and references accordingly. Ensure consistency throughout the article and maintain accurate
                    referencing of prior studies or sources.</li>
            </ol>

            <p>Remember that adding another version of your open access research article is an
                opportunity to refine and enhance your work. It allows you to incorporate
                valuable feedback and updates, contributing to the continuous evolution of
                scientific knowledge. We encourage you to take It allows you to incorporate
                valuable feedback and updates, contributing to the continuous evolution of
                scientific knowledge. We encourage you to take advantage of this option to
                further advance your research.</p>
            <h2>Open Access License:</h2>
            <p>
                Every article published on the Aakashganga platform is the author’s commitment
                to publish the manuscript under a specific open-access license (CC BY NC SA)
                that allows unrestricted access and sharing of the published work without
                anyone deriving commercial benefit from sharing this. CC BY-NC SA license is
                called the Attribution-Non-Commercial-ShareAlike license. </p>
            <p>
                This license lets others remix, tweak, and build upon your work non-
                commercially, as long as they credit you and license their new creations under
                the identical terms. This means that anyone is free to use and reuse the content,
                as long as they provide proper attribution to the original source and authors.</p>
            <h2>Publication Charges:</h2>
            <p>Open Access means we don’t earn any revenue from subscribers or individuals
                who view an article. Our only source of revenue is the  Article Processing
                Charge. This varies from region to region. As a policy the lowest charge is
                payable by Indian nationals living in India using an Indian payment method. We
                are sensitive to the needs of developing nations and are doing our best to lower
                their burden when compared to other OA platforms. We also understand the
                need to price ourselves fairly and hence even in the developed world we are
                priced much lower than other platforms.</p>
            <h2>Authorship disputes</h2>
            <p>
                Authorship disputes are one of the most common complaints made to Mega
                Journals. The submission process on Aakashganga platform is automated, and
                therefore, it is the Primary author’s responsibility to list all the author’s
                participating in that particular research and can have a reasonable claim to
                    Once the article registration fee is paid, the author information cannot be
                    changed. </p>
                <p>In the context of scholarly work, a "co-author" refers to an individual who has
                    made a substantial scholarly contribution to the reported work and shares both
                    responsibility and accountability for the outcomes and results.</p>
                <h2>
                    Competing Interests</h2>
                <p>
                    A competing interest, also referred to as a 'conflict of interest,' happens when an
                    author or group of authors possess potential conflicting interests, whether
                    professional or financial, that could potentially influence the submission and
                    publication of their paper and its research. Such conflicting interests have the
                    potential to bias or compromise the integrity of their manuscript or the research
                    findings.</p>
                <p>
                    We encourage all authors participating in the research paper submission to
                    declare any interests affecting their paper. If you are an author with
                    Aakashganga platform, we encourage you to do this by filling the ‘Statements’
                    section of the ‘My Submission’ process. By volunteering this information and
                    ensuring complete transparency, the author significantly contributes to diffusing
                    any potential concerns regarding competing interests. This will help to maintain
                    the integrity of their research.</p>
                <h2>Before you submit an article, please ensure that:</h2>
                <ul>
                    <li>The manuscript you are submitting should consist of original research that
                        has not been published previously, either in its entirety or in substantial
                        parts.</li>
                    <li>It should also not be under consideration or review by any other journal.</li>
                    <li>We encourage the submission of manuscripts that were previously posted
                        on preprint servers, such as ArXiv or BioRxiv.</li>
                    <li>All authors participating in the research are qualified researchers.</li>
                    <li>Please ensure that your research article meets all applicable research and
                        publication standards</li>
                    <li>Your manuscript includes full author and affiliation information, and a
                        conflict of interest statement.</li>
                    <li>You agree to pay any processing charges applicable to this submission. If
                        based in an EU member country, we will also require a VAT registration
                        number.</li>
                </ul>
                <h2>
                    ORCID</h2>
                <p>
                    In the near future, Aakashganga platform will become a member of ORCiD and
                    encourages the utilization of ORCID iDs whenever feasible. To ensure compliance
                    with this initiative, we require the submitting author to provide their submission
                    using an ORCID iD. </p>
                <p>
                    ORCID (Open Researcher and Contributor ID) serves as an identifier for
                    individuals engaged in research, scholarship, and innovation. It offers a
                    persistent identifier, similar to how digital object identifiers (DOIs) provide
                    identification for content-related entities on digital networks. It provides a unique
                    ORCID iD that reliably links researchers with their research and affiliations.
                    Obtaining an ORCID iD is a simple and free process, which can be completed
                    during the article submission or by logging directly into the ORCiD website. All
                    authors are offered the chance to link their ORCID iDs during the ‘Submission’
                    process. <br />
                    Know more about ORCiD id here.</p>
        </div>
    </>
}
export default authorGuidelines
