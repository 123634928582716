import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../config";
import { subscribeHomePgaeNotification } from "../../store/apiCalls/profileDetails";
function NewsLetter() {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [show, setShow] = useState(false);
  const [subscribedShow, setSubscribedShow] = useState(false);
  const initialValues = {
    emailId: "",
  };
  const setParams = (values) => {
    const params = {
      email: values.emailId,
    };
    return params;
  };
  const validate = Yup.object({
    emailId: Yup.string()
      .email("*Email is invalid")
      .required("*Email is required"),
  });
  const contactUsData = async (values) => {
    const resp = await subscribeHomePgaeNotification({
      email: values?.emailId,
    });
    if (resp.data?.success) {
      setShow(true);
      setEmail("");
    } else {
      setSubscribedShow(true);
      setEmail("");
    }
  };
  return (
    <>
      <div className="ag_newsletter_wrap">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 col-12">
              <div className="ag_newsletter_text">
                <img className="tagimg" src="/assets/images/vikramshila.png" alt="Vikramshila" />
                <p className="tagp">
                  Born from personal trials, Aakashganga is a shining star in
                  the Vikramshila universe.{" "}
                  <Link to="https://vikramshilaedu.in/about" target="_blank">
                    Learn more about Vikramshila.
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="ag_newsletter_box">
                <h4 className="tagh4">
                  Get top-notch content straight to your inbox. Just the good
                  stuff, no spam. Subscribe!
                </h4>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  innerRef={formRef}
                  validationSchema={validate}
                  onSubmit={(values, { resetForm }) => {
                    contactUsData(values);
                    resetForm();
                  }}
                >
                  {(props) => (
                    <Form>
                      <div className="ag_newsletter_input">
                        <input
                        className="taginput"
                          type="email"
                          placeholder="Enter your email"
                          onChange={(event) =>
                            props.setFieldValue("emailId", event.target.value)
                          }
                          name="emailId"
                          value={props.values.emailId}
                        />
                        {props?.errors?.emailId && props?.touched.emailId ? (
                          <small><div className="error-field newsStyle">
                            {props?.errors?.emailId}
                          </div></small>
                        ) : null}
                        <button type="submit">
                          <img
                          className="tagimg"
                            src="/assets/images/newsletter-btn.svg"
                            alt="NewsLetter"
                          />
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        dialogClassName="modal-dialog-centered"
        onHide={(e) => setShow(false)}
        show={show}
        className="success-modal md-x"
        id="subscribed-msg"
        tabIndex={-1}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header
          closeButton
          className="justify-content-center pb-1 px-0 pt-0"
        >
          <Modal.Title className="subscribingTitle">Thank you for subscribing</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="subscribingModal">
            <p>We are excited to have you join our community.</p> 
            <p>You have been successfully added to our list.</p>
            <span>We will be in touch soon. Keep Safe, Keep Smiling.</span>
            
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center d-grid mt-0 pb-4 pt-2">
          <Button className="button-primary subscribingBtn" onClick={(e) => setShow(false)}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        dialogClassName="modal-dialog-centered"
        onHide={(e) => setSubscribedShow(false)}
        show={subscribedShow}
        className="success-modal"
        id="subscribed-msg"
        tabIndex={-1}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header
          closeButton
          className="justify-content-center pb-1 px-0 pt-0"
        >
          <Modal.Title className="subscribedStyle">
            <p className="good-news">GOOD NEWS!</p> You are already subscribed
            with Mission AakashGanga
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="justify-content-center d-grid">
          <Button
            className="button-primary"
            onClick={(e) => setSubscribedShow(false)}
          >
            Thanks, I got it
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewsLetter;
