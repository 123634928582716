import { useNavigate,Link } from "react-router-dom";
const PublishJournal = () => {
	const navigate = useNavigate();
  return (
    <div className="ag_main_wrap ag_publishjournal_wrap">
      <div className="container">
        <div className="row justify-content-between align-items-center pt-5">
          <div className="col-lg-5 col-12">
            <div
              className="ag_publishjournal_text aos-init"
              data-aos="fade-right"
            >
              <div className="ag_heading_wrap">
                <h2 className="comePublic">
                  <span className="mymargin mr-0">Come, </span>Publ<i className="dotPoint">i</i>sh Your Journal<span>{" "}<br/>With Us</span>
                </h2>
              </div>
              <p className="tagp">
                <b>
                  Inviting all Universities, Institutes, and Societies to join
                  Aakashganga,
                </b>{" "}
                India's own Open Access Journal Platform.
              </p>
              <p className="tagp">
                We're here to <b>help you globally broadcast scholarly work.</b>{" "}
                We will ensure inclusivity, accessibility, and affordability.
              </p>
              <p className="tagp">
                Whether you're an independent society journal or seeking an
                institutional partnership, let's collaborate to create a journal
                that truly engages your community.
              </p>
              <Link
                to="/domestic-societies-partnerships"
                target="_blank"
                className="ag_btn_red mt-5"
              >
                CONNECT NOW
              </Link>
            </div>
          </div>
          <div className="col-lg-7 col-12">
            <div
              className="ag_publishjournal_img aos-init"
              data-aos="fade-left"
            >
              <img
                src="assets/images/publish-journal.png"
                alt="Publish Journal"
                className="tagimg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublishJournal;
