import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import JoinBlog from "../components/global/JoinBlog";
import NewsLetter from "../components/global/NewsLetter";
import SubHeader from "../components/global/SubHeader";
import PostPublishing from "../components/guidelinesForPeer/PostPublishing";
import PrePublishing from "../components/guidelinesForPeer/PrePublishing";
import RolesOfPeers from "../components/guidelinesForPeer/RolesOfPeers";
import { SUB_HEADINGS } from "../constants";
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import { isMobile } from "react-device-detect";
import MobileModalReg from "../components/Modal/MobileMobleReg";
import { Helmet } from 'react-helmet';

function GuidelinesFroPeerReviewers() {
	const [mobileAlertReg, setMobileAlertReg] = useState(false);
	const navigate = useNavigate();
	const handleRedirect = (e, path = "/registration") => {
	  e.preventDefault();
	  window.scroll({ top: 0, behavior: "smooth" });
	  navigate(path);
	};
	const token = localStorage.getItem("usertoken");
	return (
		<>
		<Helmet>
		  <title> Peer reviewer  guidelines and processes at Aakashganga</title>
          <meta name="description" content="Discover the peer reviewer  guidelines and processes at aakashganga. Explore pre-publishing double anonymous peer review and post-publishing open peer review systems, for smooth transparency and collaboration in research." />

          <meta property="og:title" content="Peer reviewer  guidelines and processes at Aakashganga" data-react-helmet="true" />
          <meta property="og:description" content="Discover the peer reviewer  guidelines and processes at aakashganga. Explore pre-publishing double anonymous peer review and post-publishing open peer review systems, for smooth transparency and collaboration in research." data-react-helmet="true" />
          <meta property="og:url" content="https://www.aakashgangaopen.in/peer-reviewer-guidelines" data-react-helmet="true" />

			<link rel="canonical" href="https://www.aakashgangaopen.in/peer-reviewer-guidelines" data-react-helmet="true" />
			<meta name="robots" content="index, follow" />
      	</Helmet>
		 <div className="tagbody">
		<AakashgangaHeader />
			<h1 className="seo-related-tag">
				Guidelines For Peer Reviewers
			</h1>
			<div className='detail-col'>
				<SubHeader
					title={SUB_HEADINGS.GUIDELINE_FOR_PEER_REVIEWERS}
					className={"guidlines"}
				/>

				<div className='content-area '>
					<div className='container'>
						<p className='pera-text tagp mt-3'>
							{" "}
							Peer Review is the bridge that connects the author
							to its destination, their published article.
						</p>
						<p className='pera-text peer tagp'>
							{" "}
							It is a proven system that uses independent
							researchers in the relevant research area to assess
							manuscripts sent to them by publishing editors for
							originality, validity, and significance. Their
							analysis determines whether a manuscript should be
							published in their journal.{" "}
						</p>
						<p className='pera-text tagp'>
							{" "}
							In its true form, it is a symbol of real
							collaboration. Besides ensuring the integrity of a
							scholarly record, Peer Review builds communication,
							discussion, and ethical conduct.{" "}
						</p>
						<p className='pera-text tagp'>
							{" "}
							Peer Review on Aakashganga Peer Review is as exactly
							as the name suggests. It is the review of your
							manuscript by your peers before it is published.{" "}
						</p>
						<p className='pera-text tagp'>
							{" "}
							The Aakashganga platform offers two kinds of Peer
							Reviews:{" "}
						</p>
						<ul className='tagul'>
							<li className='tagli'>
								<strong>
									Pre Publishing Double Anonymous Peer Review
									–
								</strong>{" "}
								In this kind of Peer Review, suitable Peer
								Reviewers, selected by our system, will be
								invited to review, and present their views
								before the article is published.
							</li>
							<li className="postpublishing tagli">
								<strong>
									Post Publishing Open Peer Review –
								</strong>{" "}
								In this system, the Peer Review is done directly
								on the platform. No reviewers are invited
								specifically for this purpose and the review
								process remains open as long as the article is
								hosted on Aakashganga. By default, all articles
								will go for this sort of review.
							</li>
						</ul>

						<p className='pera-text tagp'>
							{" "}
							<strong>
								{" "}
								You must be registered on the Aakashganga
								platform in order to be invited for a review.{" "}
							</strong>
						</p>

						<p className='pera-text efforts tagp'>
							{" "}
							We deeply appreciate the efforts of our Peer
							Reviewers and value their role in shaping the future
							of research. As a thank you gesture, we provide a
							small honorarium in the form of{" "}
							<strong> Mudras</strong> (INR) for every Peer Review
							undertaken, which is deposited in the{" "}
							<strong> Mudra Kosh </strong>
							(Wallet) in your respective account.{" "}
						</p>

						<p className='pera-text tagp'>
							{" "}
							You may use these <strong> Mudras </strong> to write
							a new research article, or update an existing
							article on the platform.{" "}
						</p>

						<p className='pera-text italic tagp mb-0'>
							<span className='red-text bold600'>Please Note:</span> The
							Mudras do not hold any cash value, and can only be
							used on the platform.{" "}
						</p>

						<div className='register-button mt-3 mb-3'>
							<button onClick={(e) =>isMobile ? setMobileAlertReg(true):  handleRedirect(e, "/registration")}>Register Now</button>
						</div>

						<p className='pera-text tagp mb-5 pb-4'>
							For more information on Mudras, please refer to the{" "}
							<Link  to="/faq" target="_blank"><strong className='underline '>FAQ section.</strong></Link>
						</p>

						<RolesOfPeers />
						<PrePublishing />
						<PostPublishing />
						<JoinBlog />
					</div>
				</div>
			</div>
			<NewsLetter />
		<AakashgangaFooter />
		<MobileModalReg mobileAlertReg={mobileAlertReg}
    setMobileAlertReg={setMobileAlertReg} />
		</div>
		</>
	);
}

export default GuidelinesFroPeerReviewers;
