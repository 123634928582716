
import React from 'react';

const ArticleCountList = ({ pagename,details}) => {
    return (
        <ul>
            <li>
                <p>
                    <span>Views</span>
                    <b>{details.views}</b>
                </p>
            </li>
            <li>
                <p>
                    <span>Citations</span>
                    <b>{details.citations}</b>
                </p>
            </li>
            <li>
                <p>
                    <span>Downloads</span>
                    <b>{details.downloads}</b>
                </p>
            </li>
            <li>
                <p>
                    <span>Shares</span>
                    <b>{details.shares}</b>
                </p>
            </li>
            <li>
                <p>
                    <span>Open Reviews</span>
                    <b>{details.openreview}</b>
                </p>
            </li>
        </ul>
   
    );
};
export default ArticleCountList