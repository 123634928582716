
import React from 'react';
import coin from "../../assets/img/icons/coinT.svg";
const ViewHeaderLink = ({ pagename,details}) => {
    return (
           <ul>
                <li>E-ISSN: {details.first}</li>
                <li><a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank"><img src={coin} alt="#" /></a></li>
                {details.second!=='' && <li>{details.second}</li>}
                {details.fourth!=='' && <li>{details.fourth}</li>}
                <li>Open Peer Review</li>
                <li>Interdisciplinary</li>
                <li>Open Access</li>
            </ul>
    );
};
export default ViewHeaderLink