import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addLastViewArticle } from "../../store/apiCalls/dashboard";
import alertSvg from "../../assets/img/icons/alert_info.svg";
import fullscreen from "../../assets/img/icons/fullscreen.svg";
import "../../assets/assets_html/scss2/article_preview.css"
import AakashgangaFooter from "../../components/global/FooterComponent.js";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { FetchArticleWithoutToken,FetchProfanityList} from "../../../src/containers/autherFlow/submitArticle/queries";
import ViewHeaderLink from "../../../src/components/viewArticle/headerLink";
import Authors from "../../components/viewArticle/authors";
import PublishedDate from "../../../src/components/viewArticle/publishedDate";
import ArticleLinks from "../../components/viewArticle/articleLinks";
import TableOfContent from "../../components/viewArticle/tableOfContent";
import LeftCommonLink from "../../components/viewArticle/leftCommonLink";
import ContentDetails from "../../components/viewArticle/contentDetails";
import ArticleCountList from "./articleCountList";
import Advertisement from "./advertisement.js";
import AccesbilityContent from "../../components/viewArticle/accesbilityContent";
import RelatedArticles from "./relatedArticles";
import { recommendedArticles } from "../../store/apiCalls/dashboard";
import copy from "../../assets/img/icons/copy.svg";
import doneSvg from "../../assets/img/icons/done02.svg";
import src from "../../assets/img/sample/coverImage.png";
import infoI from "../../assets/img/icons/infoI.svg";
import copyC from "../../assets/img/icons/copyC.svg";
import alertSearch from "../../assets/img/icons/alertSearch.svg";
import active_info from "../../../src/svg/active-info.svg";
import adsport from "../../assets/img/icons/adsport.svg";
import adSpot2 from "../../assets/img/icons/adSpot2.svg";
import notyet from "../../assets/img/icons/notarticle.svg";
import notpost from "../../assets/img/icons/notPost.svg";
import backArrow from "../../assets/img/icons/blueArrow.svg";
import jsPDF from "jspdf";
import Amendments from "./amendments";
import AakashgangaHeader from "../../components/global/HeaderComponent.js"
import {
  getOpenReviewForArticle,
  submitFlagReview,
  submitOpenReviewForArticle,
} from "../../store/apiCalls/articles";
import { toast } from "react-toastify";
import { SERVER_URL } from "../../config";

function viewPublishedArticle() {
  const dispatch = useDispatch();
  const router = useLocation();
  const MAX_WORDS = 70;
  const articleID = router.pathname.split("/")[2];
  const [disabled, setDisabled] = useState("");
  const [usejournalData, setusejournalData] = useState({})
  const [articleDetails, setarticleDetails] = useState({
    id: "",
    abbreviations: [],
    amendmentData: [],
    status: "",
    abstract: "",
    articleData: { acknowledgement: "", articleSection: [], references: "" },
    articleViewCount: 0,
    articleDownloadCount: 0,
    articleShareCount: 0,
    openReviewCount: 0,
    authorDetails: {
      author: [],
      contributionStatement: "",
      notThePrimaryAuthor: false,
    },
    citations: [],
    formulae: [],
    fundingDetails: {
      funder: [],
      fundingInformation: "",
      noFundingRecevied: true,
    },
    images: [],
    keywords: [],
    reviewType: {
      isRecommendReviewers: false,
      paymentDone: false,
      suggestedReviewers: [],
      typeOfReview: "",
    },
    runningTitle: "",
    submissionDetail: {
      articleType: {},
      journalName: "",
      journalType: {},
      societyName: "",
      specialization: {},
      subject: { subjectName: "" },
    },
    supplymentaryMaterial: [],
    title: "",
    advertisement: {
      adSpot1: "",
      adSpot2: ""
    },
    masthead: ""
  });
  const [profanityWords, setProfanityWords] = useState([]);
  const [profanityWordsFound, setProfanityWordsFound] = useState([]);
  const [profanityAlert, setprofanityAlert] = useState({
    isOPen: false,
    type: 0,
  });
  const [reason, setReason] = useState([]);
  const [reasonComments, setReasonComments] = useState("");
  const [flagFormDisabled, setFlagFormDisabled] = useState(true);
  const [recommendedList, setRecommendedList] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [previewImgCount, setPreviewImgCount] = useState();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.LoginReducer?.user);
  const viewPublishedArticle = useRef(null);
  const [rating, setRating] = useState({ rate: "", feedback: "" });
  const [ratingResponse, setRatingResponse] = useState([]);
  const [averageRating, setAverageRating] = useState([]);
  const [showRateModal, setShowRateModal] = useState(false);
  const [showFlagReviewModal, setShowFlagReviewModal] = useState(false);
  const [showReporting, setShowReporting] = useState(false);
  const [showReviewSuccess, setShowReviewSuccess] = useState(false);
  const [openReviewId, setOpenReviewId] = useState("");
  const [showReadMore, setShowReadMore] = useState([]);


  const elementRef = useRef(null);

  const handleFullscreen = () => {
    if (elementRef.current) {
      if (elementRef.current.requestFullscreen) {
        elementRef.current.requestFullscreen();
      } else if (elementRef.current.mozRequestFullScreen) { // Firefox
        elementRef.current.mozRequestFullScreen();
      } else if (elementRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
        elementRef.current.webkitRequestFullscreen();
      } else if (elementRef.current.msRequestFullscreen) { // IE/Edge
        elementRef.current.msRequestFullscreen();
      }
    }
  };

  const isLoggedIn = useSelector(
    (state) => state?.LoginReducer?.userLoginDetails?.token ?? null
  );

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");

    doc.html(viewPublishedArticle.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });
  };
  const sucessHandler = (articleData) => {
    // console.log(articleData);
    if (articleData.status === 200) {
      const article = articleData.data.data;
      const journalData = articleData?.data?.journalData;
      setusejournalData(journalData);
      const articleDetail = articleDetails;
      if (journalData?.advertisement?.addSpotFirst) {
        articleDetail.advertisement.adSpot1 = journalData?.advertisement?.addSpotFirst;
      }
      if (journalData?.advertisement?.addSpotSecond) {
        articleDetail.advertisement.adSpot2 = journalData?.advertisement?.addSpotSecond;
      }
      if (journalData?.masthead) {
        articleDetail.masthead = journalData?.masthead;
      }
      articleDetail.id = article?._id;
      articleDetail.articleStatus = article?.articleStatus;
      articleDetail.readingFlag = articleData?.data?.readingFlag;
      articleDetail.readinglistId = articleData?.data?.readinglistId;
      if (article?.status) {
        articleDetail.status = article?.status
      }
      if (article?.amendmentData)
        articleDetail.amendmentData = article?.amendmentData;
      if (article?.abbreviations)
        articleDetail.abbreviations = article?.abbreviations;
      if (article?.userId) articleDetail.userId = article?.userId;
      if (article?.abstract) articleDetail.abstract = article?.abstract;
      if (article?.articleData?.acknowledgement)
        articleDetail.articleData.acknowledgement =
          article?.articleData?.acknowledgement;
      if (article?.articleData?.articleSection)
        articleDetail.articleData.articleSection =
          article?.articleData?.articleSection;
      if (article?.articleData?.references)
        articleDetail.articleData.references = article?.articleData?.references;
      if (article?.articleViewCount)
        articleDetail.articleViewCount = article?.articleViewCount;
      if (article?.articleShareCount)
        articleDetail.articleShareCount = article?.articleShareCount;
      if (article?.articleDownloadCount)
        articleDetail.articleDownloadCount = article?.articleDownloadCount;
      if (articleData?.data.openReviewCount)
        articleDetail.openReviewCount = articleData?.data.openReviewCount;
      if (article?.authorDetails?.author)
        articleDetail.authorDetails.author = article?.authorDetails.author;
      if (article?.authorDetails?.contributionStatement)
        articleDetail.authorDetails.contributionStatement =
          article?.authorDetails.contributionStatement;
      if (article?.authorDetails?.notThePrimaryAuthor)
        articleDetail.authorDetails.notThePrimaryAuthor =
          article?.authorDetails.notThePrimaryAuthor;
      if (article?.citations) articleDetail.citations = article?.citations;
      if (article?.formulae) articleDetail.formulae = article?.formulae;
      if (article?.fundingDetails?.funder)
        articleDetail.fundingDetails.funder = article?.fundingDetails.funder;
      if (article?.fundingDetails?.fundingInformation)
        articleDetail.fundingDetails.fundingInformation =
          article?.fundingDetails.fundingInformation;
      if (article?.fundingDetails?.noFundingRecevied)
        articleDetail.fundingDetails.noFundingRecevied =
          article?.fundingDetails.noFundingRecevied;
      if (article?.images) articleDetail.images = article?.images;
      if (article?.keywords) articleDetail.keywords = article?.keywords;
      if (article?.reviewType?.isRecommendReviewers)
        articleDetail.reviewType.isRecommendReviewers =
          article?.reviewType.isRecommendReviewers;
      if (article?.reviewType?.paymentDone)
        articleDetail.reviewType.paymentDone = article?.reviewType.paymentDone;
      if (article?.reviewType?.suggestedReviewers)
        articleDetail.reviewType.suggestedReviewers =
          article?.reviewType.suggestedReviewers;
      if (article?.reviewType?.typeOfReview)
        articleDetail.reviewType.typeOfReview =
          article?.reviewType.typeOfReview;
      if (article?.runningTitle)
        articleDetail.runningTitle = article?.runningTitle;
      if (article?.submissionDetail?.articleType)
        articleDetail.submissionDetail.articleType =
          article?.submissionDetail.articleType;
      if (article?.submissionDetail?.journalName)
        articleDetail.submissionDetail.journalName =
          article?.submissionDetail.journalName;
      if (article?.submissionDetail?.journalType)
        articleDetail.submissionDetail.journalType =
          article?.submissionDetail.journalType;
      if (article?.submissionDetail?.societyName)
        articleDetail.submissionDetail.societyName =
          article?.submissionDetail.societyName;
      if (article?.submissionDetail?.specialization)
        articleDetail.submissionDetail.specialization =
          article?.submissionDetail.specialization;
      if (article?.submissionDetail?.subject)
        articleDetail.submissionDetail.subject =
          article?.submissionDetail.subject;
      if (article?.supplymentaryMaterial)
        articleDetail.supplymentaryMaterial = article?.supplymentaryMaterial;
      if (article?.title) articleDetail.title = article?.title;
      if (article?.updatedAt) articleDetail.updatedAt = article?.updatedAt;
      if (article?.articleVersion)
        articleDetail.articleVersion = article?.articleVersion;
      if (articleData?.data?.userDetail)
        articleDetail.userDetail =
          articleData?.data?.userDetail?.[0]?.user?.[0];
      setarticleDetails({ ...articleDetail });
    }
    getRecommendedArticles();
  };
  const { mutate: getArticleWithoutToken } =
    FetchArticleWithoutToken(sucessHandler); 

  const getRecommendedArticles = async () => {
    const headers = {};
    const params = {
      page: 1,
      limit: 3,
      userId: isLoggedIn ? userDetails?._id : articleDetails?.userId,
      sorting:-1
    };
    const response = await recommendedArticles(headers, params);
    if (response?.status === 200) {
      setRecommendedList(response?.data?.data);
    } else {
      return toast.error("Please try again!!");
    }
  };
  const addLastViewArticleLists = async (articleId) => {
    const headers = {};
    const params = {
      articleId: articleId,
      userId: userDetails?._id,
    };
    const response = await addLastViewArticle(headers, params);
    if (response?.status === 200) {
    } else {
      return toast.error("Please try again!!");
    }
  };
  const handleRedirect = (e, path) => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
const [updateTrigger, setUpdateTrigger]= useState(false)
  useEffect(() => {
    const articleID = router.pathname.split("/")[2];
    getArticleDetail(articleID);
    if (isLoggedIn) {
      addLastViewArticleLists(articleID);
    }
    getRating();
  }, [updateTrigger, articleID]);

  useEffect(() => {
    setDisabled(!rating.feedback);
  }, [rating]);

  useEffect(() => {
    setFlagFormDisabled(!(reason.length >= 1 && reasonComments));
  }, [reason, reasonComments])
  useEffect(() => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      headers: headers,
    };
    getProfanityList(queryParams);
  }, [])
  const sucessHandlerProfanity = (profanity) => {
    if (profanity.status === 200) {
      setProfanityWords(profanity.data.data);
    }
  };
  const { mutate: getProfanityList } = FetchProfanityList(
    sucessHandlerProfanity
  );
  async function getArticleDetail(id) {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      params: { articleId: id, userId: userDetails?._id },
    };
    getArticleWithoutToken(queryParams);
  }
  const [collapseActive, setIsActive] = useState(false);

  const [historyResult, setHistoryResult] = useState(false);
  const [imageDownload, setimageDownload] = useState(false);

  function checkEmptyArticle(details) {
    const regex = /(<([^>]+)>)/gi;
    let content = "";
    let images = details?.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/i);

    if (images || details?.replace(regex, "") !== "") content = details;
    return content;
  }
  function getAuthors() {
    return articleDetails;
  }

  function getTableOfContent() {
      let content = ["Abstract"];
      const regex = /(<([^>]+)>)/gi;

      if (articleDetails.articleData.articleSection.length > 0) {
        articleDetails.articleData.articleSection.forEach((article, k) => {
          let images = article.content.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/i);
          if (article.content.replace(regex, "") !== "" || images)
            content.push(article.title);
        });
      }
      let akimages = articleDetails.articleData.acknowledgement.match(
        /<img\s+[^>]*src="([^"]*)"[^>]*>/i
      );
      if (
        articleDetails.articleData.acknowledgement.replace(regex, "") !== "" ||
        akimages
      )
        content.push("Acknowledgements");
      if (articleDetails.articleData.references.replace(regex, "") !== "")
        content.push("References");
      return content;
  }

  function getNameFromUrl(url) {
    let name = "";
    const pieces = url.split(/[\s/]+/);
    const last = pieces[pieces.length - 1];
    if (last) {
      const dtl = last.split("_");
      if (dtl[1]) name = dtl[1].replace(/%20/g, " ");
      else name = last.replace(/%20/g, " ");
    }

    return name;
  }
  const getDateFormat = (date) => {
    let newDate = new Date(date);
    let getDate = newDate.getDate();
    let getYear = newDate.getFullYear();
    let getMonth = newDate.toLocaleString("en-US", { month: "short" });
    return getDate + " " + getMonth + " " + getYear;
  };

  const handleClick = (src, i) => {
    setPreviewImg(src);
    setPreviewImgCount(i + 1);
    setimageDownload(true);
  };

  function generatePdf() {
    handleGeneratePdf();
  }
  const isExactMatch = (str, match) => {
    return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
  };
  const escapeRegExpMatch = function (s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  };
  const submitRating = async () => {
   
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      userId: userDetails?._id,
      articleId: articleID,
      rating: Number(rating?.rate),
      feedbackText: rating?.feedback,
    };
    let isProfinityWord = false;
    const profanityWordList = [];
    for (var i = 0; i < profanityWords.length; i++) {
      if (isExactMatch(rating?.feedback.toLowerCase(), profanityWords[i])) {
        isProfinityWord = true;
        profanityWordList.push(profanityWords[i]);
      }
    }
    setProfanityWordsFound(profanityWordList);
    if (!rating?.rate) {
     
      setShowRateModal(true);
    }
    else if (isProfinityWord===true) {
      setprofanityAlert({ isOPen: true, type: 0 });
    } else {
      dispatch({ type: "SET_LOADER", payload: true });
      const response = await submitOpenReviewForArticle(headers, params);
      if (response?.status === 200) {
        dispatch({ type: "SET_LOADER", payload: false });
        setRating({ rate: "", feedback: "" });
        setShowReviewSuccess(true);
      } else {
        dispatch({ type: "SET_LOADER", payload: true });
        return toast.error("Please try again!!");
      }
    }
  };

  const handleSubmitFlagReview = async (status) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      userId: userDetails?._id,
      articleId: articleID,
      status,
      reason: reason,
      additonalComment: reasonComments,
      openReviewId,
    };
    const response = await submitFlagReview(headers, params);
    if (response?.status === 200) {
      setShowFlagReviewModal(false);
      setShowReporting(true);
      getRating();
      setReason("");
      setReasonComments("");
    } else {
      return toast.error("Please try again!!");
    }
  };

  const getRating = async () => {
    const queryParams = {
      params: {
        articleId: articleID,
        status: "approved",
        page: 1,
        limit: 6,
        userId: userDetails?._id,
      },
    };
    const response = await getOpenReviewForArticle(queryParams);
    if (response?.status === 200) {
      setAverageRating(response?.data?.data);
      setRatingResponse(response?.data?.data?.data);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleReadMore = (idx) => {
    let result = [...showReadMore];
    result[idx] = !result[idx];
    setShowReadMore(result);
  };

  const handleChangeCheckbox = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setReason([...reason, value]);
    } else {
      setReason(reason.filter((e) => e !== value));
    }
  }; 

  const tooltipIcon = (content) => {
    return (
      <div className="customTool">
        {["bottom"].map((placement) => (
          <OverlayTrigger
            key={placement}
            placement={placement}
            overlay={
              <Popover>
                <Popover.Body>
                  <div className="popoverToolWrap">
                    <p>{content}</p>
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <img className="pe-cursor ps-1" src={active_info} alt="no img" />
          </OverlayTrigger>
        ))}
      </div>
    );
  };

  const handleClick2 = (event) => { 
    const target = event.target;
        if (target.tagName.toLowerCase() === 'a') {
          // event.preventDefault();
          const hrefValue = target.getAttribute('href').substring(1);
          // console.log(hrefValue);
            const targetElement = document.getElementById(hrefValue);
            // console.log(targetElement.parentNode.parentNode);
            if (targetElement) {
                targetElement.parentNode.parentNode.classList.add('dshighlight');
                setTimeout(() => {
                  targetElement.parentNode.parentNode.classList.remove('dshighlight');
                }, 3000);
            }
        }
  }

  useEffect(() => { 

    document.addEventListener('click', handleClick2);
 
    // Clean up the event listener when the component unmounts
    // return () => {
    //     document.querySelectorAll('a').forEach(link => {
    //         link.removeEventListener('click', function(event) {
    //             // Your click event handler logic
    //         });
    //     });
    // };
}, []);
  useEffect(() => {
    let myArray = new Array(ratingResponse?.length).fill(false);
    setShowReadMore(myArray);
  }, [ratingResponse]);
  return (
    <>
      <AakashgangaHeader />
        <div className="inner-pages" ref={viewPublishedArticle}>
          <div className="centerSection pt-0">
            <div className="previewPageWrap w-100">
              <div
                className="previewHero"
                style={{
                  backgroundImage: `url(${articleDetails?.masthead ? articleDetails?.masthead : src})`,
                }}
              >
            <img src={`${articleDetails?.masthead ? articleDetails?.masthead : src}`} className="w-100" alt="" />
              </div>

              <div className="previewTags">
                <ViewHeaderLink
                  pagename="viewPublishedArticle"
                  details={{
                    first: usejournalData?.issnNumber,
                    second: articleDetails.submissionDetail.societyName,
                    fourth:
                      articleDetails.submissionDetail.articleType.articleName,
                  }}
                />
              </div>
              <div className="publiseTitle"><div className="innerMergin customds"><span style={{width:'70%',display:'block'}}>{articleDetails.title}</span></div></div>
              <Authors pagename="viewPublishedArticle" details={getAuthors()} />
              <PublishedDate
                pagename="viewPublishedArticle"
                onChange={handleFullscreen}
                details={{
                  status: articleDetails?.status,
                  publishedDate: getDateFormat(articleDetails?.updatedAt),
                }}
              />

              <div className="publisActionWrap">
              <div className="innerMergin">
                <ArticleLinks
                  pagename="viewPublishedArticle"
                  details={getAuthors()}
                  journalName={articleDetails?.submissionDetail?.journalName}
                  setUpdateTrigger = {setUpdateTrigger}
                />
              
              </div>
              </div>

              <div className="previewMain">
              <div className="innerMergin">
                <div className="row">
                  <div className="col-md-4">
                    <div className="mt-3">
                      <TableOfContent
                        pagename="viewPublishedArticle"
                        details={getTableOfContent()}
                      />

                      {articleDetails.keywords.length > 0 && (
                        <div
                          className={
                              "keyBox previewKey commanBox"
                          }
                        >
                          <span className="tagTitle">Keyword</span>
                          <div className="previewKeyArea">
                            {articleDetails.keywords.map((keyword, index) => (
                              <span key={keyword} className="t-tag">
                                {keyword}
                              </span>
                            ))}
                          </div>
                        </div>
                      )}
                      {articleDetails.abbreviations.length > 0 && (
                        <div
                          className={
                              "commanBox abbreviations mb-3 d-inline-block w-100"
                          }
                        >
                          <span className="tagTitle">Abbreviations</span>
                          {articleDetails.abbreviations.map(
                            (abbreviation, index) => (
                              <span
                                key={abbreviation}
                                className="t-tag abbreView"
                              >
                                <b>{abbreviation.apprv}</b>
                                <b>{abbreviation.fullForm}</b>
                              </span>
                            )
                          )}
                        </div>
                      )}
                      {articleDetails.images.length > 0 && (
                        <div
                          className={
                              "commanBox mb-25"
                          }
                        >
                          <span className="tagTitle">Images and Figures</span>

                          <div className="imgShowBox">
                            {articleDetails.images.map((src, index) => (
                              <b
                                onClick={(event) => handleClick(src, index)}
                                key={src}
                                style={{
                                  backgroundImage: `url(${src})`,
                                }}
                              ></b>
                            ))}
                          </div>
                        </div>
                      )}
                      {articleDetails.supplymentaryMaterial.length > 0 && (
                        <div
                          className={
                              "commanBox mb-25"
                          }
                        >
                          <span className="tagTitle">
                            Supplementary Material
                          </span>
                          <div className="docShowBox suppleBox">
                            {articleDetails?.supplymentaryMaterial?.length ===
                            0 ? (
                              <div className="fieldWrap">
                                <p>Nothing uploaded yet</p>
                              </div>
                            ) : (
                              articleDetails?.supplymentaryMaterial?.map(
                                (img, index) => (
                                  <div
                                    className="docShowList downloadImg"
                                    key={`mat${index}`}
                                  >
                                    <a href={img} target="_BLANK">
                                      <span>{getNameFromUrl(img)}</span>
                                      <b></b>{" "}
                                    </a>
                                  </div>
                                )
                              )
                            )}
                          </div>
                        </div>
                      )}
                      <LeftCommonLink
                        pagename="viewPublishedArticle"
                        details={{
                          img: infoI,
                          text: "Information, Rights and Permissions",
                          type: 'Information'
                        }}
                        
                      />
                      <LeftCommonLink
                        pagename="viewPublishedArticle"
                        details={{ img: copyC, text: "Copyright and License", type: 'Copyright' }}
                      />
                      {articleDetails?.advertisement?.adSpot1 ? (
                        <>
                        <Advertisement
                        pagename="viewPublishedArticle"
                        details={{ img: articleDetails?.advertisement?.adSpot1 ? articleDetails?.advertisement?.adSpot1 : adsport }}/> 
                        </>) : "" }
                        {recommendedList?.length ? (<>
                      <div
                        className={
                            "browseArticles mb-0 pb-0"
                        }
                      >
                        <span className="title">Browse by related articles</span>
                        <div className="row">
                                          {recommendedList && recommendedList?.length > 0 ? (
                                              recommendedList?.map((recomndArtcl, index) => {
                                                  const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
                                                  const contentWithoutSpecialChars = () => {
                                                      return recomndArtcl?.abstract?.replace(specialCharPattern, "");
                                                  };

                                                  return (
                                                          <RelatedArticles
                                                              index={index}
                                                              published={recomndArtcl?.updatedAt}
                                                              id={recomndArtcl?.readinglistId}
                                                              title={recomndArtcl?.title}
                                                              userTitle={
                                                                  recomndArtcl?.user?.[0]?.title
                                                              }
                                                              userName={
                                                                  recomndArtcl?.user?.[0]?.fullName
                                                              }
                                                              {...recomndArtcl?.authorDetails?.author?.map((autName) => {
                                                                  return (
                                                                      <>
                                                                          {autName.title}
                                                                          {autName.fullName}
                                                                      </>
                                                                  );
                                                              })}
                                                              contentWithoutSpecialChars={contentWithoutSpecialChars}
                                                              typeOfArticle={recomndArtcl?.typeofarticles?.[0]?.articleName}
                                                              journalName={
                                                                  recomndArtcl?.submissionDetail?.journalName
                                                              }
                                                              subjectName={recomndArtcl?.subjectDetail?.[0]?.subjectName}
                                                              specialization={recomndArtcl?.specializations?.[0]?.specialization}
                                                              fetchReadingList={getRecommendedArticles}
                                                              readingList={recommendedList}
                                                              articleId={recomndArtcl?._id}
                                                              pageName="articleLanding"
                                                              readingflag={recomndArtcl?.readingflag}
                                                          />
                                                  );
                                              })
                                          ) : (
                                              <div className="tabContentWrap">
                                                  <div className="notArticle">
                                                      <figure>
                                                          <img src={notyet} alt="No Article" />
                                                      </figure>
                                                      <h2>No articles yet!</h2>

                                                  </div>
                                              </div>
                                          )}
                                      </div>
                                      
                      </div>
                      </>) : "" }
                      {articleDetails?.advertisement?.adSpot2 ? (
                        <>
                      <Advertisement
                        pagename="viewPublishedArticle"
                        details={{ img: articleDetails?.advertisement?.adSpot2 ? articleDetails?.advertisement?.adSpot2 : adSpot2 }}
                      />
                      </>
                      ) : "" }
                      <AccesbilityContent
                        pagename="viewPublishedArticle"
                        details={{
                          title: "Accessibility",
                          desc: "Aakashganga, our research platform is designed with accessibility in mind, and we offer a range of options to ensure that all users can easily access and engage with our content. This includes alt text, description for images and captions for videos. We are committed to providing an inclusive experience for all users, regardless of their abilities or limitations.",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-8" >
                    <div ref={elementRef} className="publiceUiBox previewBox">
                     
                      <div className="publiceDetailsWrap d-none">
                        <ArticleCountList
                          pagename="viewPublishedArticle"
                          details={{
                            views: articleDetails?.articleViewCount,
                            citations: "--",
                            downloads: articleDetails?.articleDownloadCount,
                            shares: articleDetails?.articleShareCount,
                            openreview: articleDetails?.openReviewCount,
                          }}
                        />
                      </div>
                      <div className="previewBox">
                        <ContentDetails
                          pagename="viewPublishedArticle"
                          details={{
                            title: "Abstract",
                            desc: articleDetails.abstract,
                          }}
                        />
                        {articleDetails?.amendmentData?.map(
                            (amendment, index) => (
                              <>
                                {checkEmptyArticle(
                                  amendment?.amendmentContent
                                ) !== "" && (
                                  <Amendments
                                    pagename="viewPublishedArticle"
                                    index={index}
                                    details={amendment?.amendmentContent}
                                    submittedOn={getDateFormat(
                                      amendment?.amendmentDate
                                    )}
                                  />
                                )}
                              </>
                            )
                          )}

                        {articleDetails?.articleData?.articleSection?.map(
                            (article, index) => (
                              <>
                                {checkEmptyArticle(article?.content) !== "" && (
                                  <ContentDetails
                                    pagename="viewPublishedArticle"
                                    details={{
                                      title: article.title,
                                      desc: article.content,
                                    }}
                                  />
                                )}
                              </>
                            )
                          )}

                        {checkEmptyArticle(
                          articleDetails.articleData.acknowledgement
                        ) !== "" ? (
                          <ContentDetails
                            pagename="viewPublishedArticle"
                            details={{
                              title: "Acknowledgements",
                              desc: articleDetails.articleData.acknowledgement,
                            }}
                          />
                        ) : null}

                        {checkEmptyArticle(
                          articleDetails.articleData.references
                        ) !== "" ? (
                          <ContentDetails
                            pagename="viewPublishedArticle"
                            details={{
                              title: "References",
                              desc: articleDetails.articleData.references,
                            }}
                          />
                        ) : null}
                      </div>

                      {isLoggedIn ? null : (
                        <div className="loginBefore">
                          <h3 className="mb-4">
                          Please click one of the options below to download this article.
                          </h3>
                          <div className="loginBeforeBtn">
                            <button
                              className="button"
                              onClick={(e) => handleRedirect(e, "/login")}
                            >
                              Login
                            </button>
                            <button
                              className="button button-primary"
                              onClick={(e) => handleRedirect(e, "/registration")}
                            >
                              Register
                            </button>
                          </div>
                        </div>
                      )}
  </div>
                  
                  <div className="commitPostManage">
                      <div id={`viewScrollReviews`} style={{ scrollMarginTop:'95px'}} className="commitPostWrap">
                        {isLoggedIn ? (
                          <div className="commitPostArea">
                            <div className="commitPostAreaHead">
                              <h2>Post Publication Open Peer Reviews</h2>
                              <h3>
                                Overall Article Rating:{" "}
                                <span>
                                  {averageRating?.averageRating
                                    ? averageRating?.averageRating + "/5"
                                    : "--"}
                                </span>
                              </h3>
                            </div>

                            <div className="commitPostAreaRate">
                              <h2>
                                Please rate this article on a scale of 1 to 5
                                <p>1 being "Very poor" and 5 being "Excellent"</p>
                              </h2>

                              <div className="rateHere">
                                <i>Rate here:</i>
                                <span>
                                  {[1, 2, 3, 4, 5].map((num) => (
                                    <b
                                      className={
                                        rating.rate === num ? "active" : ""
                                      }
                                      onClick={() =>
                                        setRating({ ...rating, rate: num })
                                      }
                                      key={num}
                                    >
                                      {num}
                                    </b>
                                  ))}
                                </span>
                              </div>
                            </div>
                            <div className="commitPostAreaText">
                              <textarea
                                onChange={(e) =>
                                  setRating({
                                    ...rating,
                                    feedback: e.target.value,
                                  })
                                }
                                value={rating.feedback}
                                placeholder="Please add your review here"
                              />
                              <div className="text-right commitPostAreaBtn">
                                <span
                                  className="button-link-gray"
                                  onClick={() =>
                                    setRating({ rate: "", feedback: "" })
                                  }
                                >
                                  CANCEL
                                </span>
                                <button
                                  className="button button-primary"
                                  onClick={submitRating}
                                  disabled={disabled}
                                >
                                  Post
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="commitPostArea">
                            <div className="commitPostAreaHead">
                              <h2>
                                POST PUBLICATION OPEN REVIEWS
                                <p>
                                To post a comment, please login with your credentials or register with us.
                                </p>
                              </h2>
                              <div className="loginBeforeBtn">
                                <button
                                  className="button"
                                  onClick={(e) => handleRedirect(e, "/login")}
                                >
                                  Login
                                </button>
                                <button
                                  className="button button-primary"
                                  onClick={(e) =>
                                    handleRedirect(e, "/registration")
                                  }
                                >
                                  Register
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        <div >
                          {ratingResponse?.length > 0 ? (
                            ratingResponse?.map((rating, index) => (
                              <div className={"commitShow"}>
                                <div className="commitShowRepeat">
                                  <div className="commitShowHead">
                                    <div className="commitShowHeadUser">
                                      <figure
                                        style={{
                                          backgroundImage: `url(${rating?.user?.[0]?.profilePicture})`,
                                        }}
                                      ></figure>
                                      <b>{rating?.user?.[0]?.fullName}</b>
                                      <span>
                                        {getDateFormat(rating?.createdAt)}
                                      </span>
                                      <small>
                                        Rating: <i>{rating?.rating}</i>
                                      </small>
                                    </div>
                                    {userDetails?._id !== rating?.userId ? (
                                      <div
                                        className={
                                          rating?.flagDisable === true
                                            ? "flogCommit opacity-5"
                                            : "flogCommit"
                                        }
                                      >
                                        <b
                                          onClick={() => {
                                            !isLoggedIn ? (
                                              navigate('/login', { replace: true })
                                            ) : 
                                            setShowFlagReviewModal(true);
                                            setOpenReviewId(rating?._id);
                                          }}
                                        ></b>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="">
                                    {showReadMore[index] ? (
                                      <p>{rating?.feedbackText}</p>
                                    ) : (
                                      <p>
                                        {rating?.feedbackText
                                          ?.split(" ")
                                          ?.slice(0, MAX_WORDS)
                                          .join(" ")}
                                      </p>
                                    )}
                                    {rating?.feedbackText?.split(" ")?.length >
                                      MAX_WORDS &&
                                    showReadMore[index] === false ? (
                                      <span
                                        className="readMore"
                                        onClick={() => handleReadMore(index)}
                                      >
                                        READ MORE
                                      </span>
                                    ) : (
                                      rating?.feedbackText?.split(" ")?.length >
                                        MAX_WORDS && (
                                        <span
                                          className="readMore"
                                          onClick={() => handleReadMore(index)}
                                        >
                                          READ LESS
                                        </span>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : isLoggedIn ? (
                            <div className="tabContentWrap">
                              <div className="notArticle">
                                <figure>
                                  <img src={notpost} alt="#" />
                                </figure>
                                <h2>No reviews yet!</h2>
                                <p>
                                  Be the first one to share what you think
                                  <span>As someone leaves a review, it will appear here...</span>
                                </p>
                                
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      </div>
                      </div>
                </div>

                <div className="backBtnWrap mt-5">
                  <a onClick={() => window.history.back()}>
                    <span>Back</span>
                    <img src={backArrow} alt="#" />
                  </a>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>

      <Modal
        size="sm"
        show={showRateModal}
        id="congratsMobile"
        tabIndex={-1}
        className=" authorModal authorModalRed"
        centered
      >
  
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={alertSvg} alt="#" />
          </div>
          <h5 className="modal-title mb-0 mt-0">just one more step</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setShowRateModal(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="congratulationsPopup">
            <p className="text-center">
              Please rate the article before <br /> submitting the review
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="button button-primary redBtn"
            onClick={() => setShowRateModal(false)}
          >
            OKAY
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        keyboard={false}
        show={showFlagReviewModal}
        dialogClassName="modal-dialog-centered modal-md authorModal"
        tabIndex={-1}
        size="lg"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">flag review</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setShowFlagReviewModal(false)}
          />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="rejectModal">
            <div className="row">
              <div className="col-md-12">
                <p>Please specify your reason</p>
              </div>
              <div className="flagReviewCheck">
              <ul>
                <li>
                <label className="customCheck">
                <input
                    type="checkbox"
                    id="Methodological concerns"
                    value="Methodological concerns"
                    onChange={handleChangeCheckbox}
                  />
                    <i/>
                    <b>Methodological concerns
                  {tooltipIcon(
                    "You may have identified potential flaws or limitations in the research methodology employed in the article. If you would like to request further clarification or suggest alternative approaches that may enhance the study's validity and reliability, please leave your comments in the box below."
                  )}
                    </b>
                  </label>
                 
                  
                </li>
                <li>
                  <label className="customCheck">
                    <input
                      type="checkbox"
                      id="Inaccuracies errors"
                      value="Inaccuracies errors"
                      onChange={handleChangeCheckbox}
                    />
                    <i/>
                    <b>Inaccuracies errors {tooltipIcon(
                      "If you have noticed factual inaccuracies, data discrepancies, or logical inconsistencies within the published article, please flag it to bring these issues to the attention of the author, editors, and other readers. This can help ensure the accuracy and integrity of the research."
                    )}
                    </b>
                  </label>
                 
                </li>
                <li>
                <label className="customCheck">
                    
                  <input
                    type="checkbox"
                    id="Ethical concerns"
                    value="Ethical concerns"
                    onChange={handleChangeCheckbox}
                  />
                    <i/>
                    <b>Ethical concerns
                  {tooltipIcon(
                    "If you suspect ethical misconduct, such as plagiarism, data fabrication, or improper authorship attribution, please flag the article for these potential violations. Ethical considerations are vital in maintaining the credibility and trustworthiness of academic research."
                  )}
                    </b>
                  </label>
                  
                  
                </li>
                <li>
                <label className="customCheck">
                <input
                    type="checkbox"
                    id="Replication or generalizability concerns"
                    value="Replication or generalizability concerns"
                    onChange={handleChangeCheckbox}
                  />
                      <i/>
                      <b>Replication or generalizability concerns
                  {tooltipIcon(
                    "If as a reader, you have attempted to replicate the study's findings or believe that the results have limited generalizability, you may flag the article. This could be an opportunity to discuss potential discrepancies or limitations and encourage further research to validate or expand upon the original findings."
                  )}
                      </b>
                    </label>
                </li>
                <li>
                <label className="customCheck">
                <input
                    type="checkbox"
                    id="Novelty and contribution"
                    value="Novelty and contribution"
                    onChange={handleChangeCheckbox}
                  />
                      <i/>
                      <b>Novelty and contribution
                  {tooltipIcon(
                    "You might flag the article to express your views on the article's novelty and contribution to the field. The reader may argue that the research lacks originality or fails to provide significant new insights, or they may highlight the article's unique contributions that deserve recognition and further discussion."
                  )}</b>
                    </label>
                </li>
              </ul>
              <ul>
                <li>
                <label className="customCheck">
                <input
                    type="checkbox"
                    id="Open data and reproducibility"
                    value="Open data and reproducibility"
                    onChange={handleChangeCheckbox}
                  />
                      <i/>
                      <b> Open data and reproducibility
                  {tooltipIcon(
                    " If the article lacks transparency regarding data availability or fails to provide sufficient information for others to reproduce the findings, a reader may flag it. This encourages the author to share their data openly, enabling other researchers to verify and build upon the published research."
                  )}</b>
                    </label>
                </li>
                <li>
                <label className="customCheck">
                <input
                    type="checkbox"
                    id="Controversial or sensitive topics"
                    value="Controversial or sensitive topics"
                    onChange={handleChangeCheckbox}
                  />
                      <i/>
                      <b> Controversial or sensitive topics
                  {tooltipIcon(
                    "If the article discusses controversial or sensitive subjects, as a reader, you might flag it to draw attention to potential ethical, social, or cultural implications. This can stimulate broader discussions and ensure responsible and considerate treatment of such topics."
                  )}</b>
                    </label>
                </li>
                <li>
                <label className="customCheck">
                <input
                    type="checkbox"
                    id="Unresolved questions or ambiguities"
                    value="Unresolved questions or ambiguities"
                    onChange={handleChangeCheckbox}
                  />
                      <i/>
                      <b>Unresolved questions or ambiguities
                  {tooltipIcon(
                    "As a reader, you may identify unresolved questions, ambiguities, or gaps in the research, which you believe requires further investigation or clarification. Flagging the article allows for post-publication open access review to address these concerns and encourage scholarly dialogue."
                  )}</b>
                    </label>

                  
                  
                </li>
                <li>
                <label className="customCheck">
                <input
                    type="checkbox"
                    id="Conflicts of interest"
                    value="Conflicts of interest"
                    onChange={handleChangeCheckbox}
                  />
                      <i/>
                      <b>Conflicts of interest
                  {tooltipIcon(
                    " If the reader suspects undisclosed conflicts of interest that may have influenced the research or its conclusions, they may flag the article. This helps ensure transparency and prevents potential bias or undue influence on the study's outcomes."
                  )}</b>
                    </label>

                  
                  
                </li>
                <li>

                <label className="customCheck">
                <input
                    type="checkbox"
                    id="Data interpretation or statistical analysis"
                    value="Data interpretation or statistical analysis"
                    onChange={handleChangeCheckbox}
                  />
                      <i/>
                      <b>Data interpretation or statistical analysis
                  {tooltipIcon(
                    "If the reader questions the validity of the data interpretation or statistical analysis presented in the article, they may flag it to initiate a discussion on alternative interpretations or analytical approaches. This contributes to the refinement of research methodologies and enhances the robustness of the results."
                  )}</b>
                    </label>
                  
                  
                  
                </li>
              </ul>
              </div>
              <div className="col-md-12">
                <div className="additionalTextarea flagReviewTextarea">
                  {reason?.length >= 1 ? (
                    <span>
                      Please let us know the reason(s) for your choice
                      <sup class="supStar">*</sup>
                    </span>
                  ) : (
                    <span>
                      Please elaborate the reason why you are reporting this
                      article<sup class="supStar">*</sup>
                    </span>
                  )}

                  <textarea
                    className="inputField"
                    placeholder="Enter here..."
                    onChange={(e) => setReasonComments(e.target.value)}
                    value={reasonComments}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <button
            class="button button-primary mb-4 mt-4"
            type="submit"
            disabled={flagFormDisabled}
            onClick={() => handleSubmitFlagReview("pending")}
          >
            SUBMIT
          </button>
          <div class="guidelinesWrap pl-3 pr-0">
            <span>
              <sup class="supStar">*</sup>Mandatory
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-0">
          
        
        <div className="helpPopupText pb-2">
           
              Need help? Write to us at{" "}
              <a href="mailto:contactus@vikramshilaedu.in">
                contactus@vikramshilaedu.in
              </a>
            
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showReporting}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header">
          <div class="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h2 class="modal-title p-0">Thank you for reporting this article</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowReporting(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAss text-left pl-2 pr-2">
            <p>
              We will conduct a thorough review to assess the article's validity
              in context to the specific concern you have raised. Your feedback
              is of utmost importance to us, and we take every reported issue
              seriously.
            </p>
            <p className="mt-4">
              Thank you for your vigilance and for being an essential part of
              our academic community. We are committed to transparency and
              accountability, and will inform you about our decision in{" "}
              <u>5 working days.</u>
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer pb-0">
          <button
            class="button button-primary mb-4"
            onClick={() => setShowReporting(false)}
          >
            Okay
          </button>
          <div className="helpPopupText pb-3">
            Need help? Write to us at
            <a href="mailto:contactus@vikramshilaedu.in">
              contactus@vikramshilaedu.in
            </a>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogClassName="modal-dialog-centered "
        show={showReviewSuccess}
        id="congratsMobile"
        tabIndex={-1}
        className="authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <h2 class="modal-title">post publication open peer review</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowReviewSuccess(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAss text-center pl-0 pr-0">
            <p className="mb-2">Thank you for reviewing this article.</p>
            <p>We will let you know when your review is published.</p>
          </div>
          <div className="text-center mt-4">
            <button
              className="button button-primary"
              onClick={() => setShowReviewSuccess(false)}
            >
              okay
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-2">
          <div className="helpPopupText mt-0 pt-2">
            <ul className="helpPopupList">
              <li>
                <a href={SERVER_URL} target="_blank">
                  AakashGanga
                </a>
              </li>
              <li>
                <a href="/dashboard/submit-article" target="_blank">
                  Write an Article
                </a>
              </li>
              <li>
                <a href="/resourcecentre" target="_blank">
                  Resources
                </a>
              </li>
              <li>
                <a href="/dashboard" target="_blank">
                  Dashboard
                </a>
              </li>
            </ul>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={historyResult}
        size="md md-x"
        id="unicheck-results__modal"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered authorModal"
        className="md-x"
      >
        <Modal.Header>
          <h5 className="modal-title pr-color">how to cite this article</h5>
          <button
            type="button"
            className="btn-close"
            onClick={(e) => setHistoryResult(false)}
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body className="pt-0 pb-2">
          <div className="citeModalWrap">
            <div className="citeText">
              [ Ayoubi R, Fotouhi M, Southern K et al. The identification of
              high antibodies for Vacuolar protein sorting-associated protein 35
              (hVPS35) for use in Western Blot, immunoprecipitation and
              immunofluorescence [version 1; peer review: awaiting peer review].
              Aakashganga 2023, 12:452
              (https://doi.org/10.12688/Aakashganga.133696.1) ]
            </div>

            <div className="questionBox">
              <span>
                It is important to ensure the information in square brackets
                after the title is included in all citations of this article.
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer pb-3">
          <button
            className="button-link"
            onClick={(e) => handleRedirect(e, "/myArticle/submit")}
          >
            <img src={copy} alt="#" /> Copy Citation Details
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={imageDownload}
        size="lg"
        id="unicheck-results__modal"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered authorModal"
      >
        <Modal.Header className="imgDownloadHeader">
          <h5>figure {previewImgCount}</h5>
          <div className="imgDownloadBtn">
            <button
              type="button"
              className="btn-close"
              onClick={(e) => setimageDownload(false)}
            >
              close
            </button>
            <a className="button-link" href={previewImg} target="_BLANK">
              download image
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-2">
          <div className="imageDownloadWrap">
            <figure>
              <img src={previewImg} alt="#" />
            </figure>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        dialogclassName=""
        show={profanityAlert.isOPen}
        id="congratsMobile"
        tabIndex={-1}
        className={
          profanityAlert.type === 0
            ? "authorModal md-x"
            : "authorModal rightSide profanity-content-right"
        }
        centered
      >
        
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            <img src={alertSearch} alt="#" />
          </div>
          <h5 className="modal-title">PROFANITY check Results</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={(e) => setprofanityAlert({ isOPen: false, type: 0 })}
          ></button>
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0 pl-2 pr-2">
          <div className="grantDetails alertPopup">
            <div className="checkResultText">
              <strong>
                Thank you for sharing your assessment for this article.{" "}
              </strong>
              <p>
                Before your assessment is processed, please make sure that the
                following words are removed :
              </p>
            </div>

            <div className="offensiveBox">
              <ol>
                {profanityWordsFound.map((wrd, index) => (
                  <li>{wrd}</li>
                ))}
              </ol>
            </div>
            <div className="mt-4 mb-3">
              {profanityAlert.type === 0 && (
                <button
                  className="button button-primary"
                  type="button"
                  onClick={(e) => setprofanityAlert({ isOPen: false, type: 0 })}
                >
                  make Amendments
                </button>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="pb-0">
          <div className="helpPopupText pb-3">
            Need help? Write to us at
            <a href="mailto:contactus@vikramshilaedu.in">
              contactus@vikramshilaedu.in
            </a>
          </div>
        </Modal.Footer>
      </Modal>
      {/* <div
      className="pullUp resize"
      onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <b className="icon-arrow-right" />
    </div>
      <div
      className="pullUp resize"
      style={{bottom: "30px"}} 
      onClick={(e) => window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" })}
    >
      <b className="icon-arrow-left" />
    </div> */}
    <AakashgangaFooter />
    </>
  );
}
export default viewPublishedArticle;
