import React from "react"
import { useNavigate, Link } from "react-router-dom";
const FaqBanner = () => {
    const navigate = useNavigate();
    return (
        <div className='ag_main_wrap ag_banner_wrap'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-12 col-12 hero'>
                        <div className='ag_content_text faq-banner-content'>
                            <h2 className="questionsstyle tagh2">Any Questions For Us?</h2>
                            <p className="fontsize22 tagp">Explore our FAQs section for<br />answers to all your questions
                            </p>
                            <p className="fontsize22 mar tagp" >We're here to help.</p>
                            
                            <Link
                                to="/foreign-societies-partnerships"
                                onClick={() => navigate("/foreign-societies-partnerships")}
                                className="ag_btn_red"
                            >
                                CONTACT US
                            </Link>

                        </div>
                            <div className=''>
                                <div className='faqimg faq-banner'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FaqBanner;