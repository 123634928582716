import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import akashjournalimg from "../../assets/assets_html/images/akashjournalimg.png"
import coinimg from "../../assets/assets_html/images/coinimg.png"
import penimg from "../../assets/assets_html/images/penimg.png"
import empimg from "../../assets/assets_html/images/empimg.png"
import questionmark from "../../assets/assets_html/images/questionmark.png"
import mobileimg from "../../assets/assets_html/images/mobileimg.png";
const opportunityData = [
	{
		id: 0,
		heading: "Why Publish with Us?",
		description:
			"Understand the benefits of Open Science, and how Aakashganga helps you realise your dream of getting published!",
		imgUrl: akashjournalimg,
		imgalt: "Publish With Us",
		link: "/publish-with-us"
	},
	{
		id: 1,
		heading: "Publication Fee",
		description:
			"We offer one the most affordable APC globally. Publish with us to reap financial as well as academic benefits.",
		imgUrl: coinimg,
		imgalt: "Article Processing Charges",
		link: "/apc"
	},
	{
		id: 2,
		heading: "Author Guidelines",
		description:
			"Here is everything that you need to read before you decide to publish Open Access.",
		imgUrl: penimg,
		imgalt: "Author Submission Guidelines",
		link: "/author-submission-guidelines"
	},
	{
		id: 3,
		heading: "Join Us and Contribute",
		description:
			"Be a part of the largest database of research professionals. Register your interest to become a Peer Reviewer and/or a Publishing Editor.",
		imgUrl: empimg,
		imgalt: "Join Us And Contriute",
		link: "/registration"
	},
	{
		id: 4,
		heading: "All Your Questions Answered",
		description:
			"Get answers to all the questions that you have before beginning your journey. Right here, right now!",
		imgUrl: questionmark,
		imgalt: "Faq Section",
		link: "/faq"
	},
	{
		id: 5,
		heading: "Resource Hub to Learn",
		description:
			"Access our carefully curated resources, such as blog articles, PPts and videos for guidance.",
		imgUrl: mobileimg,
		imgalt: "Resource Hub",
		link: "https://vikramshilaedu.in/resourcecentre"
	},
];
 

function InterestedSection({ staticPageLinks}) {
	// console.log("staticPageLinks----",staticPageLinks);  
	const navigate = useNavigate();
	const [opportunityList, setOpportunityList] = useState((staticPageLinks && staticPageLinks.length > 0)?staticPageLinks:[]);
	const handleRedirect = (e, path = "/registration") => {
		e.preventDefault();
		window.scroll({ top: 0, behavior: "smooth" });
		navigate(path);
	  };

	const readMoretoggle = (opportunity) => {
		const updated = opportunityList.map(item => {
			if (item.heading === opportunity.heading) {
				return ({ ...item, showMore: !opportunity.showMore })
			} else {
				return item
			}
		})
		setOpportunityList(updated)
	}

	
	return opportunityList.map((opportunity, index) => (
		<div
		key={opportunity.title}
		className='col-xl-4 col-lg-6 col-sm-6 opportunity-card-container blog-type'
		>
			<div
				className='ag_opportunities_box aos-init'
				data-aos='zoom-in-up'
			>
				<Link 
					className='imgLinkWrap'
					to={opportunity?.link}
					target={opportunity?.id === 3 ? "" : "_blank"}
				>
					<div className={`cimg interestedImg ${opportunity?.showMore === true ? 'hide' : ''}`}
						style={{ backgroundImage: opportunity.imgUrl }}
					>
						<img src={opportunity?.image} alt={opportunity.imgalt} />
						</div>
					
					<h3
						className={
							opportunity?.showMore
							? "cactive redHeader tagh3 show"
							: "cactive tagh3 redHeader "
						}
					>{opportunity.title}</h3>
				</Link>
				<p
          className={
            index === 0 ? "cactive-descrip tagp" : "cactive-descrip tagp"
          }
        >{opportunity.desc}</p>
				<ul
          className={
            opportunity?.showMore
              ? "cactive ag_oppbullets tagul show"
              : "cactive ag_oppbullets tagul"
          }
        >
					{opportunity?.details?.map((item) => (
						<li className="tagli" key={item}>{item}</li>
					))}
				</ul>
				<Link to={opportunity?.link} target={opportunity?.id === 3 ? "" : "_blank"}  className='ag_toggle read_more'
				>
					Read More
				</Link>
			</div>
		</div>
	))

}

export default InterestedSection;
