import React from "react";

const RelatedJournals = ({
  published,
  title,
  userTitle,
  contentWithoutSpecialChars,
}) => {
  const MAX_WORDS = 10;
  const MAX_TITLE_WORDS = 5;
  // console.log("title", title)
  return (
    <>
      <div className="recommendedBox ml-0 mr-0 bgColorNone w-100">
        <div className="recommendedHead">
          <span>
            <b>Published Articles:</b> {published}
          </span>
        </div>
        <div className="recommendedTitle">
        {title?.split(" ").length > MAX_WORDS ? (
          <h2 onClick={() => (
            title === 'Samaj Shastra – The Mega Journal of Social Sciences'? window.open('/mega-journals/social-sciences')
            :title === 'Chanakya Shastra – The Mega Journal of Business and Management'? window.open('/mega-journals/business-management')
            :title === 'Charakh Shastra – The Mega Journal of Medicine'? window.open('/mega-journals/ayurveda-and-yoga')
            :title === 'Abhiyantran Shastra – The Mega Journal of Engineering'? window.open('/explore-journals/engineering-megajournal-AYSJE')
          :title === 'Soochna Shastra – The Mega Journal of Information Technology'? window.open('/explore-journals/information-technology-megajournal-SSJIT')
        :title === 'Vigyan Shastra – The Mega Journal of Sciences'? window.open('/mega-journals/ayurveda-and-yoga')
      :title === "AyurYoga Shastra – The Mega Journal of Ayurveda and Yoga" ? window.open('/explore-journals/Ayurveda-Yoga-megajournal-AJAY') : null
            )}
              dangerouslySetInnerHTML={{
                __html:
                "title"
                    ?.split(" ")
                    ?.slice(0, MAX_TITLE_WORDS)
                    .join(" ") + "....",
              }}
            ></h2>
          ) : (
            <h2 onClick={() => (
              title === 'Samaj Shastra – The Mega Journal of Social Sciences'? window.open('/mega-journals/social-sciences')
              :title === 'Chanakya Shastra – The Mega Journal of Business and Management'? window.open('/mega-journals/business-management')
              :title === 'Charakh Shastra – The Mega Journal of Medicine'? window.open('/mega-journals/ayurveda-and-yoga')
              :title === 'Abhiyantran Shastra – The Mega Journal of Engineering'? window.open('/explore-journals/engineering-megajournal-AYSJE')
            :title === 'Soochna Shastra – The Mega Journal of Information Technology'? window.open('/explore-journals/information-technology-megajournal-SSJIT')
          :title === 'Vigyan Shastra – The Mega Journal of Sciences'? window.open('/mega-journals/ayurveda-and-yoga')
        :title === "AyurYoga Shastra – The Mega Journal of Ayurveda and Yoga" ? window.open('/explore-journals/Ayurveda-Yoga-megajournal-AJAY') : null
              )}
              dangerouslySetInnerHTML={{
                __html: title
                  ?.split(" ")
                  ?.slice(0, MAX_TITLE_WORDS)
                  .join(" "),
              }}
            ></h2>
            )}
          <h3>{userTitle}</h3>
        </div>
        <div className="recommendedContent">
          {contentWithoutSpecialChars()?.split(" ").length > MAX_WORDS ? (
            <p
              dangerouslySetInnerHTML={{
                __html:
                  contentWithoutSpecialChars()
                    ?.split(" ")
                    ?.slice(0, MAX_WORDS)
                    .join(" ") + "....",
              }}
            ></p>
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: contentWithoutSpecialChars()
                  ?.split(" ")
                  ?.slice(0, MAX_WORDS)
                  .join(" "),
              }}
            ></p>
          )}
        </div>
      </div>
    </>
  );
};
export default RelatedJournals;
