import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import flag from "../../../assets/img/icons/author_flag.svg";
import ConflictInterestModal from "./ConflictInterestModal";

const ArticleDetailsModal = ({
  showArticleDetails,
  setShowArticleDetails,
  status,
  articleData,
  currentData,
  userDetail,
}) => {
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const getAbstract = (abstract) => {
    const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
    const contentWithoutSpecialChars = () => {
      return abstract?.replace(specialCharPattern, "");
    };
    const truncateText = contentWithoutSpecialChars();
    return truncateText;
  };

  const getIntroduction = (introduction) => {
    const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
    const contentWithoutSpecialChars = () => {
      return introduction?.replace(specialCharPattern, "");
    };
    const truncateText = contentWithoutSpecialChars();
    return truncateText;
  };

  const getUserDetails = (user) => {
    return (
      user?.name +
      ", " +
      user?.department +
      ", " +
      user?.city +
      ", " +
      user?.state
    );
  };

  return (
    <>
      <Modal
        show={showArticleDetails}
        keyboard={false}
        backdrop="static"
        size="lg"
        id="unicheck-results__modal"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered authorModal"
      >
        <Modal.Header className="modal-header pb-0">
          <h5 className="modal-title uploadTitle pb-0">Article Details</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={(e) => setShowArticleDetails(false)}
          />
        </Modal.Header>
        {status === "Peer Review" ? (
          <Modal.Body className="pb-0 pt-0">
            <div className="articleDetailsPopup">
              <div className="articleDetailsPopupHead">
                <strong>{articleData?.title}</strong>
              </div>

              <div className="articleDetailsPopupContent">
                <b className="commanTitle02 mt-2">ABSTRACT</b>
                <p
                  dangerouslySetInnerHTML={{
                    __html: getAbstract(articleData?.abstract),
                  }}
                ></p>
                <b className="commanTitle02">
                  {articleData?.articleData?.articleSection?.[0]?.title.toUpperCase()}
                </b>
                <p
                  dangerouslySetInnerHTML={{
                    __html: getIntroduction(
                      articleData?.articleData?.articleSection[0]?.content
                    ),
                  }}
                ></p>
                <div className="footTag">
                  {articleData?.submissionDetail?.articleType && (
                    <span className="t-tag">
                      {articleData?.submissionDetail?.articleType?.articleName}
                    </span>
                  )}
                  {articleData?.submissionDetail?.journalName && (
                    <div className="t-tag-journalWrap">
                      <span className="t-tag t-tag-journal">
                        {articleData.submissionDetail?.journalName}
                      </span>
                    </div>
                  )}
                  {articleData?.submissionDetail?.subject && (
                    <span className="t-tag">
                      {articleData?.submissionDetail?.subject?.subjectName}
                    </span>
                  )}
                  {articleData?.submissionDetail?.specialization && (
                    <span className="t-tag">
                      {
                        articleData?.submissionDetail?.specialization
                          ?.specialization
                      }
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        ) : status === "Publishing Editor" ? (
          <Modal.Body className="pb-0 pt-0">
            <div className="articleDetailsPopup">
              <div className="articleDetailsPopupHead">
                <strong>{articleData?.title}</strong>
              </div>

              <div className="articleDetailsPopupHeadContent">
                <b>
                  {userDetail?.[0]?.user?.[0]?.title +
                    " " +
                    userDetail?.[0]?.user?.[0]?.fullName}
                  {articleData?.authorDetails?.author?.length > 1 &&
                  articleData?.authorDetails?.author?.map(
                    (auth) =>
                      auth?.fullName === userDetail?.[0]?.user?.[0]?.fullName
                  )
                    ? ", "
                    : ""}
                </b>
                {articleData?.authorDetails?.author
                  ?.filter(
                    (auth) =>
                      auth?.fullName !== userDetail?.[0]?.user?.[0]?.fullName
                  )
                  .map((author, index) => {
                    return (
                      <b>
                        {(index ? ", " : "") +
                          author?.title +
                          " " +
                          author?.fullName}
                      </b>
                    );
                  })}
              </div>

              <div className="authorAff">
                {getUserDetails(
                  userDetail?.[0]?.user?.[0]?.affiliation?.affiliations?.[0]
                )}
              </div>

              <a
                className="button-link statementBtn"
                onClick={() => {
                  setShowArticleDetails(false);
                  setShowAdditionalDetails(true);
                }}
              >
                AUTHOR/S STATEMENT
                <img src={flag} alt="#" className="ml-1" />
              </a>

              <div className="articleDetailsPopupContent">
                <b className="commanTitle02 mt-2">ABSTRACT</b>
                <p
                  dangerouslySetInnerHTML={{
                    __html: getAbstract(articleData?.abstract),
                  }}
                ></p>
                <b className="commanTitle02">
                  {articleData?.articleData?.articleSection?.[0]?.title.toUpperCase()}
                </b>
                <p
                  dangerouslySetInnerHTML={{
                    __html: getIntroduction(
                      articleData?.articleData?.articleSection[0]?.content
                    ),
                  }}
                ></p>
                <div className="footTag">
                  {articleData?.submissionDetail?.articleType && (
                    <span className="t-tag">
                      {articleData?.submissionDetail?.articleType?.articleName}
                    </span>
                  )}
                  {articleData?.submissionDetail?.journalName && (
                    <span className="t-tag">
                      {articleData.submissionDetail?.journalName}
                    </span>
                  )}
                  {articleData?.submissionDetail?.subject && (
                    <span className="t-tag">
                      {articleData?.submissionDetail?.subject?.subjectName}
                    </span>
                  )}
                  {articleData?.submissionDetail?.specialization && (
                    <span className="t-tag">
                      {
                        articleData?.submissionDetail?.specialization
                          ?.specialization
                      }
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        ) : null}

        <Modal.Footer>
          <div className="articleFootBtn">
            <button
              className="button-link"
              type="button"
              onClick={() => setShowArticleDetails(false)}
            >
              Done
            </button>
            {status === "Peer Review" ? (
              <Link
                className="button button-primary"
                to={{
                  pathname: `/dashboard/article/${articleData?._id}/review-article`,
                }}
                state={{
                  _id: articleData?._id,
                  prStatus: "draft",
                  acceptId: currentData?.[0]?._id,
                }}
              >
                View full article
              </Link>
            ) : status === "Publishing Editor" ? (
              <Link
                className="button button-primary"
                to={{
                  pathname: `/dashboard/article/${articleData?._id}/review-article`,
                }}
                state={{
                  _id: articleData?._id,
                  status: "draft",
                  acceptId: currentData?.[0]?._id,
                  userDetail: userDetail,
                }}
              >
                View full article
              </Link>
            ) : null}
          </div>
        </Modal.Footer>
      </Modal>

      <ConflictInterestModal 
        showAdditionalDetails={showAdditionalDetails}
        setShowAdditionalDetails={setShowAdditionalDetails}
        articleData={articleData}
        type={"articledetails"}
        setShowArticleDetails={setShowArticleDetails}
      />
    </>
  );
};

export default ArticleDetailsModal;
