import React from "react";
import ayuryogaimg from "../../assets/assets_html/images/ayuryogaimg.png"

const JournalMainBanner = ({imgUrl, alt}) => {
	return (
		<div className='ag_main_wrap ag_banner_wrap'>
			<div className='container journalBannercontainer'>
				<div className='row align-items-center'>
					<div className='col-lg-12 col-12 hero'>
						<div className=''>
							<div className='videocontainer'>
								<img
									src={imgUrl}
									alt={alt}
									className="tagimg w-100"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default JournalMainBanner;
