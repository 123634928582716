import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

const LeftCommonLink = ({ pagename, details }) => {
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [showCopyrightModal, setShowCopyrightModal] = useState(false);

  const isLoggedIn = useSelector(
    (state) => state?.LoginReducer?.userLoginDetails?.token ?? null
  );

  return (
    <>
      <div
        className={
            "commanBox copyInfoMain"
        }
        onClick={() => {
          details.type === "Information"
            ? setShowInformationModal(true)
            : details.type === "Copyright"
            ? setShowCopyrightModal(true)
            : null
        }}
      >
        <div className={`copyInfo ${details?.class}`}>
          <img src={details.img} alt="#" /> {details.text}
        </div>
      </div>

      <Modal
        dialogclassName="modal-dialog-centered modal-lg"
        size="lg"
        className="authorModal"
        show={
          details?.type === "Information"
            ? showInformationModal
            : details?.type === "Copyright"
            ? showCopyrightModal
            : null
        }
        backdrop="static"
        id="congratsMobile"
        tabIndex={-1}
        centered
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">
            {details?.type === "Information"
              ? "Rights and Permissions"
              : details?.type === "Copyright"
              ? "Copyright and License"
              : null}
          </h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => {
              details.type === "Information"
                ? setShowInformationModal(false)
                : details.type === "Copyright"
                ? setShowCopyrightModal(false)
                : null
            }}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="needNewPopup">
            {details?.type === "Information" ? (
              <div className="legalBox-body">
                <p>
                  You warrant and certify in regard to your article publishing
                  by us, that:
                </p>
                <ul className="dotsUl">
                  <li>
                    The Contribution is your original unpublished work and you
                    have all the relevant rights to enter into an Agreement and
                    to convey concerned rights to us for publication of your
                    article as per the terms agreed upon.
                  </li>
                  <li>
                    You have obtained and enclosed all necessary permissions for
                    the reproduction of any copyright works (e.g. quotes,
                    photographs or other visual material, etc.) contained in
                    your Contribution and not owned by you and that you have
                    acknowledged all the source(s).
                  </li>
                  <li>
                    You have made any payments due for any third party
                    permissions and no further payments are required.
                  </li>
                  <li>
                    The Contribution contains no violation of any existing
                    copyright, other third party rights or any defamatory or
                    untrue statements and does not infringe any rights of
                    others.
                  </li>
                </ul>
              </div>
            ) : details?.type === "Copyright" ? (
              <div className="legalBox-body">
                <p>
                  The Copyright in the article submitted by you to us for
                  publication shall be owned by you, based on your
                  representations and warranties. You agree to grant us
                  exclusive rights to publish your article through our
                  website/platform with open access under the CC BY NC 4.0
                  International (Creative Commons Attribution – Non Commercial
                  4.0) License. This means that while you retain the copyright,
                  the content will be free to download, distribute and adapt for
                  non-commercial purposes, given appropriate attribution to the
                  original article.
                </p>
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default LeftCommonLink;
