import { Link } from "react-router-dom";

const scrollLink = (e) => {
  window.scroll({ top: 0, behavior: "smooth" });
};

const SevenMegaJournals = () => {
  return (
    <div className="ag_main_wrap ag_megajournals_wrap">
      <div className="container">
        <div className="ag_megajournals_inner_wrap">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 align-items-center">
              <div className="ag_heading_center_wrap">
                <h2 className="tagh2">Seven Mega Journals</h2>
                <h2 className="tagh2 pt-1">
                  <span>
                    Unlimited Publ
                    <span className="publish-i-dot">i</span>
                    shing Freedom
                  </span>
                </h2>
              </div>
            </div>
            <div className="col-lg-7 col-12">
              <div
                className="ag_megajournals_img_boxes aos-init"
                data-aos="fade-up"
              >
                <ul className="tagul">
                  <li className="tagli">
                      <div className="ag_megajournals_img">
                    <Link to={`/mega-journals/social-sciences`} onClick={scrollLink}>
                        <div className="ag_livesign">
                          <span>Live</span>
                          <img
                            src="assets/images/icon-live.svg"
                            alt="Live Icon"
                            className="tagimg"
                          />
                        </div>
                        <img
                          src="assets/images/samaj-shastra.png"
                          alt="Samaj Shastra"
                          className="tagimg"
                        />
                    </Link>
                      </div>
                  </li>

                  <li className="tagli">
                      <div className="ag_megajournals_img_right">
                    <Link to={`/mega-journals/business-management`} onClick={scrollLink}>
                        <div className="ag_livesign">
                          <span>Live</span>
                          <img
                            src="assets/images/icon-live.svg"
                            alt="Live Icon"
                            className="tagimg"
                          />
                        </div>
                        <img
                          src="assets/images/chanakya-shastra.png"
                          alt="Chanakya Shastra"
                          className="tagimg"
                        />
                    </Link>
                      </div>
                  </li>

                  <li className="tagli">
                      <div className="ag_megajournals_img">
                    <Link to={`/mega-journals/ayurveda-and-yoga`} onClick={scrollLink}>
                        <div className="ag_livesign">
                          <span>Live</span>
                          <img
                            src="assets/images/icon-live.svg"
                            alt="Live Icon"
                            className="tagimg"
                          />
                        </div>
                        <img
                          src="assets/images/charak-shastra.png"
                          alt="Charakh Shastra"
                          className="tagimg"
                        />
                    </Link>
                      </div>
                  </li>
                  <li className="tagli">
                      <div className="ag_megajournals_img_right">
                    <Link to={`/explore-journals/engineering-megajournal-AYSJE`} onClick={scrollLink}>
                        <div className="ag_livesign">
                          <span>Live</span>
                          <img
                            src="assets/images/icon-live.svg"
                            alt="Live Icon"
                            className="tagimg"
                          />
                        </div>
                        <img
                          src="assets/images/abhiyantran-shastra.png"
                          alt="Abhiyantran Shastra"
                          className="tagimg"
                        />
                    </Link>
                      </div>
                  </li>

                  <li className="tagli">
                    <div className="ag_megajournals_img">
                      <Link to={`/explore-journals/information-technology-megajournal-SSJIT`} onClick={scrollLink}>
                      <div className="ag_livesign">
                        <span>Live</span>
                        <img
                          src="assets/images/icon-live.svg"
                          alt="Live Icon"
                          className="tagimg"
                        />
                      </div>
                      <img
                        src="assets/images/soochna-shastra.png"
                        alt="Soochna Shastra"
                        className="tagimg"
                      />
                      </Link>
                    </div>
                  </li>

                  <li className="tagli">
                      <div className="ag_megajournals_img">
                    <Link to={`/mega-journals/ayurveda-and-yoga`} onClick={scrollLink}>
                        <div className="ag_livesign">
                          <span>Live</span>
                          <img
                            src="assets/images/icon-live.svg"
                            alt="Live Icon"
                            className="tagimg"
                          />
                        </div>
                        <img
                          src="assets/images/vigyan-shastra.png"
                          alt="Vigyan Shastra"
                          className="tagimg"
                        />
                    </Link>
                      </div>
                  </li>
                  
                  <li className="tagli">
                    <div className="ag_megajournals_img_right">
                    <Link to={`/explore-journals/Ayurveda-Yoga-megajournal-AJAY`} onClick={scrollLink}>
                      <div className="ag_livesign">
                        <span>Live</span>
                        <img
                          src="assets/images/icon-live.svg"
                          alt="Live Icon"
                          className="tagimg"
                        />
                      </div>
                      <img
                        src="assets/images/ayuryoga-shastra.png"
                        alt="Ayuryoga Shastra"
                        className="tagimg"
                      />
                    </Link>
                    </div>
                  </li>

                  
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-12 p-0">
              <div className="megaRightContent" data-aos="fade-up">
                <p className="paragraph">
                  There are no limits to where your authoring and research
                  skills can take you when it comes to Aakashganga.
                </p>
                <ul className="megaList">
                  <li>
                    <p>
                      <b className="intro">
                        Introducing seven mega-journals including
                        <span className="management"> Social Sciences<b className="colorBlack">,</b> Business & Management<b className="colorBlack">,</b> Medicine<b className="colorBlack">,</b> Engineering<b className="colorBlack">,</b> Information
                        Technology<b className="colorBlack">,</b> Ayurveda and Yoga<b className="colorBlack">,</b> and Sciences</span>
                        .</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      Aakashganga makes access to information as free as in
                      freedom - seamless, connected, and without a single
                      hiccup.
                    </p>
                  </li>
                  <li>
                    <p>
                      Together, we shall break barriers, one research paper at a
                      time. How's that for a cause worth fighting for!
                    </p>
                  </li>
                </ul>
                <div className="col-lg-12 col-12 d-flex justify-content-center mt-3">
              <Link className="ag_btn_red"
                to="/about-megajournals"
                onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
              >
              Know more
              </Link>
            </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default SevenMegaJournals;
