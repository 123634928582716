import { CONTENT_HEADING } from "../../constants";
import Content from "../global/Content";
import { useNavigate,Link } from "react-router-dom";

const AffordableApc = () => {
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  return (
    <Content title={CONTENT_HEADING.AFFORDABLE_COST}>
      <div className="affordableWrap">

      
      <div className="updating-article">
        <p className="pera-text px-lg-0 px-3 tagp">
          Ultimately, actions speak louder than words. We are committed to
          consistently supporting the research community to be able to share
          their research with the world. No researcher should ever have to hold
          their brilliance because they are facing financial constraint.
        </p>

        <p className="pera-text px-lg-0 px-3 tagp">
          We believe that every idea in this world has the scope to expand into
          something that will benefit the mankind. Therefore, we pride ourselves
          on upholding affordable APCs, high-quality publishing standards,
          ethical and transparent practices to achieve this mission.
        </p>

        <p className="pera-text px-lg-0 px-3 tagp">
          We are committed to conveying the value and affordability of the
          Article Processing Charges (APCs) to our researchers. This is crucial
          to us for building trust with you and attracting the right kind of
          submissions.
        </p>

        <p className="pera-text px-lg-0 px-3 tagp">
          That is why we display our pricing transparently, without any hidden
          charges so that you know what you are signing up for. This page is
          also updated regularly to host the current promotions, discounts and
          grants available to you.
        </p>

        <p
          className="pera-text px-lg-0 px-3 custom-affordableApc tagp bold600" 
        >
          We also understand your anxiety around publishing in predatory
          journals. Therefore, please read on to understand how we are genuinely
          committed to supporting researchers worldwide, especially in
          developing and primary countries:
        </p>

        <p className="px-lg-0 px-3 tagp">
          <strong>Global Outreach:</strong> We are striving to break down
          financial barriers for researchers not only in developing countries
          but also around the world. Our open-access model makes research
          articles easily accessible to a worldwide audience, ensuring that your
          work is reaching a broader readership.
        </p>

        <p className="px-lg-0 px-3 tagp">
          <strong>Grants and Scholarships:</strong> We will continuously strive
          to bring strong grants and scholarships that would further reduce
          costs for deserving researchers. You would be able to read and get
          inspired by such stories in our ‘Inspiring Success’ section. Currently
          grants are only available to Indian nationals.
        </p>

        <p className="px-lg-0 px-3 tagp">
          <strong>Discount Variety:</strong> Our low APCs are lowered further
          because of the array of discounts that we will continuously provide,
          including referral discounts, launch discounts, promotional period
          discounts, and institutional discounts amongst others.{" "}
        </p>

        <p className="px-lg-0 px-3 tagp">
          <i><small className="redStar">*</small>You will be able to access these discounts in your Mudra Kosh section
          in your account.</i>
        </p>

        <p className="px-lg-0 px-3 tagp">
          <strong>Collaboration with Institutions:</strong> We also welcome
          partnerships with academic institutions, research organizations and
          societies, which may help contribute to lowering costs for researchers
          affiliated with these institutions.
        </p>

        <p className="px-lg-0 px-3 tagp">
          <strong>Supporting Early-Career Researchers:</strong> Our commitment
          to nurturing young researchers by offering lower costs for Tier 2 and
          Tier 3 cities (in India) affirms our genuine interest in fostering
          academic growth.
        </p>

        <p className="px-lg-0 px-3 tagp">
          <strong>Quality and Impact:</strong> We have automated our platform
          95%, and with technology as our partner, our quality of output is
          high. This means we are able to cut through the manual intervention
          and offer you low APCs while you can build a strong impact by
          publishing with us. Researchers want value for their investment, and
          we are more than willing to bring it to the table.
        </p>

        <p className="px-lg-0 px-3 tagp">
          For more questions on our processes, policies and other aspects,
          please refer to our{" "}
          <Link to="/faq" target="_blank"><strong className='underline'>FAQ section.</strong></Link>
        </p>
      </div>
      </div>
    </Content>
  );
};

export default AffordableApc;
