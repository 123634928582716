import {React, useEffect, useState } from "react";
import { BrowserRouter, Route, Link } from 'react-router-dom';

// import { useLocation } from 'react-router-dom';
const ScrollUp = () => {
  // const location = useLocation();
  // console.log("location",location); 

  // const url = BrowserRouter.getCurrentUrl();

  // Do something with the URL
  // console.log("urlurl",url);

  // const [currentUrl, setCurrentUrl] = useState(window.location.href);

  // useEffect(() => {
  //   var updateUrl = () => setCurrentUrl(window.location.href);
  //   window.addEventListener('popstate', updateUrl);
  //   // console.log("updateUrl", currentUrl);
  //   return () => window.removeEventListener('popstate', updateUrl);
  // }, []);
  
  return (
    <>
   <div
      className="pullUp resize"
      onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })} >
      <b className="icon-arrow-right" />
    </div>
      <div
      className="pullUp resize"
      style={{bottom: "30px"}} 
      onClick={(e) => window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" })}
    >
      <b className="icon-arrow-left" />
    </div>
    </>
  );
};

export default ScrollUp;
