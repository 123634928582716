import { useNavigate,Link } from "react-router-dom";
import SubmitArticleModal from "../Modal/SubmitArticleModal";
import { useState } from "react";
import { isMobile } from 'react-device-detect';
import MobileModalAlert from "../Modal/MobileModalAlert";

const SpecialLaunch = () => {
  const [uploadArticle, setUploadArticle] = useState(false);
  const [mobileAlert, setMobileAlert] = useState(false);
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const token = localStorage.getItem("usertoken");
  return (
    <div className="ag_main_wrap ag_special_offer_wrap  mt-3" id="c" tabindex="1">
      <div className="container">
        <div className="row justify-content-between align-items-center mt-5">
          <div className="col-lg-7 col-12">
            <div
              className="ag_special_offer_img aos-init"
              data-aos="fade-right"
            >
              {/* <p className="tagp">
                <img src="assets/images/subtraction.svg" alt="Bookmark Icon" /> Grab The
                Special Launch Offer
              </p> */}
              <img
                src="assets/images/special-offer.png"
                alt="Special Launch Offer"
                className="ag_big_img tagimg"
              />
            </div>
          </div>
          <div className="col-lg-5 col-12">
            <div
              className="ag_special_offer_text aos-init"
              data-aos="fade-left"
            >
              <div className="ag_heading_wrap">
              <h2 className="tagh2">
              Who Can Publish{" "}
                  <span className="Offers">Use Mudras and Discount Coupons to Pay</span>
                </h2>
              </div>
              <p className="tagp">
              Publish with India’s first multi-discipline Mega Journals and open access journals hosting platform.
              </p>
              <ul className="tagul">
                <li className="tagli">
                  <p className="tagp">
                    <b>Indian Nationals,</b> you pay just a Rs. 1000 initial
                    registration fee. This is refunded to your wallet in full
                    upon publication or rejection.
                  </p>
                  <span>
                    <i>All standard terms and conditions apply.</i>
                  </span>
                </li>
                <li className="tagli">
                  <p className="tagp">
                    <b>Foreign Nationals,</b> you pay just a US $50 initial
                    registration fee. This is refunded to your wallet in full
                    upon publication or rejection.
                  </p>
                </li>
              </ul>
              <div className="ag_special_offer_btn">
                {!token && (
                  <Link 
                  to="/apc"
                  onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
                  style={{width:"fit-content"}}
                    // onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")}
                    className="ag_btn_red w-auto"
                  >
                    Article Publishing Charge
                  </Link>
                )}
                {token && (
                  <Link
                    to="/apc"
                    onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
                    // onClick={() => {
                    //   isMobile ? setMobileAlert(true):setUploadArticle(true);
                    // }}
                    className="ag_btn_red w-auto"
                  >
                    Article Publishing Charge
                  </Link>
                )}
                <p className="tagp">
                  <b className="bold500">Inclusive, Affordable And</b> <i>Open For All</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubmitArticleModal uploadArticle={uploadArticle} setUploadArticle={setUploadArticle} />
      <MobileModalAlert mobileAlert={mobileAlert} setMobileAlert={setMobileAlert} />
    </div>
  );
};

export default SpecialLaunch;
