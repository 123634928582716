import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import Input from "../common/Input";
import Modal from "react-bootstrap/Modal";
import AcceptModal from "../../containers/autherFlow/prdashboard/AcceptModal";
import { addOtherSpecialization } from "../../store/apiCalls/profileDetails";

const OtherSubject = ({ showSubject, setShowSubject, props, other, setOther, subjectId, subjectType }) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState("");
  const { id } = jwt_decode(localStorage.usertoken);
  const modalBody = `<p class="text-center">We will respond within <u>five working days.</u></p>`;
  const [showAccept, setShowAccept] = useState(false);

  const headers = { Authorization: `Bearer ${localStorage.usertoken || ""}` };

  const handleAddOthers = async () => {
    if (other.specialization && !other.disabled) {
      const queryParams = {
        data: {
          specialization: other.specialization,
          userId: id,
          subject: subjectId,
          subjectType: subjectType
        },
        headers,
      };
      dispatch({ type: "SET_LOADER", payload: true });
      const addResp = await addOtherSpecialization(queryParams);
      dispatch({ type: "SET_LOADER", payload: false });
      if (addResp.data?.success) {
        if (typeof props.handleGetSpecializationOption === "function") { 
          props.handleGetSpecializationOption();
        }
        else{
          props()
        }
        setOther({ show: false, specialization: "", disabled: true });
        setShowSubject(false);
        setShowAccept(true);
      }
    } else if (other.disabled) {
      setOther({ ...other, disabled: false });
    }
  };

  useEffect(() => {
    setDisabled(!other?.specialization)
  }, [other])

  return (
    <>
      <Modal
        keyboard={false}
        show={showSubject}
        onHide={() => setShowSubject(false)}
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">Provide Subject Name</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setShowSubject(false)}
          />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="rejectModal">
            <div className="row">
              <div className="col-md-12">
                <Input
                  className={"form-control active-input specialization-input"}
                  value={other?.specialization}
                  handleChange={(e) =>
                    setOther({ ...other, specialization: e.target.value })
                  }
                  type="text"
                  placeholder="Please enter subject..."
                />
              </div>
              <div className="col-md-12 mt-3 text-center">
              <button
                type="button"
                className="button button-primary w-auto"
                onClick={handleAddOthers}
                disabled={disabled}
              >
                SUBMIT
              </button>
              </div>
            </div>
            <div className="noteText">
            <h4>Please Note</h4>
            <ol>
              <li>The subject should be relevant to the Journal</li>
              <li>
                You can complete submission of your article after we approve the
                subject.
              </li>
              <li>
                Once your request is approved or rejected, we will notify you
                through email.
              </li>
            </ol>
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-2 pt-0">
          <div className="contactInfo pt-2">
             
              Add <a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a> to your safe
              sender's list
            
          </div>
        </Modal.Footer>
      </Modal>
      <AcceptModal
        showAccept={showAccept}
        setShowAccept={setShowAccept}
        title={"Request Sent Successfully"}
        body={modalBody}
        btnText="Continue"
      />
    </>
  );
};

export default OtherSubject;
