import React from "react";
import alertSearch from "../../assets/img/icons/alertSearch.svg";
import Modal from "react-bootstrap/Modal";

const GrammarSpellModal = ({
  spellCheckModal,
  setSpellCheckModal,
  mainContent,
  upperContent,
  middleContent,
  lowerContent,
}) => {
  return (
    <Modal
      dialogclassName=""
      show={spellCheckModal}
      id="congratsMobile"
      tabIndex={-1}
      className="authorModal md-x"
      centered
    >
      <Modal.Header className="modal-header">
        <div className="modal-icon">
          <img src={alertSearch} alt="#" />
        </div>
        <h5 className="modal-title p-0">Grammar and Spelling Check feedback</h5>
        <button
          type="button"
          className="btn-close"
          ariaLabel="Close"
          onClick={(e) => setSpellCheckModal(false)}
        ></button>
      </Modal.Header>
      <Modal.Body className="pb-0 pt-0 pl-2 pr-2">
        <div className="grantDetails alertPopup">
          <div className="checkResultText">
            <strong>{mainContent} </strong>
            <p>{upperContent}</p>
            <p>{middleContent}</p>
            <p>{lowerContent}</p>
          </div>
          <div className="mt-4 mb-3">
            <button
              className="button button-primary"
              type="button"
              onClick={(e) => setSpellCheckModal(false)}
            >
              Make Amendments
            </button>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="pb-0">
        <div className="helpPopupText pb-3">
          Need help? Write to us at
          <a href="mailto:contactus@vikramshilaedu.in">
            contactus@vikramshilaedu.in
          </a>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default GrammarSpellModal;
