import { useState } from "react";
import { CONTENT_HEADING } from "../../constants";
import ReactPlayer from "react-player/youtube";
import Content from "../global/Content";
import { useNavigate,Link } from "react-router-dom";
import SubmitArticleModal from "../Modal/SubmitArticleModal";
import { isMobile } from 'react-device-detect';
import MobileModalAlert from "../Modal/MobileModalAlert";
import MobileModalReg from "../Modal/MobileMobleReg";

const RegistrationProcess = () => {
  const [uploadArticle, setUploadArticle] = useState(false);
  const [mobileAlert, setMobileAlert] = useState(false);
  const [mobileAlertReg, setMobileAlertReg] = useState(false);
  const navigate = useNavigate();
	const handleRedirect = (e, path = "/registration") => {
		e.preventDefault();
		window.scroll({ top: 0, behavior: "smooth" });
		navigate(path);
	  };
  const token = localStorage.getItem("usertoken");
  
  return (
    <>
      <Content title={CONTENT_HEADING.REGISTER_ON_PLATFORM}>
        <p className="pera-text regplatform tagp">
          Your research article manuscript will be submitted directly on the
          Aakashganga platform. The first step towards this is creating an
          account with us. This is a simple process that should take under five
          minutes to complete.
        </p>
        <p className="pera-text italic tagp mb-5">
          <span className="red-text bold600">Please Note:</span> that the information we
          gather is never shared with anyone.
        </p>
      </Content>

      <Content title={CONTENT_HEADING.HOW_TO_REGISTER}>
        <div className="center-image videoPageChk">
          <iframe width="560" height="315" className="w-100" src="https://www.youtube.com/embed/w68GPd_pliU?si=GqZAcV60uoc7pfp7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay=1; loop=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" autoplay="true" loop="true" allowfullscreen="true"></iframe>
        </div>
      </Content>

      {/* <Content title={CONTENT_HEADING.ARTICLE_SUBMISSION_PROCESS}> */}
      <h2 className='large-title center-title tagh2 mt-3'>Article Submission Process on <br /> Aakashganga Platform</h2>
        <div className="center-image mb-3 videoPageChk">
          <iframe width="560" height="315" className="w-100" src="https://www.youtube.com/embed/IaPrg-5O-Ks?si=SOkK0XUcwvFDM5hL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" autoplay="true" loop="true" allowfullscreen="true"></iframe>
        </div>

        <p
          className="pera-text customregprocess tagp"
        >
          Ready to submit an article but don’t have an account?
        </p>

        <div className="register-button mt-small">
          <button onClick={(e) =>isMobile ? setMobileAlertReg(true): handleRedirect(e, "/registration")}>Register now</button>
        </div>

        <p className="pera-text tagp">
          From your account, please click the{" "}
          {/* <a href="#" className="underline blackbold">
            Publish Your Article
          </a>{" "} */}
          {!token && (
            <Link
              to="javascript:;"
              onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")}
              className="underline blackbold"
            >
              Publish Your Article
            </Link>
          )}
          {token && (
            <Link
            onClick={() => {
              isMobile ? setMobileAlert(true):setUploadArticle(true);
            }}
              className="underline blackbold"
            >
              Publish Your Article
            </Link>
          )}{" "}
          button. This takes you to the submission details screen. We require
          all the fields filled so that we know exactly what the article is
          about.
        </p>

        <p className="pera-text tagp">
          We request that you click on all the ‘I’ icons to read the
          requirements of each section before providing your details. We have
          tried to provide clear explanations and steps at every level of the
          submission process. In case, you have a question that is not answered,
          please write to us directly at
          <Link
            to="mailto:contactus@vilramshilaedu.in"
            className="underline blackbold"
          >
            {" "}
            contactus@vikramshilaedu.in
          </Link>
        </p>
      {/* </Content> */}
      <SubmitArticleModal uploadArticle={uploadArticle} setUploadArticle={setUploadArticle} />
      <MobileModalAlert mobileAlert={mobileAlert} setMobileAlert={setMobileAlert} />
      <MobileModalReg mobileAlertReg={mobileAlertReg}
    setMobileAlertReg={setMobileAlertReg} />
    </>
  );
};

export default RegistrationProcess;
