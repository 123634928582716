import React from "react";
import { Modal } from "react-bootstrap";

const MobileModalReg = ({ mobileAlertReg, setMobileAlertReg}) => {
  return (
    <div>
      <Modal
        show={mobileAlertReg}
        size="md"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered authorModal"
      >
      <Modal.Header className="modal-header pb-0">
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={(e) => setMobileAlertReg(false)}
        />
        <Modal.Title className="modal-title pl-0">
              <h2 className="fontsizeshastra ml-4">Sign-in/Register</h2>
            </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0 pt-0">
        <div className="grantDetails pl-2 pr-2 mb-3">
          <div className="writeYourContent pb-0 pt-0">
            <p className="mb-0 text-left">
              <p className="yellowTextWelcome blackColorTestRegst"><p className="d-block pb-3 mb-0">Thank you for considering registration with our platform.</p> Please sign-up using your laptop or desk computer for best responsive experience.</p>
            </p>
          </div>
        </div>
      </Modal.Body>
      
    </Modal>
    </div>
   
  );
};

export default MobileModalReg;
