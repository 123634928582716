
import React from 'react';

const Amendments = ({ pagename, index, details, submittedOn}) => {
    return (
        <div className="contentPreview amendments">
            <h2>Amendment {index + 1}<span>Submitted On: <i>{submittedOn}</i></span></h2>
            <p>{details}</p>
        </div>
   
    );
};
export default Amendments