import vikramshilaLogo from "../../assets/img/logos/aakashganga-logos-Final.svg";
import "./Logo.scss";

export default function Logo() {
  return (
    <>
      <div className="logo_container">
        <img src={vikramshilaLogo} alt="Aakashganga Logo" />
      </div>
    </>
  );
}
