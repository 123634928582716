import AakashgangaHeader from "../../components/global/HeaderComponent"
import ProfileSetupHeader from "./ProfileSetupHeader";
import { useLocation, useParams } from "react-router-dom";


function Header() {
  const { pathname } = useLocation()
  
  const { id } = useParams()
  return (
    <>
      {
        ['/personaldetails', '/previewprofile', '/sun-editor', '/submitarticle', `/editarticle/${id}`].indexOf(pathname.toLocaleLowerCase()) > -1 ? <ProfileSetupHeader /> : <AakashgangaHeader />
      }
    </>
  );
}


export default Header;
