import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import doneSvg from "../../../assets/img/icons/done02.svg";
import Confetti from "react-confetti";
import axios from 'axios';
import RejectModal from "../prdashboard/RejectModal";
import RejectModalSociety from "../prdashboard/RejectModalSociety";
import ArticleDetailsModal from "../prdashboard/ArticleDetailsModal";
import ReasonModal from "../prdashboard/ReasonModal";
import Dropdown from "react-bootstrap/Dropdown";
import notyet from "../../../assets/img/icons/notarticle.svg";
import { get15DaysAheadDate, getDateFormat } from "../../../utils";
import { Link, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  acceptRejectArticle,
  getAppointRoleForPE,
  downloadWordArticle,
  reviewArticle,
  updateAppointRoleForPE,
} from "../../../store/apiCalls/dashboard";
import { toast } from "react-toastify";
import { notAcceptedArticleReason } from "../../../store/apiCalls/dashboard";
import ConfirmationalModal from "../prdashboard/ConfirmationModal";

const PEListing = ({
  peData,
  peActiveTab,
  fetchPublishingEditor,
  totalNoOfRecords,
  setUpdateRequestList
}) => {

  // console.log(peData,'peData')
  const MAX_WORDS = 30;
  const navigate = useNavigate();
  const { id } = jwt_decode(localStorage.usertoken);
  const [showArticleDetails, setShowArticleDetails] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [reasonInput, setReasonInput] = useState("");
  const [appointRoleResponse, setAppointRoleResponse] = useState("");
  const [roleStatusResponse, setRoleStatusResponse] = useState("");
  const [showBecomePE, setShowBecomePE] = useState(false);
  const [showAcceptRequest, setShowAcceptRequest] = useState(false);
  const [showRejectRequest, setShowRejectRequest] = useState(false);
  const [showRejectPublishingRole, setShowRejectPublishingRole] =
    useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showSocietyReject, setShowSocietyReject] = useState(false);
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showNotAccepted, setShowNotAccepted] = useState(false);
  const [showReadMore, setShowReadMore] = useState([]);
  const [reasonHandle, setReasonHandle] = useState("");
  const [reasonComments, setReasonComments] = useState("");
  const [articleId, setArticleId] = useState("");
  const [rejectionArticleId, setRejectionArticleId] = useState("");
  const [articleData, setArticleData] = useState([]);
  // console.log(articleData,"articleDataarticleData")
  const [currentData, setCurrentData] = useState([]);
  const [userDetail, setUserDetail] = useState([]);

  const getType = new URLSearchParams(window.location.search).get("type") || "";
  const handleAcceptRejectArticle = async (_id, status) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: _id,
      peStatus: status,
      peId: id,
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      setShowAcceptConfirmation(false);
      setShowAccept(true);
      fetchPublishingEditor("draft");
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleApprovedArchiveArticle = async (_id, status, archiveStatus) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: _id,
      peStatus: status,
      peId: id,
      previousUndoState: archiveStatus,
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      fetchPublishingEditor("approved");
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleRejectedArchiveArticle = async (_id, status, archiveStatus) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: _id,
      peStatus: status,
      peId: id,
      previousUndoState: archiveStatus,
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      fetchPublishingEditor("rejected");
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleNotAcceptedReason = async (articleId) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      userId: id,
      articleId,
      status: "pe",
    };
    const response = await notAcceptedArticleReason(headers, params);
    if (response?.status === 200) {
      setShowNotAccepted(true);
      setReasonHandle(response?.data?.data?.[0]?.reason);
      setReasonComments(response?.data?.data?.[0]?.additionalComment);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const getReviewArticle = async (_id) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      articleId: _id,
    };
    const response = await reviewArticle(headers, params);
    if (response?.status === 200) {
      setArticleData(response?.data?.data);
      setCurrentData(response?.data?.currentStatus);
      setUserDetail(response?.data?.userDetail);
      setShowArticleDetails(true);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleReadMore = (idx) => {
    let result = [...showReadMore];
    result[idx] = !result[idx];
    setShowReadMore(result);
  };

  const handleGetAppointRoleForPE = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      userId: id,
      page: 1,
      limit: 1,
    };
    const response = await getAppointRoleForPE(headers, params);
    if (response?.status === 200) {
      setAppointRoleResponse(response?.data?.data);
      setRoleStatusResponse(response?.data?.data?.[0]?.status);
    } else {
      return toast.error("Please try again!!");
    }
  };
  const handledownloadWordArticleFun = async (headers, params) => {
    return axios.get('https://www.aakashgangaopen.in/AakashGanga/downloadWordFileArticle', {
      headers,
      params,
      responseType: 'blob',  // Ensure the response is in binary format (blob)
    });
  };
  const handledownloadWordArticle = async (articleId) => {
    try {
      const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
      const params = {
        userId: id,
        articleId: articleId,
      };
  
      const response = await handledownloadWordArticleFun(headers, params);
  
      if (response.status === 200) {
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
        
        // Create a hidden link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'article.docx'); // Set a filename for the downloaded file
        document.body.appendChild(link);
  
        // Trigger the download
        link.click();
  
        // Clean up: remove the link and revoke the object URL
        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        return toast.error('Please try again!!');
      }
    } catch (error) {
      console.error('Error downloading document:', error);
      return toast.error('An error occurred while downloading the document!');
    }
  };
  

  const handleUpdateRoleForPE = async (status) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    if (status === "Accepted") {
      const params = {
        id: appointRoleResponse?.[0]?._id,
        status: status,
      };
      const response = await updateAppointRoleForPE(headers, params);
      if (response?.status === 200) {
        setShowBecomePE(false);
        setShowAcceptRequest(true);
        navigate("/dashboard/publishing-editor");
      } else {
        return toast.error("Please try again!!");
      }
    } else if (status === "Rejected") {
      const params = {
        id: appointRoleResponse?.[0]?._id,
        status: status,
        reason: reasonInput,
      };
      const response = await updateAppointRoleForPE(headers, params);
      if (response?.status === 200) {
        setShowRejectPublishingRole(false);
        navigate("/dashboard/publishing-editor");
      } else {
        return toast.error("Please try again!!");
      }
    }
  };

  const getUserDetails = (user) => {
    return (
      user?.name +
      ", " +
      user?.department +
      ", " +
      user?.city +
      ", " +
      user?.state
    );
  };

  useEffect(() => {
    let myArray = new Array(peData?.length).fill(false);
    setShowReadMore(myArray);
  }, [peData]);

  useEffect(() => {
    handleGetAppointRoleForPE();
    if (getType === "publisheditor" && roleStatusResponse == "Pending") {
      setShowBecomePE(true);
    } else if (
      getType === "publisheditor" &&
      (roleStatusResponse == "Accepted" || roleStatusResponse == "Rejected")
    ) {
      setShowBecomePE(false);
    }
  }, [getType, roleStatusResponse]);

  return (
    <>
      <div className="contentListBox">
        {peData && peData?.length > 0 ? (
          peData?.map((editor, index) => {
            const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;

            const contentWithoutSpecialChars = () => {
              return editor?.article[0]?.abstract?.replace(
                specialCharPattern,
                ""
              );
            };

            return (
              <div>
                <div className="contentList">
                  {peActiveTab === "draft" && (
                    <div className="contentListHead">
                      <div className="timeList">
                        <span>
                          <strong>Received On:&nbsp;</strong>
                          {getDateFormat(editor?.createdAt)}
                        </span>
                        <span>
                          <strong>Due By:&nbsp;</strong>
                          {get15DaysAheadDate(editor?.updatedAt, 15)}
                        </span>
                      </div>
                      <span className="t-type mr-0">{editor?.status}</span>
                    </div>
                  )}
                  {peActiveTab === "accepted" && (
                    <div className="contentListHead">
                      <div className="timeList">
                        <span>
                          <strong>Accepted:&nbsp;</strong>
                          {getDateFormat(editor?.createdAt)}
                        </span>
                        <span>
                          <strong>Due By:</strong>&nbsp;
                          {get15DaysAheadDate(editor?.updatedAt, 15)}
                        </span>
                      </div>
                      <div className="timeList">
                        <span className="t-type text-capitalize">{editor?.article[0]?.reviewType?.typeOfReview.replace(/\bonly\b/g, '')}</span>
                        <span className="t-type text-capitalize mr-0">{editor?.status}</span>
                      </div>
                    </div>
                  )}
                  {peActiveTab === "sendForReview" && (
                    <div className="contentListHead">
                      <div className="timeList">
                        <span>
                          <strong>Sent On:&nbsp;</strong>
                          {getDateFormat(editor?.createdAt)}
                        </span>
                      </div>
                      <div className="timeList">
                        <span className="t-type text-capitalize">{editor?.article[0]?.reviewType?.typeOfReview.replace(/\bonly\b/g, '')}</span>
                        <span className="t-type text-capitalize mr-0">{editor?.status}</span>
                      </div>
                    </div>
                  )}
                  {peActiveTab === "peerReview" && (
                    <div className="contentListHead">
                      <div className="timeList">
                        <span>
                          <strong>Review Received:&nbsp;</strong>
                          {getDateFormat(editor?.createdAt)}
                        </span>
                        <span>
                          <strong>Peer Reviewer:&nbsp;</strong>
                          {editor?.prData?.[0]?.title +
                            " " +
                            editor?.prData?.[0]?.fullName}
                        </span>
                      </div>
                      <div className="timeList">
                        <span className="t-type text-capitalize">{editor?.article[0]?.reviewType?.typeOfReview.replace(/\bonly\b/g, '')}</span>
                        <span className="t-type text-capitalize mr-0">{editor?.status}</span>
                      </div>
                    </div>
                  )}
                  {peActiveTab === "approved" && (
                    <div className="contentListHead">
                      <div className="timeList">
                        <span>
                          <strong>Review Received:&nbsp;</strong>
                          {getDateFormat(editor?.createdAt)}
                        </span>
                        <span>
                          <strong>Approved On:&nbsp;</strong>
                          {getDateFormat(editor?.updatedAt)}
                        </span>
                      </div>
                      <div className="timeList">
                        <span className="t-type text-capitalize">{editor?.article[0]?.reviewType?.typeOfReview.replace(/\bonly\b/g, '')}</span>
                        <span className="t-type text-capitalize mr-0">{editor?.status}</span>
                      </div>
                    </div>
                  )}
                  {peActiveTab === "sentForRevision" && (
                    <div className="contentListHead">
                      <div className="timeList">
                        <span>
                          <strong>Review Received:&nbsp;</strong>
                          {getDateFormat(editor?.createdAt)}
                        </span>
                        <span>
                          <strong>Sent for Revision:&nbsp;</strong>
                          {getDateFormat(editor?.updatedAt)}
                        </span>
                      </div>
                      <div className="timeList">
                        <span className="t-type text-capitalize">{editor?.article[0]?.reviewType?.typeOfReview.replace(/\bonly\b/g, '')}</span>
                        <span className="t-type text-capitalize mr-0">{editor?.status}</span>
                      </div>
                    </div>
                  )}
                  {peActiveTab === "rejected" && (
                    <div className="contentListHead">
                      <div className="timeList">
                        <span>
                          <strong>Review Received:&nbsp;</strong>
                          {getDateFormat(editor?.createdAt)}
                        </span>
                        <span>
                          <strong>Rejected On:&nbsp;</strong>
                          {getDateFormat(editor?.updatedAt)}
                        </span>
                      </div>
                      <div className="timeList">
                      <span className="t-type text-capitalize">{editor?.article[0]?.reviewType?.typeOfReview.replace(/\bonly\b/g, '')}</span>
                      <span className="t-type text-capitalize mr-0">{editor?.status}</span>
                      </div>
                    </div>
                  )}

                  {peActiveTab === "notAccepted" && (
                    <div className="contentListHead">
                      <div className="timeList">
                        <span>
                          <strong>Review Received:&nbsp;</strong>
                          {getDateFormat(editor?.createdAt)}
                        </span>
                        <span>
                          <strong>Not Accepted On:&nbsp;</strong>
                          {getDateFormat(editor?.updatedAt)}
                        </span>
                      </div>
                      <div className="timeList">
                        <span className="t-type text-capitalize">{editor?.article[0]?.reviewType?.typeOfReview.replace(/\bonly\b/g, '')}</span>
                        <span className="t-type text-capitalize mr-0">{editor?.status}</span>
                      </div>
                    </div>
                  )}
                  <div className="contentListMidd">
                    {peActiveTab === "draft" ? (
                      <h2
                        onClick={() => {
                          getReviewArticle(editor?.articleId);
                        }}
                      >
                        {editor?.article[0]?.title}
                      </h2>
                    ) : (
                      <h2>
                        <Link
                          to={{
                            pathname: `/dashboard/article/${editor?.articleId}/review-article`,
                          }}
                          state={{
                            _id: editor?.articleId,
                            particularId: editor?._id,
                            typeOfReview:
                              editor?.article?.[0]?.reviewType?.typeOfReview,
                            status: peActiveTab,
                            sendForRevision: editor?.article?.[0]?.sendForRevision
                          }}
                        >
                          {editor?.article[0]?.title}
                        </Link>
                      </h2>
                    )}

                    <div className="authorName">
                      <b>
                        {editor?.user?.[0]?.title +
                          " " +
                          editor?.user?.[0]?.fullName
                        }
                        {editor?.article?.[0]?.authorDetails?.author?.length > 1 && (editor?.article?.[0]?.authorDetails?.author?.map((auth) => auth?.fullName === editor?.user?.[0]?.fullName)) ? ", " : ""}
                      </b>
                      {editor?.article?.[0]?.authorDetails?.author
                        ?.filter(
                          (auth) =>
                            auth?.fullName !== editor?.user?.[0]?.fullName
                        )
                        .map((author, index) => {
                          return (
                            <b>
                              {(index ? ", " : "") +
                                author?.title +
                                " " +
                                author?.fullName}
                            </b>
                          );
                        })}
                    </div>
                    <div className="authorAff">
                      {getUserDetails(
                        editor?.user?.[0]?.affiliation?.affiliations?.[0]
                      )}
                    </div>

                    {(peActiveTab === "draft" || peActiveTab === "accepted") &&
                      contentWithoutSpecialChars()?.split(" ").length >
                      MAX_WORDS ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            contentWithoutSpecialChars()
                              ?.split(" ")
                              ?.slice(0, MAX_WORDS)
                              .join(" ") + "....",
                        }}
                      ></p>
                    ) : (
                      (peActiveTab === "draft" ||
                        peActiveTab === "accepted") && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: contentWithoutSpecialChars()
                              ?.split(" ")
                              ?.slice(0, MAX_WORDS)
                              .join(" "),
                          }}
                        ></p>
                      )
                    )}
                  </div>
                  {peActiveTab === "draft" ? (
                    <div className="footTag d-flex flex-wrap justify-content-center">
                      {editor?.subjectDetail[0]?.subjectName && (
                        <span className="t-tag">
                          {editor?.subjectDetail[0]?.subjectName}
                        </span>
                      )}
                      {editor?.specializations[0]?.specialization && (
                        <span className="t-tag ">
                          {editor?.specializations[0]?.specialization}
                        </span>
                      )}
                      {editor?.article?.[0]?.submissionDetail?.journalName && (
                        <div className="t-tag-journalWrap">
                          <span className="t-tag t-tag-journal">
                            {editor?.article[0]?.submissionDetail?.journalName}
                          </span>
                        </div>
                      )}
                    </div>
                  ) : null}

                  <div
                    className={
                      peActiveTab === "draft"
                        ? "contentListFoot contentListTwo justify-content-end"
                        : "contentListFoot contentListTwo"
                    }
                  >
                    {peActiveTab !== "draft" ? (
                      <div className="footTag d-flex flex-wrap justify-content-center">
                        {editor?.subjectDetail[0]?.subjectName && (
                          <span className="t-tag">
                            {editor?.subjectDetail[0]?.subjectName}
                          </span>
                        )}
                        {editor?.specializations[0]?.specialization && (
                          <span className="t-tag ">
                            {editor?.specializations[0]?.specialization}
                          </span>
                        )}
                        {editor?.article?.[0]?.submissionDetail
                          ?.journalName && (
                            <div className="t-tag-journalWrap">
                              <span className="t-tag t-tag-journal">
                                {
                                  editor?.article[0]?.submissionDetail
                                    ?.journalName
                                }
                              </span>
                            </div>
                          )}
                      </div>
                    ) : null}

                    <div className="footAction">
                      {peActiveTab === "draft" &&
                        editor?.status !== "First Revision" ? (
                        <>
                          <Link
                            to="#"
                            className="button-link"
                            onClick={(e) => {
                              getReviewArticle(editor?.articleId);
                            }}
                          >
                            Take me to the article details
                          </Link>
                          <Link
                            to="#"
                            className="button-link-gray"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowConfirmation(true);
                              setArticleId(editor?._id);
                            }}
                          >
                            Reject for editorial Assessment
                          </Link>
                          {/* <Link
                            to="#"
                            className="button-link-gray"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowSocietyReject(true);
                              setArticleId(editor?._id);
                            }}
                          >
                            Reject Article
                          </Link> */}
                          <Link
                            to="#"
                            className="button button-primary"
                            onClick={() => {
                              setArticleId(editor?._id);
                              setShowAcceptConfirmation(true);
                            }}
                          >
                            Accept for editorial Assessment
                          </Link>
                        </>
                      ) : peActiveTab === "draft" &&
                        editor?.status === "First Revision" ? (
                        <>
                          {editor?.prReview && (
                            <Link
                              to={{
                                pathname: `/dashboard/article/${editor?._id}/view-report`,
                              }}
                              state={{
                                _id: editor?._id,
                                rejectionArticleId: editor?.articleId,
                                title: editor?.article?.[0]?.title,
                                createdAt: editor?.createdAt,
                                updatedAt: editor?.updatedAt,
                                prData: editor?.peData,
                                status: peActiveTab,
                                userType: "Publishing Editor",
                                sendForRevision:
                                  editor?.article?.[0]?.sendForRevision,
                              }}
                              className="button button-primary"
                            >
                              VIEW REPORT
                            </Link>
                          )}

                          <Link
                            to={{
                              pathname: `/dashboard/article/${editor?._id}/review-article`,
                            }}
                            state={{
                              _id: editor?.articleId,
                              particularId: editor?._id,
                              createdAt: editor?.createdAt,
                              updatedAt: editor?.updatedAt,
                              peData: editor?.peData,
                              typeOfReview:
                                editor?.article?.[0]?.reviewType?.typeOfReview,
                              status: peActiveTab,
                              articleStatus: "First Revision",
                              sendForRevision:
                                editor?.article?.[0]?.sendForRevision,
                            }}
                            className="button button-primary"
                          >
                            Review Article
                          </Link>
                        </>
                      ) : peActiveTab === "peerReview" || peActiveTab === "secondReview" && editor?.prReview ? (
                        <>
                        <Link
                         to={{
                          pathname: `/dashboard/article/${editor?._id}/review-article`,
                        }}
                        state={{
                          _id: editor?.articleId,
                          particularId: editor?._id,
                          createdAt: editor?.createdAt,
                          updatedAt: editor?.updatedAt,
                          peData: editor?.peData,
                          typeOfReview:
                            editor?.article?.[0]?.reviewType?.typeOfReview,
                          status: peActiveTab,
                          sendForRevision: editor?.article?.[0]?.sendForRevision,
                        }}
                        className="button button-primary"
                      >
                        VIEW ARTICLE
                      </Link>
                        <Link
                          to={{
                            pathname: `/dashboard/article/${editor?._id}/view-report`,
                          }}
                          state={{
                            _id: editor?._id,
                            rejectionArticleId: editor?.articleId,
                            title: editor?.article?.[0]?.title,
                            createdAt: editor?.createdAt,
                            updatedAt: editor?.updatedAt,
                            prData: editor?.prData,
                            status: peActiveTab,
                            userType: "Publishing Editor",
                            sendForRevision:
                              editor?.article?.[0]?.sendForRevision,
                          }}
                          className="button button-primary"
                        >
                          VIEW REPORT
                        </Link>
                        
                      </>
                      ) : peActiveTab === "sentForRevision" ? (
                        <Link
                          to="#"
                          className="button button-primary"
                          onClick={() => {
                            setShowNotAccepted(true);
                            setArticleId(editor?._id);
                            setRejectionArticleId(editor?.articleId);
                            setReasonHandle(editor?.peSentForRevisionReason);
                            setReasonComments(
                              editor?.peSentForRevisionReasonAdditionalComment
                            );
                          }}
                        >
                          VIEW REASON
                        </Link>
                      ) : peActiveTab === "accepted" ? (
                        <>
                          {/* <Link
                            to={{
                              pathname: `/dashboard/article/${editor?._id}/review-article`,
                            }}
                            state={{
                              _id: editor?.articleId,
                              particularId: editor?._id,
                              createdAt: editor?.createdAt,
                              updatedAt: editor?.updatedAt,
                              peData: editor?.peData,
                              typeOfReview:
                                editor?.article?.[0]?.reviewType?.typeOfReview,
                              status: peActiveTab,
                              sendForRevision: editor?.article?.[0]?.sendForRevision,
                            }}
                            className="button button-primary"
                          >
                            Appoint PR
                          </Link> */}
                          {editor?.article[0]?.reviewType?.typeOfReview==="only post-publication open review"?null:(
                            <div className="customDrop sideDrop dw-auto">
                            <Dropdown>
                              <Dropdown.Toggle className="button button-primary w-100" variant="success">
                                Appoint PR
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  as={Link}
                                  to="/dashboard/society-editor/pr-listing"
                                  state={{
                                    _id: editor?.articleId,
                                    particularId: editor?._id,
                                    createdAt: editor?.createdAt,
                                    updatedAt: editor?.updatedAt,
                                    peData: editor?.peData,
                                    typeOfReview: editor?.article?.[0]?.reviewType?.typeOfReview,
                                    status: peActiveTab,
                                    sendForRevision: editor?.article?.[0]?.sendForRevision,
                                  }}
                                  className="dropdown-item m-0"
                                >
                                  Manual
                                </Dropdown.Item>
                                <Dropdown.Item
                                  // onClick={handleAutomaticAppoint}
                                  className="dropdown-item m-0"
                                >
                                  Automatic
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            </div>
                          )}
                           <Link
                            to={{
                              pathname: `/dashboard/society-editor/${editor?.articleId}/edit-article`,
                            }}
                            // state={{
                            //   _id: editor?.articleId,
                            //   particularId: editor?._id,
                            //   createdAt: editor?.createdAt,
                            //   updatedAt: editor?.updatedAt,
                            //   peData: editor?.peData,
                            //   typeOfReview:
                            //     editor?.article?.[0]?.reviewType?.typeOfReview,
                            //   status: peActiveTab,
                            //   sendForRevision: editor?.article?.[0]?.sendForRevision,
                            // }}
                            className="button button-primary"
                          >
                            Edit Article
                          </Link>
                          <Link
                            to={{
                              pathname: `/dashboard/article/${editor?._id}/review-article`,
                            }}
                            state={{
                              _id: editor?.articleId,
                              particularId: editor?._id,
                              createdAt: editor?.createdAt,
                              updatedAt: editor?.updatedAt,
                              peData: editor?.peData,
                              typeOfReview:
                                editor?.article?.[0]?.reviewType?.typeOfReview,
                              status: peActiveTab,
                              sendForRevision: editor?.article?.[0]?.sendForRevision,
                            }}
                            className="button button-primary"
                          >
                            View Article
                          </Link>
                          <Link
                            // to={{
                            //   pathname: `/dashboard/article/${editor?._id}/review-article`,
                            // }}
                            // state={{
                            //   _id: editor?.articleId,
                            //   particularId: editor?._id,
                            //   createdAt: editor?.createdAt,
                            //   updatedAt: editor?.updatedAt,
                            //   peData: editor?.peData,
                            //   typeOfReview:
                            //     editor?.article?.[0]?.reviewType?.typeOfReview,
                            //   status: peActiveTab,
                            //   sendForRevision: editor?.article?.[0]?.sendForRevision,
                            // }}
                            onClick={() => handledownloadWordArticle(editor?.articleId)}
                            className="button button-primary ms-0"
                          >
                           Download Article
                          </Link>
                        </>
                      ) : peActiveTab === "approved" ? (
                        <>
                        <Link
                         to={{
                          pathname: `/dashboard/article/${editor?._id}/review-article`,
                        }}
                        state={{
                          _id: editor?.articleId,
                          particularId: editor?._id,
                          createdAt: editor?.createdAt,
                          updatedAt: editor?.updatedAt,
                          peData: editor?.peData,
                          typeOfReview:
                            editor?.article?.[0]?.reviewType?.typeOfReview,
                          status: peActiveTab,
                          sendForRevision: editor?.article?.[0]?.sendForRevision,
                        }}
                        className="button button-primary"
                      >
                        VIEW ARTICLE
                      </Link>
                          {editor?.prReview ? (
                            <Link
                              to={{
                                pathname: `/dashboard/article/${editor?._id}/view-report`,
                              }}
                              state={{
                                _id: editor?._id,
                                rejectionArticleId: editor?.articleId,
                                title: editor?.article?.[0]?.title,
                                status: peActiveTab,
                                createdAt: editor?.createdAt,
                                updatedAt: editor?.updatedAt,
                                reasonHandle: editor?.peRejectedReason,
                                userType: "Publishing Editor",
                              }}
                              className="button button-primary"
                            >
                              VIEW REPORT
                            </Link>
                          ) : null}
                        </>
                      ) : peActiveTab === "rejected" ? (
                        <>
                          <Link
                            to="#"
                            className="button button-primary"
                            onClick={() => {
                              setShowNotAccepted(true);
                              setArticleId(editor?._id);
                              setRejectionArticleId(editor?.articleId);
                              setReasonHandle(editor?.peRejectedReason);
                              setReasonComments(
                                editor?.peRejectedReasonAdditionalComment
                              );
                            }}
                          >
                            VIEW REASON
                          </Link>
                        </>
                      ) : peActiveTab === "notAccepted" ? (
                        <Link
                          to="#"
                          className="button button-primary"
                          onClick={() => {
                            handleNotAcceptedReason(editor?.articleId);
                          }}
                        >
                          VIEW REASON
                        </Link>
                      ) : peActiveTab === "sendForReview" ? (<>
                       <Link
                             to={{ pathname: "/dashboard/society-editor/pr-appointment-history" }}
                             state={"accepted"}
                            className="button button-primary"
                          >
                           Appointment History
                          </Link>
                        <Link
                            to={{
                              pathname: `/dashboard/article/${editor?._id}/review-article`,
                            }}
                            state={{
                              _id: editor?.articleId,
                              particularId: editor?._id,
                              createdAt: editor?.createdAt,
                              updatedAt: editor?.updatedAt,
                              peData: editor?.peData,
                              typeOfReview:
                                editor?.article?.[0]?.reviewType?.typeOfReview,
                              status: peActiveTab,
                              sendForRevision: editor?.article?.[0]?.sendForRevision,
                            }}
                            className="button button-primary"
                          >
                            View Article
                          </Link>
                       
                          </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="tabContentWrap">
            <div className="notArticle">
              <figure>
                <img src={notyet} alt="No Article" />
              </figure>
              {peActiveTab === "draft" ? (
                <div className="notArticleDraft">
                  <h2>No articles have arrived yet!</h2>
                  <p>
                    Articles requesting Editorial Assessment will feature here
                    {/* <span>
                      In the meantime, feel free to enjoy this moment by checking out our <u><b>playlists</b></u>. The next great adventure in scholarly exploration awaits...
                    </span> */}
                  </p>

                </div>
              ) : peActiveTab === "accepted" ? (
                <div className="notArticleAccepted">
                  <h2>
                    No accepted manuscripts yet!
                  </h2>
                  <p>
                    You help showcase ground-breaking research and ideas
                    {/* <span>
                      We appreciate the significance of your role in this journey. We aim to streamline your workflow and make the assessment process a breeze.
                    </span> */}
                  </p>

                </div>
              ) : peActiveTab === "sendForReview" ? (
                <div className="notArticleSendFor">
                  <h2>No research articles sent for peer review yet!</h2>
                  <p>
                    No shuffling through piles of paperwork or emails.  
                    {/* <span>
                      Everything you need is just a click away! Thank you for being a part of our vibrant publishing community. Appreciate it...
                    </span> */}
                  </p>

                </div>
              ) : peActiveTab === "peerReview" ? (
                <div className="notArticlePeer">
                  <h2>Awaiting Peer Reviewed Articles</h2>
                  <p>
                    Peer Review reports for your review will land here 
                    {/* <span>
                      All assessment reports in one place will allow you to make well-informed decisions about the research articles under your care.
                    </span> */}
                  </p>

                </div>
              ) : peActiveTab === "approved" ? (
                <div className="notArticleApproved">
                  <h2>No articles have met your approval yet!</h2>
                  <p>
                    All the brilliant research articles shine bright here
                    {/* <span>
                      By accepting these exceptional articles, you are opening doors to a world of knowledge and for new discoveries to flourish...
                    </span> */}
                  </p>

                </div>
              ) : peActiveTab === "sentForRevision" ? (
                <div className="notArticleSentRevision">
                  <h2>No articles sent for revision yet!</h2>
                  <p>
                    Provide valuable feedback that enhances author’s work
                    {/* <span>
                      Your feedback is a potent catalyst, fostering constructive criticism and intellectual growth. It's a win-win situation for everyone!
                    </span> */}
                  </p>

                </div>
              ) : peActiveTab === "rejected" ? (
                <div className="notArticleRejected">
                  <h2>No rejected articles here!</h2>
                  <p>
                    Articles that did not get accepted for publishing will show here
                    {/* <span>
                      In scientific terms, this stage is the Schrödinger's cat, where the articles exist in a superposition, neither accepted nor rejected until they land here...
                    </span> */}
                  </p>

                </div>
              ) : peActiveTab === "notAccepted" ? (
                <div className="notArticleNot">
                  <h2>We respect Your reasons!</h2>
                  <p>
                    Articles you could not accept for assessment will show here 
                    {/* <span>
                      We hope you will soon resume the reviewing process, contributing to the collective advancement of knowledge and scientific literature...
                    </span> */}
                  </p>

                </div>
              ) : null}
            </div>
          </div>
        )}
        {peActiveTab === "draft" && totalNoOfRecords > 1 ? (
          <div className="btnWrap mb-5 mt-4 text-center">
            <Link
              to={{ pathname: "/publishing-editor/requests" }}
              state={"draft"}
              className="button-border"
              onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              View All Drafts
            </Link>
          </div>
        ) : peActiveTab === "accepted" && totalNoOfRecords > 1 ? (
          <div className="btnWrap mb-5 mt-4 text-center">
            <Link
              to={{ pathname: "/publishing-editor/accepted" }}
              state={"accepted"}
              className="button-border"
              onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              View All Accepted
            </Link>
          </div>
        ) : peActiveTab === "sendForReview" && totalNoOfRecords > 1 ? (
          <div className="btnWrap mb-5 mt-4 text-center">
            <Link
              to={{ pathname: "/publishing-editor/review" }}
              state={"sendForReview"}
              className="button-border"
              onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              View All Sent for Review
            </Link>
          </div>
        ) : peActiveTab === "peerReview" && totalNoOfRecords > 1 ? (
          <div className="btnWrap mb-5 mt-4 text-center">
            <Link
              to={{ pathname: "/publishing-editor/peerReview" }}
              state={"peerReview"}
              className="button-border"
              onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              View All Peer Review
            </Link>
          </div>
        ) : peActiveTab === "approved" && totalNoOfRecords > 1 ? (
          <div className="btnWrap mb-5 mt-4 text-center">
            <Link
              to={{ pathname: "/publishing-editor/approved" }}
              state={"approved"}
              className="button-border"
              onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              View All Approved
            </Link>
          </div>
        ) : peActiveTab === "sentForRevision" && totalNoOfRecords > 1 ? (
          <div className="btnWrap mb-5 mt-4 text-center">
            <Link
              to={{ pathname: "/publishing-editor/revision" }}
              state={"sentForRevision"}
              className="button-border"
              onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              View All Sent for Revision
            </Link>
          </div>
        ) : peActiveTab === "rejected" && totalNoOfRecords > 1 ? (
          <div className="btnWrap mb-5 mt-4 text-center">
            <Link
              to={{ pathname: "/publishing-editor/rejected" }}
              state={"rejected"}
              className="button-border"
              onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              View All Rejected
            </Link>
          </div>
        ) : peActiveTab === "notAccepted" && totalNoOfRecords > 1 ? (
          <div className="btnWrap mb-5 mt-4 text-center">
            <Link
              to={{ pathname: "/publishing-editor/notAccepted" }}
              state={"notAccepted"}
              className="button-border"
              onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              View All Not Accepted
            </Link>
          </div>
        ) : null}
      </div>

      <Modal
        dialogClassName="modal-dialog-centered"
        className="md-x authorModal"
        show={showBecomePE}
        id="congratsMobile"
        tabIndex={-1}
      >
        <Modal.Header className="modal-header">
          <div class="modal-icon"> </div>
          <h2 class="modal-title">BECOME A PUBLISHING EDITOR</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowBecomePE(false)}
          ></button>
        </Modal.Header>

        <Modal.Body>
          <div className="aaforAss text-left pl-2 pr-2">
            <p>
              You are the chosen one! We think that your profile would be
              perfect for the role of Publishing Editor for the{" "}
              {appointRoleResponse?.[0]?.journal}.
            </p>
            <p className="mt-4">
              Would you like to accept the request to become a Publishing
              Editor?
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="button-link-gray mr-3"
            onClick={() => {
              setShowBecomePE(false);
              setShowRejectRequest(true);
            }}
          >
            REJECT
          </span>
          <button
            className="button button-primary sm ml-3"
            onClick={() => {
              handleUpdateRoleForPE("Accepted");
            }}
          >
            ACCEPT
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showAcceptRequest}

        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      ><Confetti width={"450px"} height={"300px"} numberOfPieces={100} />
        <Modal.Header className="modal-header">
          <div class="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h2 class="modal-title">CONGRATULATIONS</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowAcceptRequest(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAss text-left pl-4 pr-2">
            <p>
              You are now a Publishing Editor for the{" "}
              {appointRoleResponse?.[0]?.journal}
            </p>
            <p className="mt-4">
              You can access the Publishing Editor dashboard from the Dashboard
              dropdown on the left panel.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <button
            class="button button-primary"
            onClick={() => setShowAcceptRequest(false)}
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showRejectRequest}
        id="congratsMobile"
        tabIndex={-1}
      >
        <Modal.Header className="modal-header">
          <h2 class="modal-title">REJECT REQUEST</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowRejectRequest(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAss">
            <p className="pl-2 ml-2">
              Are you sure you want to reject the <br /> request to become a
              Publishing Editor?
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-gray bold mr-3"
            onClick={() => {
              setShowRejectRequest(false);
              setShowRejectPublishingRole(true);
            }}
          >
            YES, REJECT
          </span>
          <button
            class="button button-primary"
            onClick={() => setShowRejectRequest(false)}
          >
            NO, DON'T REJECT
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showRejectPublishingRole}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header">
          <h2 class="modal-title">Reject Publishing editor role</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowRejectPublishingRole(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pb-0">
          <div className="rejectModal">
            <div className="row">
              <div className="col-md-12">
                <div className="fieldWrap">
                  <label className="labelForm">
                    Please specify your reason <i className="reason-optional"> (Optional)</i>
                  </label>

                  <textarea
                    className="inputField mt-0"
                    placeholder="Enter your response here..."
                    onChange={(e) => setReasonInput(e.target.value)}
                    value={reasonInput}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <button
            class="button button-primary"
            onClick={() => handleUpdateRoleForPE("Rejected")}
          >
            SUBMIT
          </button>
        </Modal.Footer>
      </Modal>

      <ConfirmationalModal
        setShowReject={setShowReject}
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        title={"Confirmation"}
        body={`<div class="text-center allGoodText01"><p>Are you sure you want to reject this article for editorial assessment?</div></p>`}
        leftBtnTxt={"REJECT"}
        rightBtnTxt={"MY DASHBOARD"}
      />

      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showAcceptConfirmation}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header">
          <h2 class="modal-title">Good to know</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowAcceptConfirmation(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAss text-center">
            <p className="allGoodText02">
              Are you sure you want to accept this article for editorial
              assessment?
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-active bold mr-3"
            onClick={() => setShowAcceptConfirmation(false)}
          >
            MY DASHBOARD
          </span>
          <button
            class="button button-primary"
            onClick={() => handleAcceptRejectArticle(articleId, "accepted")}
          >
            ACCEPT
          </button>
        </Modal.Footer>
      </Modal>
      <RejectModalSociety
        showReject={showReject}
        setShowReject={setShowReject}
        articleId={articleId}
        articleType={peData[0]?.article[0]?.reviewType?.typeOfReview} 
        fetchPublishingEditor={fetchPublishingEditor}
        userId={id}
        setUpdateRequestList={setUpdateRequestList}
      />
      {/* <RejectModalSociety
        showReject={showSocietyReject}
        setShowReject={setShowSocietyReject}
        articleId={articleId}
        fetchPublishingEditor={fetchPublishingEditor}
        userId={id}
        setUpdateRequestList={setUpdateRequestList}
      /> */}
      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showAccept}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      > <Confetti width={"450px"} height={"350px"} numberOfPieces={100} />
        <Modal.Header className="modal-header">
          <div class="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h2 class="modal-title">ACCEPTED FOR EDITORIAL ASSESSMENT</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowAccept(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="acceptedArticle text-left">
            <p>Thank You! We truly appreciate you</p>
            <p className="mb-4">taking the time out to review this article.</p>
           
            <p>
              Please find this article in the{" "}
              <Link 
              // to="/publishing-editor/accepted"
               state={"accepted"}>
              Editor Acceptance
              </Link>{" "}
              section of your dashboard.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <button
            class="button button-primary"
            onClick={() => setShowAccept(false)}
          >
            OKAY
          </button>
        </Modal.Footer>
      </Modal>
      <ReasonModal
        showNotAccepted={showNotAccepted}
        setShowNotAccepted={setShowNotAccepted}
        reasonHandle={reasonHandle}
        reasonComments={reasonComments}
        peActiveTab={peActiveTab}
        _id={articleId}
        rejectionArticleId={rejectionArticleId}
      />
      {peActiveTab === "draft" && (
        <ArticleDetailsModal
          showArticleDetails={showArticleDetails}
          setShowArticleDetails={setShowArticleDetails}
          articleData={articleData}
          currentData={currentData}
          userDetail={userDetail}
          status={"Publishing Editor"}
        />
      )}
    </>
  );
};

export default PEListing;
