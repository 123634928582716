
import React from 'react';
import {useSelector } from "react-redux";

const AccesbilityContent = ({ pagename,details}) => {

    const isLoggedIn = useSelector(
        (state) => state?.LoginReducer?.userLoginDetails?.token ?? null
      );

    return (
        <div className={isLoggedIn? 'commanBox':' commanBox'}>
            <div className="contentPreview leftSideContent mb-0">
                <h2>{details.title}</h2>
                <p>{details.desc}</p>
            </div>
        </div>
   
    );
};
export default AccesbilityContent