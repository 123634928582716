import React, { useEffect, useState } from "react";
import { Pagination } from "semantic-ui-react";
import Modal from "react-bootstrap/Modal";
import {
    LinkedinShareButton,
    TwitterShareButton,
    FacebookShareButton,
  } from "react-share";
import { useDispatch, useSelector } from "react-redux";
import notyet from "../../../assets/img/icons/notarticle.svg";
import { recommendedArticles, getJournalDetailById, getLatestArticles, getMostViewedArticle, getLastBrowseArticle, getJournalDetail, getJournalBySpecialization } from "../../../store/apiCalls/dashboard";
import { getSubjectSpecialization } from '../../../store/apiCalls/profileDetails';
import { getJournalSubjects } from "../../../store/apiCalls/articles";
import RelatedArticles from "../../../components/viewArticle/relatedArticles";
import NewRelatedArticles from "../../../components/viewArticle/newRelatedArticles";
import Select from "../../../components/common/Select";
import AakashgangaHeader from "../../../components/global/HeaderComponent";
import AakashgangaFooter from "../../../components/global/FooterComponent";
import SearchDropdown from "../../../components/SearchDropdown/SearchDropdown";
import coin from "../../../assets/img/icons/coinT.svg";
import { Link } from "react-router-dom";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";
import backArrow from "../../../assets/img/icons/blueArrow.svg";
import { Helmet } from 'react-helmet';
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { SERVER_URL } from "../../../config";
import copy from "copy-to-clipboard";

function articleLandingmega() {
    const params = useParams();
     
    const { id } = params;
    
    const SHARE_URL = `${SERVER_URL}/mega-journals/${id}/all-articles`;
    const navigate = useNavigate();
    const masterData = useSelector((state) => state.MasterDataReducer.MasterData);
    const [collapseActive, setIsActive] = useState(false);
    const location = useLocation();
    const journalName = location?.state?.journalName;
    const journalId = location?.state?.journalId;

    const [modalJournalName, setJournalModalName] = useState(journalName);
    const title = `I recommend that you checkout ${modalJournalName}. The process of submission is fully automated and it offers affordable APCs, and a very short turnaround time.`;
    const userDetails = useSelector((state) => state?.LoginReducer?.user);
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const [recommendedPage, setRecommendedPage] = useState(1);
    const [latestPage, setLatestPage] = useState(1);
    const [mostViewedPage, setMostViewedPage] = useState(1);
    const dispatch = useDispatch();
    const [oldToNew, setOldToNew] = useState(-1);
    const [articleData, setArticleData] = useState([]);
    const [mostViewedArticleData, setMostViewedArticle] = useState([]);
    const [lastBrowseArticleData, setLastBrowseArticle] = useState([]);
    const limit = 10;
    const recomdLimit = 5;
    const MAX_WORDS = 30;
    const [recommendedList, setRecommendedList] = useState([]);
    const [journalData, setJournalData] = useState([]);
    const [societyjournalData, setSocietyJournalData] = useState([]);
    const [journalOptions, setJournalOptions] = useState([]);
    const [allDisciplineData, setAllDisciplineData] = useState([]);
    const [disciplineOptions, setDisciplineOptions] = useState([]);
    const [disciplineOption, setDisciplineOption] = useState([]);
    const [specializationOptions, setSpecializationOptions] = useState([]);

    const [activeTab, setActiveTab] = useState("recent");
    const [activeType, setActiveType] = useState("all-articles");
    const [show, setShow] = useState(false);
    const [showRec, setShowRec] = useState(false);
    const [showAdvertising, setShowAdvertising] = useState(false);
    const handleClose = () => setShow(false);
    const [sideMenuDiscipline, setSideMenuDiscipline] = useState('')
    const [sideMenuType, setSideMenuType] = useState('')
    const [sideMenuYear, setSideMenuYear] = useState('')
    const [selectedName, setSelectedName] = useState('')

    const [articleTypeToggle, setArticleTypeToggle] = useState(false);
    const [publicationYearToggle, setPublicationYearToggle] = useState(false);
    const [journalToggle, setJournalToggle] = useState(true);
    const [disciplineToggle, setDisciplineToggle] = useState(true);
    const [readingListData, setReadingListData] = useState({
        discipline: "",
        subject: [],
        type: [],
    });

    const [filter, setFilter] = useState(false);
    const [recommendedDivSct, setRecommendedDivSct] = useState(false);
    const [totalRecomndedList, setTotalRecomndedList] = useState("");
    const [latestArticlesCount, setLatestArticlesCount] = useState("");
    const [mostViewedArticleCount, setMostViewedArticleCount] = useState("");
    
    let recommendedTotalPages = Math.ceil(totalRecomndedList / recomdLimit);
    let latestArticlesTotalPages = Math.ceil(latestArticlesCount / limit);
    let currentViewArticle = latestPage * limit;
    let currentViewArticleFinal = currentViewArticle > latestArticlesCount ? latestArticlesCount : currentViewArticle;
    let pastViewArticle = currentViewArticle - limit < 1 ? 1 : currentViewArticle - limit;
    let mostViewedArticleTotalPages = Math.ceil(mostViewedArticleCount / limit);

    const publicationYear = [
        { key: 'All', value: "All" },
        { key: '2021', value: "2021" },
        { key: '2022', value: "2022" },
        { key: '2023', value: "2023" },
        { key: '2024', value: "2024" },
        { key: '2025', value: "2025" },
    ]
    const sidePublicationYear = [
        { key: '2021', value: "2021" },
        { key: '2022', value: "2022" },
        { key: '2023', value: "2023" },
        { key: '2024', value: "2024" },
        { key: '2025', value: "2025" }];
    const handleCodeCopy = (e) => {
            copy(SHARE_URL);
            e.target.textContent = "Copied";
            e.target.style.setProperty("color", "#ffffff");
          };
    const fetchLatestArticlesData = (newlatestPage) => {
        dispatch({ type: "SET_LOADER", payload: true });
        const fetchdata = async () => {
            const params = {
                page: newlatestPage ? newlatestPage : latestPage,
                limit: limit,
                societyId: societyjournalData?._id,
                userId: userDetails?._id,

            };
            return getLatestArticles(headers, params);
        };
        fetchdata()
            .then((ArticleRes) => {
                if (ArticleRes.status === 200 && ArticleRes?.data) {
                    dispatch({ type: "SET_LOADER", payload: false });
                    setArticleData(ArticleRes?.data);
                    setLatestArticlesCount(ArticleRes?.data?.totalCount);
                }
            })
            .catch((err) => console.log(err));
        dispatch({ type: "SET_LOADER", payload: false });
    };
    const fetchConferencePaper = (newlatestPage) => {
        dispatch({ type: "SET_LOADER", payload: true });
        const fetchdata = async () => {
            const params = {
                page: newlatestPage ? newlatestPage : latestPage,
                limit: limit,
                societyId: societyjournalData?._id,
                conferenceId: true,
                userId: userDetails?._id,

            };
            return getLatestArticles(headers, params);
        };
        fetchdata()
            .then((ArticleRes) => {
                if (ArticleRes.status === 200 && ArticleRes?.data) {
                    dispatch({ type: "SET_LOADER", payload: false });
                    setArticleData(ArticleRes?.data);
                    setLatestArticlesCount(ArticleRes?.data?.totalCount);
                }
            })
            .catch((err) => console.log(err));
        dispatch({ type: "SET_LOADER", payload: false });
    };

    const getRecommendedArticles = async () => {
        dispatch({ type: "SET_LOADER", payload: true });
        setRecommendedList([''])
        const params = () => {
            const data = {
                userId: userDetails?._id,
                page: recommendedPage,
                limit: recomdLimit,
            };
            if (readingListData?.journal != '') {
                data.journal = readingListData?.journal,
                    data.journalRequest = true
            }
            if (readingListData?.discipline != '' || sideMenuDiscipline) {
                data.subject = sideMenuDiscipline ? sideMenuDiscipline : readingListData?.discipline
                if (sideMenuDiscipline) {
                    data.subjectRequest = true
                }
            }
            if (readingListData?.subject != '') {
                data.specialization = readingListData?.subject
                    ?.map((sub) => {
                        return specializationOptions?.find((val) => val?.specialization === sub)
                            ?._id;
                    })
                    .join(",")
            }
            if (sideMenuType || readingListData?.type != '') {
                data.type = sideMenuType != '' ? sideMenuType : readingListData?.type
                    .map((sub) => {
                        return masterData?.typeOfArticledata?.find(
                            (val) => val?.articleName === sub
                        )?._id;
                    })
                    .join(",")
                if (sideMenuType) {
                    data.typeRequest = true
                }
            }
            if (sideMenuYear || readingListData?.year) {
                data.publishYear = sideMenuYear ? sideMenuYear : readingListData?.year === "All" ? "" : readingListData?.year
                if (sideMenuYear) {
                    data.publishYearRequest = true
                }
            }

            if (oldToNew) {
                data.sorting = oldToNew;
            }
            return data;
        };
        const response = await recommendedArticles({}, params());
        if (response?.status === 200) {
            dispatch({ type: "SET_LOADER", payload: false });
            setTotalRecomndedList(response?.data?.totalCount);
            setRecommendedList(response?.data?.data);
        } else {
            dispatch({ type: "SET_LOADER", payload: true });
            return toast.error("Please try again!!");
        }
    };

    const fetchMostViewedArticlesData = (newlatestPage) => {
        dispatch({ type: "SET_LOADER", payload: true });
        const fetchdata = async () => {
            const params = {
                page: newlatestPage ? newlatestPage : latestPage,
                limit: limit,
                societyId: societyjournalData?._id,
                userId: userDetails?._id,
            };
            return getMostViewedArticle({}, params);
        };
        fetchdata()
            .then((res) => {
                if (res.status === 200 && res?.data) {
                    dispatch({ type: "SET_LOADER", payload: false });
                    // console.log("res?.data?.data===>",res?.data?.data);
                    setArticleData(res?.data);
                    setLatestArticlesCount(res?.data?.totalCount);
                    // console.log("articleData",articleData)
                }
            })
            .catch((err) => console.log(err));
        dispatch({ type: "SET_LOADER", payload: false });
    };

    const fetchLastBrowseArticleData = () => {
        dispatch({ type: "SET_LOADER", payload: true });
        const fetchdata = async () => {
            const params = {
                userId: userDetails?._id,
            };
            return getLastBrowseArticle(headers, params);
        };
        fetchdata()
            .then((res) => {
                if (res.status === 200 && res?.data) {
                    dispatch({ type: "SET_LOADER", payload: false });
                    setLastBrowseArticle(res?.data?.data?.data);
                }
            })
            .catch((err) => console.log(err));
        dispatch({ type: "SET_LOADER", payload: false });
    };
    const handleRedirect = (e, path) => {
        if (e !== "") e.preventDefault();
        window.scroll({ top: 0, behavior: "smooth" });
        navigate(path);
    };
    const resumeArticle = () => {
        const articleId = lastBrowseArticleData?.[0]?.article?.[0]?._id
        handleRedirect("", `/viewPublishedArticle/${articleId}`);
    };

    const getResponseOptions = () => {
        let journal = [];
        let allDiscipline = []
        journalData &&
            journalData.forEach((element) => {
                journal.push(element);
                allDiscipline.push(element.subjectData)
            });
        setJournalOptions(journal);
        return setJournalOptions(journal);
    };
    const getAllDiscipleResponse = () => {
        let allDiscipline = []
        journalData &&
            journalData.forEach((element) => {
                allDiscipline.push(element.subjectData[0].subject)
            });
        return setAllDisciplineData(allDiscipline);
    };

    const getJournalData = async () => {
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const params = {
            journalType: masterData?.jouranlData?.[0]?._id,
        };
        const response = await getJournalDetail(headers, params);
        if (response?.status === 200) {
            setJournalData(response?.data?.data);
        } else return;
    };
    const getJournalDataSociety = async () => {
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const params = {
            journal: (id === 'social-sciences' ? 'Samaj Shastra – The Mega Journal of Social Sciences'
                : id === 'business-management' ? 'Chanakya Shastra – The Mega Journal of Business and Management'
                    : id === 'medicine' ? 'Charakh Shastra – The Mega Journal of Medicine'
                        : id === 'engineering' ? 'Abhiyantran Shastra – The Mega Journal of Engineering'
                            : id === 'information-technology' ? 'Soochna Shastra – The Mega Journal of Information Technology'
                                : id === 'sciences' ? 'Vigyan Shastra – The Mega Journal of Sciences' :
                                    id === "ayurveda-and-yoga" ? 'AyurYoga Shastra – The Mega Journal of Ayurveda and Yoga' :
                                        id === "society-institute-university" ? 'Society Journals – The Society Journal of Sciences' : null),
        };
        const response = await getJournalDetailById(headers, params);
        if (response?.status === 200) {
            // console.log("getJournalDataSociety==>",response?.data?.data);
            setSocietyJournalData(response?.data?.data);
        } else return;
    };
    const getJournalSubjectByJornlId = async (JrnlName) => {
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const queryParams = {
            params: { journalName: JrnlName },
            headers: headers,
        };
        const response = await getJournalSubjects(queryParams);
        if (response?.status === 200) {
            setSpecializationOptions(response?.data?.data?.specilisation);
        } else return;
    };
    const getSpecializationBySubjt = async () => {
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const params = {
            subjectId: sideMenuDiscipline ? sideMenuDiscipline : readingListData?.discipline
        };
        const response = await getSubjectSpecialization(headers, params);
        if (response?.status === 200) {
            return setSpecializationOptions(response?.data?.data.data);
        } else {
            return toast.error("Please try again!!");
        }
    };
    const getJournalDiscipline = () => {
        const masterSubject = [];
        journalData?.forEach((element, k) => {
            const subject = [];
            element?.subjectData?.forEach((element, p) => {
                const data = element.subject;
                subject.push(data);
            });
            masterSubject[element.journalName] = subject;
        });
        return setDisciplineOptions(masterSubject);
    }


    // const handleSelectChange = (value, type) => {
    //     setRecommendedDivSct(true);
    //     let newReadingListData = { ...readingListData };
    //     if (type === "journal") {
    //         newReadingListData = { ...readingListData, subject: [] };
    //         setSpecializationOptions(specializationOptions[value]);
    //         setDisciplineOption(disciplineOptions[value]);
    //         getJournalSubjectByJornlId(value)
    //     }
    //     setSideMenuDiscipline('')
    //     setSideMenuType('')
    //     setSideMenuYear('')
    //     return setReadingListData({ ...newReadingListData, [type]: value });
    // };
    // const handleYearSelectChange = (value, type) => {
    //     let newReadingListData = { ...readingListData };
    //     return setReadingListData({ ...newReadingListData, [type]: value });
    // }
    // const handleSelectDisciplineChange = (value, type) => {
    //     let newReadingListData = { ...readingListData };
    //     if (type === "discipline") {
    //         setRecommendedDivSct(true);
    //         newReadingListData = { ...readingListData, discipline: "" };
    //     }
    //     return setReadingListData({ ...newReadingListData, [type]: value });
    // };
    // function formatDate(dateString) {
    //     const date = new Date(dateString);
    //     const day = date.getDate();
    //     const month = date.toLocaleString('default', { month: 'long' });
    //     const year = date.getFullYear();

    //     // Add the "th" suffix to the day
    //     let daySuffix = "th";
    //     if (day === 1 || day === 21 || day === 31) {
    //         daySuffix = "st";
    //     } else if (day === 2 || day === 22) {
    //         daySuffix = "nd";
    //     } else if (day === 3 || day === 23) {
    //         daySuffix = "rd";
    //     }

    //     return ` ${month} ${day},  ${year}`;
    // }

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     const cloneSubject = { ...readingListData };
    //     if (name === "subject") {
    //         if (cloneSubject.subject.includes(value)) {
    //             cloneSubject.subject.splice(cloneSubject.subject.indexOf(value), 1);
    //         } else {
    //             if (cloneSubject.subject.length < 3) {
    //                 cloneSubject.subject.push(value);
    //             }
    //         }
    //     } else if (name === "type") {
    //         if (cloneSubject?.type.includes(value)) {
    //             cloneSubject?.type?.splice(cloneSubject?.type?.indexOf(value), 1);
    //         } else {
    //             if (cloneSubject?.type.length < 3) {
    //                 cloneSubject.type.push(value);
    //             }
    //         }
    //     }
    //     setReadingListData(cloneSubject);
    // }
    // const handleSideTypeChange = (name, value) => {
    //     setRecommendedDivSct(true);
    //     setReadingListData({
    //         // journal: "",
    //         type: [],
    //     })
    //     setSideMenuType(value)
    //     getRecommendedArticles()
    // }
    // const toggleType = () => {
    //     if (articleTypeToggle == true) {
    //         setArticleTypeToggle(false)
    //     }
    //     else {
    //         setArticleTypeToggle(true)
    //     }
    // }
    // const togglePublicationYear = () => {
    //     if (publicationYearToggle == true) {
    //         setPublicationYearToggle(false)
    //     }
    //     else {
    //         setPublicationYearToggle(true)
    //     }
    // }
    // const toggleJournal = () => {
    //     if (journalToggle == true) {
    //         setJournalToggle(false)
    //     }
    //     else {
    //         setJournalToggle(true)
    //     }
    // }
    // const toggleDiscipline = () => {
    //     if (disciplineToggle == true) {
    //         setDisciplineToggle(false)
    //     }
    //     else {
    //         setDisciplineToggle(true)
    //     }
    // }

    // const handleSideMenuChange = (name, value, valueName) => {
    //     let e = 'RelatedArticle'
    //     setRecommendedDivSct(true);
    //     setSelectedName(valueName);
    //     if (name === "discipline") {
    //         setReadingListData({
    //             journal: "",
    //             subject: [],
    //             type: [],
    //         })
    //         setSideMenuType('')
    //         setSideMenuYear('')
    //         setSideMenuDiscipline(value)
    //     }
    //     else if (name === "type") {
    //         setReadingListData({
    //             journal: "",
    //             subject: [],
    //             type: [],
    //         })
    //         setSideMenuDiscipline('')
    //         setSideMenuYear('')
    //         setSideMenuType(value)
    //     }
    //     else if (name === "year") {
    //         setReadingListData({
    //             journal: "",
    //             year: "",
    //             subject: [],
    //             type: [],
    //         })
    //         setSideMenuDiscipline('')
    //         setSideMenuType('')
    //         setSideMenuYear(value)
    //     }
    //     const element = document.getElementById(`viewScroll${e}`);
    //     if (element) {
    //         element.scrollIntoView({ behavior: 'smooth' });
    //     }
    // }
    const getArticleType = (type) => {
        setLatestPage(1);
        if (type == "recent") {
            fetchLatestArticlesData(1);
        } else if (type == "mostRead") {
            fetchMostViewedArticlesData(1);
        } else if (type == "mostViewed") {
            fetchMostViewedArticlesData(1);
        } else if (type == "conferencePapers") {
            fetchConferencePaper(1);
        }
    }

    // const getSpecializationData = async () => {
    //     const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    //     const params = {
    //         journalName: readingListData?.journal ? readingListData?.journal : ""
    //     };
    //     const response = await getJournalBySpecialization(headers, params);
    //     let journalName = response?.data?.data?.data?.[0]?.journalName;
    //     let result = journalName.substring(0, 8);
    //     if (response?.status === 200) {
    //         if (result === "AyurYoga") {
    //             let firstArray = response?.data?.data?.specilisation?.[0];
    //             let anotherArray = response?.data?.data?.specilisation?.[1];
    //             let finalArray = [...firstArray, ...anotherArray]
    //             setSpecializationOptions(finalArray);
    //         } else {
    //             setSpecializationOptions(response?.data?.data?.specilisation)
    //         }
    //     } else return;
    // };

    const clearFilter = () => {
        setFilter(true);
        setReadingListData({
            journal: readingListData.journal,
            specialization: [],
            subject: [],
            type: [],
            year: "",
        });
    };
    const handleBack = () => {
        setFilter(true);
        setReadingListData({
            journal: "",
            specialization: [],
            subject: [],
            type: [],
            year: "",
        });
        setSideMenuDiscipline('')
        setSideMenuType('')
        setSideMenuYear('')
        setRecommendedDivSct(false)
        window.scroll({ top: 0, behavior: "smooth" });
    }
    const specialCharPattern = /[!@#$%^*(),?;":{}|\u00a0]/g;

    const contentWithoutSpecialChars = (data) => {
        return data.replace(specialCharPattern, "");
    };
    useEffect(() => {
        getJournalData();
        getJournalDataSociety();
    }, [masterData]);

    useEffect(() => {
        getJournalDiscipline();
        getResponseOptions();
        getAllDiscipleResponse();
    }, [journalData]);

    useEffect(() => {
        if (readingListData?.discipline || sideMenuDiscipline) {
            getSpecializationBySubjt();
        }
    }, [readingListData, sideMenuDiscipline])

    useEffect(() => {
        getRecommendedArticles();
    }, [recommendedPage, oldToNew, readingListData.journal, sideMenuDiscipline, sideMenuType, sideMenuYear]);

    useEffect(() => {
        fetchLatestArticlesData();
    }, [societyjournalData]);

    useEffect(() => {
        fetchLastBrowseArticleData();
    }, [])

    // useEffect(() => {
    //     fetchMostViewedArticlesData();
    // }, [mostViewedPage]) 
    // console.log("articleRes", societyjournalData);
    return <>
        <Helmet>
            <title>Access Articles on Aakashganga </title>
            <meta name="description" content="Browse and access articles on Aakashganga Open to explore a diverse range of scholarly content. Our collection of open access articles covering various disciplines and topics." />

            <meta property="og:title" content="Access Articles on Aakashganga" data-react-helmet="true" />
            <meta property="og:description" content="Browse and access articles on Aakashganga Open to explore a diverse range of scholarly content. Our collection of open access articles covering various disciplines and topics." data-react-helmet="true" />
            <meta property="og:url" content="https://www.aakashgangaopen.in/access-articles" data-react-helmet="true" />

            <link rel="canonical" href="https://www.aakashgangaopen.in/access-articles" data-react-helmet="true" />
            <meta name="robots" content="index, follow" />
        </Helmet>
        <AakashgangaHeader />
        <div className="centerSection bg-white">
            <div className="societymainhead mb-4">
            <Link to={`/mega-journals/${id}`} > <img src={societyjournalData?.masthead} className="w-100 " alt="" /></Link>
            </div>
            {/* <div class="dsmar-25 ">
                <div className="journalShadow">
                <div className="ag_menu justify">
                <ul className="ag_menu ag_menuSub justify tagul">
                    <li>
                      <a href="javascript:;" class="custom-journal taga">
                
                        <span className=" text-black text-uppercase" style={{}}>
                          {" "}
                          {""}
                          {societyjournalData?.journalType?.journalType === "Society Journal"
                            ? societyjournalData?.subjectData?.[0]?.subject?.subjectName
                            : societyjournalData?.subjectData?.[0]?.subject?.subjectName}
                        </span>
                      </a>
                    </li>
                
                    <li className="active tagli custom-journal">
                      <Link
                        to="/open-access"
                        onClick={(e) => handleRedirect(e, "/open-access")}
                        className="custom-journal taga"
                      >
                        OPEN SCIENCE
                      </Link>
                    </li>
                    <li>
                      <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" class="custom-journal taga">
                     <img style={{width: '100px', maxWidth: '100%', transform:'none'}} src={coin} alt="#" />
                
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;" class="custom-journal taga">
                        E-ISSN{" "}
                        <span className="colr">
                          {" "}
                          {""}
                          {societyjournalData?.journalType?.journalType === "Society Journal"
                            ? societyjournalData?.issnNumber
                            : societyjournalData?.issnNumber}
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;" class="custom-journal taga">
                        ABBR{" "}
                        <span className="colr">
                          {" "}
                          {""}
                          {societyjournalData?.journalType?.journalType === "Society Journal"
                            ? societyjournalData?.abbreviation
                            : societyjournalData?.abbreviation}
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;" class="custom-journal taga">
                      FIRST PUBLISHED{" "}
                        <span className="colr">
                          {" "}
                          {""}
                          <div dangerouslySetInnerHTML={{__html:formatDate(societyjournalData?.createdAt)}}></div>
                
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                          </div>
            </div> */}
            <div className="row justify-content-center bg-white">
                <div className="col-md-9">
                    <div className="callpprlivebox px-4 limited-width bg-white mw-100 d-flex align-items-center gap-5">
                        <p>We are inviting Publishing Editors to join our Mega Journals</p>
                        <Link className="ag_btn_red signup-btn-journal taga m-0 mw-250" to="/registration">Register</Link>
                    </div>
                </div>
            </div>
            <div className="container-fluid maindiv d-block bg-white">
                <div className="articleHead m-0 py-4">
                    <h2>Access Articles</h2>
                    <p>{pastViewArticle}-{currentViewArticleFinal ? currentViewArticleFinal : "1"} of {latestArticlesCount ? latestArticlesCount : "1"} articles</p>
                </div>
                <div className="row justify-content-between" >
                    <div className="col-md-6">
                        <div className="cusSearch">
                            <input
                                type="search"
                                placeholder="Search for journals and articles"
                                // value={search}
                                onChange={(e) => {
                                    //   setSearch(e.target.value);
                                    //   searchJournalArticle(e.target.value);
                                }}
                            // onKeyDown={(e) => { (e.key === "Enter" && search != '') ? (navigate(`/search-result/${search}`), setSearch("")) : "" }}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <Link to={`/mega-journals/${id}`} className="underline_or_head m-0">Journal Homepage</Link>
                    </div>
                    <div className="col-md-12 pt-4">
                        <p><i className="text-grey">Articles are listed in sequence of most recent publication.</i></p>
                    </div>
                </div>
            </div>

            <div className="container-fluid maindiv d-block bg-white pt-4">
                <div className="row">
                    <div className="col-md-9" >
                        <div className="customTabs">
                            <Tab.Container defaultActiveKey="recent">
                                <Nav>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="recent"
                                            onClick={() => [
                                                setActiveTab("recent"),
                                                getArticleType("recent"),
                                            ]}
                                        >
                                            Recent
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="mostRead"
                                            onClick={() => [
                                                setActiveTab("mostRead"),
                                                getArticleType("mostRead"),
                                            ]}
                                        >
                                            Most Read
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="mostViewed"
                                            onClick={() => [
                                                setActiveTab("mostViewed"),
                                                getArticleType("mostViewed"),
                                            ]}
                                        >
                                            Most Viewed
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="conferencePapers"
                                            onClick={() => [
                                                setActiveTab("conferencePapers"),
                                                getArticleType("conferencePapers"),
                                            ]}
                                        >
                                            Conference Papers
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    {activeTab === "recent" && (
                                        <Tab.Pane eventKey="recent">
                                            <div className="row recommendedMain" >
                                                {articleData && articleData?.data?.length > 0 ? (
                                                    articleData?.data?.map((articleRes, index) => {

                                                        return (
                                                            <div className="col-md-12">
                                                                <NewRelatedArticles
                                                                    index={index}
                                                                    published={articleRes?.updatedAt}
                                                                    id={articleRes?.readinglistId}

                                                                    title={articleRes?.title}
                                                                    slug={articleRes?.slug}
                                                                    userTitle={
                                                                        articleRes?.user?.[0]?.title
                                                                    }
                                                                    pageName={"access-articles"}
                                                                    userName={
                                                                        articleRes?.authorDetails?.author
                                                                    }
                                                                    {...articleRes?.authorDetails?.author?.map((autName) => {
                                                                        return (
                                                                            <>
                                                                                {autName.title}
                                                                                {autName.fullName}
                                                                            </>
                                                                        );
                                                                    })}
                                                                    contentWithoutSpecialChars={contentWithoutSpecialChars(articleRes?.abstract)}
                                                                    typeOfArticle={articleRes?.typeofarticles?.[0]?.articleName}
                                                                    journalName={
                                                                        articleRes?.submissionDetail?.journalName
                                                                    }
                                                                    reviewType={
                                                                        articleRes?.reviewType?.typeOfReview
                                                                    }
                                                                    journalType={
                                                                        articleRes?.journalType?.[0]?.journalType
                                                                    }
                                                                    subjectName={articleRes?.subjectDetail?.[0]?.subjectName}
                                                                    specialization={articleRes?.specializations?.[0]?.specialization}
                                                                    fetchReadingList={fetchLatestArticlesData}
                                                                    readingList={articleData}
                                                                    articleId={articleRes?._id}
                                                                    readingflag={articleRes?.readingflag}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    <div className="tabContentWrap">
                                                        <div className="notArticle">
                                                            <figure>
                                                                <img src={notyet} alt="No Article" />
                                                            </figure>
                                                            <h2>No articles yet!</h2>

                                                        </div>
                                                    </div>
                                                )}
                                                {latestArticlesTotalPages > 1 && (
                                                    <div className="paginationWrap mt-4">
                                                        <Pagination
                                                            boundaryRange={1}
                                                            firstItem={null}
                                                            lastItem={null}
                                                            siblingRange={1}
                                                            activePage={latestPage}
                                                            prevItem={{ content: <b class="icon-arrow-left" /> }}
                                                            nextItem={{ content: <b class="icon-arrow-right" /> }}
                                                            onPageChange={(event, data) => { setLatestPage(data.activePage), fetchLatestArticlesData(data.activePage) }}
                                                            totalPages={latestArticlesTotalPages}
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                        </Tab.Pane>
                                    )}
                                    {activeTab === "mostRead" && (
                                        <Tab.Pane eventKey="mostRead">
                                            <div className="row recommendedMain" >
                                                {articleData && articleData?.data?.length > 0 ? (
                                                    articleData?.data?.map((articleRes, index) => {

                                                        return (
                                                            <div className="col-md-12">
                                                                <NewRelatedArticles
                                                                    index={index}
                                                                    published={articleRes?.updatedAt}
                                                                    id={articleRes?.readinglistId}

                                                                    title={articleRes?.title}
                                                                    slug={articleRes?.slug}
                                                                    userTitle={
                                                                        articleRes?.user?.[0]?.title
                                                                    }
                                                                    pageName={"access-articles"}
                                                                    userName={
                                                                        articleRes?.user
                                                                    }
                                                                    {...articleRes?.authorDetails?.author?.map((autName) => {
                                                                        return (
                                                                            <>
                                                                                {autName.title}
                                                                                {autName.fullName}
                                                                            </>
                                                                        );
                                                                    })}
                                                                    contentWithoutSpecialChars={contentWithoutSpecialChars(articleRes?.abstract)}
                                                                    typeOfArticle={articleRes?.typeofarticles?.[0]?.articleName}
                                                                    journalName={
                                                                        articleRes?.submissionDetail?.journalName
                                                                    }
                                                                    reviewType={
                                                                        articleRes?.reviewType?.typeOfReview
                                                                    }
                                                                    journalType={
                                                                        articleRes?.journalType?.[0]?.journalType
                                                                    }
                                                                    subjectName={articleRes?.subjectDetail?.[0]?.subjectName}
                                                                    specialization={articleRes?.specializations?.[0]?.specialization}
                                                                    fetchReadingList={fetchLatestArticlesData}
                                                                    readingList={articleData}
                                                                    articleId={articleRes?._id}
                                                                    readingflag={articleRes?.readingflag}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    <div className="tabContentWrap">
                                                        <div className="notArticle">
                                                            <figure>
                                                                <img src={notyet} alt="No Article" />
                                                            </figure>
                                                            <h2>No articles yet!</h2>

                                                        </div>
                                                    </div>
                                                )}
                                                {latestArticlesTotalPages > 1 && (
                                                    <div className="paginationWrap mt-4">
                                                        <Pagination
                                                            boundaryRange={1}
                                                            firstItem={null}
                                                            lastItem={null}
                                                            siblingRange={1}
                                                            activePage={latestPage}
                                                            prevItem={{ content: <b class="icon-arrow-left" /> }}
                                                            nextItem={{ content: <b class="icon-arrow-right" /> }}
                                                            onPageChange={(event, data) => { setLatestPage(data.activePage), fetchMostViewedArticlesData(data.activePage) }}
                                                            totalPages={latestArticlesTotalPages}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </Tab.Pane>
                                    )}
                                    {activeTab === "mostViewed" && (
                                        <Tab.Pane eventKey="mostViewed">
                                            <div className="row recommendedMain" >
                                                {articleData && articleData?.data?.length > 0 ? (
                                                    articleData?.data?.map((articleRes, index) => {

                                                        return (
                                                            <div className="col-md-12">
                                                                <NewRelatedArticles
                                                                    index={index}
                                                                    published={articleRes?.updatedAt}
                                                                    id={articleRes?.readinglistId}

                                                                    title={articleRes?.title}
                                                                    slug={articleRes?.slug}
                                                                    userTitle={
                                                                        articleRes?.user?.[0]?.title
                                                                    }
                                                                    pageName={"access-articles"}
                                                                    userName={
                                                                        articleRes?.user
                                                                    }
                                                                    {...articleRes?.authorDetails?.author?.map((autName) => {
                                                                        return (
                                                                            <>
                                                                                {autName.title}
                                                                                {autName.fullName}
                                                                            </>
                                                                        );
                                                                    })}
                                                                    contentWithoutSpecialChars={contentWithoutSpecialChars(articleRes?.abstract)}
                                                                    typeOfArticle={articleRes?.typeofarticles?.[0]?.articleName}
                                                                    journalName={
                                                                        articleRes?.submissionDetail?.journalName
                                                                    }
                                                                    reviewType={
                                                                        articleRes?.reviewType?.typeOfReview
                                                                    }
                                                                    journalType={
                                                                        articleRes?.journalType?.[0]?.journalType
                                                                    }
                                                                    subjectName={articleRes?.subjectDetail?.[0]?.subjectName}
                                                                    specialization={articleRes?.specializations?.[0]?.specialization}
                                                                    fetchReadingList={fetchLatestArticlesData}
                                                                    readingList={articleData}
                                                                    articleId={articleRes?._id}
                                                                    readingflag={articleRes?.readingflag}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    <div className="tabContentWrap">
                                                        <div className="notArticle">
                                                            <figure>
                                                                <img src={notyet} alt="No Article" />
                                                            </figure>
                                                            <h2>No articles yet!</h2>

                                                        </div>
                                                    </div>
                                                )}
                                                {latestArticlesTotalPages > 1 && (
                                                    <div className="paginationWrap mt-4">
                                                        <Pagination
                                                            boundaryRange={1}
                                                            firstItem={null}
                                                            lastItem={null}
                                                            siblingRange={1}
                                                            activePage={latestPage}
                                                            prevItem={{ content: <b class="icon-arrow-left" /> }}
                                                            nextItem={{ content: <b class="icon-arrow-right" /> }}
                                                            onPageChange={(event, data) => { setLatestPage(data.activePage), fetchMostViewedArticlesData(data.activePage) }}
                                                            totalPages={latestArticlesTotalPages}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </Tab.Pane>
                                    )}
                                    {activeTab === "conferencePapers" && (
                                        <Tab.Pane eventKey="conferencePapers">
                                            <div className="row recommendedMain" >
                                                {articleData && articleData?.data?.length > 0 ? (
                                                    articleData?.data?.map((articleRes, index) => {

                                                        return (
                                                            <div className="col-md-12">
                                                                <NewRelatedArticles
                                                                    index={index}
                                                                    published={articleRes?.updatedAt}
                                                                    id={articleRes?.readinglistId}

                                                                    title={articleRes?.title}
                                                                    slug={articleRes?.slug}
                                                                    userTitle={
                                                                        articleRes?.user?.[0]?.title
                                                                    }
                                                                    pageName={"access-articles"}
                                                                    userName={
                                                                        articleRes?.user
                                                                    }
                                                                    {...articleRes?.authorDetails?.author?.map((autName) => {
                                                                        return (
                                                                            <>
                                                                                {autName.title}
                                                                                {autName.fullName}
                                                                            </>
                                                                        );
                                                                    })}
                                                                    contentWithoutSpecialChars={contentWithoutSpecialChars(articleRes?.abstract)}
                                                                    typeOfArticle={articleRes?.typeofarticles?.[0]?.articleName}
                                                                    journalName={
                                                                        articleRes?.submissionDetail?.journalName
                                                                    }
                                                                    reviewType={
                                                                        articleRes?.reviewType?.typeOfReview
                                                                    }
                                                                    journalType={
                                                                        articleRes?.journalType?.[0]?.journalType
                                                                    }
                                                                    subjectName={articleRes?.subjectDetail?.[0]?.subjectName}
                                                                    specialization={articleRes?.specializations?.[0]?.specialization}
                                                                    fetchReadingList={fetchLatestArticlesData}
                                                                    readingList={articleData}
                                                                    articleId={articleRes?._id}
                                                                    readingflag={articleRes?.readingflag}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    <div className="tabContentWrap">
                                                        <div className="notArticle">
                                                            <figure>
                                                                <img src={notyet} alt="No Article" />
                                                            </figure>
                                                            <h2>No articles yet!</h2>

                                                        </div>
                                                    </div>
                                                )}
                                                {latestArticlesTotalPages > 1 && (
                                                    <div className="paginationWrap mt-4">
                                                        <Pagination
                                                            boundaryRange={1}
                                                            firstItem={null}
                                                            lastItem={null}
                                                            siblingRange={1}
                                                            activePage={latestPage}
                                                            prevItem={{ content: <b class="icon-arrow-left" /> }}
                                                            nextItem={{ content: <b class="icon-arrow-right" /> }}
                                                            onPageChange={(event, data) => setLatestPage(data.activePage)}
                                                            totalPages={latestArticlesTotalPages}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </Tab.Pane>
                                    )}
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div class="journalslist custom-journalist-class">
                            <div class=" align-items-center">
                                <div class="ag_heading_center_wrap mb-3 our-partner mt-0 info-custom">
                                    <h2 class="tagh2">Browse Articles</h2>
                                </div>
                            </div>
                            <ul class="journalInfoList">
                                <li class="mb-0 text-center fw-semibold px-0 tagli"><a className="text-black" href="javascript:;">Type</a></li>
                                <li class="mb-0 text-center fw-semibold px-0 tagli"><a className="text-black" href="javascript:;">Journal</a></li>
                                <li class="mb-0 text-center fw-semibold px-0 tagli"><a className="text-black" href="javascript:;">Subject</a></li>
                                <li class="mb-0 text-center fw-semibold px-0 tagli"><a className="text-black" href="javascript:;">Discipline</a></li>
                                <li class="mb-0 text-center fw-semibold px-0 tagli"><a className="text-black" href="javascript:;">Publication Year</a></li>
                            </ul>
                        </div>
                        <div class="journalslist mb-4 mt-4 main-class-publish">
                            <div class="footer-align-bottom">
                            </div>
                            <h2 class="font22 custom-24 tagh2 text-center">Get in Touch</h2>
                            <a class="ag_btn_red signup-btn-journal taga w-auto" href="/contact-us">Click Here</a>
                        </div>
                        <div className="journalslist custom-journalist-class mb-5">
                            <div className=" align-items-center">
                                <div className="ag_heading_center_wrap our-partner mt-0 info-custom">
                                    <h2 className="tagh2">Information</h2>
                                </div>
                            </div>
                            <ul className="journalInfoList">
                                <li class="custom-li-journal tagli text-center px-0">
                                    <Link
                                        to={`/mega-journals/${id}`}
                                        className={activeType === "description" ? "active" : null}
                                        onClick={(e) => {setActiveType("description"),handleRedirect(e, `/mega-journals/${id}`)}}
                                    >
                                        Description
                                    </Link>
                                </li>
                                <li class="custom-li-journal tagli text-center px-0">
                                    <Link
                                        // to={"all-articles"}
                                        className={
                                            activeType === "all-articles" ? "active" : null
                                        }
                                        onClick={() => setActiveType("all-articles")}
                                    >
                                        Access Articles
                                    </Link>
                                </li>
                                <li class="custom-li-journal tagli text-center px-0">
                                    <Link
                                        // to={`/mega-journals/${id}/editorial-board`}
                                        className={activeType === "editorial-board" ? "active" : null}
                                        onClick={(e) => {setActiveType("editorial-board"),handleRedirect(e, `/mega-journals/${id}/editorial-board`)}}
                                    >
                                        Editorial Board
                                    </Link>
                                </li>
                                <li class="custom-li-journal tagli text-center px-0">
                                    <Link
                                        to="/author-submission-guidelines"
                                        target="_blank"
                                        className={
                                            activeType === "author-guidelines" ? "active" : null
                                        }
                                    >
                                        Author Guidelines
                                    </Link>
                                </li>
                               

                                <li class="custom-li-journal tagli text-center px-0">
                                    <Link
                                        className={
                                            activeType === "content-type" ? "active" : null
                                        }
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveType("content-type");
                                            setShow(true);
                                        }}
                                    >
                                        Content Type
                                    </Link>
                                </li>
                                <li class="custom-li-journal tagli text-center px-0">
                                    <Link
                                        className={activeType === "recommend" ? "active" : null}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveType("recommend");
                                            setShowRec(true);
                                            // setModalPageName(pageName);
                                            // setJournalModalName(journalName);
                                        }}
                                    >
                                        Recommend
                                    </Link>
                                </li>
                                <li class="custom-li-journal tagli">
                      <Link
                        to="/how-we-publish"
                        target="_blank"
                        className={
                          activeType === "publishing-model" ? "active" : null
                        }
                      >
                        How We Publish
                      </Link>
                    </li>
                                <li class="custom-li-journal tagli text-center px-0">
                                    <Link
                                        className={activeType === "advertising" ? "active" : null}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveType("advertising");
                                            setShowAdvertising(true);
                                        }}
                                    >
                                        Advertise With Us
                                    </Link>
                                </li>
                                <li class="custom-li-journal tagli text-center px-0" >
                                    <Link
                                        to="/journal/get-in-touch"
                                        target="_blank"
                                        className={
                                            activeType === "get-in-touch" ? "active" : null
                                        }
                                    >
                                        Get in Touch
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {/* <div className="pageLeft position-relative">
                            <div class="pageMenu text-initial">
                                <div className="browseBy">
                                    BROWSE BY
                                </div>
                                <h2 className={journalToggle == true ? "sideTitle subnav active" : "sideTitle subnav"} onClick={toggleJournal}>Journal<i></i></h2>
                                <div className="pageMenu-link">
                                    <ul className={journalToggle == true ? "" : "d-none"}>
                                        {journalOptions && journalOptions?.map((val) => {
                                            return (
                                                <>
                                                    <li onClick={(value) => handleSelectChange(val?.journalName, "journal")}><a class={readingListData?.journal == val?.journalName ? "active" : ""}>{val?.journalName}</a></li>
                                                </>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <h2 className={disciplineToggle == true ? "sideTitle subnav active " : "sideTitle subnav"} onClick={toggleDiscipline}>Discipline<i></i></h2>
                                <div className="pageMenu-link">
                                    <ul className={disciplineToggle == true ? "" : "d-none"}>
                                        {allDisciplineData && allDisciplineData?.map((val) => {
                                            return (
                                                <>
                                                    <li onClick={(value) => handleSideMenuChange("discipline", val?._id, val?.subjectName)}><a class={sideMenuDiscipline == val?._id ? "active" : ""}>{val?.subjectName}</a></li>
                                                </>
                                            )
                                        })}
                                    </ul>
                                </div>

                                <h2 className={articleTypeToggle == true ? "sideTitle subnav active " : "sideTitle subnav"} onClick={toggleType}>Type<i></i></h2>
                                <div className="pageMenu-link">
                                    <ul className={articleTypeToggle == true ? "" : "d-none"}>
                                        {masterData?.typeOfArticledata?.map(
                                            (article) => {
                                                return (
                                                    <>
                                                        <li onClick={(value) => handleSideMenuChange("type", article._id, article?.articleName)}><a class={sideMenuType == article?._id ? "active" : ""}>{article?.articleName}</a></li>
                                                    </>
                                                )
                                            })}
                                    </ul>
                                </div>

                                <h2 className={publicationYearToggle == true ? "sideTitle subnav active " : "sideTitle subnav"} onClick={togglePublicationYear}>Publication Year<i></i></h2>
                                <div className="pageMenu-link border-bottom-0">
                                    <ul className={publicationYearToggle == true ? "yearLink" : "d-none"}>
                                        {sidePublicationYear?.map((item) => {
                                            return (
                                                <>
                                                    <li onClick={(value) => handleSideMenuChange("year", item?.value, item?.value)}>
                                                        <Link class={sideMenuYear == item?.value ? "active" : ""}>{item?.value}</Link></li>
                                                </>
                                            )
                                        }
                                        )}
                                    </ul>
                                </div>

                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        <Modal
          show={showAdvertising}
          size="lg"
          backdrop="static"
          keyboard={false}
          centered
          aria-labelledby="contained-modal-title-vcenter markitPopupRecomendBox"
          className="markitPopupRecomendBox"
        >
          <Modal.Header
            className="justify-content-center closeButtonStyle"
          >
            <Modal.Title className="modal-title pl-0">
              <h2 className="fontsizeshastra ml-2">Advertise With Us</h2>
            </Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowAdvertising(false);
                setActiveType("description");
              }}
              aria-label="Close"
            />
          </Modal.Header>
          <Modal.Body className="pt-0 pb-2">
            <div className="advertisingModal">
              <img src="/assets/images/advertising.png" alt="advertising" />
              <div className="advertisingContent">
                <span className="colorBlack">
                  For advertising inquiries, write to us at
                </span>{" "}
                <a href="mailto:contactus@vikramshilaedu.in">
                  contactus@vikramshilaedu.in
                </a>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter "
          centered
          className="markitPopupBox"
        >
          <Modal.Header
            className="justify-content-center closeButtonStyle"
            closeButton
            onClick={() => setActiveType("description")}
          >
            <Modal.Title className="modal-title pl-0">
              <h2 className="fontsizemod ml-2">
                Aakashganga currently accepts the following types of research
                articles for publishing
              </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0 pb-2">
            <div className="px-2 text-center">
              <p className="f-14">
                <ul className="contentModal">
                  <li className="section">
                    <strong>Original Research:</strong> These are original
                    research studies presenting new findings and insights in any
                    field of science or medicine.
                  </li>
                  <li className="efforts">
                    <strong>Review Articles:</strong> These provide
                    comprehensive and critical reviews of existing research,
                    summarizing and analysing the current state of knowledge on
                    a specific topic.
                  </li>
                  <li className="efforts">
                    <strong>Systematic Review:</strong> A comprehensive and
                    structured method of synthesizing and analyzing existing
                    research studies and evidence on a specific research
                    question or topic.
                  </li>
                  <li className="efforts">
                    <strong>Methodology Articles:</strong> These describe new
                    methods or techniques developed for research or data
                    analysis. Methodology articles focus on the technical
                    aspects of research and often include detailed protocols or
                    algorithms.
                  </li>
                  <li className="efforts">
                    <strong>Data Articles:</strong> These focus primarily on
                    data sets, describing and providing access to large data
                    collections or databases. Data articles may include data
                    descriptions, data analyses, and data visualization.
                  </li>
                  <li className="efforts">
                    <strong>Case Reports:</strong> These describe unique or
                    interesting medical or clinical cases that contribute to the
                    understanding of a particular disease, treatment, or
                    condition.
                  </li>
                  <li className="efforts">
                    <strong>Short Reports:</strong> These are concise research
                    papers presenting new and significant findings but with a
                    shorter format compared to full research articles.
                  </li>
                  <li className="efforts">
                    <strong>Perspectives or Opinions:</strong> These articles
                    express the authors’ perspectives, opinions, or commentaries
                    on a specific scientific, social, or ethical issue.
                  </li>
                  <li className="efforts">
                    <strong>Conceptual Articles:</strong> These articles propose
                    new concepts, frameworks, or theoretical models, often based
                    on existing research or literature.
                  </li>
                  <li className="efforts">
                    <strong>Commentaries:</strong> Commentaries offer critical
                    analysis or perspective on previously published research or
                    current issues within a field.
                  </li>
                  <li className="efforts">
                    <strong>Book Reviews:</strong> These articles evaluate and
                    critique recently published books relevant to the scope of
                    the journal.
                  </li>
                  <li className="efforts">
                    <strong>Conference Papers:</strong> Academics can publish their conference papers on Aakashganga, showcasing original research that unveils new discoveries and insights across the seven Mega Journals on the platform.
                  </li>
                </ul>
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="pt-3 pb-3 contactModalFooter"></Modal.Footer>
        </Modal>

        <Modal
          show={showRec}
          onHide={() => setShowRec(false)}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="markitPopupRecomendBox"
        >
          <Modal.Header
            className="justify-content-center closeButtonStyle"
            closeButton
            onClick={() => setActiveType("description")}
          >
            <Modal.Title className="modal-title">
              <h2 className="fontsizeshastra">Recommend </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0 pb-2 text-left recommendModalBox">
            <strong>Share it on:</strong>{" "}
            <ul className="ag_ftr_socials contentModal">
              <li>
                <FacebookShareButton
                  url={SHARE_URL}
                  title={title}
                  hashtags={"aakashganga"}
                >
                  <b>
                    <img
                      src="/assets/images/icon-facebook.svg"
                      alt="Facebook Icon"
                    />
                  </b>
                </FacebookShareButton>
              </li>
              <li>
                <TwitterShareButton
                  url={SHARE_URL}
                  title={title}
                  hashtag={"aakashganga"}
                >
                  <b>
                    <img
                      src="/assets/images/icon-twitter.svg"
                      alt="Twitter Icon"
                    />
                  </b>
                </TwitterShareButton>
              </li>
              <li>
                <LinkedinShareButton url={SHARE_URL} title={title}>
                  <b>
                    <img
                      src="/assets/images/icon-linkedIn.svg"
                      alt="LinkedIn Icon"
                    />
                  </b>
                </LinkedinShareButton>
              </li>
            </ul>
            <hr className="journalHr" />
            <strong>Copy Link:</strong>{" "}
            <div className="dis">
              <div class="ag_newsletter_input widrec">
                <input
                  type=""
                  value={SHARE_URL}
                  placeholder="Copy Link"
                ></input>
              </div>
              <a
                href="javascript:;"
                onClick={handleCodeCopy}
                className=" ag_btn_red ag_btn_redclr copy"
              >
                Copy
              </a>
            </div>
          </Modal.Body>
          <Modal.Footer className="pt-3 pb-3 contactModalFooter"></Modal.Footer>
        </Modal>
        <AakashgangaFooter />
    </>

}
export default articleLandingmega
